import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Instructions from './instructions';

class InstructionsController extends Component {
	constructor(props) {
		super(props);
		this.state = {instructionsStatus: ''};
		this.handleShowInstructions = this.handleShowInstructions.bind(this);
		this.toggleInstructions = this.toggleInstructions.bind(this);
		this.timeout = null;
	};

	/**
	 * Component did mount
	 * Automatically show instructions if game is not started
	 */
	componentDidMount() {
		this.handleShowInstructions();
	}

	/**
	 * Component did update
	 * Hide/show instructions when navigating to new page
	 * @param {object} prevProps 
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.page.id !== this.props.page.id) {
			this.setState({instructionsStatus: ''});
			setTimeout(() => {
				this.handleShowInstructions();
			}, 700);
		}
	}
	
	/**
	 * Component will unmount
	 */
	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	/**
	 * Determine whether to show instructions automatically
	 */
	handleShowInstructions() {
		let showInstructions = true;
		if (
			/* Do not show if user is not teacher or if game is not ready */
			!this.props.isTeacher || !this.props.game ||
			/* Do not show if active game and already seen */
			(
				this.props.game.id && 
				this.props.game.instructions && 
				this.props.game.instructions[this.props.page.id]
			)
		) {showInstructions = false;}

		if (!this.props.isTeacher) {
			showInstructions = true;
			/* Do not show if student + already seen (only for minigames, cyoa games, wave simulators, investigations)*/
			if ((
				this.props.group &&
				this.props.group.instructions &&
				this.props.group.instructions[this.props.page.id])
				/* Do not show if it's a normal menu page */
				|| this.props.page.id.includes('page')
			) { showInstructions = false; }
		}

		if (showInstructions) {
			this.timeout = setTimeout(() => {
				this.setState({instructionsStatus: 'show'});
				/* Teacher */
				if (this.props.isTeacher && this.props.game && this.props.game.id && this.props.updateGame) {
					let instructions = (this.props.game.instructions
						? JSON.parse(JSON.stringify(this.props.game.instructions))
						: {}
					);
					instructions[this.props.page.id] = true;
					this.props.updateGame({instructions: instructions});
				}
				/* Student */
				if (!this.props.isTeacher && this.props.group && this.props.updateGroup) {
					let instructions = (this.props.group.instructions
						? JSON.parse(JSON.stringify(this.props.group.instructions))
						: {}
					);
					instructions[this.props.page.id] = true;
					this.props.updateGroup({instructions: instructions});
				}
			}, 1000);
		} else {
			this.setState({instructionsStatus: ''});
		}
	}

	/**
	 * Hide/show instructions
	 */
	toggleInstructions(instructionsStatus = null) {
		let status = instructionsStatus;
		if (status === null) {
			status = (this.state.instructionsStatus === 'show' ? 'hide' : 'show');
		}
		this.setState({instructionsStatus: status}, () => {
			if (status === 'hide') {
				this.timeout = setTimeout(() => {
					this.setState({ instructionsStatus: 'hidden'});
				}, 300);
			}
		});
	}

	/**
	 * Render instructions component
	 */
	render() {
		if (this.props.page.instructions && this.props.page.instructions.length > 0) {
			let iconType = 'info';
			if (this.props.iconType) {
				iconType = this.props.iconType;
			} else {
				if (!this.props.isTeacher) iconType = 'story';
			}

			return (
				<Instructions 
					instructionsStatus={this.state.instructionsStatus}
					iconType={iconType}
					pageType={this.props.page.type}
					id={(this.props.page.id ? this.props.page.id : null)}
					instructionText={this.props.page.instructions}
					toggleInstructions={this.toggleInstructions}
					type={this.props.type}
					isTeacher={this.props.isTeacher}
				/>
			);
		}
		return null;
	}
}

// InstructionsController.defaultProps = {
// 	isActiveSession: false
// };

InstructionsController.propTypes = {
	isTeacher: PropTypes.bool.isRequired,
	// isActiveSession: PropTypes.bool,
	page: PropTypes.object.isRequired,
	game: PropTypes.object.isRequired,
	// sessionStatus: PropTypes.string.isRequired,
	group: PropTypes.object,
	iconType: PropTypes.string,
	updateGame: PropTypes.func,
	updateGroup: PropTypes.func,
	type: PropTypes.string.isRequired
};

export default InstructionsController;
