import React from 'react';
import PropTypes from 'prop-types';
import {cratersData} from 'data/groups-data';
import './control-panel.scss';

const ControlPanel = (props) => {
	let {
		isTeacher, 
		fullscreenAllowed,
		isFullscreen,
		gameCode,
		groupIndex,
		gameSection, 
		userEmail,
		showPage, 
		handleToggleFullscreen,
		handleLogout
	} = props;


	/* Teacher */
	if (isTeacher) {
		if (gameSection === 'overview' || gameSection === 'settings') {
			return (
				<div className={'ControlPanel ControlPanel--' + gameSection}>
					<div className="ControlPanel-email">logget ind som<span>{userEmail}</span></div>
					<div className="ControlPanel-settingsBtn" onClick={()=>{showPage('settings');}} />
					<div className="ControlPanel-logoutBtn" onClick={()=>{handleLogout();}} /> 
				</div>
			);
		}

		return (
			<div className={'ControlPanel ControlPanel--' + gameSection}>
				<div className="ControlPanel-gameCode"><span>Spilkode:</span> {gameCode ? gameCode : '-'}</div>
				<div className="ControlPanel-buttons">
					<div className="ControlPanel-settingsBtn" onClick={()=>{showPage('settings');}} />
					{fullscreenAllowed && <div 
						className={'ControlPanel-fullscreenBtn' + (isFullscreen ? ' fullscreen' : '')}
						onClick={handleToggleFullscreen}
					/>}
					<div className="ControlPanel-logoutBtn" onClick={()=>{handleLogout();}} /> 
				</div>
			</div>
		);
	}

	/* Group */
	let craterData = null;
	let craterImg = null;
	let groupName = null;
	craterData = (cratersData.some((crater) => {return crater.index === groupIndex;}) 
		? cratersData.filter((crater) => {return crater.index === groupIndex;})[0]
		: null
	);	
	if (craterData) {
		craterImg = require('../../assets/images/group-badges/' + craterData.id + '.png');
		groupName = craterData.name;
	}

	return (
		<div className={'ControlPanel ControlPanel--' + gameSection + ' ControlPanel--group'}>
			{craterImg && <div className="ControlPanel-groupImg">
				<img  src={craterImg} alt='group-crater-icon'/>
			</div>}
			<div className="ControlPanel-groupName">
				<span>Team</span>{groupName}
			</div>
			<div className="ControlPanel-buttons">
				{fullscreenAllowed && <div 
					className={'ControlPanel-fullscreenBtn' + (isFullscreen ? ' fullscreen' : '')}
					onClick={handleToggleFullscreen}
				/>}
				<div className="ControlPanel-logoutBtn" onClick={()=>{handleLogout();}} /> 
			</div>
		</div>	
	);
};



ControlPanel.propTypes = {
	isTeacher: PropTypes.bool.isRequired,
	fullscreenAllowed: PropTypes.bool.isRequired,
	isFullscreen: PropTypes.bool.isRequired,
	gameCode: PropTypes.string,
	groupIndex: PropTypes.number,
	gameSection: PropTypes.string.isRequired,
	userEmail: PropTypes.string,
	showPage: PropTypes.func.isRequired,
	handleToggleFullscreen: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};


export default ControlPanel;
