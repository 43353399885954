import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import {AuthSessionProvider} from 'context/auth-session';
import LoginTeacherController from './login-teacher-controller';
import RequestPasswordController from './request-password-controller';
import CreateUserController from './create-user-controller';
import LoginGroupController from './login-group-controller';
import CookieConsentController from 'components/cookie-consent/cookie-consent-controller';
import {getCookie, setCookie} from 'helpers/cookie-helper';
import ImageLoader from 'components/image-loader/image-loader';
import LandingPage from '../landing-page/landing-page';
import {firstImagesData} from 'data/images-data';
import appConfig from 'config/app.config';
import './login.scss';

class AuthController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isCheckingLogin: true,
			isPreloading: true,
			preloadImages: true,
			showSplash: true,
			showLandingPage: false,
			isLoggedIn: false,
			teacherBox: 'login',
			authSessionData: {
				userId: null,
				userTokenId: null,
				isTeacher: null,
				email: null,
			}
		};
		this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
		this.getTeacherBox = this.getTeacherBox.bind(this);
		this.goToTeacherBox = this.goToTeacherBox.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
		this.unsubscribeOnAuthStateChanged = null;
		this.timeout = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount() {
		this.timeout = setTimeout(() => {
			this.setState({showSplash: false});
		}, 800);
		this.checkIfLoggedIn();
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount() {
		clearTimeout(this.timeout);
		if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
	}

	/**
	 * Subscribe to login status
	 */
	checkIfLoggedIn() {
		this.setState({isCheckingLogin: true});

		// Unsubscribe previous onAuthStateChanged
		if (this.unsubscribeOnAuthStateChanged !== null) {
			this.unsubscribeOnAuthStateChanged();
		}

		// Subscribe to onAuthStateChanged
		this.unsubscribeOnAuthStateChanged = firebase.auth().onAuthStateChanged((user)=>{
			if (user) {
				/* Ok to cookies */
				const cookieConsent = getCookie(appConfig.cookiesAcceptedCookieName);
				if (!cookieConsent) {
					setCookie(appConfig.cookiesAcceptedCookieName, 'ok');
				}
				
				let userIsTeacher = (user.email !== null);
				user.getIdToken().then((idToken) => {
					this.setState({
						isCheckingLogin: false,
						isLoggedIn: true,
						authSessionData: {
							userId: user.uid,
							userTokenId: idToken,
							isTeacher: userIsTeacher,
							email: user.email
						}
					});

					// Check that teacher has a profile, create one if not
					if (userIsTeacher) {
						const db = firebase.firestore();
						db.collection('users').doc(user.uid).get().then((doc) => {
							if (!doc.exists) {
								db.collection('users').doc(user.uid).set({
									email: user.email, 
									isAdmin: false, 
									created: Math.floor(Date.now() / 1000)
								}).catch((error) => {
									console.error('Error creating user: ', error);
								});
							}
						}).catch((error) => {
							console.error('Error getting user: ', error);
						});
					}
				});
			} else {
				this.setState({
					showLandingPage: true,
					isCheckingLogin: false
				});
			}
		});
	}

	/**
	 * Get component for teacher box
	 */
	getTeacherBox() {
		switch (this.state.teacherBox) {
		case 'createUser':
			return CreateUserController;
		case 'resetPassword':
			return RequestPasswordController;
		default:
			return LoginTeacherController;
		}
	}

	/**
	 * Change teacher box content
	 * Options are: login (default), create user, reset password
	 * @param {string} box 
	 */
	goToTeacherBox(box) {
		this.setState({teacherBox: box});
	}

	/**
	 * Log out
	 */
	handleLogout() {
		firebase.auth().signOut();
		this.setState({isLoggedIn: false});
	}

	/* Check if all images have been preloaded */
	handlePreloadImage = (preloadingFinished) => {
		if (preloadingFinished) {
			this.setState({isPreloading: false, preloadImages: false});
		}
	}

	/**
	 * Show game content instead of landing page
	 */
	launchGame = () => {
		this.setState({
			showLandingPage: false
		});
	}

	/**
 	* Render component
 	*/
	render() {
		/* User is not loggged in */
		if (this.state.showSplash || this.state.isPreloading || this.state.isCheckingLogin) {
			return <div className="Splash-screen">
				<img className="Logo-textured-glow" src={require('../../assets/images/logo-textured.svg')} alt="logo"/>
				{this.state.preloadImages
						&& <ImageLoader images={firstImagesData} handlePreloadImage={this.handlePreloadImage} />};
			</div>;
		}
		if (!this.state.isLoggedIn) {
			const TeacherComponent = this.getTeacherBox();
			if (this.state.showLandingPage) {
				return <LandingPage launchGame={this.launchGame}/>;
			}
			return (
				<div className="Login">
					<div className="Login-logo"></div>
					<div className='Login-wrap'>
						<div className={'TeacherLogin TeacherLogin--' + this.state.teacherBox}>
							<TeacherComponent goToTeacherBox={this.goToTeacherBox} />
						</div>
						<div className="GroupLogin"><LoginGroupController /></div>
					</div>
					<CookieConsentController />
					<div className='Login-logo-cgl' onClick={() => {window.open('https://cphgamelab.dk/', '_blank', 'noopener,noreferrer');}}/>

				</div>
			);
		}

		/* User is logged in */
		const authProps = Object.assign({}, this.state.authSessionData, {handleLogout: this.handleLogout});
		return (
			<AuthSessionProvider value={authProps}>
				{this.props.children}
			</AuthSessionProvider>
		);
	}
}

AuthController.propTypes = {
	children: PropTypes.any.isRequired
};

export default AuthController;