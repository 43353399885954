import React, {Component} from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import apiHelper from 'helpers/api-helper';
import {errorFeedbackHelper} from 'helpers/error-feedback-helper';
import errorTexts from 'data/error-texts';
import LoginGroup from './login-group';

class LoginGroupController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			code: null,
			groups: null,
			feedback: null,
			isLoading: false,
			invalidGameCode: false,
			groupIndex: 0
		};
		this.handleInput = this.handleInput.bind(this);
		this.handleFindGame = this.handleFindGame.bind(this);
		this.handleJoinGame = this.handleJoinGame.bind(this);
	}

	/**
	 * Update input field
	 * @param {obj} event 
	 */
	handleInput(event) {
		let value = event.target.value;
		let name = event.target.name;
		this.setState({
			[name]: value,
			feedback: null,
			invalidGameCode: false
		});
	}

	/**
	 * Find the game with the given code, return error if not found
	 * @param {obj} event 
	 */
	handleFindGame(event) {
		event.preventDefault();
		const code = this.state.code;
		if (!code || code.length === 0) {
			this.setState({
				invalidGameCode: true,
				feedback: errorTexts[errorFeedbackHelper('empty-fields')],
			});
			return;
		}
		this.setState({isLoading: true});
		const db = firebase.firestore();
		db.collection('games').doc(code).get().then((doc) => {
			if (doc.exists) {
				db.collection('groups').where('gameId', '==', code).get().then((docs) => {
					let groups = [];
					docs.forEach((doc) => {
						let data = doc.data();
						data.id = doc.id;
						groups.push(data);
					});
					this.setState({
						groups: groups,
						isLoading: false
					});
				}).catch((error) => {
					console.error(error);
					const msg = errorTexts[errorFeedbackHelper('unknown-error')];
					this.setState({
						feedback: msg,
						isLoading: false
					});		
				});
			} else {
				const msg = errorTexts[errorFeedbackHelper('wrong-game-code')];
				this.setState({
					feedback: msg,
					isLoading: false,
					invalidGameCode: true
				});
			}
		}).catch((error) => {
			console.error(error);
			const msg = errorTexts[errorFeedbackHelper('unknown-error')];
			this.setState({
				feedback: msg,
				isLoading: false
			});
		});	
	}	


	/**
	 * Get a login token.
	 * Call firebase auth to sign in with that token.
	 * @param {number} groupIndex 
	 */
	handleJoinGame(groupIndex) {
		this.setState({
			isLoading: true,
			groupIndex: groupIndex
		});

		apiHelper('group/join-game', {gameCode: this.state.code, groupIndex: groupIndex}).then(
			(response)=>{
				let token = response.token;
				firebase.auth().signInWithCustomToken(token)
					.then(() => {
						this.setState({
							isLoading: false,
							groupIndex: 0
						});
						// All okay, should trigger the authStateChanged in LoginController
					})
					.catch((error) => {
						console.error('sign in error', error);
					});
			},
			(rejection) => {
				this.setState({
					isLoading: false,
					groups: null,
					groupIndex: 0
				});
				console.error('rejection: ', rejection);
			}
		);
	}
	

	render() {
		return (
			<LoginGroup
				handleInput={this.handleInput}
				handleFindGame={this.handleFindGame}
				handleJoinGame={this.handleJoinGame}
				code={this.state.code}
				groups={this.state.groups}
				feedback={this.state.feedback}
				isLoading={this.state.isLoading}
				groupIndex={this.state.groupIndex}
				invalidGameCode={this.state.invalidGameCode}
			/>
		);
	}
}

export default LoginGroupController;
