import React from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import apiHelper from 'helpers/api-helper';
import {
	getStatusOfSession,
	getNextSession,
	getStatusOfStory,
	getStatusOfTier,
	getRankedGroups
} from 'helpers/game-flow-helper';
import {demoGame, demoGroups} from 'data/demo-data';
import {storyTiersData} from 'data/content-data';
import popupsData from 'data/popups-teacher-data';
import SettingsController from 'components/settings/settings-controller';
import OverviewController from 'components/overview/overview-controller';
import Menu from 'components/menu/menu';
import CameraController from 'components/camera/camera-controller';
import SessionStatus from 'components/session-status/session-status';
import MissionPanel from 'components/mission-panel/mission-panel';
import NextStepController from 'components/next-step/next-step-controller';
import BaseController from 'components/base/base-controller.js';
import WaveSimulatorController from 'components/wave-simulator/wave-simulator-controller.js';

class TeacherController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isAdmin: false,
			gameId: null,
			isGuestGame: null,
			isEditingCriteria: false,
			grid: null,
			game: demoGame,
			games: [],
			groups: demoGroups
		};
		this.subscribeToGames = this.subscribeToGames.bind(this);
		this.unsubscribeGames = null;
		this.createGame = this.createGame.bind(this);
		this.deleteGame = this.deleteGame.bind(this);
		this.selectGame = this.selectGame.bind(this);
		this.subscribeToGroups = this.subscribeToGroups.bind(this);
		this.unsubscribeGroups = null;
		this.goToSession = this.goToSession.bind(this);
		this.startSession = this.startSession.bind(this);
		this.updateGame = this.updateGame.bind(this);
		this.updateGroups = this.updateGroups.bind(this);
		this.editEvaluationCallback = this.editEvaluationCallback.bind(this);
		this.openEvalWarningPopup = this.openEvalWarningPopup.bind(this);
		this.handleShowPage = this.handleShowPage.bind(this);
		this.saveEvaluation = this.saveEvaluation.bind(this);
		this.saveParamName = this.saveParamName.bind(this);
		this.getCurrentGame = this.getCurrentGame.bind(this);
		this.cancelSaveEvaluation = this.cancelSaveEvaluation.bind(this);
		this.handleSaveEvaluation = this.handleSaveEvaluation.bind(this);
		this.showStartSessionPopup = this.showStartSessionPopup.bind(this);
		this.handleStartSession = this.handleStartSession.bind(this);
		this.checkCloseSession = this.checkCloseSession.bind(this);
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Get admin status */
		const db = firebase.firestore();
		db.collection('users').doc(this.props.userId).get().then((doc) => {
			if (doc.exists) {
				if (doc.data().hasOwnProperty('isAdmin')) {
					this.setState({isAdmin: doc.data().isAdmin});
				}
			}
		}).catch((error) => {console.error('Error getting user: ', error);});

		/* Subscribe to games */
		this.subscribeToGames();
	}

	componentDidUpdate(prevProps, prevState) {
		let currentGame = this.getCurrentGame();
		let prevGame = prevState.games.filter((game) => {return game.id === currentGame.id;})[0];
		/* Check winning group has saved tag; if so, open the map for the teacher */
		if (prevGame && (prevGame.lastTaggedSessionId !== currentGame.lastTaggedSessionId)) {
			this.props.toggleBase(true, true);
		}
		/* Check if map was closed; if so, it shouldn't fade in anymore */
		if (prevProps.showBase === true && this.props.showBase === false) {
			if (this.state.fadeInBase) this.setState({fadeInBase: false});
		}
	}

	/**
	 * Called by the teacher to subscribe to all their games.
	 * @param {string} userId 
	 */
	subscribeToGames() {
		let userId = this.props.userId;
		if (this.unsubscribeGames !== null) this.unsubscribeGames();
		const db = firebase.firestore();
		return new Promise((resolve, reject)=>{
			this.unsubscribeGames = db.collection('games').where('teacherId', '==', userId)
				.onSnapshot((querySnapshot) => {
					let games = [];
					if (!querySnapshot.empty) {
						games = querySnapshot.docs.map((doc) => {
							let data = doc.data();
							data.id = doc.id;
							return data;						
						});
					}
					this.setState({games: games}, () => {resolve();});
				}, (error) => {
					console.error('could not get games: ', error);
					this.openServerErrorPopup();
					reject(error);
				});
		});
	}	

	/**
	 * Create new game
	 * @param {string} gameName 
	 * @param {array} groups 
	 */
	createGame(gameName, craters) {
		return new Promise((resolve, reject) => {
			apiHelper('teacher/create-game', {
				userTokenId: this.props.userTokenId,
				userId: this.props.userId,
				gameName: gameName,
				craters: craters
			}).then(
				(response)=>{
					resolve({status: 'ok'});
				},
				(rejection) => {
					if (rejection.error && rejection.error === 'auth-failed') {
						this.props.openAuthErrorPopup();
					} else {
						console.error(rejection);
						this.props.openServerErrorPopup();
					}
					reject({status: 'error', error: rejection});
				}
			);
		});
	}	

	/**
	 * Delete a game
	 * @param {string} gameId 
	 */
	deleteGame(gameId) {
		return new Promise((resolve, reject) => {
			apiHelper('teacher/delete-game', {userTokenId: this.props.userTokenId, gameId:gameId}).then(
				(response)=>{
					resolve(response);
				},
				(rejection) => {
					if (rejection.error && rejection.error === 'auth-failed') {
						this.props.openAuthErrorPopup();
					} else {
						console.error(rejection);
						this.props.openServerErrorPopup();
					}
					reject(rejection);
				}
			);
		});
	}

	/**
	 * Select a game and subscribe to its groups
	 * @param {string} gameId 
	 */
	selectGame(gameId, isGuestGame) {
		/* Game already selected */
		if (gameId && gameId === this.state.gameId) return;

		/* Select real game and subscribe to its groups */
		this.setState({
			gameId: gameId,
			isGuestGame: isGuestGame,
			groups: [],
		}, () => {
			this.subscribeToGroups(gameId);
		});

		/* Set game code in game controller state */
		this.props.setGameCode(gameId);
	}	

	/**
	 * Subscribe to the groups of a game
	 * @param {string} gameId 
	 */
	subscribeToGroups(gameId) {
		if (this.unsubscribeGroups !== null) this.unsubscribeGroups();
		let db = firebase.firestore();		
		return new Promise((resolve, reject)=>{
			this.unsubscribeGroups = db.collection('groups').where('gameId', '==', gameId)
				.onSnapshot((querySnapshot) => {
					let groups = [];
					querySnapshot.forEach((doc) => {
						let data = doc.data();
						data.id = doc.id;
						groups.push(data);
					});
					this.setState({groups: groups}, () => {resolve();});
				}, (error) => {
					console.error('could not get groups: ', error);
					this.props.openServerErrorPopup();
					reject(error);
				});
		});
	}	

	/**
	 * Navigate to a session
	 * @param {string} sessionId
	 * @param {string} sessionTitle
	 * @param {string} pageId 
	 */
	goToSession(sessionId, sessionTitle, pageId) {
		this.props.showPage(pageId, true).then(() => {
			/* Open base */
			this.setState({fadeInBase: true}, () => {
				this.props.toggleBase(true, true);
			});
		});

		// let game = null;
		// let sessionStatus = null;
		// if (this.state.gameId) {
		// 	if (this.state.games.some((game) => {return game.id === this.state.gameId;})) {
		// 		game = this.state.games.filter((game) => {return game.id === this.state.gameId;})[0];

		// 		if (game.sessions.some((session) => {return session.id === sessionId;})) {
		// 			sessionStatus = game.sessions.filter((session) => {return session.id === sessionId;})[0].status;
		// 		}
		// 	}
		// }

		// /* Demo game or active game with session status 'closed' or flagged as 'active' */
		// if (!game || (game.activeSessionId && game.activeSessionId === sessionId) || sessionStatus === 'closed') {
		// 	this.props.showPage(pageId, true);
		// } else {
		// 	/* New session: session is 'ready' or 'open' and not flagged as 'active' */
		// 	let popupData = JSON.parse(JSON.stringify(popupsData.goToSession));
		// 	popupData.text = popupData.text.replace('%title%', '<span>' + sessionTitle + '</span>');
		// 	let btnConfig = [];
		// 	let startBtn = {
		// 		text: popupData.buttonTexts.start,
		// 		type: 'button',
		// 		action: this.startSession,
		// 		parameters: [sessionId, pageId],
		// 		showLoadingIcon: true
		// 	};
		// 	let viewBtn = {
		// 		text: popupData.buttonTexts.view,
		// 		type: 'button',
		// 		action: this.props.showPage,
		// 		parameters: [pageId, true]
		// 	};
		// 	btnConfig.push(startBtn, viewBtn);
		// 	this.props.openPopup(popupData, btnConfig, 'newSession');
		// } 
	}	

	/**
	 * Start session and redirect to its first page
	 * @param {string} sessionId 
	 * @param {string} pageId 
	 */
	startSession(sessionId, pageId) {
		/* Get game */
		let game = this.state.game;
		if (this.state.gameId) {
			if (this.state.games.some((game) => {return game.id === this.state.gameId;})) {
				game = this.state.games.filter((game) => {return game.id === this.state.gameId;})[0];
			}
		}
		if (game) {
			// Mark session as open
			let sessions = JSON.parse(JSON.stringify(game.sessions));
			sessions.forEach((session) => {
				if (session.id === sessionId) session.status = 'open';
			});

			// Update game
			let gameUpdates = {
				sessions: sessions,
				activeSessionId: sessionId,
			};

			// Reset evaluation state
			this.setState({
				isEditingParamXName: false,
				isEditingParamYName: false,
				grid: null
			});

			// Go to page of new session and show base
			this.updateGame(gameUpdates).then((response) => {
				this.props.showPage(pageId, true);
				this.props.toggleBase(true, true);
			}, (error) => {console.log(error);});

		} else {
			console.log('error: no game found');
		}
	}

	handleStartSession(sessionId, pageId) {
		/* Get game */
		let game = this.state.game;
		if (this.state.gameId) {
			if (this.state.games.some((game) => {return game.id === this.state.gameId;})) {
				game = this.state.games.filter((game) => {return game.id === this.state.gameId;})[0];
			}
		}
		if (game) {
			let storyId = 'story-' + sessionId.split('-')[1];
			let tier = storyTiersData.filter((tier) => {return tier.storyIds.includes(storyId);})[0];
			let firstLandingStatus = getStatusOfStory(
				game,
				this.props.stories.filter((story) => {return story.id === 'story-0';})[0]
			);
			if (firstLandingStatus !== 'closed') {
				this.showStartSessionPopup('warning1', sessionId, pageId);
				return;
			}

			let rebootBaseStatus = getStatusOfStory(
				game,
				this.props.stories.filter((story) => {return story.id === 'story-1';})[0]
			);
			if (rebootBaseStatus !== 'closed' && tier.index !== 1) {
				this.showStartSessionPopup('warning2', sessionId, pageId);
				return;
			}

			if (tier.index === 2 || tier.index === 3) {
				let previousTier = storyTiersData.filter((t) => {return t.index === tier.index - 1;})[0];
				let previousTierStatus = getStatusOfTier(game, previousTier, this.props.stories);
				if (previousTierStatus !== 'closed') {
					this.showStartSessionPopup('warning3', sessionId, pageId);
					return;
				}
			}

			this.startSession(sessionId, pageId);
		}
	}

	showStartSessionPopup(warning, sessionId, pageId) {
		let popupData = popupsData.startSessionWarning[warning];
		let btnConfig = [];
		let okBtn = {
			text: popupData.buttonTexts.ok,
			type: 'button',
			action: this.props.closePopup,
			parameters: []
		};
		btnConfig.push(okBtn);

		if (warning !== 'warning1') {
			let startBtn = {
				text: popupData.buttonTexts.start,
				type: 'button',
				action: this.startSession,
				parameters: [sessionId, pageId]
			};
			btnConfig.push(startBtn);
		}
		this.props.openPopup(popupData, btnConfig, 'startSession');
	}

	/**
	 * Close game session
	 * @param {string} sessionId 
	 */
	closeSession = (sessionId) => {
		/* Get game */
		let game = this.state.game;
		if (this.state.gameId) {
			if (this.state.games.some((game) => {return game.id === this.state.gameId;})) {
				game = this.state.games.filter((game) => {return game.id === this.state.gameId;})[0];
			}
		}
		if (game) {
			// Mark session as closed
			let sessions = JSON.parse(JSON.stringify(game.sessions));
			sessions.forEach((session) => {
				if (session.id === sessionId) session.status = 'closed';
			});

			let gameUpdates = {sessions: sessions};
			this.updateGame(gameUpdates).then(() => {
				console.log('session marked as closed');
			});
		} else {
			console.log('error: no game found');
		}
	}


	/**
	 * Update game
	 * @param {object} updates 
	 */
	updateGame(updates) {
		return new Promise((resolve, reject) => {
			if (this.state.gameId !== null) {
				let db = firebase.firestore();
				db.collection('games').doc(this.state.gameId).update(updates).then(() => {
					resolve();
				}, (error) => {
					reject(error);
					console.error('could not update game: ', error);
					this.props.openServerErrorPopup();
				});

			} else {
				// reject('no game id');
				let demoGame = Object.assign({}, this.state.game, updates);
				this.setState({game: demoGame}, () => {
					resolve();
				});
			}
		});
	}

	/**
	 * 
	 * @param {array} groups
	 */
	updateGroups(groups) {
		return new Promise((resolve, reject) => {
			let promises = [];
			let db = firebase.firestore();
			groups.forEach((group) => {
				if (group.id && group.updates) {
					promises.push(db.collection('groups').doc(group.id).update(group.updates));
				}
			});
			Promise.all(promises).then(
				() => {resolve();},
				(error) => {
					reject(error);
					console.error('could not update groups: ', error);
					this.props.openServerErrorPopup();
				}
			);
		});
	}

	/**
	 * Check if teacher can go to another page
	 * @param {string} pageId
	 */
	handleShowPage(pageId, closePopup = false) {
		if (this.props.page.id.includes('evaluation')
			&& (this.state.isEditingParamXName || this.state.isEditingParamYName || this.state.grid)
		) {
			let game = this.getCurrentGame();
			let sessionStatus = getStatusOfSession(this.props.sessionData.id, game);
			if (sessionStatus !== 'ready') {
				this.openEvalWarningPopup(pageId);
			} else {
				this.props.showPage(pageId, closePopup);
			}
		} else {
			this.props.showPage(pageId, closePopup);
		}
	}

	/**
	* Get updates from the evaluation controller
	* @param {string} type - can be 'criteria', 'grid' or 'handleInput'
	* @param {bool} isEditing
	*/
	editEvaluationCallback (type, data) {
		if (type === 'criteria') {
			this.setState({ ['isEditing' + data.name.charAt(0).toUpperCase() + data.name.slice(1)]: data.isEditing });
		} else {
			this.setState(data);
		}
	}

	/**
	* Teacher clicks "Ja" on the warning evaluation popup
	*/
	handleSaveEvaluation(pageId) {
		let promises = [];

		if (this.state.isEditingParamXName) {
			promises.push(this.saveParamName(this.state.paramXName, 'paramXName'));
		}
		if (this.state.isEditingParamYName) {
			promises.push(this.saveParamName(this.state.paramYName, 'paramYName'));
		}
		if (this.state.grid) {
			promises.push(this.saveEvaluation(this.state.grid));
		}
		
		Promise.all(promises).then(() => {
			this.handleShowPage(pageId, true);
		});
	}

	/**
	* Teacher clicks "Nej" on the warning evaluation popup
	*/
	cancelSaveEvaluation(pageId) {
		this.setState({
			isEditingParamXName: false,
			isEditingParamYName: false,
			grid: null
		}, () => { this.props.showPage(pageId, true); });
	}

	openEvalWarningPopup (pageId) {
		let popupData = popupsData.paramsEvaluationWarning;
		if (this.state.grid) {
			popupData = popupsData.gridEvaluationWarning;
		}
		let btnConfig = [];
		let saveBtn = {
			text: popupData.buttonTexts.yes,
			type: 'button',
			action: this.handleSaveEvaluation,
			showLoadingIcon: true,
			parameters: [pageId]
		};
		let cancelBtn = {
			text: popupData.buttonTexts.no,
			type: 'button',
			action: this.cancelSaveEvaluation,
			parameters: [pageId]
		};
		btnConfig.push(saveBtn, cancelBtn);
		this.props.openPopup(popupData, btnConfig, 'evaluation-warning');
	}

	/**
	 * Save param name in evaluation
	 */
	saveParamName(paramName, paramId) {
		return new Promise((resolve)=>{
			let game = this.getCurrentGame();
			let evaluationParams = {};
			if (game.evaluationParams) evaluationParams = game.evaluationParams;
			if (!evaluationParams[this.props.sessionData.id]) evaluationParams[this.props.sessionData.id] = {};
			evaluationParams[this.props.sessionData.id][paramId] = paramName;
			if (!evaluationParams.lastSaved) evaluationParams.lastSaved = {};
			evaluationParams.lastSaved[paramId] = paramName;

			this.updateGame({evaluationParams: evaluationParams}).then(() => {
				this.setState({ ['isEditing' + paramId.charAt(0).toUpperCase() + paramId.slice(1)]: false });
				resolve(evaluationParams.lastSaved[paramId]);
			});
		});
	}

	/**
	 * Save group evaluation
	 */
	saveEvaluation(grid) {
		return new Promise((resolve)=>{
			let populatedGrid = grid.filter((square) => {return square.groupIds.length > 0;});
			let groups = JSON.parse(JSON.stringify(this.state.groups));
			let groupUpdates = [];
			populatedGrid.forEach((square) => {
				square.groupIds.forEach((groupId) => {
					let groupIndex = groups.findIndex((group) => {return group.id === groupId;});
					if (groupIndex >= 0) {
						if (!groups[groupIndex].evaluation) groups[groupIndex].evaluation = {};
						if (!groups[groupIndex].evaluation[this.props.sessionData.id]) {
							groups[groupIndex].evaluation[this.props.sessionData.id] = {};
						}
						groupUpdates.push({
							id: groups[groupIndex].id,
							updates: {
								evaluations: {
									...groups[groupIndex].evaluations,
									[this.props.sessionData.id]: {x: square.x, y: square.y}
								}
							}
						});
					}
				});
			});

			this.updateGroups(groupUpdates).then(() => {
				if (getStatusOfSession(this.props.sessionData.id, this.getCurrentGame()) !== 'closed') {
					this.checkCloseSession(populatedGrid);
				}
				this.setState({ grid: null });
				resolve();
			});
		});
	}

	activateTagging = () => {
		return new Promise((resolve, reject)=>{
			/** Get ranked groups */
			let game = this.getCurrentGame();
			let sessionStatus = getStatusOfSession(this.props.sessionData.id, game);
			let sessionData = Object.assign({}, this.props.sessionData, {status: sessionStatus});
			let rankedGroups = getRankedGroups(JSON.parse(JSON.stringify(this.state.groups)), sessionData, game);
			
			/** Get winning group id */
			let winningGroupId = null;
			if (rankedGroups.length > 0) winningGroupId = rankedGroups[0].id;

			if (!winningGroupId) return;

			if (!this.state.groups.some((group) => {return group.id === winningGroupId;})) return;

			/** Activate tagging by adding special props to session */
			this.setState({isLoading: true});
			let craterId = this.state.groups.filter((group) => {return group.id === winningGroupId;})[0].craterId;
			let sessions = JSON.parse(JSON.stringify(game.sessions));
			let sessionIndex = sessions.findIndex((session) => {return session.id === sessionData.id;});

			sessions[sessionIndex].winningGroupId = winningGroupId;
			sessions[sessionIndex].winningGroupCraterId = craterId;
			sessions[sessionIndex].tagName = '';
			sessions[sessionIndex].winningGroupCanTag = true;
			this.updateGame({sessions: sessions}).then(() => {
				this.setState({isLoading: false});
				this.props.toggleBase(true, true);
				this.props.closePopup();
			});
			resolve();
		});
	}

	showTaggingWarningPopup = (pageId) => {
		let popupData = popupsData.taggingWarning;
		let btnConfig = [];
		let saveBtn = {
			text: popupData.buttonTexts.yes,
			type: 'button',
			action: this.activateTagging,
			showLoadingIcon: true,
			parameters: []
		};
		let cancelBtn = {
			text: popupData.buttonTexts.no,
			type: 'button',
			action: this.props.showPage,
			parameters: [pageId, true]
		};
		btnConfig.push(saveBtn, cancelBtn);
		this.props.openPopup(popupData, btnConfig);
	}


	/**
	 * If all groups have been evaluated, close session
	 */
	checkCloseSession(populatedGrid) {
		/* Check if all playing groups have been evaluated */
		let numberOfPlayingGroups = this.state.groups.filter((group) => {
			return group.isPlaying === true;
		}).length;
		let numberOfEvaluatedGroups = 0;
		populatedGrid.forEach((square) => {
			numberOfEvaluatedGroups = numberOfEvaluatedGroups + square.groupIds.length;
		});
		/* Change session status to 'closed' */
		if (numberOfPlayingGroups === numberOfEvaluatedGroups) {
			this.closeSession(this.props.sessionData.id);
		}
	}

	/**
	 * Get current game based on id
	 */
	getCurrentGame() {
		let game = this.state.game;
		if (this.state.gameId) {
			if (this.state.games.some((game) => {return game.id === this.state.gameId;})) {
				game = this.state.games.filter((game) => {return game.id === this.state.gameId;})[0];
			}
		}
		return game;
	}


	/**
	 * Render teacher component
	 */
	render() {
		/* Get game */
		let game = this.getCurrentGame();
		
		/* Settings page */
		if (this.props.pageId.includes('settings')) {
			return (
				<SettingsController 
					isAdmin={this.state.isAdmin}
					games={this.state.games}
					stories={this.props.stories} 
					popupFormData={this.props.popupFormData}
					videos={this.props.videos}
					createGame={this.createGame}
					deleteGame={this.deleteGame}
					selectGame={this.selectGame}
					showPage={this.props.showPage}
					openPopup={this.props.openPopup}
					closePopup={this.props.closePopup}
					updatePopupState={this.props.updatePopupState}
					updateGame={this.updateGame}
				/>
			);
		}

		/* Game overview page */
		let isOverviewPage = this.props.pageId.includes('overview');
		if (isOverviewPage) {
			let storyIndex = 0;
			if (this.props.pageId.includes('-')) [, storyIndex] = this.props.pageId.split('-');
			return (
				<React.Fragment>
					<CameraController 
						game={game}
						page={this.props.page}
						toggleBase={this.props.toggleBase}
						toggleWaveSimulator={this.props.toggleWaveSimulator}
						stories={this.props.stories}
					/>
					<OverviewController
						isTeacher={true}
						storyIndex={parseInt(storyIndex)}
						game={game}
						stories={this.props.stories}
						goToSession={this.goToSession}
						openPopup={this.props.openPopup}
					/>
					<BaseController
						isTeacher={true}
						game={game}
						toggleBase={this.props.toggleBase}
						updateGame={this.updateGame}
						baseIsOpen={this.props.showBase}
						basePopupIsOpen={this.props.showBasePopup}
						stories={this.props.stories}
						page={this.props.page}
						openedFromOverview={true}
					/>
				</React.Fragment>
			);
		}

		/* Game page */
		if (this.props.page && this.props.sessionData) {
			// let isActiveSession = false;
			// let activeSessionId = null;
			// if (game.sessions.some((session) => {return session.status === 'active';})) {
			// 	activeSessionId = game.sessions.filter((session) => {
			// 		return session.status === 'active';
			// 	})[0].id;
			// 	isActiveSession = (activeSessionId === this.props.sessionData.id);
			// }

			/* Session status */
			let sessionStatus = getStatusOfSession(this.props.sessionData.id, game);
			let sessionData = Object.assign({}, this.props.sessionData, {status: sessionStatus});
			// sessionData.status = sessionStatus;
			// let isActiveSession = sessionData.status === 'active';

			// let isActiveSession = (game.activeSessionId && game.activeSessionId === this.props.sessionData.id);
			/* Next session data */
			let nextSessionData = getNextSession(this.props.sessionData, this.props.stories, game);

			let waveSimulator = this.props.minigames.filter((minigame) => {
				return minigame.id === 'waveSimulator-1';
			})[0];

			return (
				<React.Fragment>
					<Menu 
						isTeacher={true} 
						page={this.props.page}
						sessionData={sessionData}
						game={game}
						groups={this.state.groups}
						showPage={this.handleShowPage}
					/>
					<SessionStatus sessionData={sessionData} startSession={this.handleStartSession} />
					<MissionPanel 
						isTeacher={true}
						// isActiveSession={isActiveSession}
						page={this.props.page}
						sessionData={sessionData}
						minigames={this.props.minigames}
						game={game}
						groups={this.state.groups}
						showPage={this.props.showPage}
						updateGame={this.updateGame}
						updateGroups={this.updateGroups}
						openPopup={this.props.openPopup}
						closePopup={this.props.closePopup}
						editEvaluationCallback={this.editEvaluationCallback}
						saveParamName={this.saveParamName}
						saveEvaluation={this.saveEvaluation}
						goToSession={this.goToSession}
						closeSession={this.closeSession}
						toggleBase={this.props.toggleBase}
						activateTagging={this.activateTagging}
					/>
					<CameraController 
						game={game}
						page={this.props.page}
						toggleBase={this.props.toggleBase}
						toggleWaveSimulator={this.props.toggleWaveSimulator}
						stories={this.props.stories}
					/>
					<NextStepController
						page={this.props.page}
						sessionData={sessionData}
						showPage={this.handleShowPage}
						closeSession={this.closeSession}
						nextSessionData={nextSessionData}
						game={game}
						showTaggingWarningPopup={this.showTaggingWarningPopup}
					/>

					<BaseController 
						isTeacher={true}
						game={game}
						toggleBase={this.props.toggleBase}
						updateGame={this.updateGame}
						baseIsOpen={this.props.showBase}
						basePopupIsOpen={this.props.showBasePopup}
						stories={this.props.stories}
						page={this.props.page}
						openedFromOverview={false}
						fadeInBase={this.state.fadeInBase}
					/>

					{this.props.page.storyIndex === 3 && this.props.page.missionIndex === 1
						&& <WaveSimulatorController
							isTeacher={true}
							page={this.props.page}
							game={game}
							group={null}
							updateGroup={null}
							waveSimulator={waveSimulator}
							showWaveSimulator={this.props.showWaveSimulator}
							toggleWaveSimulator={this.props.toggleWaveSimulator}
						/>
					}
				</React.Fragment>
			);
		}

		/* Unknown page */
		return <div>Unknown page: {this.props.pageId}.</div>;
	}
}

TeacherController.propTypes = {
	userId: PropTypes.string.isRequired,
	userTokenId: PropTypes.string.isRequired,
	pageId: PropTypes.string.isRequired,
	showBase: PropTypes.bool.isRequired,
	showBasePopup: PropTypes.bool.isRequired,
	stories: PropTypes.array.isRequired,
	page: PropTypes.object,
	sessionData: PropTypes.object,
	minigames: PropTypes.array.isRequired,
	videos: PropTypes.array.isRequired,
	toggleBase: PropTypes.func.isRequired,
	showPage: PropTypes.func.isRequired,
	setGameCode: PropTypes.func.isRequired,
	popupFormData: PropTypes.object.isRequired,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	updatePopupState: PropTypes.func.isRequired,
	openAuthErrorPopup: PropTypes.func.isRequired,
	openServerErrorPopup: PropTypes.func.isRequired,
	toggleWaveSimulator: PropTypes.func.isRequired,
	showWaveSimulator: PropTypes.bool.isRequired
};


export default TeacherController;