import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {getMinigameInstructions, saveMinigameInstructions} from 'helpers/game-content-helper';
import WaveSimulators from './wave-simulators';

class WaveSimulatorsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			waveSimulators: [],
			isEditingWaveSimulator: false,
			isEditingTeacherInstructions: false,
			isEditingGroupInstructions: false,
			isSaving: false,
			errorMsg: null,
			selectedWaveSimulator: null,
			selectedWaveSimulatorIndex: null,
			teacherInstructions: null,
			groupInstructions: null,
		};
		this.subscribeToWaveSimulators = this.subscribeToWaveSimulators.bind(this);
		this.unsubscribeWaveSimulators = null;
		this.selectWaveSimulator = this.selectWaveSimulator.bind(this);
		this.updateWaveSimulator = this.updateWaveSimulator.bind(this);
		this.updateInstructions = this.updateInstructions.bind(this);
		this.handleSaveWaveSimulator = this.handleSaveWaveSimulator.bind(this);
		this.saveWaveSimulator = this.saveWaveSimulator.bind(this);
	};

	/**
	 * Component mounted
	 */
	componentDidMount() {
		this.subscribeToWaveSimulators().then((response) => {
			this.selectWaveSimulator(0);
		});
	}

	/**
	 * Subscribe to wave simulators
	 */
	subscribeToWaveSimulators() {
		if (this.unsubscribeWaveSimulators !== null) this.unsubscribeWaveSimulators();
		const db = firebase.firestore();
		return new Promise((resolve, reject)=>{
			this.unsubscribeWaveSimulators = db.collection('waveSimulators').onSnapshot((querySnapshot) => {
				let waveSimulators = [];
				if (!querySnapshot.empty) {
					waveSimulators = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = doc.id;
						return data;
					});
				}
				this.setState({waveSimulators: waveSimulators}, () => {
					resolve({status: 'ok', waveSimulators: waveSimulators});
				});

			}, (error) => {resolve({status: 'error', error: error});});
		});
	}

	/**
	 * Select page
	 * @param {object} waveSimulatorIndex
	 */
	selectWaveSimulator(waveSimulatorIndex) {
		let selectedWaveSimulatorIndex = waveSimulatorIndex;
		let selectedWaveSimulator = this.state.waveSimulators[waveSimulatorIndex];
		let groupInstructions = null;
		let teacherInstructions = null;
		let promises = [];

		if (selectedWaveSimulator.hasTeacherInstructions) {
			promises.push(getMinigameInstructions(selectedWaveSimulator.id, 'teacher'));
		}
		if (selectedWaveSimulator.hasGroupInstructions) {
			promises.push(getMinigameInstructions(selectedWaveSimulator.id, 'group'));
		}

		if (promises.length > 0) {
			Promise.all(promises).then((responses) => {
				let responseIndex = 0;
				if (selectedWaveSimulator.hasTeacherInstructions) {
					if (responses[responseIndex].status === 'ok') {
						teacherInstructions = responses[responseIndex].instructions;
					}
					responseIndex = responseIndex + 1;
				}
				if (selectedWaveSimulator.hasGroupInstructions) {
					if (responses[responseIndex].status === 'ok') {
						groupInstructions = responses[responseIndex].instructions;
					}
				}

				this.setState({
					selectedWaveSimulatorIndex: selectedWaveSimulatorIndex,
					selectedWaveSimulator: selectedWaveSimulator,
					teacherInstructions: teacherInstructions,
					groupInstructions: groupInstructions
				});
			});	
		} else {
			this.setState({
				selectedWaveSimulatorIndex: selectedWaveSimulatorIndex,
				selectedWaveSimulator: selectedWaveSimulator,
				teacherInstructions: teacherInstructions,
				groupInstructions: groupInstructions
			});
		}
	}
		
	/* Update wave simulator data */
	updateWaveSimulator(event) {
		let selectedWaveSimulator = JSON.parse(JSON.stringify(this.state.selectedWaveSimulator));
		if (event.target.name === 'title') selectedWaveSimulator[event.target.name] = event.target.value;
		this.setState({
			selectedWaveSimulator: selectedWaveSimulator,
			isEditingWaveSimulator: true
		});
	}

	/**
	 * Update instructions
	 * @param {object} event
	 */
	updateInstructions(event, role) {
		let isEditingTeacherInstructions = this.state.isEditingTeacherInstructions;
		let isEditingGroupInstructions = this.state.isEditingGroupInstructions;
		if (role === 'teacher') isEditingTeacherInstructions = true;
		if (role === 'group') isEditingGroupInstructions = true;

		this.setState({
			[event.target.name]: event.target.value,
			isEditingTeacherInstructions: isEditingTeacherInstructions,
			isEditingGroupInstructions: isEditingGroupInstructions,
			errorMsg: null
		});
	}

	/**
	 * Handle save wave simulator
	 */
	handleSaveWaveSimulator() {
		if (
			(
				!this.state.isEditingWaveSimulator &&
				!this.state.isEditingTeacherInstructions &&
				!this.state.isEditingGroupInstructions
			) || this.state.isSaving
		) return;
		
		this.setState({isSaving: true});

		let promises = [];
		let selectedWaveSimulator = JSON.parse(JSON.stringify(this.state.selectedWaveSimulator));
		
		/* Save wave simulator in DB */
		if (this.state.isEditingWaveSimulator) {
			promises.push(this.saveWaveSimulator(selectedWaveSimulator));
		}
		
		/* Save instructions in storage */
		if (this.state.isEditingTeacherInstructions) {
			promises.push(saveMinigameInstructions(selectedWaveSimulator.id, 'teacher', this.state.teacherInstructions));
		}

		if (this.state.isEditingGroupInstructions) {
			promises.push(saveMinigameInstructions(selectedWaveSimulator.id, 'group', this.state.groupInstructions));
		}

		Promise.all(promises).then(
			() => {
				this.setState({
					isEditingWaveSimulator: false,
					isEditingTeacherInstructions: false,
					isEditingGroupInstructions: false,
					isSaving: false
				});
			}, (error) => {console.error(error);this.setState({isSaving: false, errorMsg: 'save error'});}
		);
	}

	/* Save wave simulator (database) */
	saveWaveSimulator(selectedWaveSimulator) {
		let dataToUpdate = {
			title: selectedWaveSimulator.title,
			loadTeacherInstructions: (this.state.teacherInstructions && this.state.teacherInstructions.length > 0),
			loadGroupInstructions: (this.state.groupInstructions && this.state.groupInstructions.length > 0)
		};
		const db = firebase.firestore();
		return db.collection('waveSimulators').doc(selectedWaveSimulator.id).update(dataToUpdate);
	}

	/**
	 * Render component
	 */
	render() {
		return (
			<WaveSimulators
				goToPage={this.props.goToPage}
				waveSimulators={this.state.waveSimulators}
				selectWaveSimulator={this.selectWaveSimulator}
				isEditing={this.state.isEditingWaveSimulator || this.state.isEditingTeacherInstructions || 
					this.state.isEditingGroupInstructions}
				isSaving={this.state.isSaving}
				selectedWaveSimulator={this.state.selectedWaveSimulator}
				errorMsg={this.state.errorMsg}
				updateWaveSimulator={this.updateWaveSimulator}
				updateInstructions={this.updateInstructions}
				teacherInstructions={this.state.teacherInstructions}
				groupInstructions={this.state.groupInstructions}
				handleSaveWaveSimulator={this.handleSaveWaveSimulator}
			/>
		);
	}
}

WaveSimulatorsController.propTypes = {
	goToPage: PropTypes.func.isRequired
};

export default WaveSimulatorsController;