import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getRankedGroups, getStatusOfSession} from 'helpers/game-flow-helper';
import Result from './result';

class ResultController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,

		};
		this.handleActivateTagging = this.handleActivateTagging.bind(this);
	};

	/**
	 * Loading state for activate tagging btn
	 * @param {string} winningGroupId 
	 */
	handleActivateTagging() {
		this.setState({ isLoading: true }, () => {
			this.props.activateTagging().then(() => {
				this.setState({ isLoading: false });
			});
		});
	}

	render() {
		let sessions = [];
		/* Loop over mission sessions up to and including this session */
		this.props.sessionData.missionSessions.forEach((session, sessionIndex) => {
			if (sessionIndex <= this.props.sessionData.sessionIndex) {
				sessions.push({id: session.id, title: session.title});
			}
		});

		/* activateTaggingBtn should only be shown on the last session of a mission */
		let lastSessionIndex =
			this.props.sessionData.missionSessions[this.props.sessionData.missionSessions.length - 1].sessionIndex;
		
		let showActivateTaggingBtn = false;
		if (this.props.game.sessions.some((session) => {return session.id === this.props.sessionData.id;})) {
			let gameSessionData = this.props.game.sessions.filter((session) => {
				return session.id === this.props.sessionData.id;
			})[0];
			let missionSessionStatuses = this.props.sessionData.missionSessions.map((missionSession) => {
				return getStatusOfSession(missionSession.id, this.props.game);
			});
			let missionIsCompleted = missionSessionStatuses.every((status) => {return status === 'closed';});
			if (
				missionIsCompleted
				&& !gameSessionData.hasOwnProperty('winningGroupId')
				&& lastSessionIndex === this.props.sessionData.sessionIndex
			) {
				showActivateTaggingBtn = true;
			}
		}

		let groups = JSON.parse(JSON.stringify(this.props.groups));
		let rankedGroups = getRankedGroups(groups, this.props.sessionData, this.props.game);

		return (
			<Result 
				isLoading={this.state.isLoading}
				page={this.props.page}
				sessions={sessions}
				rankedGroups={rankedGroups}
				handleActivateTagging={(showActivateTaggingBtn ? this.handleActivateTagging : null)}
			/>
		);
	}
}

ResultController.propTypes = {
	page: PropTypes.object.isRequired,
	game: PropTypes.object.isRequired,
	sessionData: PropTypes.object.isRequired,
	groups: PropTypes.array.isRequired,
	activateTagging: PropTypes.func.isRequired
};

export default ResultController;
