import React from 'react';
import PropTypes from 'prop-types';
import {useDrop} from 'react-dnd';

const EvaluationDnDContainer = ({squareIndex, numberOfGroups, handleDrop, children}) => {
	const [{isOver}, drop] = useDrop({
		accept: 'groupId',
		collect: (monitor) => {
			return {
				isOver: monitor.isOver(),
				canDrop: monitor.canDrop(),
			};
		},
		drop(item) {
			handleDrop(item.groupId, squareIndex);
		}
	});
	let groupGridLayout = null;
	if (numberOfGroups && numberOfGroups > 0) {
		if (numberOfGroups === 1) groupGridLayout = 1;
		if (numberOfGroups > 1 && numberOfGroups < 5) groupGridLayout = 4;
		if (numberOfGroups > 4 && numberOfGroups < 10) groupGridLayout = 9;
		if (numberOfGroups > 9 && numberOfGroups < 13) groupGridLayout = 12;
	}
	let className = 'EvaluationGrid-square' +
		(groupGridLayout ? ' EvaluationGrid-square--gridSize' + groupGridLayout : '') +
		(isOver ? ' EvaluationGrid-square--active' : '');
	return <div ref={drop} className={className}>{children}</div>;
};

EvaluationDnDContainer.propTypes = {
	squareIndex: PropTypes.number.isRequired,
	numberOfGroups: PropTypes.number,
	handleDrop: PropTypes.func.isRequired,
	children: PropTypes.array
};


export default EvaluationDnDContainer;