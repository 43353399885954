import React from 'react';
import PropTypes from 'prop-types';
import BaseElement from './base-element';
import BaseTag from './base-tag';
import {baseUiTexts} from 'data/ui-texts';
import './base.scss';

const Base = (props) => {
	let {
		isTeacher, 
		isEditing,
		isSaving,
		elements, 
		tags, 
		expandTagId, 
		tagName, 
		expandTag, 
		editTagName, 
		saveTagName,
		baseIsOpen,
		handleCloseBase,
		popupData,
		popupIsOpen,
		popupIsOpenProps,
		showPopup,
		isOverviewPage,
		showCloseAnimation,
		showFadeInAnimation
	} = props;

	let vehicleMissionStatus = elements
		.find((element) => {return element.missionId === 'mission-5-2';}).status;

	return (
		<div className={'Base-container'
			+ (showFadeInAnimation ? ' Base-container--fadeIn' : baseIsOpen ? ' Base-container--open' : '')
			+ (showCloseAnimation ? ' Base-container--closed' : '')
			+ (isOverviewPage ? ' Base-container--closeToCenter' : '')
		}>
			{/* Close base button */}
			<div className="Base-close" onClick={() => {handleCloseBase();}} />
			
			<div className={'Base' + (vehicleMissionStatus === 'repaired' ? ' Base--repaired' : '')}>
				{/* Base elements */}
				{elements.map((element) => {
					return <BaseElement key={element.id} element={element} />;
				})}

				{/* Base tags */}
				{tags.map((tag) => {
					return (
						<BaseTag
							key={tag.id}
							isTeacher={isTeacher}
							isEditing={isEditing}
							isSaving={isSaving}
							tag={tag}
							expandTagId={expandTagId}
							tagName={tagName}
							expandTag={expandTag}
							editTagName={editTagName}
							saveTagName={saveTagName}
						/>
					);
				})}

				{/* Base popup */}
				{popupData.missionTitle && <React.Fragment>
					<div className={'BasePopup' +
						(popupIsOpen ? ' BasePopup--open' : '') +
						(tags.find((tag) => {return tag.id === 'stars';}).isCurrentMission ? ' BasePopup--lower' : '') +
						(popupIsOpenProps ? ' BasePopup--noAnimation' : '')}
					>
						<div className="BasePopup-missionTitle"><span>{baseUiTexts.mission}</span>
							{popupData.missionTitle}
						</div>
						{popupData.baseLocation &&
							<div className="BasePopup-missionInfo"><span>{baseUiTexts.baseLocation}</span>
								{popupData.baseLocation}
							</div>}
						<div className="BasePopup-missionInfo"><span>{baseUiTexts.missionStatus}</span>
							{baseUiTexts[popupData.missionStatus]}
						</div>
						<div className="BasePopup-missionInfo"><span>{baseUiTexts.goal}</span>
							{popupData.missionGoal}
						</div>
						<div className="BasePopup-playBtn" onClick={() => { handleCloseBase(); } } />
					</div>
					<div
						className={'Base-showPopupBtn' +
							(popupIsOpen ? ' Base-showPopupBtn--hide' : ' Base-showPopupBtn--show') +
							(popupIsOpenProps ? ' Base-showPopupBtn--noAnimation' : '')}
						onClick={() => {showPopup();}}>
						<span>{baseUiTexts.mission}</span>
						{popupData.missionTitle}
					</div>
				</React.Fragment>}
			</div>
		</div>
	);
};


Base.propTypes = {
	isTeacher: PropTypes.bool.isRequired,
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	elements: PropTypes.array.isRequired,
	tags: PropTypes.array.isRequired,
	expandTagId: PropTypes.string,
	tagName: PropTypes.string,
	expandTag: PropTypes.func.isRequired,
	editTagName: PropTypes.func.isRequired,
	saveTagName: PropTypes.func.isRequired,
	baseIsOpen: PropTypes.bool.isRequired,
	handleCloseBase: PropTypes.func.isRequired,
	popupData: PropTypes.object.isRequired,
	showPopup: PropTypes.func.isRequired,
	popupIsOpen: PropTypes.bool.isRequired,
	popupIsOpenProps: PropTypes.bool.isRequired,
	isOverviewPage: PropTypes.bool.isRequired,
	showCloseAnimation: PropTypes.bool.isRequired,
	showFadeInAnimation: PropTypes.bool
};

export default Base;
