const basicPageAudios = {
	'page-5-2-2-intro': [{
		location: 'before-text',
		filename: 'cyoa-rover.mp3',
		audioType: 'cyoa'
	}],
	'page-5-2-1-experiment': [{
		location: 'before-text',
		filename: 'den-gamle-rover-mixed.mp3',
		audioType: 'cyoa'
	}],
	'page-9-1-1-feature': [
		{
			location: 'after-text',
			filename: 'mars-red-moan.mp3',
			audioType: 'feature',
			// label: '<b>Mars Red Moan</b>'
		},		
		{
			location: 'after-text',
			filename: 'motion-paa-mars.mp3',
			audioType: 'feature',
			label: '<b>Indslag 3: Motion på Mars</b>'
		},
		{
			location: 'after-text',
			filename: 'radiomars-news.mp3',
			audioType: 'feature',
			label: '<b>Indslag 4: Mars News</b>'
		},
		{
			location: 'after-text',
			filename: 'sciendomus.mp3',
			audioType: 'feature',
			label: '<b>Indslag 5: Sciendomus</b>'
		},
	]
}

const basicPageTables = {
	'page-2-2-1-energyConsumption': [
		{
			id: 'table-1',
			title: null,
			header: ['Mad medbragt til Mars', 'Mængde (tons)', 'Energitæthed pr. 100 g'],
			content: [
				['Ost og andre mælkeprodukter', '0.5', '3000 kJ'],
				['Grøntsager', '9', '500 kJ'],
				['Frugt og nødder', '2', '1000 kJ'],
				['Brød, mel og andre kornprodukter', '5', '1500 kJ'],
				['Kød', '1', '2400 kJ'],
				['Fisk', '1', '1500 kJ'],
			]
		},
		{
			id: 'table-2',
			title: '*Basalstofskiftet',
			header: ['Køn', 'Alder i år', 'Basalstofskifte BMR'],
			content: [
				['Kvinder', '19-30', ['0,0615', '2,08']],
				['Mænd', '19-30', ['0,064', '2,84']],
			]
		}
	],
	'page-2-2-2-task': [
		{
			id: 'table-1',
			title: null,
			header: ['Delfunktion', 'Værdi'],
			content: [
				['Pilleproduktion', '100 g/dag'],
				['Strømforbrug', '100W'],
				['Vægt', '270 kg'],
				['Volume (vand i vækstrørene)', '200 L'],
				['Dyrkningstid', '50 dage'],
			]
		}
	],
	'page-2-3-1-rocketFuel': [
		{
			id: 'table-1',
			title: null,
			header: ['Brændsel', 'Brændværdi     i MJ/kg'],
			content: [
				['Benzin', '42,7'],
				['Flaskegas', '46,0'],
				['Fyringsolie (svær)', '40,6'],
				['Methan', '50'],
				['Hydrogen', '120'],
				['Frisk fyrretræ', '11,4'],
				['Halm', '13,5'],
			]
		}
	],
	'page-2-3-1-supplyNeeds': [
		{
			id: 'table-1',
			title: null,
			header: ['Genstand', 'Funktion', 'Vægt kg'],
			content: [
				['Mad', '', 'Tjek tidligere udregninger'],
				['Smeltekammer', 'Smelter is til drikkevand', '200 kg'],
				['Destilleringsapparat', 'Destillerer forurenet vand til drikkevand', '200 kg'],
				['Kran', 'Til at hejse is op fra krateret', '500 kg'],
				['Nye solpaneler', 'Leverer 50 W i timen eller 1800 kJ energi til basen per dag', '20 kg per stk.'],
				['Bioreaktor', 'Producerer 100 g mad per dag, men bruger 100 W for at køre', '270 kg per stk.'],
			]
		}
	],
	'page-2-3-1-calculateLoad': [
		{
			id: 'table-1',
			title: null,
			header: ['Genstand', 'Vægt'],
			content: [
				['Nye beboelsesmoduler', '15000 kg'],
				['200 nye solceller', '4000 kg'],
				['Reservedele til roveren', '300 kg'],
				['Reservedele til vandrensningsanlægget', '60 kg'],
				['Reservedele til elektrolysesystemet', '50 kg'],
				['Reservedele til hovedcomputeren', '20 kg'],
				['Reservedele til opvarmningssystemet', '80 kg'],
				['Reservedele til ventilationssystemet', '40 kg'],
				['Eksperimentelt udstyr', '100 kg'],
				['Reservedele til lys', '40 kg'],
				['Reservedele til plantekammer', '80 kg'],
				['Reservedele til kommunikationssystemet', '100 kg'],
				['30 nye Marsdragter', '1000 kg'],
				['Reservedele til vedligehold af beboelsesmoduler', '1000 kg'],
				['Medicin og andet den slags', '300 kg'],
				['Personlige goder til kolonisterne', '1000 kg'],
			]
		}
	]
};

module.exports = {basicPageTables, basicPageAudios};