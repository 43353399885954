import React from 'react';
import PropTypes from 'prop-types';
import {minigamesUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import Button from 'components/button/button';
import MissionControlMessage from 'components/layout/mission-control-message';
import './info-minigames-intro.scss';

const InfoMinigamesIntro = ({toggleInfoMinigame, page, isTeacher, infoMinigameStatus, setInfoMinigameStatus}) => {
	return (
		<div className="InfoMinigame">
			<div className="InfoMinigame-title">{page.title}</div>
			{page.storyText && <MissionControlMessage message={page.storyText} type={page.storyType} />}
			<div className="InfoMinigame-text">
				{renderMarkdown(page.text)}
			</div>
			{toggleInfoMinigame && <Button
				class={isTeacher ? '' : 'wide'}
				text={isTeacher ? minigamesUiTexts.browseMinigame : minigamesUiTexts.play + '!'}
				onClick={() => {toggleInfoMinigame(true);}}
				disabled={!isTeacher && ['ready', 'locked', 'closed'].includes(infoMinigameStatus)}
			/>}
			{(isTeacher && (infoMinigameStatus === 'ready' || infoMinigameStatus === 'closed')) && 
				<Button text={minigamesUiTexts.startMinigame} onClick={() => {setInfoMinigameStatus('open');}} 
				/>
			}
			{(isTeacher && infoMinigameStatus === 'open') && 
				<Button text={minigamesUiTexts.stopMinigame} onClick={() => {setInfoMinigameStatus('closed');}} 
				/>
			}
		</div>
	);
};

InfoMinigamesIntro.propTypes = {
	toggleInfoMinigame: PropTypes.func,
	page: PropTypes.object.isRequired,
	isTeacher: PropTypes.bool.isRequired,
	infoMinigameStatus: PropTypes.string.isRequired,
	setInfoMinigameStatus: PropTypes.func
};


export default InfoMinigamesIntro;
