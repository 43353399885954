import React from 'react';
import {loginUiTexts} from 'data/ui-texts';
import './game-loading.scss';

const GameLoading = () => {
	return (
		<div className="GameLoading">
			<div className="GameLoading-rocket-wrapper">
				<img className="GameLoading-rocket-tail"
					src={require('../../assets/images/rocket-tail.png')} alt="rocket"></img>
				<img className="GameLoading-rocket"
					src={require('../../assets/images/rocket-loading.svg')} alt="rocket"></img>
			</div>
			<div className="GameLoading-message">{loginUiTexts.loading} <span>.</span><span>.</span><span>.</span></div>
		</div>
	);
};

export default GameLoading;