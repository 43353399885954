import React from 'react';
// import PropTypes from 'prop-types';
import './tips.scss';

class Tips extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showTips: false,
		};
		this.toggleTips = this.toggleTips.bind(this);
	};

	toggleTips() {
		let showTips = !this.state.showTips;
		this.setState({showTips});
	}


	render() {
		return (
			<div className={'Tips' + (this.state.showTips ? ' show' : '')} onClick={this.toggleTips}>
				<h3>Markdown cheat sheet</h3>
				<table>
					<tbody>
						<tr><td><pre>*italic*</pre></td><td><i>italic</i></td></tr>
						<tr><td><pre>**bold**</pre></td><td><b>bold</b></td></tr>
						<tr><td><pre>* item <br />* item</pre></td><td><ul><li>item</li><li>item</li></ul></td></tr>
						<tr><td><pre>1. item <br />2. item</pre></td><td><ol><li>item</li><li>item</li></ol></td></tr>
						<tr><td><pre># Header 1</pre></td><td><h1>Header 1</h1></td></tr>
						<tr><td><pre>## Header 2</pre></td><td><h2>Header 2</h2></td></tr>
					</tbody>
				</table>
			</div>
		);
	}
}

// Tips.propTypes = {
// 	type: PropTypes.string
// };

export default Tips;