/**
 * Get the position of the draglayer 
 * @param {object} initialOffset 
 * @param {object} currentOffset 
 */
function getItemStyles(initialOffset, currentOffset) {
	if (!initialOffset || !currentOffset) {
		return {
			display: 'none',
		};
	}
	let { x, y } = currentOffset;
	const transform = `translate(${x}px, ${y}px)`;
	return {
		transform,
		WebkitTransform: transform
	};
}


export {getItemStyles};