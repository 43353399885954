const cyoaGamesData = [
	{
		id: 'cyoaGame-1',
		title: 'Start',
		entries: [
			{
				id: 'start',
				index: 0,
				texts: [
				`
					Team Cheetah har bedt os om at vejlede dem om, hvordan de skal komme hjem, før de løber tør for oxygen. Først skal vi hjælpe dem med at vælge den bedste vej hjem, og i løbet af de næste dage skal vi være klar til at rådgive dem, hvis de løber ind i problemer.
					Team Cheetah har foreslået 3 veje hjem, den direkte vej hjem gennem en sandslette, den lange vej hjem, som vil tage dem uden om stormen, og at køre direkte mod stormen for at komme i læ i en kløft, som løber hjem til basen. Vi har samlet noget information om de tre veje hjem, som team Cheetah foreslår. Der er også en side vi kan tilgå med generel information om roveren.
					
					Den lange omvej
					Afstand til basen: 600 km
					Afstand til sikkerhed for stormen: Omkring 250 km, hvis ikke stormen skifter retning
					Køreunderlag: Grus og småsten

					Sandslette
					Afstand til basen: 150 km
					Køreunderlag: Sand
					
					Kløften
					Afstand til basen: 280 km
					Afstand til sikkerhed for stormen: Omkring 110 km, men vi kører denne afstand direkte mod stormen.
					Storm Hastighed: 25 m/s
					Køreunderlag: Sand og grus
				`],
				choices: [
					{
						id: 'choice-0-0',
						index: 1,
						nextEntryId: 'charge-now',
						text: 'Kør den lange vej'
					},
					{
						id: 'choice-0-1',
						index: 2,
						nextEntryId: 'charge-now',
						text: 'Kør gennem Sandsletten'
					},
					{
						id: 'choice-0-2',
						index: 3,
						nextEntryId: 'charge-now',
						text: 'Kør mod Kløften'
					},
				]
			},
			/** Long Way Route */
			{
				id: 'charge-now',
				index: 1,
				texts:[
				`
					Besked fra Team Cheetah:
					Vi har modtaget jeres råd om rute, og vi er klar til at køre. Vi kan dog se, at der er 3 timer og 48 minutter til, solen går ned, og vi har kun 130 kWh ud af 200 kWh ladt op i batteriet. Skal vi oplade, før vi kører, eller køre med det samme?
				`],
				choices: [
					{
						id: 'choice-1-0',
						index: 1,
						nextEntryId: 'life-support',
						text: 'Lad op nu'
					},
					{
						id: 'choice-1-1',
						index: 2,
						nextEntryId: 'life-support',
						text: 'Kør med det samme'
					},
				]
			},
			{
				id: 'life-support',
				index: 2,
				texts:[
				`
					Besked fra Team Cheetah:
					Det lykkedes os at lade batteriet helt op, før natten faldt på, og vi er lige begyndt at køre. Vi kan dog se, at vi bruger 1 kWh per time på at holde roveren varm og andre life support-funktioner. Vi tror godt, at vi kan overleve i vores Marsdragter, hvis vi skruer life support ned på et minimum. Det vil spare os 0.8 kWh per time. Her bliver nok ret koldt, men måske er det det værd?
				`,
				`
					Besked fra Team Cheetah:
					Vi startede med at køre med det samme og er allerede kørt næsten 100 km. Nu er det ved at blive nat, og vi kan se, at vi bruger 1 kWh per time på at holde roveren varm og andre life support-funktioner. Vi tror godt, at vi kan overleve i vores Marsdragter, hvis vi skruer life support ned på et minimum. Det vil spare os 0.8 kWh per time. Her bliver nok ret koldt, men måske er det det værd?
				`],
				choices: [
					{
						id: 'choice-2-0',
						index: 1,
						nextEntryId: 'hill',
						text: 'Skru life support til minimum'
					},
					{
						id: 'choice-2-1',
						index: 2,
						nextEntryId: 'hill',
						text: 'Behold life support som det er'
					},
				]
			},
			{
				id: 'hill',
				index: 3,
				texts:[
				`
					Besked fra Team Cheetah:
					Her er ved at være temmelig koldt i roveren, men vi er OK. Vi kan dog se, at vores iltforbrug er gået op med omkring 0.1 liter per minut. Udover det har vi et problem. Vi er kommet til en skråning, som ikke var tydelig på kortet. På højdekurverne at dømme er skråningen 1 km høj og 10 km lang. Vi kan muligvis godt køre op ad skråningen, men vi er bange for, at vi ved at køre op ad en så stejl hældning vil dræne vores batteri. Der er en anden måde at komme op ad bakken. Hvis vi kører en omvej på ca. 100 km, vil vi også komme op på bakken, men stigningen vil være 10 gange mindre. Hvad skal vi gøre?
				`,
				`
					Besked fra Team Cheetah:
					Vi har et problem. Vi er kommet til en skråning, som ikke var tydelig på kortet. På højdekurverne at dømme er skråningen 1 km høj og 10 km lang. Vi kan muligvis godt køre op ad skråningen, men vi er bange for, at vi ved at køre op ad en så stejl hældning vil dræne vores batterier. Der er en anden måde at komme op ad bakken. Hvis vi kører en omvej på ca. 100 km, vil vi også komme op på bakken, men stigningen vil være 10 gange mindre. Hvad skal vi gøre?
				`],
				choices: [
					{
						id: 'choice-3-0',
						index: 1,
						nextEntryId: 'storm',
						text: 'Tag den korte, men stejle vej'
					},
					{
						id: 'choice-3-1',
						index: 2,
						nextEntryId: 'storm',
						text: 'Tag den ikke så stejle, men lange vej'
					},
				]
			},
			{
				id: 'storm',
				index: 4,
				texts: [
				`
					Besked fra Team Cheetah:
					Kalder basen, kalder basen. Kan I høre os? Vi nåede ikke ud af stormen og er nu fanget i den. Der er masser af støv i luften, og sigtbarheden er kun et par meter. Vi ved ikke, om vi tør køre videre i stormen, eller om vi skal vente på, at den driver over? SAT-OPS viste, at det ville tage mellem 15 og 20 timer, før stormen er passeret. Har vi nok oxygen til, at vi kan vente? Der er også en smule dagslys i stormen, så vi kan måske brugen tiden, mens vi venter på at lade vores batterier op?
				`],
				choices: [
					{
						id: 'choice-4-0',
						index: 1,
						nextEntryId: 'clean-sun-panels',
						text: 'Kør videre gennem stormen'
					},
					{
						id: 'choice-4-1',
						index: 2,
						nextEntryId: 'clean-sun-panels',
						text: 'Vent til stormen er drevet over'
					},
					{
						id: 'choice-4-2',
						index: 3,
						nextEntryId: 'clean-sun-panels',
						text: 'Lad batterierne op mens i venter'
					},
				]
			},
			{
				id: 'clean-sun-panels',
				index: 5,
				texts: [
				`
					Besked fra Team Cheetah:
					Vi har kørt og er kommet lidt tættere på basen, mens det stormede. Desværre kunne vi ikke se så meget, mens vi kørte, så vi har kørt ind i nogle sten og ned i nogle huller. Dette har medført skade på roveren, så vi kan ikke længere køre så hurtigt, og det koster os lidt mere energi at køre.
				`,
				`
					Besked fra Team Cheetah:
					Stormen har passeret, og vi er klar til at fortsætte. Vi håber bare, at vi stadig kan nå tilbage til basen, før vi løber tør for oxygen.
				`,
				`
					Besked fra Team Cheetah:
					Vi har fået noget strøm, mens vi ventede på, at stormen drev over. Vores solpaneler er dog blevet helt dækket af støv, så herfra bliver det nok svært at få ladet op, hvis vi ikke renser panelerne. Det vil nok tage os et par timer at få fjernet støvet, hvor vi så ikke kører. Skal vi gøre dette?
				`],
				choices: [
					{
						id: 'choice-5-0',
						index: 1,
						nextEntryId: 'crater',
						text: 'Rens solpaneler',
						requiredPrevChoice: [3]
					},
					{
						id: 'choice-5-1',
						index: 2,
						nextEntryId: 'crater',
						text: 'Lad dem være',
						requiredPrevChoice: [3]
					},
					{
						id: 'choice-5-2',
						index: 3,
						nextEntryId: 'crater',
						text: 'Fortsæt',
						requiredPrevChoice: [1, 2]
					},
				]
			},
			{
				id: 'crater',
				index: 6,
				texts: [
				`
					Besked fra Team Cheetah:
					Er kommet til et stort krater, og er lidt i tvivl, om det er bedst at køre ned i krateret og op på den anden side, eller om man skal køre udenom. Det vil tage lidt tid at køre ned i krateret og op på den anden side, og der skal bremses en del på vej ned. Bunden af krateret ser ud til at være grus og sten. Krateret ser ud til at være 200 meter dybt og ca 30 kilometer bredt.
					Man kan også køre uden om, hvilket gør, at vi skal køre længere. Udover det ser det ud til, at man ville være nødt til at køre gennem sand for at komme uden om krateret.
					Hvilken vej vil I råde til, at man tager?
				`],
				choices: [
					{
						id: 'choice-6-0',
						index: 1,
						nextEntryId: 'leaving-scientist',
						text: 'Kør ned i krateret'
					},
					{
						id: 'choice-6-1',
						index: 2,
						nextEntryId: 'leaving-scientist',
						text: 'Kør uden om'
					},
				]
			},
			{
				id: 'leaving-scientist',
				index: 7,
				texts: [
				`
					Besked fra Team Cheetah:
					Vi har snart ikke mere oxygen tilbage, og vi tror ikke, at vi kan nå tilbage til basen. Vi har snakket om det og er blevet enige om, at hvis der er en chance for, at en af os klarer det, er det bedre, end at vi begge dør. Vi har overvejet at trække lod om, hvem af os der ikke kommer med resten af vejen...
					Vi vil gerne høre, hvad I på basen tænker om dette?
				`],
				choices: [
					{
						id: 'choice-7-0',
						index: 1,
						nextEntryId: null,
						text: 'Efterlad den ene fra ekspeditionen',
					},
					{
						id: 'choice-7-1',
						index: 2,
						nextEntryId: null,
						text: 'Efterlad ikke nogen',
					},
				]
			},
			{
				id: 'one-lived',
				index: 8,
				texts: [
				`
					Den eneste overlevende fra Team Cheetah er ankommet sikkert til basen. Udmattet, meget trist og ked af det. Vi må håbe at det at se sin vej gå ud i Mars’ ødemark til sin død ikke giver varige traumer. Vi kan i de mindste glæde os over, at de ikke begge er døde. Mission Control vil sikkert gerne have en rapport over, hvordan det lykkedes jer at guide den overlevende kolonist sikkert hjem til basen gennem Mars’ ubarmhjertige landskab.
				`],
				choices: []
			},
			{
				id: 'both-lived',
				index: 9,
				texts: [
				`
					Team Cheetah er ankommet sikkert til basen, de er begge udmattede men lettede og i god behold. Mission Control vil sikkert gerne have en rapport over hvordan det lykkedes jer at guide dem sikkert hjem til basen gennem Mars utilgivendene landskab.
				`],
				choices: []
			},
			{
				id: 'both-died',
				index: 10,
				texts: [
				`
					På trods af vores hårde arbejde løb Team Cheetah tør for oxygen før de nåede hjem til basen. Tabet af to kolonister vil være hård for koloni, både psykisk men også tabet af ekspertise og arbejdskraft. Vi vidste godt at det var farligt at tage til Mars og at det ikke var sikkert alle ville klare den, men virkeligheden er hårdere end en hypotetisk mulighed. Vi må håbe at de resterende kolonister kan komme sig. Mission Control vil sikkert gerne have en rapport over hvad der skete, så vi måske i fremtiden kan undgå at noget lignende sker.
				`],
				choices: []
			},
			{
				id: 'both-died-one-left',
				index: 11,
				texts: [
				`
					På trods af vores hårde arbejde og det heroiske offer af den ene kolonist løb Team Cheetah tør for oxygen, før de nåede hjem til basen. Tabet af to kolonister vil være hårdt for kolonien, både psykisk, men betyder også tab af ekspertise og arbejdskraft. Vi vidste godt, at det var farligt at tage til Mars, og at det ikke var sikkert, at alle ville klare den, men virkeligheden er hårdere end en hypotetisk mulighed. Vi må håbe, at de resterende kolonister kan komme sig. Mission Control vil sikkert gerne have en rapport over, hvad der skete, så vi måske i fremtiden kan undgå, at noget lignende sker.
				`],
				choices: []
			},
			/** Dessert / Canyon Routes, changed entries indexes */
			{
				id: 'crater-dessert-canyon',
				index: 2,
				texts: [
				`
					Besked fra Team Cheetah:
					Vi er kommet til et stort krater og er lidt i tvivl, om det er bedst at køre ned i krateret og op på den anden side, eller om man skal køre udenom. Det vil tage lidt tid at køre ned i krateret og op på den anden side, og der skal bremses en del på vej ned. Bunden af krateret ser ud til at være grus og sten. Krateret ser ud til at være 200 meter dybt og cirka 30 kilometer bredt. Man kan også køre udenom, hvilket gør, at vi skal køre længere. Udover det ser det ud til, at man ville være nødt til at køre gennem sand for at komme uden om krateret.
					Hvilken vej vil I råde til, at man tager?
				`],
				choices: [
					{
						id: 'choice-2-0',
						index: 1,
						nextEntryId: null,
						text: 'Kør ned i krateret'
					},
					{
						id: 'choice-2-1',
						index: 2,
						nextEntryId: null,
						text: 'Kør uden om'
					},
				]
			},
			{
				id: 'life-support-dessert-canyon',
				index: 3,
				requiredChoiceIdIndex: 1, // check this.state.choicesMade [1] if it is 1 or 2 and get according text
				texts:[
				`
					Besked fra Team Cheetah:
					Det lykkedes os at lade batteriet helt op før natten faldt på og vi er lige begyndt at køre. Vi kan dog se at vi bruger 1 kWh per time på at holde roveren varm og andre life support funktioner, vi tror godt at vi kan overleve i vores mars dragter hvis vi skrue life support ned på et minimum, det vil spare os 0.8 kWh per time. Her bliver nok ret koldt, men måske er det det værd?
				`,
				`
					Besked fra Team Cheetah:
					Det er ved at blive nat, og vi kan se, at vi bruger 1 kWh per time på at holde roveren varm og andre life support-funktioner. Vi tror godt, at vi kan overleve i vores Marsdragter, hvis vi skruer life support ned på et minimum. Det vil spare os 0.8 kWh per time. Her bliver nok ret koldt, men måske er det det værd?
				`],
				choices: [
					{
						id: 'choice-2-0',
						index: 1,
						nextEntryId: null,
						text: 'Skru life support til minimum'
					},
					{
						id: 'choice-2-1',
						index: 2,
						nextEntryId: null,
						text: 'Behold life support som det er'
					},
				]
			},
			{
				id: 'leaving-scientist-dessert',
				index: 6,
				texts: [
				`
					Besked fra Team Cheetah:
					Vi har snart ikke mere oxygen tilbage, og vi tror ikke, at vi kan nå tilbage til basen. Vi har snakket om det og er blevet enige om, at hvis der er en chance for, at en af os klarer det, er det bedre, end at vi begge dør. Vi har overvejet at trække lod om, hvem af os der ikke kommer med resten af vejen...
					Vi vil gerne høre hvad I på basen tænker om dette?
				`],
				choices: [
					{
						id: 'choice-6-0',
						index: 1,
						nextEntryId: null,
						text: 'Efterlad den ene fra ekspeditionen',
					},
					{
						id: 'choice-6-1',
						index: 2,
						nextEntryId: null,
						text: 'Efterlad ikke nogen',
					},
				]
			},
			{
				id: 'hill-canyon',
				index: 6,
				texts: [
				`
					Besked fra Team Cheetah:
					Vi har et problem. Vi er kommet til en skråning i kløften, som ikke var tydelig på kortet. På højdekurverne at dømme er skråningen 1 km høj og 10 km lang, hvis man køre gennem denne del af kløften. Vi kan muligvis godt køre op ad skråningen, men vi er bange for, at vi ved at køre op ad en så stejl hældning vil dræne vores batterier. Der er en anden måde at komme op ad bakken. Længere tilbage i kløften var der en forgrening. Hvis man kører tilbage og tager den omvej, vil det ligge ca. 50 km til ruten, men man vil også komme op på bakken, og stigningen vil være 5 gange mindre. Hvad er bedst?
				`],
				choices: [
					{
						id: 'choice-6-0',
						index: 1,
						nextEntryId: null,
						text: 'Tag den korte, men stejle vej',
					},
					{
						id: 'choice-6-1',
						index: 2,
						nextEntryId: null,
						text: 'Tag den ikke så stejle, men lange vej',
					},
				]
			},
		]
	},
	{
		id: 'cyoaGame-2',
		title: 'Atom reaktoren',
		entries: [
			{
				id: 'start',
				index: 0,
				texts: [`
					Teksts...
					Diagram over atomkraftværket...
				`],
				choices: []
			},
		]
	}
];

module.exports = {
	cyoaGamesData
};