import React from 'react';
import PropTypes from 'prop-types';
import {storyTiersData} from 'data/content-data';
import {getStatusOfStory} from 'helpers/game-flow-helper';
import './overview-stories.scss';

const OverviewStories = ({isTeacher, storyIndex, game, stories, handleSelectStory}) => {
	return (
		<div className="OverviewStories">
			{/* Loop over story tiers */}
			{storyTiersData.map((tier) => {
				return (
					<div key={tier.index} className={'OverviewStories-tier OverviewStories-tier--' + tier.index}>
						{/* Loop over stories in tier */}
						{tier.storyIds.map((storyId) => {
							let story = stories.filter((story) => {return story.id === storyId;})[0];

							/* Get status of story */
							let status = getStatusOfStory(game, story);

							return (
								<div 
									key={story.storyIndex} 
									className={
										'OverviewStories-story OverviewStories-story--' + story.storyIndex + 
										' OverviewStories-story--' + status +
										' OverviewStories-story--' + (isTeacher ? 'teacher' : 'group') +
										(story.storyIndex === storyIndex ? ' OverviewStories-story--selected' : '')
									}
									onClick={()=>{
										if (
											isTeacher || 
											(status !== 'ready' && status !== 'locked')
										) handleSelectStory(story.storyIndex);
										
									}}
								>{story.title}</div>
							);
						})}
					</div>
				);
			})}
			
		</div>
	);
};

OverviewStories.propTypes = {
	isTeacher: PropTypes.bool.isRequired,
	storyIndex: PropTypes.number.isRequired,
	game: PropTypes.object.isRequired,
	stories: PropTypes.array.isRequired,
	handleSelectStory: PropTypes.func.isRequired,
};

export default OverviewStories;
