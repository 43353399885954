import React from 'react';
import appConfig from 'config/app.config';
import {getCookie, setCookie} from 'helpers/cookie-helper';
import CookieConsent from './cookie-consent';

class CookieConsentController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showCookieConsent: false
		};
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		let cookieConsent = getCookie(appConfig.cookiesAcceptedCookieName);
		if (!cookieConsent) this.setState({showCookieConsent: true});
	}

	/* Accept and close cookies popup */
	handleClosePopup = () => {
		setCookie(appConfig.cookiesAcceptedCookieName, 'ok');
		this.setState({showCookieConsent: false});
	}

	/* Render component */
	render = () => {
		if (this.state.showCookieConsent) {
			return <CookieConsent handleClosePopup = {this.handleClosePopup}/>;
		}
		return null;
	}
}

export default CookieConsentController;