import React from 'react';
import PropTypes from 'prop-types';
import ManageSubmissions from './manage-submissions';
import InstructionsController from 'components/instructions/instructions-controller';
import './investigation.scss';
import './teacher-view.scss';

const TeacherView = (props) => {
	let {
		isOpenForSubmissions,
		page,
		investigationStep,
		nextStepIndex,
		prevStepIndex,
		investigationId, 
		// investigationTitle, 
		// teacherInstructions,
		groups,
		selectedGroups,
		toggleIsOpenForSubmissions,
		toggleGroup,
		updateGroups,
		goToInvestigationStep,
		toggleInvestigation, 
		openPopup,
		closePopup,
		game,
		updateGame,
		nrOfSteps,
		toggleTutorial
	} = props;

	return (
		<div className={'Investigation Investigation--teacher Investigation--' + investigationId}>
			<div className="Investigation-close" onClick={() => {toggleInvestigation(false);}} />
			<InstructionsController
				isTeacher={true}
				page={page}
				updateGame={updateGame}
				game={game}
				type='popup'
			/>
			<ManageSubmissions 
				investigationId={investigationId}
				investigationStep={investigationStep}
				nextStepIndex={nextStepIndex}
				prevStepIndex={prevStepIndex}
				groups={groups} 
				selectedGroups={selectedGroups}
				isOpenForSubmissions={isOpenForSubmissions} 
				toggleIsOpenForSubmissions={toggleIsOpenForSubmissions}
				toggleGroup={toggleGroup}
				updateGroups={updateGroups}
				goToInvestigationStep={goToInvestigationStep}
				openPopup={openPopup}
				closePopup={closePopup}
				nrOfSteps={nrOfSteps}
				toggleTutorial={toggleTutorial}
			/>

		</div>


	);
};


TeacherView.propTypes = {
	isOpenForSubmissions: PropTypes.bool.isRequired,
	page: PropTypes.object.isRequired,
	investigationStep: PropTypes.object.isRequired,
	nextStepIndex: PropTypes.number,
	prevStepIndex: PropTypes.number,
	investigationId: PropTypes.string.isRequired,
	// investigationTitle: PropTypes.string.isRequired,
	// teacherInstructions: PropTypes.string,
	groups: PropTypes.array.isRequired,
	selectedGroups: PropTypes.array.isRequired,
	toggleIsOpenForSubmissions: PropTypes.func.isRequired,
	toggleGroup: PropTypes.func.isRequired,
	updateGroups: PropTypes.func.isRequired,
	goToInvestigationStep: PropTypes.func.isRequired,
	toggleInvestigation: PropTypes.func.isRequired,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	updateGame: PropTypes.func,
	game: PropTypes.object.isRequired,
	nrOfSteps: PropTypes.number.isRequired,
	toggleTutorial: PropTypes.func,
};

export default TeacherView;
