let popupsData = {
	tagBaseElement: {
		closeBtn: true,
		title: 'TEAM %groupName%',
		text: [
			'Tillykke, på baggrund af jeres gode resultater, har Mission Control besluttet at lade jer navngive den sektion på basen, som I netop har fuldført.',
			'I kan skrive op til 14 tegn.'
		],
		form: [
			{type: 'text', label: '', name: 'tagName', placeholder:'Skriv et tag'}
		],
		buttonTexts: { submit: 'Gem'}
	},
	minigamePartAlreadyCompleted: {
		closeBtn: true,
		title: 'TEAM %groupName%',
		text: [
			'Et gruppemedlem har allerede fuldført denne del.'
		],
		form: null,
		buttonTexts: null
	}
};

export default popupsData;