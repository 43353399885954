import React from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import CameraBase from './camera-base';
import CameraPopup from './camera-popup';
import './camera.scss';

const Camera = ({
	page,
	camIndex,
	camerasData,
	showCamIndex,
	elements
}) => {

	return (
		<React.Fragment>
			<div className={'Camera Camera--' + page.type}>
				<div className="Camera-title">{generalUiTexts.camera}</div>
				<div className="Camera-images">
					{camerasData.map((camera) => {
						return (
							<div 
								key={camera.id} 
								className={'Camera-frame Camera-frame--' + camera.id
									+ (camera.onClick ? ' Camera-frame--clickable' : '')}
								onClick={() => {return camera.onClick && camera.onClick.func(camera.onClick.params);}}>
								{camera.id === 'cam1' && camera.type !== 'waveSimulator'
									? <CameraBase
										type="Camera"
										elements={elements}
										missionId={'mission-' + page.storyIndex + '-' + (page.missionIndex + 1)}
									/>
									: camera.id === 'cam3'
										? <CameraBase
											type="BaseOverview"
											elements={elements}
											missionId={'mission-' + page.storyIndex + '-' + (page.missionIndex + 1)}
										/>
										: <div className="Camera-image" style={camera.style} />}
								{camera.overlay.hasOverlay
									&& <div className={'Camera-overlay Camera-overlay--'
										+ camera.overlay.class}/>}
								<div className="Camera-info1">{camera.title}<span>{camera.source}</span></div>
								<div className="Camera-info2">{camera.name}<span>{camera.status}</span></div>
							</div>
						);
					})}
				</div>
			</div>
			{camIndex !== null &&
				<CameraPopup
					cameraData={camerasData[camIndex]}
					showCamIndex={showCamIndex}
					elements={elements}
					page={page}
				/>
			}
		</React.Fragment>
	);
};

Camera.propTypes = {
	page: PropTypes.object.isRequired,
	camIndex: PropTypes.number,
	camerasData: PropTypes.array.isRequired,
	showCamIndex: PropTypes.func.isRequired,
	elements: PropTypes.array.isRequired
};


export default Camera;
