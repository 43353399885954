import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {subjectIds, subjectsData} from 'data/content-data';
import './stories.scss';

class Stories extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	};

	render() {
		let {
			isEditing,
			isSaving,
			stories,
			missions,
			selectedStory,
			selectedMission,
			selectedSessions,
			errorMsg,
			selectStory,
			selectMission,
			updateStory,
			addStoryObjective,
			updateMission,
			updateSession,
			handleSave,
			goToPage
		} = this.props;
	
		let saveBtnClass = 'StoriesAdmin-saveBtn ' + (isSaving ? ' saving' : '');
		if (isEditing) saveBtnClass += ' editing';
		if (!isEditing) saveBtnClass += ' disabled';
	
		return (
			<div className="StoriesAdmin">
				<div className="StoriesAdmin-title">Rediger historier, missioner og spilgange</div>
				<p className="StoriesAdmin-backBtn" onClick={() => {goToPage(null);}}>&lArr; tilbage</p>
	
				{/* Select story */}
				<form className="StoriesAdmin-navForm" onChange={(event) => {selectStory(event);}}>
					<select>
						{stories.map((story, index) => {
							return <option key={index} value={index}>{story.title}</option>;
						})}
					</select>
				</form>

				{/* Select mission */}
				{(selectedStory && selectedStory.missionIds && selectedStory.missionIds.length > 0) &&
					<form className="StoriesAdmin-navForm" onChange={(event) => {selectMission(event);}}>
						<select>
							{selectedStory.missionIds.map((missionId, index) => {
								let missionTitle = 
									missions.filter((mission) => {return mission.id === missionId;})[0].title;
								return (
									<option key={index} value={missionId}>{missionTitle}</option>
								);
							})}
						</select>
					</form>}

				<form className="StoriesAdmin-form">
					{/* Save */}
					<div 
						className={saveBtnClass}
						onClick={(event) => {handleSave(event);}}>Gem</div>
					{errorMsg && <div>{errorMsg}</div>}

					{/* Edit story */}
					<hr></hr>
					<div className="StoriesAdmin-title">Historie</div>
					<div className="StoriesAdmin-inputRow">
						<span className="StoriesAdmin-label">Titel:</span>
						<input 
							className="StoriesAdmin-input"
							name="title"
							value={selectedStory ? selectedStory.title : ''}
							placeholder=""
							onChange={(event) => {updateStory(event);}}
						/>
					</div>
					<div className="StoriesAdmin-inputRow">
						<span className="StoriesAdmin-label">Intro:</span>
						<textarea 
							className="StoriesAdmin-textarea small"
							name="introduction"
							value={selectedStory && selectedStory.introduction ? selectedStory.introduction : ''}
							placeholder=""
							onChange={(event) => {updateStory(event);}}
						/>
					</div>
					<div className="StoriesAdmin-inputRow">
						<span className="StoriesAdmin-label">Objektiver:</span>
						<div className="StoriesAdmin-addBtn" onClick={() => {addStoryObjective();}}>
							+ Tilføj objektiv
						</div>						
						{(selectedStory && selectedStory.objectives) && 
							selectedStory.objectives.map((objective, index) => {
								return (
									<p key={index}>
										<input 
											className="StoriesAdmin-input long"
											name="objectives"
											value={objective ? objective : ''}
											placeholder=""
											onChange={(event) => {updateStory(event, index);}}
										/>
									</p>
								);
							})}
					</div>

					{/* Edit missions */}
					<hr></hr>
					<div className="StoriesAdmin-title">Mission</div>
					<div className="StoriesAdmin-inputRow">
						<span className="StoriesAdmin-label">Titel:</span>
						<input 
							className="StoriesAdmin-input"
							name="title"
							value={selectedMission && selectedMission.title ? selectedMission.title : ''}
							placeholder=""
							onChange={(event) => {updateMission(event);}}
						/>
					</div>
					<div className="StoriesAdmin-inputRow">
						<span className="StoriesAdmin-label">Description:</span>
						<textarea 
							className="StoriesAdmin-textarea small"
							name="description"
							value={selectedMission && selectedMission.description ? selectedMission.description : ''}
							placeholder=""
							onChange={(event) => {updateMission(event);}}
						/>
					</div>
					<div className="StoriesAdmin-inputRow">
						<span className="StoriesAdmin-label">Goal:</span>
						<textarea 
							className="StoriesAdmin-textarea small"
							name="goal"
							value={selectedMission && selectedMission.goal ? selectedMission.goal : ''}
							placeholder=""
							onChange={(event) => {updateMission(event);}}
						/>
					</div>

					{/* Edit sessions */}
					<hr></hr>
					<div className="StoriesAdmin-title">Spilgang(e)</div>
					{selectedSessions && selectedSessions.map((selectedSession, index) => {
						let isExperiment = (selectedSession.hasOwnProperty('isExperiment') 
							? selectedSession.isExperiment
							: false
						);
						return (
							<React.Fragment key={index}>
								<div className="StoriesAdmin-inputRow">
									<span className="StoriesAdmin-label">Titel:</span>
									<input 
										className="StoriesAdmin-input"
										name="title"
										value={selectedSession.title ? selectedSession.title : ''}
										placeholder=""
										onChange={(event) => {updateSession(event, selectedSession.id);}}
									/>
								</div>
								<div className="StoriesAdmin-inputRow">
									<span className="StoriesAdmin-label">Varighed:</span>
									<input 
										className="StoriesAdmin-input short"
										name="minutes"
										value={selectedSession.minutes ? selectedSession.minutes : ''}
										placeholder=""
										onChange={(event) => {updateSession(event, selectedSession.id);}}
									/> minutter
								</div>
								<div className="StoriesAdmin-inputRow">
									<span className="StoriesAdmin-label">Eksperiment?</span>
									<div className="StoriesAdmin-radioButtons">
										<div className="StoriesAdmin-radioButtonWrap">
											<input 
												className="StoriesAdmin-radio"
												type="radio" 
												name={'isExperiment-' + selectedSession.id} 
												value={'true'}
												checked={isExperiment}
												onChange={(event) => {updateSession(event, selectedSession.id);}}
											/>Ja
										</div>
										<div className="StoriesAdmin-radioButtonWrap">
											<input 
												className="StoriesAdmin-radio"
												type="radio" 
												name={'isExperiment-' + selectedSession.id} 
												value={'false'}
												checked={!isExperiment}
												onChange={(event) => {updateSession(event, selectedSession.id);}}
											/>Nej
										</div>
									</div>
								</div>
								<div className="StoriesAdmin-inputRow">
									<span className="StoriesAdmin-label">Emner:</span>
									<div className="StoriesAdmin-checkboxes">
										{subjectIds.map((subjectId) => {
											let checked = (selectedSession.subjectIds && 
												selectedSession.subjectIds.indexOf(subjectId) >= 0);
											return (
												<div key={subjectId} className="StoriesAdmin-checkboxWrap">
													<input 
														className="StoriesAdmin-checkbox"
														type="checkbox"
														name={'subjectIds-' + selectedSession.id}
														value={subjectId}
														checked={checked}
														onChange={(event) => {
															updateSession(event, selectedSession.id);
														}}
													/>{subjectsData[subjectId].name}
												</div>
											);
										})}
									</div>
								</div>
								<p>&nbsp;</p>
								<p>&nbsp;</p>
							</React.Fragment>
						);
					})}
				</form>
			</div>
		);
	}
};

Stories.propTypes = {
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	stories: PropTypes.array.isRequired,
	missions: PropTypes.array.isRequired,
	selectedStory: PropTypes.object,
	selectedMission: PropTypes.object,
	selectedSessions: PropTypes.array,
	errorMsg: PropTypes.string,
	selectStory: PropTypes.func.isRequired,
	selectMission: PropTypes.func.isRequired,
	updateStory: PropTypes.func.isRequired,
	addStoryObjective: PropTypes.func.isRequired,
	updateMission: PropTypes.func.isRequired,
	updateSession: PropTypes.func.isRequired,
	handleSave: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default Stories;