import React from 'react';
import PropTypes from 'prop-types';
import './stats.scss';
import Button from 'components/button/button';
import {Line} from 'react-chartjs-2';
import {formatDateStats} from 'helpers/date-helper';
import {statsUiTexts} from 'data/ui-texts';

const Stats = (props) => {

	let {
		isLoading,
		users,
		games,
		goToPage,
		downloadEmails,
		chartData,
	} = props;

	if (isLoading) {
		return (
			<div className="StatsAdmin">
				<p className="StatsAdmin-backBtn" onClick={() => {goToPage(null);}}>&lArr; {statsUiTexts.goback}</p>
				<div className="StatsAdmin-title">{statsUiTexts.title}</div>
				<p className="StatsAdmin-loading" > {statsUiTexts.loading} 
					<span>.</span><span>.</span><span>.</span></p>
			</div>
		);
	}

	return (
		<div className="StatsAdmin">
			<p className="StatsAdmin-backBtn" onClick={() => {goToPage(null);}}>&lArr; {statsUiTexts.goback}</p>
			<div className="StatsAdmin-title">{statsUiTexts.title}</div>

			{/* Chart */}
			<div className="StatsAdmin-chart">
				<Line data={chartData} options={{maintainAspectRatio: false}}/>
			</div>

			{/* Overview */}
			<div className='StatsAdmin-overview'>
				<p className='StatsAdmin-overviewTitle'>{statsUiTexts.overview}</p>
				<div className='StatsAdmin-overviewContent'>
					<p>
						<span>{statsUiTexts.teachers} </span>{users.length}
					</p>
					<Button class={'StatsAdmin-downloadBtn'} text={statsUiTexts.buttontext} 
						onClick={downloadEmails} />
				</div>
			</div>

			

			<div className={'StatsAdmin-users'}>
				<table>
					<thead>
						<tr>
							<td>{statsUiTexts.email}</td>
							<td>{statsUiTexts.created}</td>
							<td>{statsUiTexts.games}</td>
						</tr>
					</thead>
					<tbody>	
						{users.map((user, index) => {
							let numberOfGames = games.filter((game) => {
								return game.hasOwnProperty('teacherId') && game.teacherId !== null 
								&& game.teacherId === user.id;
							}).length;
							return (
								<tr key={index}>
									<td>{user.email}</td>
									<td>{user.created ? formatDateStats(user.created) : ''}</td>
									<td>{numberOfGames}</td>
								</tr>
							);
						})}
						
					</tbody>
				</table>

			</div>
		</div>
	);
};

Stats.propTypes = {
	goToPage: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	downloadEmails: PropTypes.func.isRequired,
	users: PropTypes.array.isRequired,
	games: PropTypes.array.isRequired,
	chartData: PropTypes.object.isRequired,
};

export default Stats;