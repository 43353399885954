import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {storyUiTexts} from 'data/ui-texts';
import './mission-control-message.scss';

const MissionControlMessage = ({message, type}) => {

	/* Default message is from mission control */
	let startMsg = storyUiTexts.missionControlStart;
	let endMsg = storyUiTexts.missionControlEnd;

	if (type === 'shipLog') {
		startMsg = storyUiTexts.shipLogStart;
		endMsg = storyUiTexts.shipLogEnd;
	}
	
	return (
		<div className={'MissionControlMessage' + (type ? ' MissionControlMessage--' + type : '')}>
			{startMsg && <div className="MissionControlMessage-start"><span>&lt;</span>{startMsg}:</div>}
			<div className="MissionControlMessage-content">{renderMarkdown(message)}</div>
			{endMsg && <div className="MissionControlMessage-end"><span>&lt;</span>{endMsg}</div>}
		</div>
	);
};

MissionControlMessage.defaultProps = {
	type: 'missionControlMessage'
};


MissionControlMessage.propTypes = {
	message: PropTypes.string.isRequired,
	type: PropTypes.string
};

export default MissionControlMessage;
