const hiddenBaseMinigamesImages = [
	{
		id: 'office',
		filename: 'image-office.png',
		graph: 'graph-office.png',
		title: 'Kontor',
		information: [
			'Dimensioner: 10m lang, 7m bred, 2.2m høj',
			'Max effekt af varmelegeme: 2000 W'
		]
	},
	{
		id: 'workshop-lab',
		filename: 'image-workshop-lab.png',
		graph: 'graph-workshop-lab.png',
		title: 'Værksted og laboratorie',
		information: [
			'Dimensioner: 20 lang, 10m bred, 3m høj',
			'Max effekt af varmelegeme: 5000 W'
		]
	},
	{
		id: 'kitchen',
		filename: 'image-kitchen.png',
		graph: 'graph-kitchen.png',
		title: 'Køkken',
		information: [
			'Dimensioner: 15 lang, 10m bred, 2.2m høj',
			'Max effekt af varmelegeme: 3500 W'
		]
	},
	{
		id: 'living',
		filename: 'image-living.png',
		graph: 'graph-common.png',
		title: 'Opholdsrum',
		information: [
			'Dimensioner: 30 lang, 15m bred, 2.2m høj',
			'Max effekt af varmelegeme: 7000 W'
		]
	},
	{
		id: 'technic',
		filename: 'image-technic.png',
		graph: 'graph-common.png',
		title: 'Teknik',
		information: [
			'Dimensioner: 15 m lang, 5m bred, 4 m høj',
			'Max effekt af varmelegeme: 500 W'
		]
	},
	{
		id: 'reactor',
		filename: 'image-reactor.png',
		graph: 'graph-reactor.png',
		title: 'Reaktor',
		information: [
			'Dimensioner: radius 7.5 m, højde 8 m',
			'Max effekt af varmelegeme: 0 W'
		]
	},
	{
		id: 'control',
		filename: 'image-control.png',
		graph: 'graph-common.png',
		title: 'Kontrolrum',
		titleDash: 'Kontrol-rum',
		information: [
			'Dimensioner: 7 m lang, 7 m bred, 2.2 m høj',
			'Max effekt af varmelegeme: 400 W'
		]
	},
	{
		id: 'storage',
		filename: 'image-storage.png',
		graph: 'graph-common.png',
		title: 'Opbevaring',
		information: [
			'Dimensioner: 8 m lang, 7 m bred, 4 m høj',
			'Max effekt af varmelegeme: 200 W'
		]
	},
];

const infoMinigamesData = [
	{
		id: 'infoMinigame-1',
		images: [
			{
				id: 'rock-1',
				filename: 'image-rock-1.png',
				title: 'Sten 1'
			},
			{
				id: 'rock-2',
				filename: 'image-rock-2.png',
				title: 'Sten 2'
			},
			{
				id: 'rock-3',
				filename: 'image-rock-3.png',
				title: 'Sten 3'
			}
		],
		xAxisValues: ['A', 'B', 'C', 'D'],
		yAxisValues: ['1', '2', '3', '4'],
		steps: ['choose-rock', 'choose-coordinates', 'show-graph']
	},
	{
		id: 'infoMinigame-2',
		images: hiddenBaseMinigamesImages,
		constructionMethods: null,
		steps: []
	},
	{
		id: 'infoMinigame-3',
		images: hiddenBaseMinigamesImages,
		constructionMethods: [
			{
				id: 'melting',
				title: 'Smelting',
				text: [
					`<strong>Energiforbrug</strong>
					• Varme Kapasitet: 0.8 J/gC
					• Smeltetemperatur: 340 J/g
					• Smeltepunkt: 1200 C
					
					<strong>Fabrikations tid</strong>
					• Størknings Tid: 24 timer
					• Indsamlings Tid: 50 kg/time
					• Støbetid: 0.04 m^3/time
					
					<strong>Termisk isoleringsevne</strong>
					• Varmeledningsevne: 0.1 W/m*K
					
					<strong>Absorbtionsevne</strong>
					• Linære absorbtionskoefficient: 11.26 m^-1
					
					<strong>Holdbarhed</strong>
					• Ekstremt holdbart
					• Næsten ingen skade fra mars klima
					 
					<strong>Andet</strong>
					• Densitet: 3000 kg/m^3
					`,
					`<strong>Beskrivelse:</strong>
					Robotter indsamler sten og grus fra mars overflade.
					Materialet afleveres i en smelteovn som laver stenen til en slags lava. Denne “lava” bliver af andre robotter støbt til en mur, lag for lag. Hver lag er 20 cm tykt, og når laget er størknet kan næste lag laves. En smeltningsmur er lufttæt.
					`
				]
			},
			{
				id: 'sintering',
				title: 'Sintring',
				text: [
					`<strong>Energiforbrug</strong>
					• Varme Kapasitet: 0.8 J/gC
					• Tryk energi til 14 MPa: 11 kJ/mursten
					• Sintring temperatur: 1000 C
					
					<strong>Fabrikations tid</strong>
					• Presse Tid per 20 sten: 3 timer
					• Indsamlings Tid: 50 kg/time
					• Konstruktionstid: 0.5 m^3/time
					
					<strong>Termisk isoleringsevne</strong>
					• Varmeledningsevne: 0.15 W/m*K
					
					<strong>Absorbtionsevne</strong>
					• Linære absorbtionskoefficient: 9.38 m^-1
					
					<strong>Holdbarhed</strong>
					• Ret holdbart
					• Næsten ingen skade fra mars klima
					
					<strong>Andet</strong>
					• Densitet: 2500 kg/m^3
					`,
					`<strong>Beskrivelse:</strong>
					Robotter indsamler sten og grus fra mars overflade.
					Materialet afleveres i en presse, som oppvarming materialet og presser det med 14 MPa til mursten på 10x10x10 cm i størrelse. Disse mursten bliver så langt så tæt som muligt. En stenmur af denne slags er ikke lufttæt.
					`
				]
			},
			{
				id: 'cold-pressing',
				title: 'Koldpresning',
				text: [
					`<strong>Energiforbrug</strong>
					• Vandproduktion: 418 J/g
					• Tryk energi til 13 MPa: 10 kJ/mursten
					
					<strong>Fabrikations tid</strong>
					• Presse Tid per 20 sten: 1 time
					• Indsamlings Tid: 50 kg/time
					• Konstruktionstid: 0.5 m^3/time
					
					<strong>Termisk isoleringsevne</strong>
					• Varmeledningsevne: 0.3 W/m*K
					
					<strong>Absorbtionsevne</strong>
					• Linære absorbtionskoefficient: 8.26 m^-1
					
					<strong>Holdbarhed</strong>
					• Ikke så holdbar
					• Vand fordamper fra stenen og den nedbrydes dermed over tid 
					
					<strong>Andet</strong>
					• Densitet: 2200 kg/m^3
					• Vandindenhold: 20 % (masse)
					`,
					`<strong>Beskrivelse:</strong>
					Robotter indsamler sten og grus fra mars overflade.
					Materialet afleveres i en presse hvor det blandes med vand og presser med 13 MPa til mursten på 10x10x10 cm i størrelse. Disse mursten bliver så langt så tæt som muligt. En stenmur af denne slags er ikke lufttæt.
					`
				]
			},
			{
				id: 'concrete',
				title: 'Svovlbeton',
				text: [
					`<strong>Energiforbrug</strong>
					• Svovl Udvinding: 350 J/g
					• Smeltning af svovl: 213 J/g
					
					<strong>Fabrikations tid</strong>
					• Størknings Tid: 2 timer
					• Indsamlings Tid: 50 kg/time
					• Svovl udvinding: 1 kg/time
					• Støbetid: 0.04 m^3/time
					
					<strong>Termisk isoleringsevne</strong>
					• Varmeledningsevne: 0.8 W/m*K
					
					<strong>Absorbtionsevne</strong>
					• Linære absorbtionskoefficient: 9.0 m^-1
					
					<strong>Holdbarhed</strong>
					• Ret holdbar.
					• Sprækker hurtigt ved store temperatursvingninger
					• Svovl fordamper langsomt I tynd atmosphere
					
					<strong>Andet</strong>
					• Densitet: 2400 kg/m^3 
					• Svovl Indenhold: 15 % (masse)
					`,
					`<strong>Beskrivelse:</strong>
					Robotter indsamler sten og grus fra mars overflade.
					Dette blandes med flydende svovl som virker som bindingmidlet i betongen. Svovl betonen støbes i 20 cm tykke lag som skal størkne før næste lag bliver støbt ovenpå.
					Svovlen bliver udvundet fra grus på mars overflade og smeltet før det blandes med grussen til beton.
					`
				]
			},
		],
		steps: []
	},
];

module.exports = {
	infoMinigamesData
};