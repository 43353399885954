const cratersData = [
	{
		index: 1,
		id: 'arandas',
		name: 'Arandas',
	},
	{
		index: 2,
		id: 'bacolor',
		name: 'Bacolor',
	},
	{
		index: 3,
		id: 'eberswalde',
		name: 'Eberswalde',
	},
	{
		index: 4,
		id: 'jezero',
		name: 'Jezero',
	
	},
	{
		index: 5,
		id: 'korolev',
		name: 'Korolev',
	},
	{
		index: 6,
		id: 'lowell',
		name: 'Lowell',
	},
	{
		index: 7,
		id: 'magong',
		name: 'Magong',
	},
	{
		index: 8,
		id: 'terby',
		name: 'Terby'
	},
	{
		index: 9,
		id: 'victoria',
		name: 'Victoria'
	},
	{
		index: 10,
		id: 'zunil',
		name: 'Zunil'
	}
];

module.exports = {
	cratersData
};