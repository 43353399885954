import React from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/logo/logo';
import ControlPanel from 'components/control-panel/control-panel';
import './game.scss';

const Game = (props) => {
	let {
		isTeacher, 
		popupIsOpen, 
		fullscreenAllowed,
		isFullscreen,
		gameCode,
		groupIndex,
		pageId, 
		userEmail,
		showPage, 
		handleToggleFullscreen,
		handleLogout,
		children,
		baseIsOpen
	} = props;

	let gameSection = (pageId.indexOf('-') >= 0 ? pageId.substr(0, pageId.indexOf('-')) : pageId);

	let className = 'Game Game--' + gameSection + 
		(isTeacher ? ' Game--teacher' : ' Game--group') +
		(popupIsOpen ? ' Game--showPopup' : '') +
		(baseIsOpen ? ' Game--showBase' : '');

	let logoLink = null;
	if (gameSection === 'page') {
		let storyIndex = 0;
		[, storyIndex] = pageId.split('-');
		logoLink = () => {showPage('overview-' + storyIndex);};
	}

	return (
		<div className={className}>
			{children}
			<Logo
				gameSection={gameSection} 
				onClick={logoLink}
			/>
			<ControlPanel
				isTeacher={isTeacher}
				fullscreenAllowed={fullscreenAllowed}
				isFullscreen={isFullscreen}
				gameCode={gameCode}
				groupIndex={groupIndex}
				gameSection={gameSection} 
				userEmail={userEmail}
				showPage={showPage} 
				handleToggleFullscreen={handleToggleFullscreen}
				handleLogout={handleLogout}
			/>
		</div>
	);
};

Game.propTypes = {
	isTeacher: PropTypes.bool.isRequired,
	popupIsOpen: PropTypes.bool.isRequired,
	fullscreenAllowed: PropTypes.bool.isRequired,
	isFullscreen: PropTypes.bool.isRequired,
	gameCode: PropTypes.string,
	groupIndex: PropTypes.number,
	pageId: PropTypes.string.isRequired,
	userEmail: PropTypes.string,
	showPage: PropTypes.func.isRequired,
	handleToggleFullscreen: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	children: PropTypes.object.isRequired,
	baseIsOpen: PropTypes.bool.isRequired
};

export default Game;
