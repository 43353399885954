import {detect} from 'detect-browser';
import browsers from 'config/app.browsers';

/* Check if browser is supported */
function getBrowserInfo() {
	const ClientBrowser = detect();
	let browserInfo = {
		browser: ClientBrowser,
		supportedBrowsers: browsers,
		status: 'ok'
	};
	if (ClientBrowser) {
		if (ClientBrowser.name) {
			let SupportedBrowser = browsers.filter((browser) => { return browser.id === ClientBrowser.name;});
			if (SupportedBrowser.length === 1) {
				if (ClientBrowser.version) {
					let ClientVersion = (ClientBrowser.version.split('.'))[0];
					if (ClientVersion < SupportedBrowser[0].version) {
						browserInfo.status = 'browser-old';
					}
				} else {
					browserInfo.status = 'browser-old';
				}
			} else {
				browserInfo.status = 'browser-unknown';
			}
		} else {
			browserInfo.status = 'browser-unknown';
		}
	} else {
		browserInfo.status = 'browser-unknown';
	}

	return browserInfo;
}

function getBrowserName() {
	const ClientBrowser = detect();
	return ClientBrowser.name;
}


export {
	getBrowserInfo,
	getBrowserName
};