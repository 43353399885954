import React, {Component} from 'react';
import PropTypes from 'prop-types';
import WaveSimulator from './wave-simulator';
import {data} from '../../data/wave-simulator-data';
import convert from 'helpers/color-helper';

class WaveSimulatorController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			waveType: '',
			environment: '',
			audioFrequency: 0,
			audioWavelength: 0,
			lightFrequency: 0,
			lightWavelength: 0,
			temperature: 0,
			velocity: 0,
			isPlaying: false,
			color: '#fff',
			updateTime: 0,
			lineSequence: [],
			frequencyIsLocked: false,
			wavelengthIsLocked: true
		};

		this.handleToggleWaveSimulator = this.handleToggleWaveSimulator.bind(this);
		this.changeWaveType = this.changeWaveType.bind(this);
		this.changeFrequency = this.changeFrequency.bind(this);
		this.changeWavelength = this.changeWavelength.bind(this);
		this.changeTemperature = this.changeTemperature.bind(this);
		this.changeEnvironment = this.changeEnvironment.bind(this);
		this.iniOscillator = this.iniOscillator.bind(this);
		this.toggleWave = this.toggleWave.bind(this);
		this.startSound = this.startSound.bind(this);
		this.stopAnimation = this.stopAnimation.bind(this);
		this.drawWave = this.drawWave.bind(this);
		this.createLongWave = this.createLongWave.bind(this);
		this.handleVariableLocking = this.handleVariableLocking.bind(this);
		this.resetSimulator = this.resetSimulator.bind(this);
		this.audioContext = null;
		this.oscillator = null;
		this.canvas = null;
		this.sineWave = null;
		this.timeout = null;
		this.waveHeight = null;
		this.waveWidth = null;
		this.xAxis = null;
		this.yAxis = null;
		this.framerateChange = 0;
	}

	componentDidMount() {
		let waveSimulator = null;
		if (this.props.waveSimulator) waveSimulator = this.props.waveSimulator;
		this.setState({
			waveSimulator: waveSimulator,
		}, () => {
			this.resetSimulator();
		});
	}

	componentWillUnmount() {
		clearTimeout(this.timeout);
		if (this.state.isPlaying) { this.stopSound(); }
	}

	resetSimulator() {
		if (this.state.isPlaying) {
			this.stopSound();
			if (this.state.environment === 'light') this.stopAnimation();
		}
		this.setState({
			waveType: 'light',
			environment: 'earth',
			lightFrequency: data.initialLightFrequency, // Thz
			lightWavelength: data.initialLightWavelength, // nm
			audioFrequency: data.initialAudioFrequency, // hz
			audioWavelength: data.initialAudioWavelength, // cm
			temperature: data.initialTemperature['light']['earth'], // K
			velocity: data.velocity['light'], // m/s
			isPlaying: false,
			color: '#fff',
		});
	}

	toggleWave() {
		if (this.state.waveType === 'light') {
			if (this.state.isPlaying) {
				this.stopAnimation();
			} else {
				this.startAnimation();
				this.createColor();
			}
		} else {
			if (this.state.isPlaying) {
				this.stopAnimation();
				this.stopSound();
			} else {
				this.startAnimation();
				this.startSound();
			}
		}

		this.setState({isPlaying: !this.state.isPlaying});
	}

	startSound() {
		this.iniOscillator();
		this.oscillator.connect(this.audioContext.destination);
		this.oscillator.start();
		this.oscillator.frequency.setValueAtTime(this.state.audioFrequency, this.audioContext.currentTime);
	}

	stopSound() {
		if (this.oscillator) {
			this.oscillator.stop();
			this.oscillator.disconnect(this.audioContext.destination);
			this.oscillator = null;
		}
	}

	createColor() {
		this.setState({
			color: convert(this.state.lightWavelength)
		});
	}

	iniOscillator() {
		if (!this.oscillator) {
			try {
				window.AudioContext = window.AudioContext || window.webkitAudioContext;
				this.audioContext = new AudioContext();
			} catch (e) {
				alert('Web Audio API is not supported in this browser');
			}
			this.oscillator = this.audioContext.createOscillator();
			this.oscillator.type = 'sine';
		}
	}

	changeFrequency(e) {
		let newFrequency = Number(e.target.value);
		if (this.state.waveType === 'light') {
			let color = convert(this.state.lightWavelength);
			this.setState({
				lightFrequency: newFrequency,
				lightWavelength: this.state.velocity / (newFrequency / 1e9) / 1e12,
				color: color
			});
		} else {
			this.setState({
				audioFrequency: newFrequency,
				audioWavelength: this.state.velocity / (newFrequency / 100)
			}, () => {
				this.iniOscillator();
				this.oscillator.frequency.setValueAtTime(this.state.audioFrequency, this.audioContext.currentTime);
				this.createLongWave();
			});
		}
	}

	changeWavelength(e) {
		let newWavelength = Number(e.target.value);
		if (this.state.waveType === 'light') {
			let color = convert(this.state.lightWavelength);
			this.setState({
				lightFrequency: this.state.velocity / (newWavelength / 1e9) / 1e12,
				lightWavelength: newWavelength,
				color: color
			});
		} else {
			this.setState({
				audioFrequency: this.state.velocity / (newWavelength / 100),
				audioWavelength: newWavelength
			}, () => {
				this.iniOscillator();
				this.oscillator.frequency.setValueAtTime(this.state.audioFrequency, this.audioContext.currentTime);
				this.createLongWave();
			});
		}
	}

	changeTemperature(e) {
		let newTemperature = Number(e.target.value);
		if (this.state.waveType === 'sound') {
			let velocity = 0,
				wavelength = 0,
				frequency = 0;
			if (this.state.environment === 'earth') {
				velocity = 20.05 * (Math.sqrt(newTemperature)).toFixed(2);
			} else if (this.state.environment === 'mars') {
				velocity = 15.73 * (Math.sqrt(newTemperature)).toFixed(2);
			}
			let newValues = this.getNewFrequencyWavelength(velocity);
			wavelength = newValues.wavelength;
			frequency = newValues.frequency;
			this.setState({
				velocity: velocity,
				temperature: newTemperature,
				audioFrequency: frequency,
				audioWavelength: wavelength
			}, () => {
				this.iniOscillator();
				this.oscillator.frequency.setValueAtTime(this.state.audioFrequency, this.audioContext.currentTime);
				this.createLongWave();
			});
		} else {
			this.setState({ temperature: newTemperature });
		}
	}

	changeWaveType(waveType) {
		if (this.state.waveType === waveType) { return; }
		if (this.state.waveType === 'light' || waveType === 'sound') {
			let velocity = data.velocity['sound'][this.state.environment];
			let temperature = data.initialTemperature['sound'][this.state.environment];
			this.setState({
				waveType: 'sound',
				velocity: velocity,
				temperature: temperature,
				audioWavelength: velocity === 0 ? 0 : data.initialAudioWavelength,
				audioFrequency: velocity === 0 ? 0 : velocity / (data.initialAudioWavelength / 100),
				color: '#fff',
			}, () => {
				this.iniOscillator();
				if (this.state.isPlaying) { this.startSound(); }
			});
		} else if (this.state.waveType === 'sound' || waveType === 'light') {
			this.setState({
				waveType: 'light',
				velocity: data.velocity['light'],
				color: convert(this.state.lightWavelength),
			});
			if (this.state.isPlaying) { this.stopSound(); }
		}
	}

	changeEnvironment(environment) {
		if (this.state.environment === environment) { return; }

		let velocity = 0,
			frequency = 0,
			wavelength = 0,
			temperature = data.initialTemperature['light']['space'],
			newEnvironment = '';

		if (environment === 'earth' || (!environment && this.state.environment === 'space')) {
			velocity = data.velocity.sound['earth'];
			temperature = data.initialTemperature.sound['earth'];
			let newValues = this.getNewFrequencyWavelength(velocity);
			wavelength = newValues.wavelength;
			frequency = newValues.frequency;
			newEnvironment = 'earth';

		} else if (environment === 'mars' || (!environment && this.state.environment === 'earth')) {
			velocity = data.velocity.sound['mars'];
			temperature = data.initialTemperature.sound['mars'];
			let newValues = this.getNewFrequencyWavelength(velocity);
			wavelength = newValues.wavelength;
			frequency = newValues.frequency;
			newEnvironment = 'mars';
		} else {
			newEnvironment = 'space';
		}
		
		if (this.state.waveType === 'sound') {
			this.setState({
				velocity: velocity,
				audioFrequency: frequency,
				audioWavelength: wavelength,
				environment: newEnvironment,
				temperature: temperature
			}, () => {
				if (!this.oscillator) { this.iniOscillator(); }
				this.oscillator.frequency.setValueAtTime(this.state.audioFrequency, this.audioContext.currentTime);
			});
		} else {
			if (newEnvironment === 'space') {
				temperature = data.initialTemperature['light']['space'];
			} else {
				if (this.state.environment !== 'space') {
					temperature = this.state.temperature;
				} else {
					temperature = data.initialTemperature['light'][newEnvironment];
				}
			}
			this.setState({
				environment: newEnvironment,
				temperature: temperature
			});
		}
	}

	getNewFrequencyWavelength(velocity) {
		let frequency = 0,
			wavelength = 0;
		if (this.state.wavelengthIsLocked) {
			wavelength = this.state.audioWavelength === 0
				? data.initialAudioWavelength
				: this.state.audioWavelength;
			frequency = velocity / (wavelength / 100);
		} else {
			frequency = this.state.audioFrequency === 0
				? data.initialAudioFrequency
				: this.state.audioFrequency;
			wavelength = velocity / (frequency / 100);
		}
		return {wavelength, frequency};
	}

	handleVariableLocking(variableToLock, variableToUnlock) {
		if (this.state[variableToLock + 'IsLocked']) {
			this.setState({
				[variableToLock + 'IsLocked']: false,
				[variableToUnlock + 'IsLocked']: true
			});
		} else {
			this.setState({
				[variableToLock + 'IsLocked']: true,
				[variableToUnlock + 'IsLocked']: false
			});
		}
	}

	startAnimation() {
		this.canvas = document.getElementById('WaveSimulator-canvas');
		this.canvas.width = 700;
		this.canvas.height = 400;
		this.sineWave = this.canvas.getContext('2d');
		this.sineWave.font = '18px sans-serif';
		this.sineWave.lineWidth = 2;
		this.sineWave.lineJoin = 'round';
		this.waveHeight = this.canvas.height;
		this.waveWidth = this.canvas.width;
		
		this.xAxis = Math.floor(this.waveHeight / 2);
		this.yAxis = Math.floor(this.waveWidth / 6);

		this.createLongWave();
		this.drawWave();
	}

	stopAnimation() {
		clearTimeout(this.timeout);
		this.sineWave.clearRect(0, 0, this.waveHeight * 4, this.waveWidth);
		this.sinewave = null;
	}

	createLongWave() {
		let sequence = Math.round((this.state.audioWavelength / 10) / 2); // half a wavelength
		let floor = 1;
		let x = 1;
		let step = 1;
		let length = (sequence + (sequence - 2)) * (60 - sequence);
		let sequenceArray = this.state.lineSequence;
		if (sequenceArray.length >= length) {
			sequenceArray = [];
		}
		for (let i = 0; i <= length - 1; i += 1) {
			x += step;
			if (x >= sequence || x <= floor) {
				step = -step;
			}
			sequenceArray.push(x);
		}
		this.setState({lineSequence: sequenceArray});
	}

	drawWave() {
		let wavelength;
		let moveFrame;
		if (this.state.waveType === 'light') {
			wavelength = this.state.lightWavelength;
			wavelength = (wavelength / 100) * 1.5; // times 1.5 so we have a bigger wavelength in the animation
			moveFrame = 3;
		} else {
			wavelength = this.state.audioWavelength / 10;
			moveFrame = this.state.velocity / 100;
		}
		let sequenceArray = this.state.lineSequence;
		let amplitude = 50;
		this.sineWave.clearRect(0, 0, this.waveHeight * 4, this.waveWidth);
		this.drawAxes();

		if (this.state.waveType === 'sound') {
			if (this.state.environment !== 'space') {
				// Sound wave
				this.sineWave.strokeStyle = '#fff';
				let distance = 10;
				for (let i = 0; i <= this.state.lineSequence.length; i++) {
					this.sineWave.lineWidth = 0.2 + (this.state.lineSequence[i] * 0.56);
					this.sineWave.beginPath();
					this.sineWave.moveTo(distance + (i * distance), this.xAxis - amplitude);
					this.sineWave.lineTo(distance + (i * distance), this.xAxis + amplitude);
					this.sineWave.stroke();
				}
				// The speed of sound on Mars is roughly 31% slower that Earth.
				// The normal framerate is 30 fps. 31.2% of 30 is 9.36 which leaves us framerate of 20.64 fps
				// 2 / 3 of 30 is 20. So every 3rd frame we don't update the line sequence making the framerate 20 fps.
				this.framerateChange = this.state.environment === 'earth' ? 1 : (this.framerateChange + 1) % 3;
				if (this.framerateChange !== 0) {
					let temp = sequenceArray.pop();
					sequenceArray.unshift(temp);
				}
			}
		} else {
			// Light wave
			this.sineWave.lineWidth = 2;
			this.sineWave.beginPath();
			this.sineWave.strokeStyle = this.state.color;
			let x;
			let y;
			for (let i = 0; i <= this.waveWidth; i++) {
				x = (this.state.updateTime + i) / (wavelength);
				y = (amplitude * Math.sin(x)) + this.xAxis;
				this.sineWave.lineTo(i, y); 
			}
			this.sineWave.stroke();
		}

		// Update the time and draw again
		this.setState({
			updateTime: this.state.updateTime - moveFrame,
			lineSequence: sequenceArray
		});
		this.timeout = setTimeout(this.drawWave, 33); // 33ms is for 30 fps. 16ms for 60 fps
	}

	drawAxes() {
		// Draw X and Y axes
		this.sineWave.save();
		this.sineWave.beginPath();
		this.sineWave.lineWidth = 1;
		this.sineWave.strokeStyle = '#fff';
		this.sineWave.moveTo(0, this.xAxis);
		this.sineWave.lineTo(this.waveWidth, this.xAxis);
		this.sineWave.stroke();
		this.sineWave.restore();
	}

	/**
	 * Hide/show wave simulator popup
	 */
	handleToggleWaveSimulator(showWaveSimulator) {
		this.props.toggleWaveSimulator(showWaveSimulator);
		if (!showWaveSimulator) this.resetSimulator();
	}

	render() {
		let currentFrequency = this.state.waveType === 'light'
			? this.state.lightFrequency
			: this.state.audioFrequency;
		let currentWavelength = this.state.waveType === 'light'
			? this.state.lightWavelength
			: this.state.audioWavelength;

		let slidersDisabled = {
			frequency: this.state.velocity === 0,
			wavelength: this.state.velocity === 0,
			temperature: this.state.environment === 'space'
		};

		if (this.props.showWaveSimulator) {
			return (
				<WaveSimulator
					changeWaveType={this.changeWaveType}
					waveType={this.state.waveType}
					toggleWave={this.toggleWave}
					changeFrequency={this.changeFrequency}
					frequency={currentFrequency}
					changeWavelength={this.changeWavelength}
					wavelength={currentWavelength}
					changeTemperature={this.changeTemperature}
					temperature={this.state.temperature}
					isPlaying={this.state.isPlaying}
					color={this.state.color}
					changeEnvironment={this.changeEnvironment}
					slidersDisabled={slidersDisabled}
					velocity={this.state.velocity}
					environment={this.state.environment}
					toggleWaveSimulator={this.handleToggleWaveSimulator}
					isTeacher={this.props.isTeacher}
					waveSimulator={this.state.waveSimulator}
					handleVariableLocking={this.handleVariableLocking}
					frequencyIsLocked={this.state.frequencyIsLocked}
					wavelengthIsLocked={this.state.wavelengthIsLocked}
					updateGroup={this.props.updateGroup}
					updateGame={this.props.updateGame}
					group={this.props.group}
					game={this.props.game}
				/>
			);
		}
		return null;
	}
}

WaveSimulatorController.propTypes = {
	game: PropTypes.object.isRequired,
	waveSimulator: PropTypes.object,
	isTeacher: PropTypes.bool.isRequired,
	updateGroup: PropTypes.func,
	updateGame: PropTypes.func,
	group: PropTypes.object,
	showWaveSimulator: PropTypes.bool.isRequired,
	toggleWaveSimulator: PropTypes.func.isRequired
};

export default WaveSimulatorController;