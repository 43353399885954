import React from 'react';
import PropTypes from 'prop-types';
import {waveSimulatorUiTexts as uiTexts} from 'data/ui-texts';
import {environments, wavelengthRange, frequencyRange, temperatureRange} from 'data/wave-simulator-data';
import InstructionsController from 'components/instructions/instructions-controller';
import './wave-simulator.scss';

const WaveSimulator = ({
	changeWaveType,
	waveType,
	toggleWave,
	changeFrequency,
	changeWavelength,
	changeTemperature,
	frequency,
	wavelength,
	temperature,
	isPlaying,
	changeEnvironment,
	slidersDisabled,
	velocity,
	environment,
	isTeacher,
	waveSimulator,
	toggleWaveSimulator,
	handleVariableLocking,
	frequencyIsLocked,
	wavelengthIsLocked,
	updateGroup,
	updateGame,
	group,
	game
}) => {
	return (
		<div className="WaveSimulator">
			{waveSimulator.instructions &&
				<InstructionsController
					isTeacher={isTeacher}
					page={{type: 'waveSimulator', instructions: waveSimulator.instructions, id: waveSimulator.id}}
					iconType="info"
					updateGroup={updateGroup}
					updateGame={updateGame}
					group={group}
					game={game}
					type='popup'
				/>}
			<div className="WaveSimulator-heading">{waveSimulator.title}</div>
			<div className="WaveSimulator-variables">
				<div className="WaveSimulator-variables-container">
					<div className="WaveSimulator-variables-name">{uiTexts.frequency}</div>
					<div className="WaveSimulator-variables-number">{Math.round(frequency)}</div>
					<div className="WaveSimulator-variables-unit">{uiTexts.frequencyUnit[waveType]}</div>
				</div>
				<div className="WaveSimulator-variables-container">
					<div className="WaveSimulator-variables-name">{uiTexts.wavelength}</div>
					<div className="WaveSimulator-variables-number">{Math.round(wavelength)}</div>
					<div className="WaveSimulator-variables-unit">{uiTexts.wavelengthUnit[waveType]}</div>
				</div>
				<div className="WaveSimulator-variables-container">
					<div className="WaveSimulator-variables-name">{uiTexts.velocity}</div>
					<div className="WaveSimulator-variables-number">
						{ waveType === 'sound' ? Math.round(velocity) : <div>3&#8226;10<sup>8</sup></div>}
					</div>
					<div className="WaveSimulator-variables-unit">{uiTexts.velocityUnit}</div>
				</div>
				<div className="WaveSimulator-variables-container">
					<div className="WaveSimulator-variables-name">{uiTexts.temperature}</div>
					<div className="WaveSimulator-variables-number">{environment === 'space' 
						? temperature : Math.round(temperature)}</div>
					<div className="WaveSimulator-variables-unit">{uiTexts.temperatureUnit[waveType]}</div>
				</div>
			</div>
			<div className="WaveSimulator-view">
				<canvas id="WaveSimulator-canvas" width="700" height="400"></canvas>
			</div>
			<div className="WaveSimulator-controlpanel">
				<button className="Button" onClick={() => {toggleWave();}}>
					{isPlaying ? uiTexts.stop : uiTexts.start}
				</button>
				<div className="WaveSimulator-controlpanel-type">
					<div className="WaveSimulator-controlpanel-param light" onClick={() => {changeWaveType('light');}}>
						{uiTexts.light}
					</div>
					<div className="WaveSimulator-controlpanel-param sound" onClick={() => {changeWaveType('sound');}}>
						{uiTexts.sound}
					</div>
					<div
						className={'WaveSimulator-controlpanel-rotary ' + waveType}
						onClick={() => {changeWaveType(null);}}>
					</div>
					<div className="WaveSimulator-controlpanel-label">{uiTexts.waveType}</div>
				</div>

				<div className="WaveSimulator-controlpanel-sliders">
					<div className="WaveSimulator-controlpanel-frequency">
						{uiTexts.frequency}
						<div
							className={'WaveSimulator-controlpanel-lock ' + (frequencyIsLocked ? '' : 'unlocked')}
							onClick={() => {handleVariableLocking('frequency', 'wavelength');}}>
						</div>
					</div>
					<input
						className="WaveSimulator-controlpanel-range"
						type="range"
						name="frequency"
						min={waveType === 'sound'
							? frequencyRange.min[waveType][environment]
							: frequencyRange.min[waveType]}
						max={waveType === 'sound'
							? frequencyRange.max[waveType][environment]
							: frequencyRange.max[waveType]}
						value={frequency}
						disabled={slidersDisabled.frequency}
						onChange={(e) => {changeFrequency(e);}}>
					</input>

					<div className="WaveSimulator-controlpanel-wavelength">
						{uiTexts.wavelength}
						<div
							className={'WaveSimulator-controlpanel-lock ' + (wavelengthIsLocked ? '' : 'unlocked')}
							onClick={() => {handleVariableLocking('wavelength', 'frequency');}}>
						</div>
					</div>
					<input
						className="WaveSimulator-controlpanel-range"
						type="range"
						name="wavelength"
						min={wavelengthRange.min[waveType]}
						max={wavelengthRange.max[waveType]}
						value={wavelength}
						disabled={slidersDisabled.wavelength}
						onChange={(e) => {changeWavelength(e);}}>
					</input>

					<div className="WaveSimulator-controlpanel-temperature">
						{uiTexts.temperature}
					</div>
					<input
						className="WaveSimulator-controlpanel-range"
						type="range"
						name="temperature"
						min={temperatureRange.min}
						max={temperatureRange.max}
						value={temperature}
						disabled={slidersDisabled.temperature}
						onChange={(e) => {changeTemperature(e);}}>
					</input>
				</div>

				<div className="WaveSimulator-controlpanel-environment">
					{environments.map((environment, index) => {
						return (
							<div
								key={index}
								className={'WaveSimulator-controlpanel-param ' + environment}
								onClick={() => {changeEnvironment(environment);}}>
								{uiTexts.environments[environment]}
							</div>
						);
					})}
					<div
						className={'WaveSimulator-controlpanel-rotary ' + environment}
						onClick={() => {changeEnvironment(null);}}>
					</div>
					<div className="WaveSimulator-controlpanel-label">{uiTexts.atmosphere}</div>
				</div>
			</div>
			<div className="WaveSimulator-close" onClick={() => {toggleWaveSimulator(false);}} />
		</div>
	);
};

WaveSimulator.propTypes = {
	changeWaveType: PropTypes.func.isRequired,
	waveType: PropTypes.string.isRequired,
	toggleWave: PropTypes.func.isRequired,
	changeFrequency: PropTypes.func.isRequired,
	changeWavelength: PropTypes.func.isRequired,
	changeTemperature: PropTypes.func.isRequired,
	frequency: PropTypes.number.isRequired,
	wavelength: PropTypes.number.isRequired,
	temperature: PropTypes.number.isRequired,
	isPlaying: PropTypes.bool.isRequired,
	changeEnvironment: PropTypes.func.isRequired,
	slidersDisabled: PropTypes.object.isRequired,
	velocity: PropTypes.number.isRequired,
	environment: PropTypes.string.isRequired,
	isTeacher: PropTypes.bool.isRequired,
	waveSimulator: PropTypes.object,
	toggleWaveSimulator: PropTypes.func.isRequired,
	handleVariableLocking: PropTypes.func.isRequired,
	frequencyIsLocked: PropTypes.bool.isRequired,
	wavelengthIsLocked: PropTypes.bool.isRequired,
	updateGroup: PropTypes.func,
	updateGame: PropTypes.func,
	group: PropTypes.object,
	game: PropTypes.object.isRequired
};
export default WaveSimulator;
