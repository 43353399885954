import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Audio from 'components/audio/audio';
import {addHTMLElement} from '../../helpers/text-helper';
import './clues.scss';

class Clues extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clueIndex: this.props.clueIndex,
			zoomClue: false
		};
		this.goToClue = this.goToClue.bind(this);
		this.toggleZoomClue = this.toggleZoomClue.bind(this);
	};

	/**
	 * Show clue
	 * @param {number} clueIndex 
	 */
	goToClue(clueIndex) {
		this.setState({clueIndex: clueIndex, zoomClue: false});
	}

	/**
	 * Zoom clue
	 */
	toggleZoomClue() {
		let zoomClue = !this.state.zoomClue;
		this.setState({zoomClue: zoomClue});
	}

	render() {
		let clue = this.props.investigationClues[this.state.clueIndex];

		let prevClueIndex = this.state.clueIndex - 1;
		if (prevClueIndex < 0) prevClueIndex = this.props.investigationClues.length - 1;

		let nextClueIndex = this.state.clueIndex + 1;
		if (nextClueIndex >= this.props.investigationClues.length) nextClueIndex = 0;

		let imgUrl = null;
		if (clue.type === 'image') {
			try {
				imgUrl = require('assets/images/investigations/' + 
					this.props.investigationId + '/' + clue.filename);
			} catch (e) {
				console.error('Could not find image: ' + clue.filename);
			}
		}
		return (
			<React.Fragment>
				<div className="Clues">
					{this.props.investigationClues.length > 1
						&& <React.Fragment>
							<div className="Clues-prevBtn" onClick={() => {this.goToClue(prevClueIndex);}} />
							<div className="Clues-nextBtn" onClick={() => {this.goToClue(nextClueIndex);}} />
						</React.Fragment>}
					<div className={'Clues-clue Clues-clue--' + (this.state.clueIndex + 1)}>
						<div className="Clues-clueNumber">
							{this.state.clueIndex + 1}/{this.props.investigationClues.length}</div>
						{clue.title && <div className="Clues-clueTitle">{clue.title}</div>}
						{clue.type === 'text' && (clue.textHasWrapper
							? <div className="Clues-clueTextWrapper">
								<div className="Clues-clueText">
									{Array.isArray(clue.text) ? addHTMLElement(clue.text) : clue.text}
								</div>
							</div>
							: <div className="Clues-clueText">
								{Array.isArray(clue.text) ? addHTMLElement(clue.text) : clue.text}
							</div>
						)}
						{imgUrl && <div className="Clues-clueImage" onClick={() => {this.toggleZoomClue();}}>
							{clue.imageCaption && <div>
								{Array.isArray(clue.imageCaption)
									? addHTMLElement(clue.imageCaption)
									: clue.imageCaption}
							</div>}
							<img src={imgUrl} alt="" />
						</div>}
						{clue.type === 'audio' && clue.soundFiles.length > 0 && <div className="Clues-clueSounds">
							{clue.soundFiles.map((sound, i) => {
								return <div key={i} className="Clues-clueSound">
									<div className="Clues-clueSoundTitle">{sound.title}</div>
									<Audio audioFile={sound.filename} audioType="investigation"/>
								</div>;
							})}
						</div>}
					</div>
				</div>
				{this.state.zoomClue && <div
					className={'CluePopup CluePopup--' + this.state.clueIndex}
					onClick={() => {this.toggleZoomClue();}}
				>
					{imgUrl && <div className="CluePopup-clueImage"><img src={imgUrl} alt="" /></div>}
				</div>}
			</React.Fragment>


		);
	}
}


Clues.propTypes = {
	investigationId: PropTypes.string.isRequired,
	clueIndex: PropTypes.number.isRequired,
	investigationClues: PropTypes.array.isRequired,
};

export default Clues;

