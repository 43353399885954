import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import Tips from 'components/admin/tips';
import './investigations.scss';

class Investigations extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pdfWidth: 10,
			pdfOrientations: []
		};
	};

	render() {
		let {
			isEditing,
			isSaving,
			investigations,
			selectedInvestigation,
			instructions,
			errorMsg,
			selectInvestigation,
			updateInvestigation,
			updateInstructions,
			handleSaveInvestigation,
			goToPage
		} = this.props;
	
		let saveBtnClass = 'InvestigationsAdmin-saveBtn ' + (isSaving ? ' saving' : '');
		if (isEditing) saveBtnClass += ' editing';
		if (!isEditing) saveBtnClass += ' disabled';
	
		return (
			<div className="InvestigationsAdmin">
				<div className="InvestigationsAdmin-title">Rediger investigations</div>
				<p className="InvestigationsAdmin-backBtn" onClick={() => {goToPage(null);}}>&lArr; tilbage</p>
	
				{/* Select investigation */}
				<form className="InvestigationsAdmin-navForm" onChange={(event) => {selectInvestigation(event);}}>
					<select>
						{investigations.map((investigation, index) => {
							return (
								<option key={index} value={index}>{investigation.title}</option>
							);
						})}
					</select>
				</form>

	
				<div>
					<form className="InvestigationsAdmin-form">
						{/* Save */}
						<div 
							className={saveBtnClass}
							onClick={(event) => {handleSaveInvestigation(event);}}>Gem</div>
						{errorMsg && <div>{errorMsg}</div>}

						{/* Edit title */}
						<div className="InvestigationsAdmin-inputRow">
							<span className="InvestigationsAdmin-label">Titel:</span>
							<input 
								className="InvestigationsAdmin-input"
								name="title"
								value={selectedInvestigation ? selectedInvestigation.title : ''}
								placeholder="titel"
								onChange={(event) => {updateInvestigation(event);}}
							/>
						</div>
						
	
						{/* Edit parts */}
						{(selectedInvestigation && selectedInvestigation.parts) && 
								selectedInvestigation.parts.map((part, index) => {
									return (
										<React.Fragment key={index}>
											<div className="InvestigationsAdmin-inputRow">
												<p className="InvestigationsAdmin-label">
													Tekst placeholder (Del {(index + 1)} - {part}):</p>
												<textarea 
													className="InvestigationsAdmin-textarea small"
													name="placeholder"
													value={selectedInvestigation.placeholders[part] ? selectedInvestigation.placeholders[part] : ''}
													placeholder=""
													onChange={(event) => {updateInvestigation(event, part);}}
												/>
												<div className="InvestigationsAdmin-preview small">
													{renderMarkdown((selectedInvestigation.placeholders[part] ? selectedInvestigation.placeholders[part] : ''))}
												</div>
											</div>

											<div className="InvestigationsAdmin-inputRow">
												<p className="InvestigationsAdmin-label">
													Gruppeinstruktioner (Del {(index + 1)} - {part}):</p>
												<textarea 
													className="InvestigationsAdmin-textarea"
													name="group"
													value={instructions[part].group ? instructions[part].group : ''}
													placeholder=""
													onChange={(event) => {updateInstructions(event, part);}}
												/>
												<div className="InvestigationsAdmin-preview">
													{renderMarkdown((instructions[part].group ? instructions[part].group : ''))}
												</div>
											</div>
										</React.Fragment>
									);
								})
						}
						
						
					</form>
				</div>
	
	
				{/* Markdown tips */}
				<Tips />
			</div>
		);
	}
};

Investigations.propTypes = {
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	investigations: PropTypes.array.isRequired,
	selectedInvestigation: PropTypes.object,
	instructions: PropTypes.object.isRequired, 
	errorMsg: PropTypes.string,
	selectInvestigation: PropTypes.func.isRequired,
	updateInvestigation: PropTypes.func.isRequired,
	updateInstructions: PropTypes.func.isRequired,
	handleSaveInvestigation: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default Investigations;