// import config from '../config/app.config.js';

function verifyEmail(value) {
	let error = null;
	if (value) {
		if (
			value.match(/^[0-9a-zA-ZæøåÆØÅ #@$!?;:%&(){}|~+=,_"'`*^/.-]+$/) === null ||
			/\S/.test(value) === false
		) {
			error = 'invalid-email-chars';
		}
	} else {
		error = 'empty-string';
	}
	return error;
}

function verifyTextInput(value, maxLength = null) {
	let error = null;
	if (value) {
		if (
			// check if there are forbidden characters
			value.match(/^[0-9a-zA-ZæøåÆØÅ #$!?;:%&()+=,_"'/.-]+$/) === null ||
			// check if there are only white spaces
			/\S/.test(value) === false
		) {
			error = 'invalid-chars';
		}
		if (maxLength && value.length > maxLength) error = 'name-too-long';
	} else {
		error = 'no-name';
	}
	return error;
}

export {verifyEmail, verifyTextInput};