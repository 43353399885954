import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import config from 'config/app.config';
import Backup from './backup';

class BackupController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDownloading: null,
			isUploading: false,
			fileToUpload: null,
			feedbackMsg: null,
			investigations: null,
			pages: null,
			cyoaGames: null,
			minigames: null,
			waveSimulators: null
		};
		this.downloadDBData = this.downloadDBData.bind(this);
		this.selectFileToUpload = this.selectFileToUpload.bind(this);
		this.uploadFileToDatabase = this.uploadFileToDatabase.bind(this);
		this.readJSONFile = this.readJSONFile.bind(this);
		this.downloadInstructions = this.downloadInstructions.bind(this);
		this.downloadInvestigations = this.downloadInvestigations.bind(this);
		this.downloadPages = this.downloadPages.bind(this);
	};

	componentDidMount() {
		const db = firebase.firestore();
		Promise.all([
			db.collection('investigations').get(),
			db.collection('pages').get(),
			db.collection('minigames').get(),
			db.collection('cyoaGames').get(),
			db.collection('waveSimulators').get()
		]).then((responses) => {
			this.setState({
				investigations: responses[0].docs,
				pages: responses[1].docs,
				minigames: responses[2].docs,
				cyoaGames: responses[3].docs,
				waveSimulators: responses[4].docs,
			});
		});
	}

	/**
	 * Download pages data from database
	 */
	downloadDBData(collectionName) {
		if (this.state.isDownloading !== null) return;
		this.setState({isDownloading: collectionName + 'Data'});
		const db = firebase.firestore();
		db.collection(collectionName).get().then((docs) => {
			let documents = [];
			docs.forEach((doc) => {
				let data = doc.data();
				data.id = doc.id;
				documents.push(data);
			});

			let collection = {
				timestamp: Math.floor(Date.now() / 1000),
				collectionId: collectionName,
				documents: documents
			};
			
			let json = JSON.stringify(collection, null, 4);
			let blob = new Blob([json], {type: 'text/json'});
			let url = window.URL.createObjectURL(blob);
			let link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', collectionName + 'Data_' + db._databaseId.projectId + '.json');
			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link);
			this.setState({isDownloading: null});
		}).catch((error) => {
			console.error(error);
			this.setState({isDownloading: null});
		});
	}

	/**
	 * Download group or teacher instructions
	 * instructionsType: string = 'group' or 'teacher'
	 */
	downloadInstructions(instructionsType) {
		if (this.state.isDownloading !== null) return;
		this.setState({isDownloading: instructionsType + 'InstructionsFiles'});
		
		let instructionFiles = [];

		[
			this.state.pages,
			this.state.minigames,
			this.state.cyoaGames,
			this.state.waveSimulators
		].forEach((collection) => {
			collection.forEach((doc) => {
				if (instructionsType === 'teacher'
					? doc.data().hasTeacherInstructions
					: doc.data().hasGroupInstructions
				) {
					instructionFiles.push({
						location: 'bucket',
						path: 'instructions/' + instructionsType + '/' + doc.id + '.md',
						fileName: doc.id + '.md'
					});
				}
			});
		});

		this.zipAndDownloadFiles(instructionsType + 'Instructions', instructionFiles).then(() => {
			this.setState({isDownloading: null});
		}).catch((error) => {
			console.error(error);
			this.setState({isDownloading: null});
		});
	}

	/**
	 * Download page texts
	 */
	downloadPages() {
		if (this.state.isDownloading !== null) return;
		this.setState({isDownloading: 'pageFiles'});
		
		let pageFiles = [];

		this.state.pages.forEach((doc) => {
			let page = doc.data();
			if (page.hasText) {
				pageFiles.push({
					location: 'bucket',
					path: 'pages/' + doc.id + '.md',
					fileName: doc.id + '.md',
				});
			}
			if (page.hasText2) {
				pageFiles.push({
					location: 'bucket',
					path: 'pages/' + doc.id + '2.md',
					fileName: doc.id + '2.md',
				});
			}
			if (page.hasStoryText) {
				pageFiles.push({
					location: 'bucket',
					path: 'pages/' + doc.id + '-story.md',
					fileName: doc.id + '-story.md'
				});
			}
		});

		this.zipAndDownloadFiles('pages', pageFiles).then(() => {
			this.setState({isDownloading: null});
		}).catch((error) => {
			console.error(error);
			this.setState({isDownloading: null});
		});
	}

	/**
	 * Download investigation group instructions
	 */
	downloadInvestigations() {
		if (this.state.isDownloading !== null) return;
		this.setState({isDownloading: 'investigationFiles'});
		
		let investigationFiles = [];

		this.state.investigations.forEach((doc) => {
			let investigation = doc.data();
			if (investigation.instructionFiles && investigation.instructionFiles.length > 0) {
				investigation.instructionFiles.forEach((instruction) => {
					investigationFiles.push({
						location: 'bucket',
						path: 'investigations/' + doc.id + '-' + instruction + '.md',
						fileName: doc.id + '-' + instruction + '.md'
					});
				});
			}
		});

		this.zipAndDownloadFiles('investigations', investigationFiles).then(() => {
			this.setState({isDownloading: null});
		}).catch((error) => {
			console.error(error);
			this.setState({isDownloading: null});
		});
	}

	/**
	 * Zip files, save on server and download
	 * @param {string} outputName 
	 * @param {array} files 
	 */
	zipAndDownloadFiles = (outputName, files) => {
		return new Promise((resolve, reject) => {
			let url = config.apiURL + 'admin/zip-and-download-files';
			let body = {outputName: outputName, files: files, userTokenId: this.props.userTokenId};

			var myHeaders = new Headers();
			myHeaders.append('Content-Type', 'application/json');

			let fetchConfig = {
				method: 'POST',
				mode: 'cors',
				headers: myHeaders,
				cache: 'default',
				body: JSON.stringify(body)
			};

			fetch(url, fetchConfig).then(
				(response)=>{
					response.blob().then((blob) => {
						const url = window.URL.createObjectURL(new Blob([blob]));
						const link = document.createElement('a'); 
						link.href = url;
						link.setAttribute('download', outputName + '.zip');
						document.body.appendChild(link);
						link.click();
						link.parentNode.removeChild(link);
						resolve();
					});
				},
				(error) => {
					console.error(error);
					reject();
				}
			);
		});
	}

	/**
	 * Select json-file
	 * @param {object} event 
	 */
	selectFileToUpload(event) {
		if (event.target.files.length > 1) {
			let feedbackMsg = 'Choose 1 file only!';
			this.setState({feedbackMsg: feedbackMsg});
		} else {
			this.setState({fileToUpload: event.target.files[0]});
		}
	}


	/**
	 * Upload json file to database
	 */
	uploadFileToDatabase() {
		let fileToUpload = this.state.fileToUpload;
		if (!fileToUpload) return;
		let allowedFileNames = [
			'investigationsData_cgl-marsbasen-test.json',
			'investigationsData_cgl-marsbasen-demo.json',
			'investigationsData_cgl-marsbasen-production.json',
			'cyoaGamesData_cgl-marsbasen-test.json',
			'cyoaGamesData_cgl-marsbasen-demo.json',
			'cyoaGamesData_cgl-marsbasen-production.json',
			'waveSimulatorsData_cgl-marsbasen-test.json',
			'waveSimulatorsData_cgl-marsbasen-demo.json',
			'waveSimulatorsData_cgl-marsbasen-production.json',
			'minigamesData_cgl-marsbasen-test.json',
			'minigamesData_cgl-marsbasen-demo.json',
			'minigamesData_cgl-marsbasen-production.json',
			'missionsData_cgl-marsbasen-test.json',
			'missionsData_cgl-marsbasen-demo.json',
			'missionsData_cgl-marsbasen-production.json',
			'pagesData_cgl-marsbasen-test.json',
			'pagesData_cgl-marsbasen-demo.json',
			'pagesData_cgl-marsbasen-production.json',
			'sessionsData_cgl-marsbasen-test.json',
			'sessionsData_cgl-marsbasen-demo.json',
			'sessionsData_cgl-marsbasen-production.json',
			'storiesData_cgl-marsbasen-test.json',
			'storiesData_cgl-marsbasen-demo.json',
			'storiesData_cgl-marsbasen-production.json',
			'videosData_cgl-marsbasen-test.json',
			'videosData_cgl-marsbasen-demo.json',
			'videosData_cgl-marsbasen-production.json',
		];
		if (allowedFileNames.indexOf(fileToUpload.name) === -1) {
			let feedbackMsg = 'Wrong file name';
			console.error(feedbackMsg);
			this.setState({feedbackMsg: feedbackMsg});
		} else {
			this.setState({isUploading: true});
			this.readJSONFile(fileToUpload).then((file)=> {	
				if (file.hasOwnProperty('collectionId') && file.hasOwnProperty('documents')) {
					let collectionId = file.collectionId;
					const db = firebase.firestore();
					let batch = db.batch();
					// Update each document in collection
					file.documents.forEach((document) => {
						let documentId = document.id;
						let documentObj = JSON.parse(JSON.stringify(document));
						delete documentObj.id;

						let documentRef = db.collection(collectionId).doc(documentId);
						batch.set(documentRef, documentObj);
					});

					// Commit batch
					batch.commit().then(() => {
						this.setState({
							isUploading: false,
							feedbackMsg: 'Upload successful'
						});
						setTimeout(() => {
							this.setState({ feedbackMsg: null });
						}, 3000);
					}).catch((error) => {
						console.error(error);
						this.setState({
							isUploading: false,
							feedbackMsg: 'Something went wrong'
						});
					});
				} else {
					this.setState({isUploading: false});
					console.error('wrong format');
				}
			});
		}
	}

	/**
	 * Read json file from client computer
	 * @param {object} file
	 */
	readJSONFile(file) {
		return new Promise((resolve, reject)=>{
			let fileReader = new FileReader();
			fileReader.readAsText(file, 'UTF-8');
			fileReader.onerror = (event)=>{
				reject(event.error);
			};
			fileReader.onloadend = (event)=>{
				let textData = event.target.result;
				let data = JSON.parse(textData);
				resolve(data);
			};
		});
	}

	/**
	 * Render component
	 */
	render() {
		let databaseProject = 'test';
		if (config.env === 'demo') databaseProject = 'demo';
		if (config.env === 'production') databaseProject = 'production';

		return (
			<Backup
				db={databaseProject}
				isDownloading={this.state.isDownloading}
				isUploading={this.state.isUploading}
				downloadDBData={this.downloadDBData}
				goToPage={this.props.goToPage}
				selectFileToUpload={this.selectFileToUpload}
				uploadFileToDatabase={this.uploadFileToDatabase}
				feedbackMsg={this.state.feedbackMsg}
				downloadInstructions={this.downloadInstructions}
				downloadInvestigations={this.downloadInvestigations}
				downloadPages={this.downloadPages}
			/>
		);
	}
}


BackupController.propTypes = {
	userTokenId: PropTypes.string.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default BackupController;