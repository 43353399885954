let popupsData = {
	createGame: {
		closeBtn: true,
		title: 'Opret nyt spilforløb',
		text: null,
		form: [
			{type: 'text', label: 'Hvad vil du kalde spilforløbet?', name: 'gameName', placeholder:'spilnavn'}
		],
		buttonTexts: { submit: 'Opret nyt spilforløb'}
	},
	deleteGame: {
		closeBtn: true,
		title: 'Slet spilforløb',
		text: 'Er du sikker på, du vil slette spilforløbet?',
		buttonTexts: { ok: 'Slet'}
	},
	goToSession: {
		closeBtn: true,
		title: 'Start eller se spilgang',
		text: 'Vil du starte spilgangen %title%, eller vil du bare se den?',
		buttonTexts: { start: 'Start', view: 'Se'}
	},

	editInvestigationPopup: {
		closeBtn: true,
		title: 'Rediger',
		text: 'Du skal lukke for gruppernes input, før du kan redigere i deres besvarelser',
		buttonTexts: { ok: 'Ok'}
	},
	noGroupsSelectedPopup: {
		closeBtn: true,
		title: 'Ingen valgte',
		text: 'Du skal vælge et eller flere input, før du kan fortsætte.',
		buttonTexts: { ok: 'Ok'}
	},

	evaluationSessionReadyWarning: {
		closeBtn: true,
		title: '',
		text: '<p>Du kan ikke gemme evalueringer, så længe du ikke har startet spilgangen.</p>',
		buttonTexts: {ok: 'Ok'}
	},

	paramsEvaluationWarning: {
		closeBtn: true,
		title: '',
		text: '<p>Du har ændret noget i evalueringen som ikke er gemt.</p><p>Vil du gemme dine ændringer?</p>',
		buttonTexts: { yes: 'Ja', no: 'Nej'}
	},

	gridEvaluationWarning: {
		closeBtn: true,
		title: '',
		text: '<p>Du har ikke gemt evalueringen. Først når du gemmer, vil denne mission være fuldført.</p>',
		buttonTexts: { yes: 'Gem', no: 'Fortsæt'}
	},

	taggingWarning: {
		closeBtn: true,
		title: '',
		text: 'Du har ikke aktiveret tagging. Først når du aktiverer, får vindergruppen mulighed for at navngive den sektion på basen som I netop har fuldført.',
		buttonTexts: { yes: 'Aktivér', no: 'Fortsæt'}
	},
	
	logoutWarningPopup: {
		closeBtn: true,
		title: '',
		text: 'Er du sikker på, at du vil logge ud af spillet?',
		buttonTexts: { yes: 'Ja', no: 'Nej'}
	},

	startSessionWarning: {
		warning1: {
			closeBtn: true,
			title: 'Start spilgang',
			text: 'I skal gennemfører kapitlet "First landing" før I kan starte denne del.',
			buttonTexts: { ok: 'Ok'}
		},
		warning2: {
			closeBtn: true,
			title: 'Start spilgang',
			text: 'I får den bedste oplevelse, hvis I gennemfører "Reboot basen" før I starter denne del.',
			buttonTexts: { ok: 'Luk', start: 'Start alligevel'}
		},
		warning3: {
			closeBtn: true,
			title: 'Start spilgang',
			text: 'I får den bedste oplevelse, hvis I gennemfører flere af de foregående kapitler før I starter denne del.',
			buttonTexts: { ok: 'Luk', start: 'Start alligevel'}
		}
	},

	showVideos: {
		closeBtn: true,
		title: 'Instruktionsvideoer',
		text: 'Download:',
		buttonTexts: null
	},

	showResources: {
		closeBtn: true,
		title: 'Ressourcer',
		text: 'Download:' +
			`<ul>
				<li><a href="resources/badges.pdf" target="_blank">Badges med gruppenavne</a></li>
				<li><a href="resources/SkabelonEgenTekst.docx" target="_blank">Skabelon til egen tekst</a></li>
				<li><a href="resources/Lektionsplaner_og_lektionsmateriale.zip" target="_blank">Lektionsplaner og lektionsmateriale</a></li>
				<li><a href="resources/Peer_review.pdf" target="_blank">Skema til peer review</a></li>
				<li><a href="resources/Besked-fra-mission-control.docx" target="_blank">Word-skabelon til feedback fra Mission Control</a></li>
				<li><a href="resources/logbogspost_mission_gruppe.docx" target="_blank">Skabelon til logbog til elever</a></li>
				<li><a href="resources/MarsbasenOversigtLærere.xlsx" target="_blank">Læreplandsoversigt til lærere</a></li>
				<li><a href="resources/ElevIndholdsoversigt.xlsx" target="_blank">Indholdsoversigt til elever</a></li>
				<li><a href="resources/Eksamensspoergsmaal.zip" target="_blank">Eksamensspørgsmål</a></li>
			</ul>`,
		buttonTexts: null
	},
};

export default popupsData;