const baseData = [
	{
		id: 'mission-0-1',
		location: null,
		elements: [],
		tagId: null,
	},
	{
		id: 'mission-1-1',
		location: 'Dome',
		elements: ['dome'],
		tagId: 'dome'
	},
	{
		id: 'mission-1-2',
		location: 'Solar cells',
		elements: ['solarpanels'],
		tagId: 'solarpanels'
	},
	{
		id: 'mission-2-1',
		location: 'Ice area',
		elements: ['ice'],
		tagId: 'ice'
	},
	{
		id: 'mission-2-2',
		location: 'Greenhouse',
		elements: ['greenhouse'],
		tagId: 'greenhouse'
	},
	{
		id: 'mission-2-3',
		location: 'Rocket',
		elements: ['rocket'],
		tagId: 'rocket'
	},
	{
		id: 'mission-3-1',
		location: 'Satellite',
		elements: ['satellite'],
		tagId: 'satellite'
	},
	{
		id: 'mission-3-2',
		location: 'Entrance',
		elements: ['entrance'],
		tagId: 'entrance'
	},
	{
		id: 'mission-4-1',
		location: 'Communication hub',
		elements: ['communication'],
		tagId: 'communication'
	},
	{
		id: 'mission-4-2',
		location: 'Dorms',
		elements: ['dorms'],
		tagId: 'dorms'
	},
	{
		id: 'mission-5-1',
		location: 'New crater',
		elements: ['crater'],
		tagId: 'crater'
	},
	{
		id: 'mission-5-2',
		location: 'Small vehicle',
		elements: [
			'bigvehicle1',
			'bigvehicle2',
			'smallvehicle1',
			'smallvehicle2',
			'smallvehicle3',
			'smallvehicle4',
			'drone1',
			'drone2',
			'3dbase', // CHECK
		],
		tagId: 'vehicles'
	},
	{
		id: 'mission-6-1',
		location: 'Lab',
		elements: ['lab'],
		tagId: 'lab'
	},
	{
		id: 'mission-6-2',
		location: 'Garage',
		elements: ['garage'],
		tagId: 'garage'
	},
	{
		id: 'mission-7-1',
		location: 'Rover',
		elements: ['curiosity'],
		tagId: 'curiosity'
	},
	{
		id: 'mission-7-2',
		location: 'Stars',
		elements: ['stars'],
		tagId: 'stars'
	},
	{
		id: 'mission-8-1',
		location: 'Mountain',
		elements: ['spacesuit'],
		tagId: 'spacesuit'
	},
	{
		id: 'mission-8-2',
		location: 'Mountain',
		elements: ['hidden-base'],
		tagId: 'hidden-base'
	},
	{
		id: 'mission-9-1',
		location: null,
		elements: [],
		tagId: null
	},
	{
		id: 'mission-9-2',
		location: null,
		elements: [],
		tagId: null
	},
]

module.exports = {
	baseData
};