import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Stories from './stories';

class StoriesController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stories: [],
			missions: [],
			sessions: [],
			selectedStoryIndex: 0,
			selectedStory: null,
			selectedMissionIndex: null,
			selectedMission: null,
			selectedSessions: [],
			isEditingStory: false,
			isEditingMission: false,
			isEditingSessions: false,
			isSaving: false,
			errorMsg: null
		};
		this.subscribeToStories = this.subscribeToStories.bind(this);
		this.unsubscribeStories = null;
		this.subscribeToMissions = this.subscribeToMissions.bind(this);
		this.unsubscribeMissions = null;
		this.subscribeToSessions = this.subscribeToSessions.bind(this);
		this.unsubscribeSessions = null;
		this.selectStory = this.selectStory.bind(this);
		this.selectMission = this.selectMission.bind(this);
		this.updateStory = this.updateStory.bind(this);
		this.addStoryObjective = this.addStoryObjective.bind(this);
		this.updateMission = this.updateMission.bind(this);
		this.updateSession = this.updateSession.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.saveStory = this.saveStory.bind(this);
		this.saveMission = this.saveMission.bind(this);
		this.saveSession = this.saveSession.bind(this);
	};

	/**
	 * Component mounted
	 */
	componentDidMount() {
		Promise.all([
			this.subscribeToStories(),
			this.subscribeToMissions(),
			this.subscribeToSessions()
		]).then(() => {
			this.selectStory({target: {value: 0}});
		});
	}


	/**
	 * Subscribe to stories
	 */
	subscribeToStories() {
		if (this.unsubscribeStories !== null) this.unsubscribeStories();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeStories = db.collection('stories').onSnapshot((querySnapshot) => {
				let stories = [];
				if (!querySnapshot.empty) {
					stories = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = doc.id;
						return data;						
					});
				}
				this.setState({stories: stories}, () => {resolve({status: 'ok', stories: stories});});
			}, (error) => {resolve({status: 'error', error: error});});
		});
	}

	/**
	 * Subscribe to missions
	 */
	subscribeToMissions() {
		if (this.unsubscribeMissions !== null) this.unsubscribeMissions();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeMissions = db.collection('missions').onSnapshot((querySnapshot) => {
				let missions = [];
				if (!querySnapshot.empty) {
					missions = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = doc.id;
						return data;						
					});
				}
				this.setState({missions: missions}, () => {resolve({status: 'ok', missions: missions});});
			}, (error) => {resolve({status: 'error', error: error});});
		});
	}

	/**
	 * Subscribe to sessions
	 */
	subscribeToSessions() {
		if (this.unsubscribeSessions !== null) this.unsubscribeSessions();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeSessions = db.collection('sessions').onSnapshot((querySnapshot) => {
				let sessions = [];
				if (!querySnapshot.empty) {
					sessions = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = doc.id;
						return data;						
					});
				}
				this.setState({sessions: sessions}, () => {resolve({status: 'ok', sessions: sessions});});
			}, (error) => {resolve({status: 'error', error: error});});
		});
	}

	/**
	 * Select story
	 * @param {object} event 
	 */
	selectStory(event) {
		let selectedStoryIndex = parseInt(event.target.value);
		let selectedStory = JSON.parse(JSON.stringify(this.state.stories))[selectedStoryIndex];
		this.setState({
			selectedStoryIndex: 0,
			selectedStory: selectedStory,
			selectedMissionIndex: null,
			selectedMission: null,
			selectedSessions: []
		}, () => {
			if (selectedStory.missionIds && selectedStory.missionIds.length > 0) {
				this.selectMission({target: {value: selectedStory.missionIds[0]}});
			}
		});
	}

	/**
	 * Select mission and its sessions
	 * @param {object} event 
	 */
	selectMission(event) {
		let selectedMissionId = event.target.value;
		let selectedMissionIndex = 
			this.state.missions.findIndex((mission) => {return mission.id === selectedMissionId;});
		let selectedMission = null;
		let selectedSessions = [];
		if (selectedMissionIndex >= 0) {
			selectedMission = JSON.parse(JSON.stringify(this.state.missions))[selectedMissionIndex];
			if (selectedMission.sessionIds && selectedMission.sessionIds.length > 0) {
				selectedMission.sessionIds.forEach((sessionId) => {
					if (this.state.sessions.some((session) => {return session.id === sessionId;})) {
						selectedSessions.push(
							this.state.sessions.filter((session) => {return session.id === sessionId;})[0]
						);
					}
				});
			}					
		}
		this.setState({
			selectedMissionIndex: selectedMissionIndex,
			selectedMission: selectedMission,
			selectedSessions: selectedSessions
		});
	}

	/**
	 * Update page title / menu-title
	 * @param {object} event 
	 * @param {number} objectiveIndex
	 */
	updateStory(event, objectiveIndex = null) {
		let selectedStory = JSON.parse(JSON.stringify(this.state.selectedStory));
		if (event.target.name === 'objectives') {
			selectedStory.objectives[objectiveIndex] = event.target.value;
		} else {
			selectedStory[event.target.name] = event.target.value;
		}
		this.setState({
			selectedStory: selectedStory,
			isEditingStory: true,
			errorMsg: null
		});
	}

	addStoryObjective() {
		let selectedStory = JSON.parse(JSON.stringify(this.state.selectedStory));
		if (selectedStory.hasOwnProperty('objectives')) {
			selectedStory.objectives.push('');
		} else {
			selectedStory.objectives = [''];
		}
		this.setState({
			selectedStory: selectedStory,
			isEditingStory: true,
			errorMsg: null
		});
	}

	updateMission(event) {
		let selectedMission = JSON.parse(JSON.stringify(this.state.selectedMission));
		selectedMission[event.target.name] = event.target.value;
		this.setState({
			selectedMission: selectedMission,
			isEditingMission: true,
			errorMsg: null
		});
	}

	/**
	 * Update sessions
	 * @param {object} event 
	 * @param {number} sessionId
	 */
	updateSession(event, sessionId) {		
		let selectedSessions = JSON.parse(JSON.stringify(this.state.selectedSessions));
		let sessionIndex = selectedSessions.findIndex((session) => {return session.id === sessionId;});
		
		if (sessionIndex >= 0) {
			if (event.target.name.includes('isExperiment')) {
				selectedSessions[sessionIndex].isExperiment = (event.target.value === 'true');
			} else if (event.target.name.includes('subjectIds')) {
				if (!selectedSessions[sessionIndex].hasOwnProperty('subjectIds')) {
					selectedSessions[sessionIndex].subjectIds = [];
				}
				let subjectIndex = selectedSessions[sessionIndex].subjectIds.indexOf(event.target.value);
				if (subjectIndex >= 0) {
					selectedSessions[sessionIndex].subjectIds.splice(subjectIndex, 1);
				} else {
					selectedSessions[sessionIndex].subjectIds.push(event.target.value);
				}
			} else if (event.target.name === 'minutes') {
				selectedSessions[sessionIndex][event.target.name] = parseInt(event.target.value);
			} else {
				selectedSessions[sessionIndex][event.target.name] = event.target.value;
			}
			selectedSessions[sessionIndex].isEditing = true;
			this.setState({
				selectedSessions: selectedSessions,
				isEditingSessions: true,
				errorMsg: null
			});
		} else {
			this.setState({
				errorMsg: 'unknown session id: ' + sessionId
			});			
		}
	}


	/**
	 * Handle save page
	 */
	handleSave() {		
		if (
			(!this.state.isEditingStory && !this.state.isEditingMission && !this.state.isEditingSessions) || 
			this.state.isSaving
		) return;
		
		this.setState({isSaving: true});

		let promises = [];
		if (this.state.isEditingStory) promises.push(this.saveStory(this.state.selectedStory));
		if (this.state.isEditingMission) promises.push(this.saveMission(this.state.selectedMission));
		if (this.state.isEditingSessions) {
			this.state.selectedSessions.forEach((session) => {
				if (session.isEditing && session.isEditing === true) {
					promises.push(this.saveSession(session));
				}
			});
		}

		Promise.all(promises).then(
			() => {
				this.setState({
					isEditingStory: false, 
					isEditingMission: false, 
					isEditingSessions: false, 
					isSaving: false
				});
			}, (error) => {console.error(error);this.setState({isSaving: false, errorMsg: 'save error'});}
		);		
	}

	/* Save story (database) */
	saveStory(selectedStory) {
		let objectives = (selectedStory.objectives ? selectedStory.objectives : []);
		objectives = objectives.filter((objective) => {return objective.length > 0;});
		let storyData = {
			title: selectedStory.title,
			introduction: selectedStory.introduction ? selectedStory.introduction : '',
			objectives: objectives
		};
		const db = firebase.firestore();
		return db.collection('stories').doc(selectedStory.id).update(storyData);
	}

	/* Save mission (database) */
	saveMission(mission) {
		let missionData = {
			title: mission.title,
			description: mission.description ? mission.description : '',
			goal: mission.goal ? mission.goal : '',
		};
		const db = firebase.firestore();
		return db.collection('missions').doc(mission.id).update(missionData);
	}	

	/* Save session (database) */
	saveSession(session) {
		let sessionData = {
			title: session.title,
			minutes: session.minutes ? session.minutes : 90,
			isExperiment: session.isExperiment,
			subjectIds: session.subjectIds ? session.subjectIds : []			
		};
		const db = firebase.firestore();
		return db.collection('sessions').doc(session.id).update(sessionData);
	}		


	/**
	 * Render component
	 */
	render() {
		return (
			<Stories 
				isEditing={this.state.isEditingStory || this.state.isEditingMission || this.state.isEditingSessions}
				isSaving={this.state.isSaving}
				stories={this.state.stories}
				missions={this.state.missions}
				selectedStory={this.state.selectedStory}
				selectedMission={this.state.selectedMission}
				selectedSessions={this.state.selectedSessions}
				errorMsg={this.state.errorMsg}
				selectStory={this.selectStory}
				selectMission={this.selectMission}
				updateStory={this.updateStory}
				addStoryObjective={this.addStoryObjective}
				updateMission={this.updateMission}
				updateSession={this.updateSession}
				handleSave={this.handleSave}
				goToPage={this.props.goToPage}
			/>
		);
	}
}

StoriesController.propTypes = {
	goToPage: PropTypes.func.isRequired
};

export default StoriesController;