import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {infoMinigamesData} from '../../data/info-minigames-data';
import InfoMinigamesIntro from './info-minigames-intro';
import Lips from './lips/lips';
import HiddenBase from './hidden-base/hidden-base';

class InfoMinigamesController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showInfoMinigame: false,
			infoMinigame: null,
			selectedImage: null,
			selectedConstructionMethod: null,
			currentStep: null,
			selectedGridCoordinates: '',
			prevSelectedCoordinates: []
		};
	};

	/**
	 * Start new game
	 */
	componentDidMount() {
		let infoMinigame = null;
		if (this.props.infoMinigame) infoMinigame = this.loadInfoMinigameData(this.props.infoMinigame.id);
		this.setState({infoMinigame: infoMinigame}, () => {if (infoMinigame) this.resetGame();});
	}

	/**
	 * Navigate from one info minigame page directly to another, the correct info minigame should be rendered
	 */
	componentDidUpdate(prevProps) {
		let infoMinigame = null;
		if (prevProps.infoMinigame.id !== this.props.infoMinigame.id) {
			infoMinigame = this.loadInfoMinigameData(this.props.infoMinigame.id);
			this.setState({infoMinigame: infoMinigame}, () => {if (infoMinigame) this.resetGame();});
		}
	}

	/**
	 * Load game data from local json file and merge with DB data
	 */
	loadInfoMinigameData = (infoMinigameId) => {
		let infoMinigameData = null;
		if (infoMinigamesData.some((infoMinigameData) => {return infoMinigameId === infoMinigameData.id;})) {
			infoMinigameData = JSON.parse(JSON.stringify(infoMinigamesData)).find((infoMinigameData) => {
				return infoMinigameId === infoMinigameData.id;
			});
			if (this.props.infoMinigame.title && this.props.infoMinigame.title.length > 0) {
				infoMinigameData.title = this.props.infoMinigame.title;
			}
			if (this.props.infoMinigame.instructions) {
				infoMinigameData.instructions = this.props.infoMinigame.instructions;
			}
		}
		return infoMinigameData;
	}

	resetGame = () => {
		this.setState({
			currentStep: this.state.infoMinigame.steps[0],
			selectedImage: null,
			selectedConstructionMethod: null,
			selectedGridCoordinates: '',
			prevSelectedCoordinates: []
		});
	}

	/**
	 * Hide/show InfoMinigames game popup
	 */
	toggleInfoMinigame = (showInfoMinigame) => {
		this.setState({showInfoMinigame});
		if (showInfoMinigame === false) this.resetGame();
	}

	/**
	 * Get minigame status
	 */
	getInfoMinigameStatus = () => {
		let infoMinigameStatus = 'locked';
		if (
			this.props.sessionStatus === 'open' && 
			this.props.game && 
			this.props.game.id !== null && 
			this.props.infoMinigame && 
			this.props.game.infoMinigames.some((minigame) => {return minigame.id === this.props.infoMinigame.id;})
		) {
			infoMinigameStatus = this.props.game.infoMinigames.filter((minigame) => {
				return minigame.id === this.props.infoMinigame.id;
			})[0].status;
		}
		return infoMinigameStatus;
	}

	/**
	 * Open / close InfoMinigames game (teacher only)
	 * @param {string} infoMinigameStatus 
	 */
	setInfoMinigameStatus = (infoMinigameStatus) => {
		let infoMinigames = JSON.parse(JSON.stringify(this.props.game.infoMinigames));
		let infoMinigameIndex = infoMinigames.findIndex((infoMinigame) => {
			return infoMinigame.id === this.props.infoMinigame.id;
		});
		if (infoMinigameIndex < 0) {
			infoMinigames.push({id: this.props.infoMinigame.id, status: infoMinigameStatus});
		} else {
			infoMinigames[infoMinigameIndex].status = infoMinigameStatus;
		}
		this.props.updateGame({ infoMinigames: infoMinigames });
	}

	selectImage = (rock) => {
		this.setState({
			selectedImage: rock,
			selectedConstructionMethod: null
		});
		this.goToStep(this.state.infoMinigame.steps[this.state.infoMinigame.steps.indexOf(this.state.currentStep) + 1]);
	}

	selectConstructionMethod = (constructionMethod) => {
		this.setState({
			selectedImage: null,
			selectedConstructionMethod: constructionMethod
		});
	}

	goToStep = (step) => {
		// Note: Clear the input in step 2 and previously selected coordinates if another rock was chosen
		if (step === 'choose-coordinates') {
			this.setState({ selectedGridCoordinates: '' });
			if (this.state.currentStep === 'choose-rock') {
				this.setState({ prevSelectedCoordinates: [] });
			}
		}
		this.setState({ currentStep: step });
	}

	selectCoordinate = (value) => {
		let isFirstCoordinateLetter = isNaN(parseInt((this.state.selectedGridCoordinates.substring(0, 1))));
		let isSecondCoordinateLetter = isNaN(parseInt((value)));
		if ((this.state.selectedGridCoordinates.length === 0)
			|| (((isFirstCoordinateLetter && !isSecondCoordinateLetter)
				|| (!isFirstCoordinateLetter && isSecondCoordinateLetter))
				&& this.state.selectedGridCoordinates.length < 2)
		) {
			this.setState({ selectedGridCoordinates: this.state.selectedGridCoordinates + value });
		}
	}

	deleteCoordinate = () => {
		let oldValue = this.state.selectedGridCoordinates;
		this.setState({ selectedGridCoordinates: oldValue.substring(0, oldValue.length - 1) });
	}

	showGraph = (gridCoordinates) => {
		if (gridCoordinates.length !== 2) return;

		let prevSelectedCoordinates = JSON.parse(JSON.stringify(this.state.prevSelectedCoordinates));
		prevSelectedCoordinates.push(gridCoordinates);
		this.setState({ prevSelectedCoordinates });
		this.goToStep(this.state.infoMinigame.steps[this.state.infoMinigame.steps.indexOf(this.state.currentStep) + 1]);
	}

	render() {
		let infoMinigameStatus = this.getInfoMinigameStatus();

		if (!this.state.showInfoMinigame) {
			return (
				<InfoMinigamesIntro
					setInfoMinigameStatus={this.setInfoMinigameStatus}
					page={this.props.page}
					toggleInfoMinigame={(this.state.infoMinigame ? this.toggleInfoMinigame : null)}
					isTeacher={this.props.isTeacher}
					infoMinigameStatus={infoMinigameStatus}
				/>
			);
		}
		if (this.state.infoMinigame.id === 'infoMinigame-1') {
			return <Lips
				toggleInfoMinigame={this.toggleInfoMinigame}
				isTeacher={this.props.isTeacher}
				lipsGame={this.state.infoMinigame}
				updateGame={this.props.updateGame}
				updateGroup={this.props.updateGroup}
				game={this.props.game}
				group={this.props.group}
				selectedImage={this.state.selectedImage}
				currentStep={this.state.currentStep}
				selectImage={this.selectImage}
				goToStep={this.goToStep}
				selectedGridCoordinates={this.state.selectedGridCoordinates}
				selectCoordinate={this.selectCoordinate}
				deleteCoordinate={this.deleteCoordinate}
				showGraph={this.showGraph}
				prevSelectedCoordinates={this.state.prevSelectedCoordinates}
			/>;
		}
		if (this.state.infoMinigame.id === 'infoMinigame-2' || this.state.infoMinigame.id === 'infoMinigame-3') {
			return <HiddenBase
				toggleInfoMinigame={this.toggleInfoMinigame}
				isTeacher={this.props.isTeacher}
				hiddenBaseGame={this.state.infoMinigame}
				updateGame={this.props.updateGame}
				updateGroup={this.props.updateGroup}
				game={this.props.game}
				group={this.props.group}
				selectedImage={this.state.selectedImage}
				selectImage={this.selectImage}
				selectedConstructionMethod={this.state.selectedConstructionMethod}
				selectConstructionMethod={this.selectConstructionMethod}
			/>;
		}
		return null;
	}
}

InfoMinigamesController.propTypes = {
	page: PropTypes.object.isRequired,
	game: PropTypes.object.isRequired,
	group: PropTypes.object,
	sessionStatus: PropTypes.string.isRequired,
	updateGame: PropTypes.func,
	updateGroup: PropTypes.func,
	infoMinigame: PropTypes.object,
	isTeacher: PropTypes.bool.isRequired
};

export default InfoMinigamesController;