import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {cyoaGamesData} from 'data/cyoa-data';
import {getMinigameInstructions, saveMinigameInstructions} from 'helpers/game-content-helper';
import CyoaGames from './cyoa-games';

class CyoaGamesController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cyoaGames: [],
			isEditingCyoaGame: false,
			isEditingTeacherInstructions: false,
			isEditingGroupInstructions: false,
			isSaving: false,
			errorMsg: null,
			selectedCyoaGame: null,
			selectedCyoaGameIndex: null,
			teacherInstructions: null,
			groupInstructions: null,
		};
		this.subscribeToCyoaGames = this.subscribeToCyoaGames.bind(this);
		this.unsubscribeCyoaGames = null;
		this.selectCyoaGame = this.selectCyoaGame.bind(this);
		this.updateCyoaGame = this.updateCyoaGame.bind(this);
		this.updateInstructions = this.updateInstructions.bind(this);
		this.handleSaveCyoaGame = this.handleSaveCyoaGame.bind(this);
		this.saveCyoaGame = this.saveCyoaGame.bind(this);
	};

	/**
	 * Component mounted
	 */
	componentDidMount() {
		this.subscribeToCyoaGames().then((response) => {
			this.selectCyoaGame(0);
		});
	}


	/**
	 * Subscribe to cyoa games
	 */
	subscribeToCyoaGames() {
		if (this.unsubscribeCyoaGames !== null) this.unsubscribeCyoaGames();
		const db = firebase.firestore();
		return new Promise((resolve, reject)=>{
			this.unsubscribeCyoaGames = db.collection('cyoaGames').onSnapshot((querySnapshot) => {
				let cyoaGames = [];
				if (!querySnapshot.empty) {
					cyoaGames = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = doc.id;
						if (!data.entries) {
							let cyoaGameData = cyoaGamesData.filter((cyoaGame) => {
								return cyoaGame.id === data.id;
							})[0];
							data.entries = cyoaGameData.entries;
						}
						return data;
					});
				}
				this.setState({cyoaGames: cyoaGames}, () => {
					resolve({status: 'ok', cyoaGames: cyoaGames});
				});

			}, (error) => {resolve({status: 'error', error: error});});
		});
	}


	/**
	 * Select page
	 * @param {object} cyoaGameIndex
	 */
	selectCyoaGame(cyoaGameIndex) {
		let selectedCyoaGameIndex = cyoaGameIndex;
		let selectedCyoaGame = this.state.cyoaGames[cyoaGameIndex];
		let groupInstructions = null;
		let teacherInstructions = null;
		let promises = [];

		if (selectedCyoaGame.hasTeacherInstructions) {
			promises.push(getMinigameInstructions(selectedCyoaGame.id, 'teacher'));
		}
		if (selectedCyoaGame.hasGroupInstructions) {
			promises.push(getMinigameInstructions(selectedCyoaGame.id, 'group'));
		}

		if (promises.length > 0) {
			Promise.all(promises).then((responses) => {
				let responseIndex = 0;
				if (selectedCyoaGame.hasTeacherInstructions) {
					if (responses[responseIndex].status === 'ok') {
						teacherInstructions = responses[responseIndex].instructions;
					}
					responseIndex = responseIndex + 1;
				}
				if (selectedCyoaGame.hasGroupInstructions) {
					if (responses[responseIndex].status === 'ok') {
						groupInstructions = responses[responseIndex].instructions;
					}
				}

				this.setState({
					selectedCyoaGameIndex: selectedCyoaGameIndex,
					selectedCyoaGame: selectedCyoaGame,
					teacherInstructions: teacherInstructions,
					groupInstructions: groupInstructions
				});
			});	
		} else {
			this.setState({
				selectedCyoaGameIndex: selectedCyoaGameIndex,
				selectedCyoaGame: selectedCyoaGame,
				teacherInstructions: teacherInstructions,
				groupInstructions: groupInstructions
			});
		}
	}
		
	/* Update cyoa game data */
	updateCyoaGame(event) {
		let selectedCyoaGame = JSON.parse(JSON.stringify(this.state.selectedCyoaGame));
		if (event.target.name === 'title') selectedCyoaGame[event.target.name] = event.target.value;
		this.setState({
			selectedCyoaGame: selectedCyoaGame,
			isEditingCyoaGame: true
		});
	}

	/**
	 * Update instructions
	 * @param {object} event
	 */
	updateInstructions(event, role) {
		let isEditingTeacherInstructions = this.state.isEditingTeacherInstructions;
		let isEditingGroupInstructions = this.state.isEditingGroupInstructions;
		if (role === 'teacher') isEditingTeacherInstructions = true;
		if (role === 'group') isEditingGroupInstructions = true;

		this.setState({
			[event.target.name]: event.target.value,
			isEditingTeacherInstructions: isEditingTeacherInstructions,
			isEditingGroupInstructions: isEditingGroupInstructions,
			errorMsg: null
		});
	}



	/**
	 * Handle save cyoa game
	 */
	handleSaveCyoaGame() {
		if (
			(
				!this.state.isEditingCyoaGame &&
				!this.state.isEditingTeacherInstructions &&
				!this.state.isEditingGroupInstructions
			) || this.state.isSaving
		) return;
		
		this.setState({isSaving: true});

		let promises = [];
		let selectedCyoaGame = JSON.parse(JSON.stringify(this.state.selectedCyoaGame));
		
		/* Save minigame in DB */
		if (this.state.isEditingCyoaGame) {
			promises.push(this.saveCyoaGame(selectedCyoaGame));
		}
		
		/* Save instructions in storage */
		if (this.state.isEditingTeacherInstructions) {
			promises.push(saveMinigameInstructions(selectedCyoaGame.id, 'teacher', this.state.teacherInstructions));
		}

		if (this.state.isEditingGroupInstructions) {
			promises.push(saveMinigameInstructions(selectedCyoaGame.id, 'group', this.state.groupInstructions));
		}

		Promise.all(promises).then(
			() => {
				this.setState({
					isEditingCyoaGame: false, 
					isEditingTeacherInstructions: false, 
					isEditingGroupInstructions: false,
					isSaving: false
				});
			}, (error) => {console.error(error);this.setState({isSaving: false, errorMsg: 'save error'});}
		);		
	}

	/* Save cyoa game (database) */
	saveCyoaGame(selectedCyoaGame) {
		let dataToUpdate = {
			title: selectedCyoaGame.title,
			entries: new Array(selectedCyoaGame.entries.length),
			loadTeacherInstructions: (this.state.teacherInstructions && this.state.teacherInstructions.length > 0),
			loadGroupInstructions: (this.state.groupInstructions && this.state.groupInstructions.length > 0)
		};
		selectedCyoaGame.entries.forEach((entry, index) => {
			dataToUpdate.entries[index] = {};
			dataToUpdate.entries[index].entryText = entry.entryText;
			dataToUpdate.entries[index].choices = new Array(entry.choices.length);
			entry.choices.forEach((choice, choiceIndex) => {
				dataToUpdate.entries[index].choices[choiceIndex] = {};
				dataToUpdate.entries[index].choices[choiceIndex].choiceText = choice.choiceText;
				dataToUpdate.entries[index].choices[choiceIndex].type = choice.type;
				if (choice.newEntryText) {
					dataToUpdate.entries[index].choices[choiceIndex].newEntryText = choice.newEntryText;
				}
			});
		});
		const db = firebase.firestore();
		return db.collection('cyoaGames').doc(selectedCyoaGame.id).update(dataToUpdate);
	}


	/**
	 * Render component
	 */
	render() {
		return (
			<CyoaGames
				goToPage={this.props.goToPage}
				cyoaGames={this.state.cyoaGames}
				selectCyoaGame={this.selectCyoaGame}
				isEditing={this.state.isEditingCyoaGame || this.state.isEditingTeacherInstructions || 
					this.state.isEditingGroupInstructions}
				isSaving={this.state.isSaving}
				selectedCyoaGame={this.state.selectedCyoaGame}
				errorMsg={this.state.errorMsg}
				updateCyoaGame={this.updateCyoaGame}
				updateInstructions={this.updateInstructions}
				teacherInstructions={this.state.teacherInstructions}
				groupInstructions={this.state.groupInstructions}
				handleSaveCyoaGame={this.handleSaveCyoaGame}
			/>
		);
	}
}

CyoaGamesController.propTypes = {
	goToPage: PropTypes.func.isRequired
};

export default CyoaGamesController;