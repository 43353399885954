import React from 'react';
import PropTypes from 'prop-types';
import {minigamesUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import Button from 'components/button/button';
import MissionControlMessage from 'components/layout/mission-control-message';
import MinigameGroupStatuses from './minigame-group-statuses';
import './minigame-intro.scss';

const MinigameIntro = ({
	isTeacher,
	page,
	minigame,
	groups,
	minigameStatus,
	setMinigameStatus,
	toggleMinigame,
	sessionStatus,
	minigameNumber
}) => {
	let playBtnText = isTeacher ? 'Gennemse' : 'Spil!';
	if (page.type === 'minigameAndInvestigation') playBtnText = isTeacher ? 'Gennemse Spil 1' : 'Spil 1';
	if (minigameNumber) playBtnText = isTeacher ? 'Gennemse Spil ' + minigameNumber : 'Spil ' + minigameNumber;
	return (
		<div className="MinigameIntro">
			{minigameNumber === 1 && <React.Fragment>
				<div className="MinigameIntro-title">{page.title}</div>
				{page.storyText && <MissionControlMessage message={page.storyText} type={page.storyType} />}
				<div className="MinigameIntro-text">
					{renderMarkdown(page.text ? page.text : '')}
				</div>
			</React.Fragment>}
			{toggleMinigame &&
				<Button
					class={isTeacher ? '' : 'wide'}
					text={playBtnText}
					onClick={() => {toggleMinigame(true);}}
					disabled={!isTeacher && ['ready', 'locked', 'closed'].includes(minigameStatus)}
				/>
			}
			{(isTeacher && (minigameStatus === 'ready' || minigameStatus === 'closed')) && 
				<Button
					text={minigamesUiTexts.startMinigame}
					onClick={() => {setMinigameStatus('open');}}
				/>
			}
			{(isTeacher && minigameStatus === 'open') &&
				<Button
					text={minigamesUiTexts.stopMinigame}
					onClick={() => {setMinigameStatus('closed');}}
				/>
			}

			{isTeacher &&
				<MinigameGroupStatuses
					minigame={minigame}
					groups={groups}
					minigameStatus={minigameStatus}
					sessionStatus={sessionStatus}
				/>}
		</div>
	);
};


MinigameIntro.propTypes = {
	isTeacher: PropTypes.bool.isRequired,
	page: PropTypes.object.isRequired,
	minigame: PropTypes.object,
	groups: PropTypes.array,
	minigameStatus: PropTypes.string.isRequired,
	setMinigameStatus: PropTypes.func,
	toggleMinigame: PropTypes.func,
	sessionStatus: PropTypes.string.isRequired,
	minigameNumber: PropTypes.number
};

export default MinigameIntro;
