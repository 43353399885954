import React from 'react';
import PropTypes from 'prop-types';
import {getSessionMenu} from 'helpers/game-flow-helper';
import './menu.scss';

const Menu = (props) => {
	let {
		isTeacher, 
		page,
		sessionData,
		game,
		groups,
		showPage,
	} = props;

	let menu = getSessionMenu(isTeacher, page, sessionData, game, groups);

	if (menu) {
		return (
			<div className={'Menu Menu--' + (isTeacher ? 'teacher' : 'group')}>
				<div 
					className="Menu-title" 
					onClick={() => {showPage('overview-' + page.storyIndex);}}
				>{sessionData.storyTitle}</div>
				<div className="Menu-items">
					{menu.map((item, index) => {
						let className = 'Menu-item' + 
							(item.selected ? ' Menu-item--selected' : '') +
							' Menu-item--' + item.status;
						return (
							<div 
								key={index}
								className={className}
								onClick={() => {showPage(item.pageId);}}
							>{item.title}</div>
						);
					})}
				</div>
			</div>
		);
	}

	return null;
};

Menu.propTypes = {
	isTeacher: PropTypes.bool.isRequired,
	page: PropTypes.object.isRequired,
	sessionData: PropTypes.object.isRequired,
	game: PropTypes.object.isRequired,
	groups: PropTypes.array,
	showPage: PropTypes.func.isRequired,
};

export default Menu;
