import React from 'react';
import PropTypes from 'prop-types';
import config from 'config/app.config';
import {generalUiTexts} from 'data/ui-texts';
import GroupBadge from 'components/group-badge/group-badge';
import './group-login-statuses.scss';

const GroupLoginStatuses = ({page, game, groups}) => {

	/* Get login info */
	function renderGroupInfo() {
		/* Started game */
		if (game.id && game.id !== null) {
			return (
				<div className='GroupLoginStatuses-info'>
					<p>{generalUiTexts.loginInfo}</p>
					<p><span>{generalUiTexts.url}:</span> {config.groupURL}</p>
					<p><span>{generalUiTexts.code}:</span> {game.id}</p>
				</div>
			);
		}

		/* Demo game info */
		return (
			<div className={'GroupLoginStatuses-info GroupLoginStatuses-info--demo'}>
				{generalUiTexts.demoInfo}
			</div>
		);
	}



	return (
		<div className="GroupLoginStatuses">
			<div className="GroupLoginStatuses-title">{page.title}</div>
			{renderGroupInfo()}
			<div className='GroupLoginStatuses-container'>
				{groups.map((group) => {
					return <GroupBadge key={group.id} group={group} showName={true} page="groupLogin" />;
				})}
			</div>
		</div>
	);
};


GroupLoginStatuses.propTypes = {
	page: PropTypes.object.isRequired,
	game: PropTypes.object.isRequired,
	groups: PropTypes.array.isRequired
};

export default GroupLoginStatuses;
