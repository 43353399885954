import React from 'react';
import PropTypes from 'prop-types';
import InstructionsController from 'components/instructions/instructions-controller';
import {addHTMLElement} from 'helpers/text-helper';
import Button from 'components/button/button';
import './hidden-base.scss';

const HiddenBase = ({
	toggleInfoMinigame,
	isTeacher,
	hiddenBaseGame,
	updateGame,
	updateGroup,
	group,
	game,
	selectedImage,
	selectImage,
	selectedConstructionMethod,
	selectConstructionMethod
}) => {
	return (
		<div className="HiddenBase">
			{hiddenBaseGame.instructions &&
				<InstructionsController
					isTeacher={isTeacher}
					page={{type: 'infoMinigame', instructions: hiddenBaseGame.instructions, id: hiddenBaseGame.id}}
					iconType="info"
					updateGroup={updateGroup}
					updateGame={updateGame}
					group={group}
					game={game}
					type='popup'
				/>}
			<div className="HiddenBase-close" onClick={() => {toggleInfoMinigame(false);}} />
			<div className="HiddenBase-content">
				<div className="HiddenBase-rooms">
					{hiddenBaseGame.images.map((room) => {
						return <div key={room.id} onClick={() => {selectImage(room);}}
							className={'HiddenBase-room HiddenBase-room--' + room.id
							+ (selectedImage && selectedImage.id === room.id ? ' HiddenBase-room--selected' : '')}
						><span></span><div>{room.titleDash ? room.titleDash : room.title}</div></div>;
					})}
				</div>

				
				{selectedImage && <div className="HiddenBase-infoWrapper">
					{selectedImage.title}
					<div className="HiddenBase-info">
						{hiddenBaseGame.constructionMethods && addHTMLElement(selectedImage.information)}
						<img alt="graph" src={require('assets/images/infoMinigames/infoMinigame-2/'
							+ selectedImage.graph)} />
					</div>
				</div>}
				{hiddenBaseGame.constructionMethods && selectedConstructionMethod
					&& <div className="HiddenBase-infoWrapper">
						{selectedConstructionMethod.title}
						<div className="HiddenBase-info HiddenBase-info--construction">
							{addHTMLElement(selectedConstructionMethod.text)}
						</div>
					</div>}

				{hiddenBaseGame.constructionMethods && <div className="HiddenBase-constructionMethods">
					<p>Konstruktionsmetoder:</p>
					{hiddenBaseGame.constructionMethods.map((constructionMethod, i) => {
						return <Button key={i} text={constructionMethod.title}
							onClick={() => {selectConstructionMethod(constructionMethod);}}
							class={'dark coordBtn ' + (selectedConstructionMethod
								&& selectedConstructionMethod.id === constructionMethod.id ? 'selected ' : '')}
						/>;
					})}
				</div>}
			</div>
		</div>
	);
};

HiddenBase.propTypes = {
	toggleInfoMinigame: PropTypes.func.isRequired,
	isTeacher: PropTypes.bool.isRequired,
	hiddenBaseGame: PropTypes.object.isRequired,
	updateGame: PropTypes.func,
	updateGroup: PropTypes.func,
	game: PropTypes.object.isRequired,
	group: PropTypes.object,
	selectedImage: PropTypes.object,
	selectImage: PropTypes.func.isRequired,
	selectedConstructionMethod: PropTypes.object,
	selectConstructionMethod: PropTypes.func.isRequired
};

export default HiddenBase;
