import React, {Component} from 'react';
import PropTypes from 'prop-types';
import apiHelper from 'helpers/api-helper';
import {errorFeedbackHelper, emailErrors} from 'helpers/error-feedback-helper';
import errorTexts from 'data/error-texts';
import CreateUser from './create-user';

class CreateUserController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			userCreated: false,
			email: null,
			password: null,
			passwordRepeat: null,
			feedback: null,
			invalidInputEmail: false,
			invalidInputPassword: false,
			invalidInputPasswordRepeat: false
		};
		this.handleCreateUser = this.handleCreateUser.bind(this);
		this.createFirebaseUser = this.createFirebaseUser.bind(this);
		this.getErrorFeedback = this.getErrorFeedback.bind(this);
		this.handleInput = this.handleInput.bind(this);
	};

	/**
	 * Update input fields
	 * @param {obj} event 
	 */
	handleInput(event) {
		let value = event.target.value;
		let name = event.target.name;
		this.setState({
			[name]: value,
			feedback: null,
			['invalidInput' + name.charAt(0).toUpperCase() + name.slice(1)]: false
		});
	}

	/**
	 * Handle create user request
 	 * @param {obj} event 
 	 */
	handleCreateUser(event) {
		event.preventDefault();
		let email = this.state.email;
		let password = this.state.password;
		let passwordRepeat = this.state.passwordRepeat;
		let error = null;
		if (!email || !password || !passwordRepeat) error = 'empty-fields';
		if (!error) {
			if (password && password.length < 8) {
				this.getErrorFeedback('weak-password');
				this.setState({invalidInputPassword: true});
				return;
			} else if (password && password !== passwordRepeat) {
				this.getErrorFeedback('no-password-match');
				this.setState({invalidInputPasswordRepeat: true});
				return;
			}
			this.createFirebaseUser(email, password);
		} else {
			const msg = errorTexts[errorFeedbackHelper(error)];
			this.setState({
				feedback: msg,
				isLoading: false,
				invalidInputEmail: email ? false : true,
				invalidInputPassword: password ? false : true,
				invalidInputPasswordRepeat: passwordRepeat ? false : true
			});
		}
	}

	/**
	 * Error feedback
	 * @param {string} errorCode 
	 */
	getErrorFeedback(errorCode) {
		const errorStatus = errorFeedbackHelper(errorCode);
		const errorMsg = errorTexts[errorStatus];
		this.setState({
			feedback: errorMsg
		});
	}

	/**
	 * Create user
	 * @param {string} email 
	 * @param {string} password 
	 */
	createFirebaseUser(email, password) {
		this.setState({isLoading: true});
		apiHelper('teacher/create-teacher', {
			email: email,
			password: password
		}).then(
			()=>{
				this.setState({userCreated: true});
			},
			(error) => {
				console.error(error);
				this.getErrorFeedback(error.status);
				let invalidInputEmail = emailErrors.indexOf(errorFeedbackHelper(error.status)) === -1 ? false : true;
				this.setState({
					isLoading: false,
					invalidInputEmail: invalidInputEmail,
					invalidInputPassword: !invalidInputEmail
				});
			}
		);
	}

	/**
	 * Render create user component
	 */
	render() {
		return (
			<CreateUser
				isLoading={this.state.isLoading}
				userCreated={this.state.userCreated}
				email={this.state.email}
				password={this.state.password}
				passwordRepeat={this.state.passwordRepeat}
				feedback={this.state.feedback}
				handleInput={this.handleInput}
				handleCreateUser={this.handleCreateUser}
				goToTeacherBox={this.props.goToTeacherBox}
				invalidInputEmail={this.state.invalidInputEmail}
				invalidInputPassword={this.state.invalidInputPassword}
				invalidInputPasswordRepeat={this.state.invalidInputPasswordRepeat}
			/>
		);		
	}
}

CreateUserController.propTypes = {
	goToTeacherBox: PropTypes.func.isRequired
};

export default CreateUserController;