let popupsData = {
	introduction: {
		closeBtn: true,
		title: 'Introduktion',
		text: `I er et hold pionerer, der er sendt afsted til Mars for at etablere en permanent base. Undervejs skal I med udgangspunkt i konkret fysikfaglighed beregne energiforbruget til vandforsyning, overveje Mars' placering i verdensrummet, lave et alarmsystem til basen og meget andet. <br><br> Jeres første mission er at gennemføre First Landing, hvor I får jeres første briefing fra Mission Control og inddeles i teams. Spillet Marsbasen er målrettet gymnasiet og dækker hele pensum i Fysik C.`,
		buttonTexts: null
	},
	minigameCompleted: {
		closeBtn: true,
		title: 'Fuldført!',
		text: 'I klarede udfordringen og tjente %points% point.',
		buttonTexts: { ok: 'Ok'}
	},
	puzzleCompleted: {
		closeBtn: true,
		title: 'Perfekt!',
		text: 'Alle placeringer er rigtige',
		buttonTexts: { ok: 'Ok'}
	},
	puzzleIncomplete: {
		closeBtn: true,
		title: 'Ikke helt rigtigt!',
		text: '%correctAnswers% ud af %totalAnswers% rigtige placeringer',
		buttonTexts: { ok: 'Ok'}
	},
	puzzleWrong: {
		closeBtn: true,
		title: 'Forkert',
		text: '0 rigtige placeringer',
		buttonTexts: { ok: 'Ok'}
	},
	roverStaticData: {
		closeBtn: true,
		title: '',
		text:
			'* Antal personer ombord: **2** \n\n' +
			'* Batterikapacitet: **200 kWh** \n\n' +
			'* Life support energiforbrug: **1 kWh/time** \n\n' +
			'* Energiforbrug ved kørsel: \n\n' +
			'    * Sand: **4 kWh/km** \n' +
			'    * Sand og grus: **1.3 kWh/km** \n' +
			'    * Grus og sten: **0.4 kWh/km** \n\n' +

			'* Opladningshastighed: **20 kWh/time** \n\n' +

			'* Oxygen ombord: **2000 liter** \n\n' +
			'* Oxygenforbrug per person ved 25 grader: **0.25-0.35L/min** \n\n' +

			'* Rovermasse: **1500 kg** \n\n' +
			'* Mars’ tyngdekraft: **3.721 m/s<sup>2</sup>** \n\n' +

			'* Roverens kørehastighed: \n\n' +
			'    * Sand: **10 km/t** \n' +
			'    * Sand og grus: **15 km/t** \n' +
			'    * Grus og sten: **20 km/t** \n\n' +

			'* Dagslængde: **14 timer og 20 minutter** \n\n' +
			'* Nattelængde: **10 timer og 17 minutter** \n\n'
		,
		buttonTexts: null
	}
};

module.exports = {
	popupsData
};