import React from 'react';
import PropTypes from 'prop-types';
import {useDragLayer} from 'react-dnd';
import {getItemStyles} from 'helpers/dnd-helper';
import './puzzle-dnd-drag-layer.scss';

const PuzzleDnDDragLayer = ({pieces}) => {
	const {
		item,
		initialOffset,
		currentOffset,
	} = useDragLayer((monitor) => {
		return {
			item: monitor.getItem(),
			initialOffset: monitor.getInitialSourceClientOffset(),
			currentOffset: monitor.getSourceClientOffset(),
		};
	});

	let piece = null;
	if (item && item.piece) piece = item.piece;

	let style = getItemStyles(initialOffset, currentOffset);

	return (
		<div className="Puzzle-dragPiece">
			{piece && 
				<div
					className={'Puzzle-piece Puzzle-piece--' + piece.id
						+ (piece.type ? ' Puzzle-piece--' + piece.type : '')}
					style={style}
				>
					{piece.icon && <div className={'Puzzle-icon Puzzle-icon--' + piece.icon} />}
					<div className="Puzzle-pieceName" dangerouslySetInnerHTML={{ __html: piece.name}}></div>
				</div>}
		</div>
	);
};

PuzzleDnDDragLayer.propTypes = {
	pieces: PropTypes.array.isRequired
};

export default PuzzleDnDDragLayer;