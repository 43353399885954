/* eslint-disable no-unreachable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Rover from './rover';

/** Check original code in assets/html/faa_roveren_hjem.py (Viktor from KU) */
/** There are 3 very similar functions for the three routes: long way, dessert, canyon, yet too different to refactor */

class RoverController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			choicesMade: [],
			entry: null,
			gameReady: false,
			showBlur: false,
			currentTimeStep: 0,
			nextEntryId: null,
			scientistOptionChosen: null,
			craterOptionChoosen: null,
			lifeSupportOptionChoosen: null,
			stormOptionChoosen: null,
			cleanSollarPanelsOptionChosen: null,
			hillOptionChosen: null
		};

		this.drivingInSand = 4;
		this.drivingInGraval = 0.4;
		this.drivingInGravalSand = 1.3;

		this.startEnergy = 130;
		this.batteryCapacity = 200;
		this.chargeRateDay = 20;
		this.energyUsePassiveNormal = 1;
		this.energyUsePassiveSave = 0.2;

		this.marsGravity = 3.721; // m/s2;
		this.roverWeight = 1500; // kg;

		this.startOxygen = 2000;
		this.oxygenConsumptionBase = 0.25 * 60;
		this.oxygenConsumptionCold = 0.3 * 60;
		this.oxygenConsumptionWorking = 0.1 * 60;

		this.distanceLongWay = 600;
		this.distanceDesert = 150;
		this.distanceCanyon = 280;

		this.timeStart = 0;
		this.timeEnd = 72;
		this.timeStep = 72 * 60;
		this.timeStorm = 18;
		this.timeStormStop = this.timeStorm + 16;

		this.nightLength = 10.33;
		this.dayLength = 14.33;
		this.sun = [3.8];

		let i;
		for (i = 0; i < 3; i++) {
			this.sun.push(this.sun[this.sun.length - 1] + this.nightLength);
			this.sun.push(this.sun[this.sun.length - 1] + this.dayLength);
		}
	};

	/**
	* Start new game
	*/
	componentDidMount() {
		this.resetGame();
	}

	/**
	* Reset game by setting the first entry as the starting point and all choices to null
	*/
	resetGame = () => {
		this.time = this.linspace(this.timeStart, this.timeEnd, this.timeStep); // # hours
		this.oxygen = this.linspace(this.startOxygen, this.startOxygen, this.timeStep); // # liters
		this.oxygenConsumption = []; // # liters
		this.energy = this.linspace(this.startEnergy, this.startEnergy, this.timeStep); // # kWh
		this.energyConsumption = [0]; // # kWh
		this.distance = null; // will be instantiated after first choice
		let startEntry = this.props.cyoaGame.entries.filter((entry) => {return entry.id === 'start';})[0];
		this.setState({
			choicesMade: [],
			entry: startEntry,
			currentTimeStep: 0,
			nextEntryId: null,
			scientistOptionChosen: null,
			craterOptionChoosen: null,
			lifeSupportOptionChoosen: null,
			stormOptionChoosen: null,
			cleanSollarPanelsOptionChosen: null,
			hillOptionChosen: null,
			gameReady: true,
			showBlur: false
		});
	}

	toggleCyoaGame = (showCyoa) => {
		this.props.toggleCyoaGame(showCyoa);
		if (showCyoa === false) {
			this.resetGame();
		}
	}

	/** Returns as many evenly spaced samples as cardinality value, calculated over the interval [start, stop] */
	linspace = (startValue, stopValue, cardinality) => {
		let arr = [];
		let step = (stopValue - startValue) / (cardinality - 1);
		for (let i = 0; i < cardinality; i++) {
			arr.push(startValue + (step * i));
		}
		return arr;
	}

	/**
	 * Select choice
	 */
	selectChoice = (choice) => {
		this.setState({showBlur: true}, () => {
			setTimeout(() => {
				let newEntry = this.getEntry(choice.nextEntryId);
				let choicesMade = JSON.parse(JSON.stringify(this.state.choicesMade));

				/** Add new choice to state */
				choicesMade[this.state.entry.index] = choice.index;

				/** First choice: choose route and set the right distance to base */
				if (this.distance === null) {
					if (choice.index === 1) {
						this.distance = this.linspace(this.distanceLongWay, this.distanceLongWay, this.timeStep);
					}
					if (choice.index === 2) {
						this.distance = this.linspace(this.distanceDesert, this.distanceDesert, this.timeStep);
					}
					if (choice.index === 3) {
						this.distance = this.linspace(this.distanceCanyon, this.distanceCanyon, this.timeStep);
					}
				}

				/** From the second choice onwards, there is different game logic based on the chosen route */
				if (choicesMade.length >= 2) {
					let params = [
						choicesMade,
						this.time,
						this.oxygen,
						this.oxygenConsumption,
						this.energy,
						this.energyConsumption,
						this.distance
					];
					/** Add choice and nextEntryId to state
					 * We will show the entry at nextEntryId when there is an event that triggers it, not right away
					 * Ex: nightfall, caught by storm, energy drops below 400 liters, etc. */
					this.setState({
						choicesMade: choicesMade,
						nextEntryId: choice.nextEntryId
					}, () => {
						if (choicesMade[0] === 1) this.longWay(...params);
						if (choicesMade[0] === 2) this.dessert(...params);
						if (choicesMade[0] === 3) this.canyon(...params);
					});
				} else {
					/** Else, just add choice and new entry to state */
					this.setState({
						choicesMade: choicesMade,
						entry: newEntry,
						showBlur: false
					});
				}
			}, 300);
		});
	}

	/**
	 * Get entry from entries array
	 */
	getEntry = (entryId) => {
		return this.props.cyoaGame.entries.filter((entry) => {return entry.id === entryId;})[0];
	}

	updateStats = (
		t, speed, oxygenConsumption, energyConsumptionDriving, energyConsumptionSystems, roverDriveDamage
	) => {
		let divider = 60;
		let prevTimeStep = t === 0 ? (this.time.length - 1) : (t - 1);

		if (speed > 0) {
			// eslint-disable-next-line max-len
			this.energy[t] = this.energy[prevTimeStep] - (speed / divider) * energyConsumptionDriving - energyConsumptionSystems / divider;
			this.energyConsumption.push(speed * energyConsumptionDriving + energyConsumptionSystems);
		}
		if (speed === 0) {
			// eslint-disable-next-line max-len
			this.energy[t] = this.energy[prevTimeStep] + energyConsumptionDriving / divider - energyConsumptionSystems / divider;
			this.energyConsumption.push(- energyConsumptionDriving + energyConsumptionSystems);
		}

		this.distance[t] = this.distance[prevTimeStep] - (speed / divider) * roverDriveDamage;

		this.oxygen[t] = this.oxygen[prevTimeStep] - oxygenConsumption / divider;
		
		return {
			Time: this.time,
			Oxygen: this.oxygen,
			OxygenConsumption: oxygenConsumption,
			Energy: this.energy,
			EnergyConsumption: this.energyConsumption,
			Distance: this.distance
		};
	}

	checkDay = (dayCycle, sun, s, time, t) => {
		let newDayCycle = dayCycle;
		let newS = s;
		if (dayCycle === true) {
			if (sun[s] < time[t]) {
				newDayCycle = false;
				newS += 1;
			}
		} else {
			if (sun[s] < time[t]) {
				newDayCycle = true;
				newS += 1;
			}
		}

		return {dayCycle: newDayCycle, s: newS};
	}

	/* eslint-disable no-param-reassign */
	checkCharging = (
		dayCycle,
		t,
		speed,
		speedChargeSave,
		energyUseDrive,
		energyUseDriveChargeSave,
		charging,
		chargeRateDay,
		batteryCapacity,
		energyUsePassive,
		nightLength
	) => {
		let prevTimeStep = t === 0 ? (this.time.length - 1) : (t - 1);
		if (charging === false && this.energy[prevTimeStep] < energyUsePassive * nightLength) {
			speedChargeSave = speed;
			energyUseDriveChargeSave = energyUseDrive;
			charging = true;
		}
	
		let capModifier;
		let chargeRate;
		if (dayCycle === true) {
			chargeRate = chargeRateDay;
			capModifier = 1;
		} else {
			chargeRate = 0;
			if (this.state.choicesMade[0] === 2) {
				capModifier = 1;
			} else {
				capModifier = 0.5;
			}
		}
	
		if (charging === true && this.energy[prevTimeStep] > batteryCapacity * capModifier) {
			speed = speedChargeSave;
			energyUseDrive = energyUseDriveChargeSave;
			charging = false;
		}
		
		if (charging === true) {
			energyUseDrive = chargeRate;
			speed = 0;
		}
	
		return {speed, speedChargeSave, energyUseDrive, energyUseDriveChargeSave, charging};
	}

	longWay = (choices, Time, Oxygen, OxygenConsumption, Energy, EnergyConsumption, Distance) => {
		let charging = false;
		let batteryCapacity = 200;
		let speedChargeSave = 20;
		let speedStormSave = 20;
		let energyUseDriveChargeSave = this.drivingInGraval;
		let energyUseDrive = this.drivingInGraval;
		let Speed = 20; // #km/h;
		let nrOfScientists = 2;
		let roverDriveDamage = 1;
		let roverChargeDamage = 1;

		let oxygenConsumptionLeak = 0;

		let bakkenHeight = 1; // #km;
		let bakkenLength = 10; // #km;
		let bakkenAroundLength = 100; // #km;

		let distanceToSafe = 260;

		let energyBakken = 1000 * bakkenHeight * this.marsGravity * this.roverWeight / 3600 / 1000;

		let oxygenConsumtionResting = this.oxygenConsumptionBase;
		let energyUsePasive = this.energyUsePassiveNormal;

		let inCrater = false;
		let craterChosen = false;

		let bakkenChosen = false;
		let onBakken = false;

		let varmingChosen = false;
		let scientistChosen = false;
		let stormChosen = false;

		let cleanSolarPanelsChosen = false;
		let reparationDone = true;

		if (choices[1] === 1) charging = true;
			
		if (choices[1] === 2) charging = false;

		let S = 0;
		let dayCycle = true;
		let oxygenUse;
		let saveTime;
		let craterDistance = 400;
		let energyUseDriveCraterSave;
		let speedCraterSave;
		for (let t = 0; t < Time.length; t++) {
			let prevTStep = t === 0 ? (this.time.length - 1) : (t - 1);

			let checkDayResult = this.checkDay(dayCycle, this.sun, S, Time, t);
			dayCycle = checkDayResult.dayCycle;
			S = checkDayResult.s;

			/*
			* Bakken
			*/
			if (Distance[prevTStep] < this.distanceLongWay - 100
				&& Distance[prevTStep] > this.distanceLongWay - 100 - bakkenLength
			) {
				if (bakkenChosen === false) {
					if (choices[3] === 2) {
						Distance[prevTStep] += bakkenAroundLength;
					}
					bakkenChosen = true;
				}
				
				if (onBakken === false) {
					onBakken = true;
				}
				
				if (choices[3] === 1) {
					Speed = 5;
					energyUseDrive = this.drivingInGraval + 2 * energyBakken / bakkenLength;
				}

				if (choices[3] === 2) {
					Speed = 20;
					energyUseDrive = this.drivingInGraval + energyBakken / bakkenAroundLength;
				}
			}

			if (Distance[prevTStep] < this.distanceLongWay - 100 - bakkenLength && onBakken === true) {
				energyUseDrive = 0.4;
				Speed = 20;
				energyUseDriveChargeSave = 0.4;
				speedChargeSave = 20;

				onBakken = false;
			}

			/**
			 * Opvarmningen / Life support
			 */

			if (dayCycle === false && varmingChosen === false) {
				varmingChosen = true;
				

				if (choices[2] === 1) {
					oxygenConsumtionResting = this.oxygenConsumptionCold; // #l/h
					energyUsePasive = this.energyUsePassiveSave;
				}

				if (choices[2] === 2) {
					oxygenConsumtionResting = this.oxygenConsumptionBase; // #l/h
					energyUsePasive = this.energyUsePassiveNormal;
				}
			}

			/**
			 * Stormen_Rammer
			 */

			if (Time[t] > this.timeStorm && stormChosen === false) {
				if (Distance[t - 1] > this.distanceLongWay - distanceToSafe) {
					if (charging === true) {
						speedStormSave = speedChargeSave;
					} else {
						speedStormSave = Speed;
					}
				} else {
					stormChosen = true;
					choices[4] = 0;
					choices[5] = 0;
				}
			}

			if (Time[t] > this.timeStorm && stormChosen === false) {
				stormChosen = true;

				if (choices[4] === 1) {
					roverDriveDamage = 0.75;
					energyUseDrive = energyUseDrive * 1.25;
					energyUseDriveChargeSave = energyUseDriveChargeSave * 1.25;
					Speed = speedStormSave;
					energyUseDrive = energyUseDriveChargeSave;
					charging = false;
					roverChargeDamage = 0.0;
				}

				if (choices[4] === 2) {
					roverChargeDamage = 0.0;
					Speed =  0.000001;
					energyUseDrive = energyUseDriveChargeSave;
					charging = false;
				}

				if (choices[4] === 3) {
					roverChargeDamage = 0.33;
					speedChargeSave = speedStormSave;
					Speed = 0;
					charging = true;
				}
			}

			/**
			 * Rens_Solceller
			 */

			if (S === 3 && Time[t] < this.timeStormStop && choices[4] > 1) {
				Speed = 0.000001;
			}

			if (Time[t] > this.timeStormStop && cleanSolarPanelsChosen === false) {
				Speed = speedStormSave;
				if (choices[4] < 3) {
					roverChargeDamage = 1.0;
				} else {
					if (choices[5] === 1) {
						roverChargeDamage = 1;
						reparationDone = false;
						saveTime = t + 120;
					}
	
					if (choices[5] === 2) {
						roverChargeDamage = 0.33;
					}
				}

				cleanSolarPanelsChosen = true;
			}


			if (cleanSolarPanelsChosen === true && t < saveTime && reparationDone === false) {
				Speed = 0.000001;
			} else if (reparationDone === false && t === saveTime) {
				Speed = speedChargeSave;
				reparationDone = true;
			}

			/**
			 * Krater
			 */

			if (Distance[prevTStep] < this.distanceLongWay - craterDistance
				&& Distance[prevTStep] > this.distanceLongWay - craterDistance - 30
			) {
				if (craterChosen === false) {
					if (choices[6] === 2) {
						Distance[prevTStep] += 0.5 * Math.PI * 30;
					}
					craterChosen = true;
				}

				if (inCrater === false) {
					energyUseDriveCraterSave = energyUseDrive;
					speedCraterSave = Speed;
					inCrater = true;
				}

				if (choices[6] === 1) {
					Speed = 5;
					energyUseDrive = this.drivingInGraval - 0.062;
					if (Distance[prevTStep] < this.distanceLongWay - craterDistance - 5) {
						Speed = 15;
						energyUseDrive = this.drivingInGraval;
					}
					if (Distance[prevTStep] < this.distanceLongWay - craterDistance - 25) {
						Speed = 5;
						energyUseDrive = this.drivingInGraval + 0.062 * 2;
					}
				}

				if (choices[6] === 2) {
					energyUseDrive = this.drivingInSand;
					Speed = 10; // #km/h
					energyUseDriveChargeSave = this.drivingInSand;
					speedChargeSave = 10; // #km/h
				}
			}
			if (Distance[prevTStep] < this.distanceLongWay - craterDistance - 30 && inCrater === true) {
				energyUseDrive = energyUseDriveCraterSave;
				Speed = speedCraterSave;
				energyUseDriveChargeSave = energyUseDriveCraterSave;
				speedChargeSave = speedCraterSave;

				inCrater = false;
			}

			// /**
			//  * Efterlad en forsker
			//  */

			if (Oxygen[prevTStep] < 400 && scientistChosen === false) {
				scientistChosen = true;

				if (choices[7] === 1) {
					nrOfScientists = 1;
				}

				if (choices[7] === 2) {
					nrOfScientists = 2;
				}
			}

			if (Time[prevTStep] > 45 && S > 3) {
				batteryCapacity = 80;
			}

			let chargeRateDayUsed = this.chargeRateDay * roverChargeDamage;

			let checkChargingResult = this.checkCharging(
				dayCycle, t, Speed, speedChargeSave, energyUseDrive, energyUseDriveChargeSave, charging,
				chargeRateDayUsed, batteryCapacity, energyUsePasive, this.nightLength);

			Speed = checkChargingResult.speed;
			speedChargeSave = checkChargingResult.speedChargeSave;
			energyUseDrive = checkChargingResult.energyUseDrive;
			energyUseDriveChargeSave = checkChargingResult.energyUseDriveChargeSave;
			charging = checkChargingResult.charging;

			if (Speed === 0) {
				// eslint-disable-next-line max-len
				oxygenUse = (oxygenConsumtionResting + this.oxygenConsumptionWorking) * nrOfScientists + oxygenConsumptionLeak;
			} else {
				oxygenUse = oxygenConsumtionResting * nrOfScientists + oxygenConsumptionLeak;
			}

			let updateResult = this.updateStats(t, Speed, oxygenUse, energyUseDrive, energyUsePasive, roverDriveDamage);
			Time = updateResult.Time;
			Energy = updateResult.Energy;
			EnergyConsumption = updateResult.EnergyConsumption;
			Oxygen = updateResult.Oxygen;
			OxygenConsumption = updateResult.OxygenConsumption;
			Distance = updateResult.Distance;

			if (varmingChosen === true && choices.length === 2) {
				let newEntry = this.getEntry('life-support');
				this.setState({
					currentTimeStep: t - 1,
					entry: newEntry
				}, () => {
					this.setState({showBlur: false});
				});
				return;
			}

			if (bakkenChosen === true && choices.length === 3) {
				let newEntry = this.getEntry('hill');
				this.setState({
					currentTimeStep: t - 1,
					entry: newEntry
				}, () => {
					this.setState({showBlur: false});
				});
				return;
			}

			if (stormChosen === true && choices.length === 4) {
				let newEntry = this.getEntry('storm');
				this.setState({
					currentTimeStep: t - 1,
					entry: newEntry
				}, () => {
					this.setState({showBlur: false});
				});
				return;
			}

			if (cleanSolarPanelsChosen === true && choices.length === 5) {
				let newEntry = this.getEntry(this.state.nextEntryId);
				this.setState({
					currentTimeStep: t - 1,
					entry: newEntry
				}, () => {
					this.setState({showBlur: false});
				});
				return;
			}

			if (scientistChosen === true) {
				if (this.state.scientistOptionChosen !== true) {
					let newEntry = this.getEntry('leaving-scientist');
					this.setState({
						currentTimeStep: t - 1,
						entry: newEntry,
						scientistOptionChosen: true
					}, () => {
						this.setState({showBlur: false});
					});
					return;
				}
			}

			if (craterChosen === true) {
				if (this.state.craterOptionChoosen !== true) {
					let newEntry = this.getEntry('crater');
					this.setState({
						currentTimeStep: t - 1,
						entry: newEntry,
						craterOptionChoosen: true
					}, () => {
						this.setState({showBlur: false});
					});
					return;
				}
			}

			if (Distance[prevTStep] <= 0 && Oxygen[prevTStep] > 0) {
				Time = Time.slice(0, t);
				Oxygen = Oxygen.slice(0, t);
				Energy = Energy.slice(0, t);
				Distance = Distance.slice(0, t);

				let newEntry;
				if (nrOfScientists === 2) {
					newEntry = this.getEntry('both-lived');
				} else {
					newEntry = this.getEntry('one-lived');
				}
				this.setState({
					currentTimeStep: t - 1,
					entry: newEntry
				}, () => {
					this.setState({showBlur: false});
				});
				return;
			} else if (Distance[prevTStep] >= 0 && Oxygen[prevTStep] < 0) {
				Time = Time.slice(0, t);
				Oxygen = Oxygen.slice(0, t);
				Energy = Energy.slice(0, t);
				Distance = Distance.slice(0, t);

				let newEntry;
				if (nrOfScientists === 2) {
					newEntry = this.getEntry('both-died');
				} else {
					newEntry = this.getEntry('both-died-one-left');
				}
				this.setState({
					currentTimeStep: t - 1,
					entry: newEntry
				}, () => {
					this.setState({showBlur: false});
				});
				return;
			}
		}
	}

	dessert = (choices, Time, Oxygen, OxygenConsumption, Energy, EnergyConsumption, Distance) => {
		let S = 0;
		let dayCycle = true;
		let charging = false;
		let batteryCapacity = 200;
		let speedChargeSave = 10;
		let energyUseDriveChargeSave = this.drivingInSand;
		let energyUseDrive = this.drivingInSand;

		let Speed = 10; // #km/h
		let nrOfScientists = 2;
		let roverDriveDamage = 1;
		let roverChargeDamage = 1;

		let oxygenConsumptionLeak = 0;

		let oxygenConsumtionResting = this.oxygenConsumptionBase;
		let energyUsePasive = this.energyUsePassiveNormal;

		let inCrater = false;
		let craterChosen = false;
		let varmingChosen = false;
		let scientistChosen = false;
		let stormChosen = false;
		let cleanSolarPanelsChosen = false;
		let reparationDone = true;

		if (choices[1] === 1) {
			charging = true;
		}
			
		if (choices[1] === 2) {
			charging = false;
		}

		let energyUseDriveCraterSave;
		let speedCraterSave;
		let saveTime;
		let oxygenUse;
		for (let t = 0; t < Time.length; t++) {
			let prevTStep = t === 0 ? (this.time.length - 1) : (t - 1);

			let checkDayResult = this.checkDay(dayCycle, this.sun, S, Time, t);
			dayCycle = checkDayResult.dayCycle;
			S = checkDayResult.s;

			/**
			 * Krater
			 */
			
			if (Distance[prevTStep] < this.distanceDesert - 21 && Distance[prevTStep] > this.distanceDesert - 35) {
				if (craterChosen === false) {
					if (choices[2] === 2) {
						Distance[prevTStep] += 0.5 * Math.PI * 14;
					}
					craterChosen = true;
				}


				if (inCrater === false) {
					energyUseDriveCraterSave = energyUseDrive;
					speedCraterSave = Speed;
					inCrater = true;
				}


				if (choices[2] === 1) {
					Speed = 5;
					energyUseDrive = this.drivingInGraval - 0.062;
					if (Distance[prevTStep] < this.distanceDesert - 23) {
						Speed = 20;
						energyUseDrive = this.drivingInGraval;
					}
					if (Distance[prevTStep] < this.distanceDesert - 33) {
						Speed = 5;
						energyUseDrive = this.drivingInGraval + 0.062 * 2;
					}
				}

				if (choices[2] === 2) {
					energyUseDrive = this.drivingInSand;
					Speed = 10; // #km/h
					energyUseDriveChargeSave = this.drivingInSand;
					speedChargeSave = 10; // #km/h
				}
			}
			if (Distance[prevTStep] < this.distanceDesert - 35 && inCrater === true) {
				energyUseDrive = energyUseDriveCraterSave;
				Speed = speedCraterSave;
				energyUseDriveChargeSave = energyUseDriveCraterSave;
				speedChargeSave = speedCraterSave;

				inCrater = false;
			}

			/**
			 * Opvarmningen
			 */

			if (dayCycle === false && varmingChosen === false) {
				varmingChosen = true;

				if (choices[3] === 1) {
					oxygenConsumtionResting = this.oxygenConsumptionCold; // #l/h
					energyUsePasive = this.energyUsePassiveSave;
				}

				if (choices[3] === 2) {
					oxygenConsumtionResting = this.oxygenConsumptionBase; // #l/h
					energyUsePasive = this.energyUsePassiveNormal;
				}
			}

			/**
			 * Stormen_Rammer
			 */

			if (Time[t] > this.timeStorm && Time[t] < this.timeStormStop && stormChosen === false) {
				stormChosen = true;

				if (choices[4] === 1) {
					roverDriveDamage = 0.75;
					energyUseDrive = energyUseDrive * 1.25;
					Speed = speedChargeSave;
					energyUseDrive = energyUseDriveChargeSave;
					charging = false;
					roverChargeDamage = 0.0;
				}

				if (choices[4] === 2) {
					roverChargeDamage = 0.0;
				}

				if (choices[4] === 3) {
					roverChargeDamage = 0.33;
				}
			}

			/**
			 * Rens_Solceller
			 */

			if (S === 3 && Time[t] < this.timeStormStop && choices[4] > 1) {
				Speed = 0.000001;
			}
				
			if (Time[t] > this.timeStormStop && cleanSolarPanelsChosen === false) {
				saveTime = t;
				if (choices[4] < 3) {
					roverChargeDamage = 1.0;
				} else {
					if (choices[5] === 1) {
						roverChargeDamage = 1;
						reparationDone = false;
						saveTime = t + 120;
					}

					if (choices[5] === 2) {
						roverChargeDamage = 0.33;
					}
				}
				cleanSolarPanelsChosen = true;
			}


			if (cleanSolarPanelsChosen === true && t < saveTime && reparationDone === false) {
				Speed = 0;
			} else if (reparationDone === false && t === saveTime) {
				Speed = speedChargeSave;
				reparationDone = true;
			}

			/**
			 * Efterlad en forsker
			 */

			if (Oxygen[t - 1] < 400 && scientistChosen === false) {
				scientistChosen = true;

				if (choices[6] === 1) {
					nrOfScientists = 1;
				}

				if (choices[6] === 2) {
					nrOfScientists = 2;
				}
			}

			if (Time[t - 1] > 45 && S > 3) {
				batteryCapacity = 100;
			}

			if (S > 4) {
				batteryCapacity = 0;
			}

			let chargeRateDayUsed = this.chargeRateDay * roverChargeDamage;

			let checkChargingResult = this.checkCharging(
				dayCycle, t, Speed, speedChargeSave, energyUseDrive, energyUseDriveChargeSave, charging,
				chargeRateDayUsed, batteryCapacity, energyUsePasive, this.nightLength);

			Speed = checkChargingResult.speed;
			speedChargeSave = checkChargingResult.speedChargeSave;
			energyUseDrive = checkChargingResult.energyUseDrive;
			energyUseDriveChargeSave = checkChargingResult.energyUseDriveChargeSave;
			charging = checkChargingResult.charging;

			if (Speed === 0.0) {
				// eslint-disable-next-line max-len
				oxygenUse = (oxygenConsumtionResting + this.oxygenConsumptionWorking) * nrOfScientists + oxygenConsumptionLeak;
			} else {
				oxygenUse = oxygenConsumtionResting * nrOfScientists + oxygenConsumptionLeak;
			}


			let updateResult = this.updateStats(t, Speed, oxygenUse, energyUseDrive, energyUsePasive, roverDriveDamage);
			Time = updateResult.Time;
			Energy = updateResult.Energy;
			EnergyConsumption = updateResult.EnergyConsumption;
			Oxygen = updateResult.Oxygen;
			OxygenConsumption = updateResult.OxygenConsumption;
			Distance = updateResult.Distance;

			if (craterChosen === true) {
				if (this.state.craterOptionChoosen !== true) {
					let newEntry = this.getEntry('crater-dessert-canyon');
					this.setState({
						currentTimeStep: t - 1,
						entry: newEntry,
						craterOptionChoosen: true
					}, () => {
						this.setState({showBlur: false});
					});
					return;
				}
			}
			if (varmingChosen === true) {
				if (this.state.lifeSupportOptionChoosen !== true) {
					let newEntry = this.getEntry('life-support-dessert-canyon');
					this.setState({
						currentTimeStep: t - 1,
						entry: newEntry,
						lifeSupportOptionChoosen: true
					}, () => {
						this.setState({showBlur: false});
					});
					return;
				}
			}

			if (stormChosen === true) {
				if (this.state.stormOptionChoosen !== true) {
					let newEntry = this.getEntry('storm');
					this.setState({
						currentTimeStep: t - 1,
						entry: newEntry,
						stormOptionChoosen: true
					}, () => {
						this.setState({showBlur: false});
					});
					return;
				}
			}

			if (cleanSolarPanelsChosen === true) {
				if (this.state.cleanSollarPanelsOptionChosen !== true) {
					let newEntry = this.getEntry(this.state.nextEntryId);
					this.setState({
						currentTimeStep: t - 1,
						entry: newEntry,
						cleanSollarPanelsOptionChosen: true
					}, () => {
						this.setState({showBlur: false});
					});
					return;
				}
			}

			if (scientistChosen === true) {
				if (this.state.scientistOptionChosen !== true) {
					let newEntry = this.getEntry('leaving-scientist-dessert');
					this.setState({
						currentTimeStep: t - 1,
						entry: newEntry,
						scientistOptionChosen: true
					}, () => {
						this.setState({showBlur: false});
					});
					return;
				}
			}

			if (Distance[prevTStep] <= 0 && Oxygen[t - 1] > 0) {
				Time = Time.slice(0, t);
				Oxygen = Oxygen.slice(0, t);
				Energy = Energy.slice(0, t);
				Distance = Distance.slice(0, t);

				let newEntry;
				if (nrOfScientists === 2) {
					newEntry = this.getEntry('both-lived');
				} else {
					newEntry = this.getEntry('one-lived');
				}
				this.setState({
					currentTimeStep: t - 1,
					entry: newEntry
				}, () => {
					this.setState({showBlur: false});
				});
				return;
			} else if (Distance[prevTStep] >= 0 && Oxygen[t - 1] < 0) {
				Time = Time.slice(0, t);
				Oxygen = Oxygen.slice(0, t);
				Energy = Energy.slice(0, t);
				Distance = Distance.slice(0, t);

				let newEntry;
				if (nrOfScientists === 2) {
					newEntry = this.getEntry('both-died');
				} else {
					newEntry = this.getEntry('both-died-one-left');
				}
				this.setState({
					currentTimeStep: t - 1,
					entry: newEntry
				}, () => {
					this.setState({showBlur: false});
				});
				return;
			}
		}
	}

	canyon = (choices, Time, Oxygen, OxygenConsumption, Energy, EnergyConsumption, Distance) => {
		let S = 0;
		let dayCycle = true;
		let charging = false;
		let batteryCapacity = 200;
		let speedChargeSave = 15; // #km/h
		let speedStormSave = 15;
		let energyUseDriveChargeSave = 0.75 * this.drivingInGraval + 0.25 * this.drivingInSand;
		let energyUseDrive = 0.75 * this.drivingInGraval + 0.25 * this.drivingInSand;
		let Speed = 15; // #km/h
		let nrOfScientists = 2;
		let roverDriveDamage = 1;
		let roverChargeDamage = 1;

		let oxygenConsumptionLeak = 0;

		let bakkenHeight = 1; // #km
		let bakkenLength = 10; // #km
		let bakkenAroundLength = 50; // #km

		let canyonEntry = 110; // #km;
		let stormVelocity = 90;
		let lessStormTime = canyonEntry / (stormVelocity + Speed);

		let energyBakken = 1000 * bakkenHeight * this.marsGravity * this.roverWeight / 3600 / 1000;

		let oxygenConsumtionResting = this.oxygenConsumptionBase;
		let energyUsePasive = this.energyUsePassiveNormal;

		let inCrater = false;
		let craterChosen = false;

		let bakkenChosen = false;
		let onBakken = false;

		let varmingChosen = false;
		let scientistChosen = false;
		let stormChosen = false;

		let cleanSolarPanelsChosen = false;
		let reparationDone = true;

		let Safty = false;

		if (choices[1] === 1) {
			charging = true;
		}
		
		if (choices[1] === 2) {
			charging = false;
		}

		let energyUseDriveCraterSave;
		let speedCraterSave;
		let saveTime;
		let oxygenUse;
		// eslint-disable-next-line no-unused-vars
		let energyUseDriveBakkenSave;
		// eslint-disable-next-line no-unused-vars
		let speedBakkenSave;
		for (let t = 0; t < Time.length; t++) {
			let prevTStep = t === 0 ? (this.time.length - 1) : (t - 1);

			let checkDayResult = this.checkDay(dayCycle, this.sun, S, Time, t);
			dayCycle = checkDayResult.dayCycle;
			S = checkDayResult.s;


			/**
			 * Krater
			 */

			if (Distance[prevTStep] < this.distanceCanyon - 50 && Distance[prevTStep] > this.distanceCanyon - 70) {
				if (craterChosen === false) {
					if (choices[2] === 2) {
						Distance[prevTStep] += 0.5 * Math.PI * 20;
					}
					craterChosen = true;
				}


				if (inCrater === false) {
					energyUseDriveCraterSave = energyUseDrive;
					speedCraterSave = Speed;
					inCrater = true;
				}


				if (choices[2] === 1) {
					Speed = 5;
					energyUseDrive = this.drivingInGraval - 0.062;
					if (Distance[prevTStep] < this.distanceCanyon - 54) {
						Speed = 20;
						energyUseDrive = this.drivingInGraval;
					}
					if (Distance[prevTStep] < this.distanceCanyon - 66) {
						Speed = 5;
						energyUseDrive = this.drivingInGraval + 0.062 * 2;
					}
				}

				if (choices[2] === 2) {
					energyUseDrive = this.drivingInSand;
					energyUseDrive = 0.75 * this.drivingInGraval + 0.25 * this.drivingInSand;
					Speed = 15; // #km/h
					energyUseDriveChargeSave = this.drivingInSand;
					energyUseDriveChargeSave = 0.75 * this.drivingInGraval + 0.25 * this.drivingInSand;
					speedChargeSave = 15; // #km/h
				}
			}
			if (Distance[prevTStep] < this.distanceCanyon - 70 && inCrater === true) {
				energyUseDrive = energyUseDriveCraterSave;
				Speed = speedCraterSave;
				energyUseDriveChargeSave = energyUseDriveCraterSave;
				speedChargeSave = speedCraterSave;

				inCrater = false;
			}

			/**
			 * Opvarmningen
			 */


			if (dayCycle === false && varmingChosen === false) {
				varmingChosen = true;

				if (choices[3] === 1) {
					oxygenConsumtionResting = this.oxygenConsumptionCold; // #l/h
					energyUsePasive = this.energyUsePassiveSave;
				}

				if (choices[3] === 2) {
					oxygenConsumtionResting = this.oxygenConsumptionBase; // #l/h
					energyUsePasive = this.energyUsePassiveNormal;
				}
			}

			/**
			 * Stormen_Rammer
			 */

			if (Time[t] > this.timeStorm - lessStormTime && stormChosen === false) {
				if (Distance[prevTStep] > this.distanceCanyon - canyonEntry) {
					if (charging === true) {
						speedStormSave = speedChargeSave;
					} else {
						speedStormSave = Speed;
					}
				} else {
					stormChosen = true;
					choices[4] = 0;
					choices[5] = 0;
				}
			}

			if (Time[t] > this.timeStorm - lessStormTime && stormChosen === false) {
				stormChosen = true;

				if (choices[4] === 1) {
					roverDriveDamage = 0.75;
					energyUseDrive = energyUseDrive * 1.25;
					energyUseDriveChargeSave = energyUseDriveChargeSave * 1.25;
					Speed = speedStormSave;
					energyUseDrive = energyUseDriveChargeSave;
					charging = false;
					roverChargeDamage = 0.0;
				}

				if (choices[4] === 2) {
					roverChargeDamage = 0.0;
					Speed = 0.000001;
					energyUseDrive = energyUseDriveChargeSave;
					charging = false;
				}

				if (choices[4] === 3) {
					roverChargeDamage = 0.33;
					speedChargeSave = speedStormSave;
					Speed = 0;
					charging = true;
				}
			}


			/**
			 * Rens_Solceller
			 * OPS OPS, maske ikke vigtig!!!!
			 */

			if (S === 3 && Time[t] < this.timeStormStop - lessStormTime && choices[4] > 1) {
				Speed = 0.000001;
			}

			if (Time[t] > this.timeStormStop - lessStormTime && cleanSolarPanelsChosen === false) {
				saveTime = t;
				Speed = speedStormSave;
				if (choices[4] < 3) {
					roverChargeDamage = 1.0;
				} else {
					if (choices[5] === 1) {
						roverChargeDamage = 1;
						reparationDone = false;
						saveTime = t + 120;
					}
	
					if (choices[5] === 2) {
						roverChargeDamage = 0.33;
					}
				}

				cleanSolarPanelsChosen = true;
			}


			if (cleanSolarPanelsChosen === true && t < saveTime && reparationDone === false) {
				Speed = 0.000001;
			} else if (reparationDone === false && t === saveTime) {
				Speed = speedChargeSave;
				reparationDone = true;
			}

			if (Safty === true) {
				if (Time[t] > this.timeStorm - lessStormTime) {
					roverChargeDamage = 0.45;
				}
				if (Time[t] > this.timeStormStop - lessStormTime) {
					roverChargeDamage = 1;
					Safty = false;
				}
			}

			/**
			 * Bakken
			 */

			if (Distance[prevTStep] < this.distanceCanyon - 200
				&& Distance[prevTStep] > this.distanceCanyon - 200 - bakkenLength
			) {
				if (bakkenChosen === false) {
					if (choices[6] === 2) {
						Distance[prevTStep] += bakkenAroundLength;
					}
					bakkenChosen = true;
				}

				if (onBakken === false) {
					energyUseDriveBakkenSave = 1.3;
					speedBakkenSave = 15;
					onBakken = true;
				}

				if (choices[6] === 1) {
					Speed = 5;
					energyUseDrive = this.drivingInGravalSand + 2 * energyBakken / bakkenLength;
				}

				if (choices[6] === 2) {
					Speed = 20;
					energyUseDrive = this.drivingInGravalSand + energyBakken / bakkenAroundLength;
				}
			}

			if (Distance[prevTStep] < this.distanceCanyon - 200 - bakkenLength && onBakken === true) {
				energyUseDrive = 1.3;
				Speed = 15;
				energyUseDriveChargeSave = 1.3;
				speedChargeSave = 15;

				onBakken = false;
			}

			/**
			 * Efterlad en forsker
			 */

			if (Oxygen[prevTStep] < 400 && scientistChosen === false) {
				scientistChosen = true;

				if (choices[7] === 1) {
					nrOfScientists = 1;
				}

				if (choices[7] === 2) {
					nrOfScientists = 2;
				}
			}


			if (Time[prevTStep] > 45 && S > 3) {
				batteryCapacity = 80;
			}

			if (S === 5) {
				batteryCapacity = 0;
			}

			let chargeRateDayUsed = this.chargeRateDay * roverChargeDamage;

			let checkChargingResult = this.checkCharging(
				dayCycle, t, Speed, speedChargeSave, energyUseDrive, energyUseDriveChargeSave, charging,
				chargeRateDayUsed, batteryCapacity, energyUsePasive, this.nightLength);

			Speed = checkChargingResult.speed;
			speedChargeSave = checkChargingResult.speedChargeSave;
			energyUseDrive = checkChargingResult.energyUseDrive;
			energyUseDriveChargeSave = checkChargingResult.energyUseDriveChargeSave;
			charging = checkChargingResult.charging;

			if (Speed === 0) {
				// eslint-disable-next-line max-len
				oxygenUse = (oxygenConsumtionResting + this.oxygenConsumptionWorking) * nrOfScientists + oxygenConsumptionLeak;
			} else {
				oxygenUse = oxygenConsumtionResting * nrOfScientists + oxygenConsumptionLeak;
			}

			let updateResult = this.updateStats(t, Speed, oxygenUse, energyUseDrive, energyUsePasive, roverDriveDamage);
			Time = updateResult.Time;
			Energy = updateResult.Energy;
			EnergyConsumption = updateResult.EnergyConsumption;
			Oxygen = updateResult.Oxygen;
			OxygenConsumption = updateResult.OxygenConsumption;
			Distance = updateResult.Distance;

			if (craterChosen === true) {
				if (this.state.craterOptionChoosen !== true) {
					let newEntry = this.getEntry('crater-dessert-canyon');
					this.setState({
						currentTimeStep: t - 1,
						entry: newEntry,
						craterOptionChoosen: true
					}, () => {
						this.setState({showBlur: false});
					});
					return;
				}
			}
			if (varmingChosen === true) {
				if (this.state.lifeSupportOptionChoosen !== true) {
					let newEntry = this.getEntry('life-support-dessert-canyon');
					this.setState({
						currentTimeStep: t - 1,
						entry: newEntry,
						lifeSupportOptionChoosen: true
					}, () => {
						this.setState({showBlur: false});
					});
					return;
				}
			}

			if (stormChosen === true && choices[4] !== 0 && choices[5] !== 0) {
				if (this.state.stormOptionChoosen !== true) {
					let newEntry = this.getEntry('storm');
					this.setState({
						currentTimeStep: t - 1,
						entry: newEntry,
						stormOptionChoosen: true
					}, () => {
						this.setState({showBlur: false});
					});
					return;
				}
			}

			if (cleanSolarPanelsChosen === true && choices[4] !== 0 && choices[5] !== 0) {
				if (this.state.cleanSollarPanelsOptionChosen !== true) {
					let newEntry = this.getEntry(this.state.nextEntryId);
					this.setState({
						currentTimeStep: t - 1,
						entry: newEntry,
						cleanSollarPanelsOptionChosen: true
					}, () => {
						this.setState({showBlur: false});
					});
					return;
				}
			}

			if (bakkenChosen === true) {
				if (this.state.hillOptionChosen !== true) {
					let newEntry = this.getEntry('hill-canyon');
					this.setState({
						currentTimeStep: t - 1,
						entry: newEntry,
						hillOptionChosen: true
					}, () => {
						this.setState({showBlur: false});
					});
					return;
				}
			}

			if (scientistChosen === true) {
				if (this.state.scientistOptionChosen !== true) {
					let newEntry = this.getEntry('leaving-scientist');
					this.setState({
						currentTimeStep: t - 1,
						entry: newEntry,
						scientistOptionChosen: true
					}, () => {
						this.setState({showBlur: false});
					});
					return;
				}
			}

			if (Distance[prevTStep] <= 0 && Oxygen[prevTStep] > 0) {
				Time = Time.slice(0, t);
				Oxygen = Oxygen.slice(0, t);
				Energy = Energy.slice(0, t);
				Distance = Distance.slice(0, t);

				let newEntry;
				if (nrOfScientists === 2) {
					newEntry = this.getEntry('both-lived');
				} else {
					newEntry = this.getEntry('one-lived');
				}
				this.setState({
					currentTimeStep: t - 1,
					entry: newEntry
				}, () => {
					this.setState({showBlur: false});
				});
				return;
			} else if (Distance[prevTStep] >= 0 && Oxygen[prevTStep] < 0) {
				Time = Time.slice(0, t);
				Oxygen = Oxygen.slice(0, t);
				Energy = Energy.slice(0, t);
				Distance = Distance.slice(0, t);

				let newEntry;
				if (nrOfScientists === 2) {
					newEntry = this.getEntry('both-died');
				} else {
					newEntry = this.getEntry('both-died-one-left');
				}
				this.setState({
					currentTimeStep: t - 1,
					entry: newEntry
				}, () => {
					this.setState({showBlur: false});
				});
				return;
			}
		}
	}

	render() {
		if (this.state.gameReady) {
			return (
				<Rover
					entry={this.state.entry}
					selectChoice={this.selectChoice}
					choicesMade={this.state.choicesMade}
					time={this.time}
					oxygen={this.oxygen}
					energy={this.energy}
					distance={this.distance}
					sunCycles={this.sun}
					currentTimeStep={this.state.currentTimeStep}
					isTeacher={this.props.isTeacher}
					toggleCyoaGame={this.toggleCyoaGame}
					cyoaGameStatus={this.props.cyoaGameStatus}
					cyoaGame={this.props.cyoaGame}
					updateGroup={this.props.updateGroup}
					updateGame={this.props.updateGame}
					game={this.props.game}
					group={this.props.group}
					openPopup={this.props.openPopup}
					showBlur={this.state.showBlur}
				/>
			);
		}
		return null;
	}
}

RoverController.propTypes = {
	game: PropTypes.object.isRequired,
	updateGame: PropTypes.func,
	cyoaGame: PropTypes.object,
	isTeacher: PropTypes.bool.isRequired,
	updateGroup: PropTypes.func,
	group: PropTypes.object,
	openPopup: PropTypes.func.isRequired,
	toggleCyoaGame: PropTypes.func.isRequired,
	cyoaGameStatus: PropTypes.string.isRequired,
};

export default RoverController;
