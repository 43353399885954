import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {investigationsData} from 'data/investigations-data';
import {getInvestigationInstructions, saveInvestigationInstructions} from 'helpers/game-content-helper';
import Investigations from './investigations';

class InvestigationsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			investigations: [],
			selectedInvestigationIndex: 0,
			selectedInvestigation: null,
			instructions: {},
			isEditingInvestigation: false,
			isEditingInstructions: false,
			isSaving: false,
			errorMsg: null
		};
		this.subscribeToInvestigations = this.subscribeToInvestigations.bind(this);
		this.unsubscribeInvestigations = null;
		this.selectInvestigation = this.selectInvestigation.bind(this);
		this.updateInvestigation = this.updateInvestigation.bind(this);
		this.updateInstructions = this.updateInstructions.bind(this);
		this.handleSaveInvestigation = this.handleSaveInvestigation.bind(this);
		this.saveInvestigation = this.saveInvestigation.bind(this);
	};

	/**
	 * Component mounted
	 */
	componentDidMount() {
		this.subscribeToInvestigations().then((response) => {
			let investigations = (response.status === 'ok' ? response.investigations : []);
			let selectedInvestigationIndex = 0;
			let selectedInvestigation = investigations[0];
			let promises = [];
			let instructions = {};
			selectedInvestigation.parts.forEach((part) => {
				if (selectedInvestigation.instructions[part].hasTeacherInstructions) {
					promises.push(getInvestigationInstructions(selectedInvestigation.id, 
						[{part: part, role: 'teacher', text: ''}]));
				}
				if (selectedInvestigation.instructions[part].hasGroupInstructions) {
					promises.push(getInvestigationInstructions(selectedInvestigation.id, 
						[{part: part, role: 'group', text: ''}]));
				}
			});
	
			Promise.all(promises).then((responses) => {
				let promiseIndex = 0;
				selectedInvestigation.parts.forEach((part) => {
					instructions[part] = {teacher: null, group: null};
					if (selectedInvestigation.instructions[part].hasTeacherInstructions) {
						if (responses[promiseIndex].status === 'ok') {
							instructions[part].teacher = responses[promiseIndex].instructions[0].text;
						}
						promiseIndex = promiseIndex + 1;
					}
					if (selectedInvestigation.instructions[part].hasGroupInstructions) {
						if (responses[promiseIndex].status === 'ok') {
							instructions[part].group = responses[promiseIndex].instructions[0].text;
						}
						promiseIndex = promiseIndex + 1;
					}				
				});
	
				this.setState({
					selectedInvestigationIndex: selectedInvestigationIndex,
					selectedInvestigation: selectedInvestigation,
					instructions: instructions,
				});
			});	
		});
	}


	/**
	 * Subscribe to investigations
	 */
	subscribeToInvestigations() {
		if (this.unsubscribeInvestigations !== null) this.unsubscribeInvestigations();
		const db = firebase.firestore();
		return new Promise((resolve, reject)=>{
			this.unsubscribeInvestigations = db.collection('investigations').onSnapshot((querySnapshot) => {
				let investigations = [];
				if (!querySnapshot.empty) {
					investigations = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = doc.id;
						let investigationData = investigationsData.filter((investigation) => {
							return investigation.id === data.id;
						})[0];
						data.parts = investigationData.parts;
						data.instructions = investigationData.instructions;
						return data;						
					});
				}
				this.setState({investigations: investigations}, () => {
					resolve({status: 'ok', investigations: investigations});
				});

			}, (error) => {resolve({status: 'error', error: error});});
		});
	}



	/**
	 * Select page
	 * @param {object} event 
	 */
	selectInvestigation(event) {
		let selectedInvestigationIndex = parseInt(event.target.value);
		let selectedInvestigation = this.state.investigations[selectedInvestigationIndex];
		let promises = [];
		let instructions = {};
		selectedInvestigation.parts.forEach((part) => {
			if (selectedInvestigation.instructions[part].hasTeacherInstructions) {
				promises.push(getInvestigationInstructions(selectedInvestigation.id, 
					[{part: part, role: 'teacher', text: ''}]));
			}
			if (selectedInvestigation.instructions[part].hasGroupInstructions) {
				promises.push(getInvestigationInstructions(selectedInvestigation.id, 
					[{part: part, role: 'group', text: ''}]));
			}
		});

		Promise.all(promises).then((responses) => {
			let promiseIndex = 0;
			selectedInvestigation.parts.forEach((part) => {
				instructions[part] = {teacher: null, group: null};
				if (selectedInvestigation.instructions[part].hasTeacherInstructions) {
					if (responses[promiseIndex].status === 'ok') {
						instructions[part].teacher = responses[promiseIndex].instructions[0].text;
					}
					promiseIndex = promiseIndex + 1;
				}
				if (selectedInvestigation.instructions[part].hasGroupInstructions) {
					if (responses[promiseIndex].status === 'ok') {
						instructions[part].group = responses[promiseIndex].instructions[0].text;
					}
					promiseIndex = promiseIndex + 1;
				}				
			});

			this.setState({
				selectedInvestigationIndex: selectedInvestigationIndex,
				selectedInvestigation: selectedInvestigation,
				instructions: instructions
			});
		});	
	}
		
	/* Update investigation data */
	updateInvestigation(event, part) {
		let selectedInvestigation = JSON.parse(JSON.stringify(this.state.selectedInvestigation));

		if (event.target.name === 'title') selectedInvestigation[event.target.name] = event.target.value;
		if (event.target.name === 'placeholder') selectedInvestigation.placeholders[part] = event.target.value;
		this.setState({
			selectedInvestigation: selectedInvestigation,
			isEditingInvestigation: true
		});
	}

	/**
	 * Update instructions
	 * @param {object} event 
	 */
	updateInstructions(event, part) {
		let instructions = JSON.parse(JSON.stringify(this.state.instructions));
		instructions[part][event.target.name] = event.target.value;
		this.setState({
			instructions: instructions,
			isEditingInstructions: true,
			errorMsg: null
		});
	}



	/**
	 * Handle save investigation
	 */
	handleSaveInvestigation() {		
		if ((!this.state.isEditingInvestigation && !this.state.isEditingInstructions) || this.state.isSaving) return;
		
		this.setState({isSaving: true});

		let promises = [];
		let selectedInvestigation = JSON.parse(JSON.stringify(this.state.selectedInvestigation));
		
		/* Save investigation in DB */
		if (this.state.isEditingInvestigation) {
			promises.push(this.saveInvestigation(selectedInvestigation));
		}
		
		/* Save instructions in storage */
		if (this.state.isEditingInstructions) {
			let instructions = [];
			selectedInvestigation.parts.forEach((part) => {
				if (this.state.instructions[part] && this.state.instructions[part].teacher) {
					instructions.push({part: part, role: 'teacher', text: this.state.instructions[part].teacher});
				}
				if (this.state.instructions[part] && this.state.instructions[part].group) {
					instructions.push({part: part, role: 'group', text: this.state.instructions[part].group});
				}
			});
			promises.push(saveInvestigationInstructions(this.state.selectedInvestigation.id, instructions));
		}

		Promise.all(promises).then(
			() => {
				this.setState({
					isEditingInvestigation: false, 
					isEditingInstructions: false, 
					isSaving: false
				});
			}, (error) => {console.error(error);this.setState({isSaving: false, errorMsg: 'save error'});}
		);		
	}

	/* Save investigation (database) */
	saveInvestigation(selectedInvestigation) {
		let investigationData = {
			title: selectedInvestigation.title,
			placeholders: selectedInvestigation.placeholders
		};
		const db = firebase.firestore();
		return db.collection('investigations').doc(selectedInvestigation.id).update(investigationData);
	}


	/**
	 * Render component
	 */
	render() {
		return (
			<Investigations 
				isEditing={this.state.isEditingInvestigation || this.state.isEditingInstructions}
				isSaving={this.state.isSaving}
				investigations={this.state.investigations}
				selectedInvestigation={this.state.selectedInvestigation}
				instructions={this.state.instructions}
				errorMsg={this.state.errorMsg}
				selectInvestigation={this.selectInvestigation}
				updateInvestigation={this.updateInvestigation}
				updateInstructions={this.updateInstructions}
				handleSaveInvestigation={this.handleSaveInvestigation}
				goToPage={this.props.goToPage}
			/>
		);
	}
}

InvestigationsController.propTypes = {
	goToPage: PropTypes.func.isRequired
};

export default InvestigationsController;