import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './group-submissions.scss';

class GroupSubmissions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			groupIndex: this.props.groupIndex
		};
		this.goToGroup = this.goToGroup.bind(this);
	};

	/**
	 * Show group
	 * @param {number} groupIndex 
	 */
	goToGroup(groupIndex) {
		this.setState({groupIndex: groupIndex});
	}

	render() {
		let selectedGroup = this.props.selectedGroups[this.state.groupIndex];
		let title = selectedGroup.title;
		let	text = selectedGroup.text;
		

		let prevGroupIndex = this.state.groupIndex - 1;
		if (prevGroupIndex < 0) prevGroupIndex = this.props.selectedGroups.length - 1;

		let nextGroupIndex = this.state.groupIndex + 1;
		if (nextGroupIndex >= this.props.selectedGroups.length) nextGroupIndex = 0;

		return (
			<div className="GroupSubmissions">
				{this.props.selectedGroups.length > 1
					&& <div className="GroupSubmissions-prevBtn" onClick={() => {this.goToGroup(prevGroupIndex);}} />}
				{this.props.selectedGroups.length > 1
					&& <div className="GroupSubmissions-nextBtn" onClick={() => {this.goToGroup(nextGroupIndex);}} />}
				<div className="GroupSubmissions-group">
					<div className="GroupSubmissions-groupNumber">
						{this.state.groupIndex + 1}/{this.props.selectedGroups.length}</div>
					<div className="GroupSubmissions-groupText">
						<div><strong>{title}</strong></div>
						<div>{text}</div>
					</div>
				</div>
			</div>


		);
	}
}

GroupSubmissions.propTypes = {
	groupIndex: PropTypes.number.isRequired,
	selectedGroups: PropTypes.array.isRequired,
};

export default GroupSubmissions;

