import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Camera from './camera';
import {baseData} from 'data/base-data';
import {getStatusOfMission} from 'helpers/game-flow-helper';
import {checkIfFullscreen, toggleFullscreen} from 'helpers/fullscreen-helper';

class CameraController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			camIndex: null
		};
		this.showCamIndex = this.showCamIndex.bind(this);
		this.togglePhetSimulator = this.togglePhetSimulator.bind(this);
		this.openInNewTab = this.openInNewTab.bind(this);
	};

	/**
	 * Hide/show camera popup
	 */
	showCamIndex(camIndex) {
		this.setState({camIndex: camIndex});
	}

	/**
	 * Handle opening the wave interference game window
	 * simulatorType: '/wave-interference' or '/color-vision'
	 */
	togglePhetSimulator(simulatorType) {
		if (checkIfFullscreen()) {
			toggleFullscreen();
			setTimeout(() => {
				window.open(simulatorType, '_blank', 'width=1100,height=620');
			}, 1500);
		} else {
			window.open(simulatorType, '_blank', 'width=1100,height=620');
		}
	}

	openInNewTab(href) {
		Object.assign(document.createElement('a'), {
			target: '_blank',
			href: href,
		}).click();
	}

	/**
	 * Render instructions component
	 */
	render() {
		/* Get base element statuses (depends on game progress) */
		let elements = JSON.parse(JSON.stringify(baseData)).map((mission) => {
			return mission.elements.map((element) => {return {id: element, missionId: mission.id};});
		}).flat();
		elements.forEach((element) => {
			let story = this.props.stories.find((story) => {
				return story.storyIndex === Number(element.missionId.split('-')[1]);
			});
			let elementMission = story.missions.find((mission) => {
				return element.missionId === mission.id;
			});
			/* Get status of mission corresponding to the element */
			if (elementMission) {
				let elementMissionStatus = getStatusOfMission(this.props.game, elementMission);
				if (elementMissionStatus === 'closed') {
					element.status = 'repaired';
				}
			}
		});

		/* Default images */
		let cam1Img = require('../../assets/images/camera/cam-1.png');
		let cam1Style = {backgroundImage: 'url(' + cam1Img + ')'};
		let cam1Name = 'CAM 01';
		let cam1Title = 'Planet overview';
		let cam1Source = ' ';
		let cam1Status = 'Recording ...';
		let cam1OnClick = {
			func: this.showCamIndex,
			params: 0
		};
		let cam1Type = 'default';
		let cam1Overlay = {
			hasOverlay: true,
			class: 'red'
		};

		let cam2Img = require('../../assets/images/camera/cam-2.png');
		let cam2Style = {backgroundImage: 'url(' + cam2Img + ')'};
		let cam2Name = 'CAM 02';
		let cam2Title = 'Planet surface';
		let cam2Source = 'Sattelite 03';
		let cam2Status = 'Recording ...';
		let cam2OnClick = null;
		let cam2Type = 'default';
		let cam2Overlay = {
			hasOverlay: false,
			class: null
		};

		let cam3Img = require('../../assets/images/camera/map-after.jpg');
		let cam3Style = {
			backgroundImage: 'url(' + cam3Img + ')',
			backgroundSize: 'cover',
			backgroundPosition: 'center center'
		};
		let cam3Name = 'CAM 03';
		let cam3Title = 'Base overview';
		let cam3Source = 'Rover Discovery';
		let cam3Status = 'Recording ...';
		let cam3OnClick = {
			func: this.props.toggleBase,
			params: true
		};
		let cam3Type = 'base';
		let cam3Overlay = {
			hasOverlay: true,
			class: 'red'
		};

		// let cam3Img = require('../../assets/images/camera/cam-3.png');
		// let cam3Style = {backgroundImage: 'url(' + cam3Img + ')'};
		// let cam3Title = 'Desert area 27';
		// let cam3Source = 'Rover Curiosity';
		// let cam3Status = 'Recording ...';
		// let cam3OnClick = null;
		// let cam3Overlay = false;

		/* Custom images */
		if (this.props.page.storyIndex === 1 && this.props.page.missionIndex === 1) {
			cam1Img = require('../../assets/images/camera/solar-cells-before.png');
			cam1Style = {
				backgroundImage: 'url(' + cam1Img + ')',
				backgroundSize: 'cover',
				backgroundPosition: 'center center'
			};
			cam1Title = 'Solar cells';
			cam1Source = 'Rover Discovery';
		}

		/* Wave simulator and wave interference in Alarmsystemet I and II */
		if (this.props.page.storyIndex === 3 && this.props.page.missionIndex === 1) {
			cam1Img = require('../../assets/images/camera/wave-simulator.png');
			cam1Style = {
				backgroundImage: 'url(' + cam1Img + ')',
				backgroundSize: 'cover',
				backgroundPosition: 'center center'
			};
			cam1Name = 'Simulator I';
			cam1Title = '';
			cam1Source = '';
			cam1Status = '';
			cam1OnClick = {
				func: this.props.toggleWaveSimulator,
				params: true
			};
			cam1Type = 'waveSimulator';
			cam1Overlay = {
				hasOverlay: true,
				class: 'black'
			};

			cam2Img = require('../../assets/images/camera/phet-simulator.png');
			cam2Style = {
				backgroundImage: 'url(' + cam2Img + ')',
				backgroundSize: 'cover',
				backgroundPosition: 'center center'
			};
			cam2Name = 'Simulator II';
			cam2Title = '';
			cam2Source = '';
			cam2Status = '';
			cam2OnClick = {
				func: this.togglePhetSimulator,
				params: ['/wave-interference']
			};
			cam2Type = 'waveInterference';
			cam2Overlay = {
				hasOverlay: true,
				class: 'black'
			};
		}

		/* Wave interference in Nedslaget - Den gamle rover */
		if (this.props.page.storyIndex === 5 &&
			this.props.page.missionIndex === 1 &&
			this.props.page.sessionIndex === 0
		) {
			cam2Img = require('../../assets/images/camera/phet-simulator.png');
			cam2Style = {
				backgroundImage: 'url(' + cam2Img + ')',
				backgroundSize: 'cover',
				backgroundPosition: 'center center'
			};
			cam2Name = 'Simulator II';
			cam2Title = '';
			cam2Source = '';
			cam2Status = '';
			cam2OnClick = {
				func: this.togglePhetSimulator,
				params: ['/wave-interference']
			};
			cam2Type = 'waveInterference';
			cam2Overlay = {
				hasOverlay: true,
				class: 'black'
			};
		}

		/* Message from team Cheta in Nedslaget - Få roveren hjem */
		if (this.props.page.storyIndex === 5 &&
			this.props.page.missionIndex === 1 &&
			this.props.page.sessionIndex === 1
		) {
			cam2Img = require('../../assets/images/camera/pdf-thumbnail.png');
			cam2Style = {
				backgroundImage: 'url(' + cam2Img + ')',
				backgroundSize: 'cover',
				backgroundPosition: 'center center'
			};
			cam2Name = '';
			cam2Title = '';
			cam2Source = '';
			cam2Status = '';
			cam2OnClick = {
				func: this.openInNewTab,
				params: ('resources/faa-roveren-hjem.pdf')
			};
			cam2Type = 'rover-message';
			cam2Overlay = {
				hasOverlay: false,
				class: null
			};
		}

		/* Morse code image in Lys og information */
		if (this.props.page.storyIndex === 3 &&
			this.props.page.missionIndex === 0 &&
			this.props.page.sessionIndex === 2
		) {
			cam2Img = require('../../assets/images/camera/morse-code.svg');
			cam2Style = {
				backgroundImage: 'url(' + cam2Img + ')',
				backgroundSize: 'contain',
				backgroundPosition: 'top center',
				backgroundColor: '#011B1C'
			};
			cam2Name = 'Morse Code';
			cam2Title = '';
			cam2Source = '';
			cam2Status = '';
			cam2OnClick = {
				func: this.showCamIndex,
				params: 1
			};
			cam2Type = 'morseCode';
			cam2Overlay = {
				hasOverlay: true,
				class: 'black'
			};
		}

		/* Wave interference in Lyd og kommunikation */
		if (this.props.page.storyIndex === 3 &&
			this.props.page.missionIndex === 0 &&
			this.props.page.sessionIndex === 1
		) {
			cam2Img = require('../../assets/images/camera/phet-simulator.png');
			cam2Style = {
				backgroundImage: 'url(' + cam2Img + ')',
				backgroundSize: 'cover',
				backgroundPosition: 'center center'
			};
			cam2Name = 'Simulator II';
			cam2Title = '';
			cam2Source = '';
			cam2Status = '';
			cam2OnClick = {
				func: this.togglePhetSimulator,
				params: ['/wave-interference']
			};
			cam2Type = 'waveInterference';
			cam2Overlay = {
				hasOverlay: true,
				class: 'black'
			};
		}

		/* Sciendomus in Sciendomus I and II */
		if (this.props.page.storyIndex === 4 && this.props.page.missionIndex === 1) {
			cam2Img = require('../../assets/images/camera/sciendomus.png');
			cam2Style = {
				backgroundImage: 'url(' + cam2Img + ')',
				backgroundSize: 'cover',
				backgroundPosition: 'center center'
			};
			cam2Name = '';
			cam2Title = '';
			cam2Source = '';
			cam2Status = '';
			cam2OnClick = {
				func: this.openInNewTab,
				params: ('resources/sciendomus.pdf')
			};
			cam2Type = 'sciendomus';
			cam2Overlay = {
				hasOverlay: false,
				class: null
			};
		}

		/* Nedslaget > Meteoritten > Hvor landede den? */
		if (
			this.props.page.storyIndex === 5
			&& this.props.page.missionIndex === 0
			&& this.props.page.id === 'page-5-1-1-landingInvestigation'
		) {
			cam2Img = require('../../assets/images/camera/map-print.png');
			cam2Style = {
				backgroundImage: 'url(' + cam2Img + ')',
				backgroundSize: 'cover',
				backgroundPosition: 'center center'
			};
			cam2Name = '';
			cam2Title = '';
			cam2Source = '';
			cam2Status = '';
			cam2OnClick = {
				func: this.openInNewTab,
				params: ('resources/map-print.pdf')
			};
			cam2Type = 'meteoritten';
			cam2Overlay = {
				hasOverlay: false,
				class: null
			};
		}

		/* Den mærklige sten > Den mærklige sten > Den mærklig sten I */
		if (this.props.page.storyIndex === 7 &&
			this.props.page.missionIndex === 0 &&
			this.props.page.sessionIndex === 0
		) {
			cam2Img = require('../../assets/images/camera/pdf-thumbnail_2.png');
			cam2Style = {
				backgroundImage: 'url(' + cam2Img + ')',
				backgroundSize: 'cover',
				backgroundPosition: 'center center'
			};
			cam2Name = '';
			cam2Title = '';
			cam2Source = '';
			cam2Status = '';
			cam2OnClick = {
				func: this.openInNewTab,
				params: ('resources/LIPS-manual.pdf')
			};
			cam2Type = 'rover-message';
			cam2Overlay = {
				hasOverlay: false,
				class: null
			};
		}

		/* Et mysterie > Et mysterie > Rumdragter > Byg en svejsemaske */
		if (this.props.page.storyIndex === 8 &&
			this.props.page.missionIndex === 0 &&
			this.props.page.sessionIndex === 1 &&
			this.props.page.id === 'page-8-1-2-buildAMask'
		) {
			cam2Img = require('../../assets/images/camera/color-vision-thumbnail.png');
			cam2Style = {
				backgroundImage: 'url(' + cam2Img + ')',
				backgroundSize: 'cover',
				backgroundPosition: 'center center'
			};
			cam2Name = 'Simulator';
			cam2Title = '';
			cam2Source = '';
			cam2Status = '';
			cam2OnClick = {
				func: this.togglePhetSimulator,
				params: ['/color-vision']
			};
			cam2Type = 'waveInterference';
			cam2Overlay = {
				hasOverlay: true,
				class: 'black'
			};
		}

		/* Et mysterie > Genstart reaktoren > Drift af værket */
		if (
			this.props.page.storyIndex === 8
			&& this.props.page.missionIndex === 1
			&& this.props.page.id === 'page-8-2-3-cyoa'
		) {
			cam2Img = require('../../assets/images/camera/table.png');
			cam2Style = {
				backgroundImage: 'url(' + cam2Img + ')',
				backgroundSize: 'cover',
				backgroundPosition: 'center center'
			};
			cam2Name = '';
			cam2Title = '';
			cam2Source = '';
			cam2Status = '';
			cam2OnClick = {
				func: this.openInNewTab,
				params: ('resources/Marsbasen-Atomreaktor.pdf')
			};
			cam2Type = 'meteoritten';
			cam2Overlay = {
				hasOverlay: false,
				class: null
			};
		}

		/* Initialise all cameras */
		let camerasData = [{
			id: 'cam1',
			name: cam1Name,
			style: cam1Style,
			title: cam1Title,
			source: cam1Source,
			status: cam1Status,
			onClick: cam1OnClick,
			type: cam1Type,
			overlay: cam1Overlay,
		},
		{
			id: 'cam2',
			name: cam2Name,
			style: cam2Style,
			title: cam2Title,
			source: cam2Source,
			status: cam2Status,
			onClick: cam2OnClick,
			type: cam2Type,
			overlay: cam2Overlay,
		},
		{
			id: 'cam3',
			name: cam3Name,
			style: cam3Style,
			title: cam3Title,
			source: cam3Source,
			status: cam3Status,
			onClick: cam3OnClick,
			type: cam3Type,
			overlay: cam3Overlay,
		}];

		return (
			<Camera 
				camIndex={this.state.camIndex}
				camerasData={camerasData}
				showCamIndex={this.showCamIndex}
				toggleBase={this.props.toggleBase}
				toggleWaveSimulator={this.props.toggleWaveSimulator}
				elements={elements}
				page={this.props.page}
			/>
		);
	}
}

CameraController.propTypes = {
	game: PropTypes.object.isRequired,
	page: PropTypes.object.isRequired,
	toggleBase: PropTypes.func.isRequired,
	toggleWaveSimulator: PropTypes.func.isRequired,
	stories: PropTypes.array.isRequired
};

export default CameraController;
