import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

/**
 * Get contents of text file
 * @param {string} path 
 */
function getText(path) {
	return new Promise((resolve) => {
		let storageRef = firebase.storage().ref();
		storageRef.child(path).getDownloadURL()
			.then((url) => {
				let client = new XMLHttpRequest();
				client.open('GET', url);
				client.onreadystatechange = () => {
					if (client.readyState === 4) {
						if (client.status === 200) {
							resolve({status: 'ok', text: client.responseText});
						} else {
							resolve({status: 'error', text: null});
						}
					}
				};
				client.send();
			})
			.catch((error) => {
				resolve({status: 'error', error: error});
			});
	});
}

/**
 * Save chapter text
 * @param {string} id 
 * @param {string} text 
 */
function saveText(path, text) {
	return new Promise((resolve, reject) => {
		let storageRef = firebase.storage().ref();
		let pageChapterPageRef = storageRef.child(path);
		pageChapterPageRef.putString(text)
			.then(() => {
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export {
	getText,
	saveText
};