import React from 'react';
import PropTypes from 'prop-types';
import {baseUiTexts} from 'data/ui-texts';
import Button from 'components/button/button';
import './base-tag.scss';


const BaseTag = (props) => {
	let {
		isTeacher, 
		isEditing,
		isSaving,
		tag,
		expandTagId, 
		tagName, 
		expandTag, 
		editTagName, 
		saveTagName
	} = props;

	let groupIcon = null;
	if (tag.craterId) {
		groupIcon = require('../../assets/images/group-badges/' + tag.craterId + '.png');
	}

	return (
		<div 
			className={'BaseTag BaseTag--' + tag.id + 
				(tag.craterId ? ' BaseTag--tagged' : '') +
				(expandTagId === tag.id ? ' Base-tag--open' : '') +
				(tag.isCurrentMission ? ' BaseTag--currentMission' : '')
			}
		>
			{/* Group icon */}
			{tag.craterId && 
				<div className={'BaseTag-icon' + (tag.isPulsating ? ' BaseTag-icon--pulsating' : '')}
					onClick={() => {if (tag.groupId) expandTag(tag.id);}}
				>
					<img src={groupIcon} alt="" />
				</div>
			}
			{/* Tag name / input field  */}
			{tag.craterId && 
				<div className={'BaseTag-name' + (expandTagId === tag.id ? ' BaseTag-name--open' : '')}>
					{!isTeacher && <span>{(tag.name ? tag.name : '')}</span>}
					{isTeacher &&
						<input
							className="input--teacher"
							name="tagName"
							value={tagName !== null ? tagName : tag.name}
							placeholder="tag"
							onChange={(event) => {editTagName(event);}}
						/>
					}
				</div>
			}
			{/* Save button */}
			{(expandTagId === tag.id) &&
				<div className="BaseTag-saveBtn">
					<Button
						hidden={!isEditing}
						isLoading={isSaving}
						text={baseUiTexts.save}
						onClick={()=>{saveTagName();}}
					/>	
				</div>
			}			
		</div>
	);
};


BaseTag.propTypes = {
	isTeacher: PropTypes.bool.isRequired,
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	tag: PropTypes.object.isRequired,
	expandTagId: PropTypes.string,
	tagName: PropTypes.string,
	expandTag: PropTypes.func.isRequired,
	editTagName: PropTypes.func.isRequired,
	saveTagName: PropTypes.func.isRequired
};

export default BaseTag;
