import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {DndProvider} from 'react-dnd';
import TouchBackend from 'react-dnd-touch-backend';
import popupsData from 'data/popups-teacher-data';
import {evaluationData} from 'data/evaluation-data.js';
import Evaluation from './evaluation';

class EvaluationController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSavingEvaluation: false,
			isSavingParamXName: false,
			isSavingParamYName: false,
			isEditingEvaluation: false,
			isEditingParamXName: false,
			isEditingParamYName: false,
			paramXName: evaluationData.parameters.x,
			paramYName: evaluationData.parameters.y,
			groupIds: [],
			evaluationGrid: [],
			hoverGroupId: null
		};
		this.timeout = null;
	};

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Get custom evaluation parameter names */
		let game = this.props.game;
		let paramXName = this.state.paramXName;
		let paramYName = this.state.paramYName;

		if (game.evaluationParams && game.evaluationParams[this.props.sessionId]) {
			if ( game.evaluationParams[this.props.sessionId].paramXName) {
				paramXName =  game.evaluationParams[this.props.sessionId].paramXName;
			}
			if ( game.evaluationParams[this.props.sessionId].paramYName) {
				paramYName =  game.evaluationParams[this.props.sessionId].paramYName;
			}
		} else if (game.evaluationParams && game.evaluationParams.lastSaved) {
			if ( game.evaluationParams.lastSaved.paramXName) {
				paramXName =  game.evaluationParams.lastSaved.paramXName;
			}
			if ( game.evaluationParams.lastSaved.paramYName) {
				paramYName =  game.evaluationParams.lastSaved.paramYName;
			}
		}

		/* Get groups */
		let evaluationGrid = [];
		let index = 0;
		for (let y = evaluationData.maxVal; y >= evaluationData.minVal; y--) {
			for (let x = evaluationData.minVal; x <= evaluationData.maxVal; x++) {
				let square = {
					index: index,
					x: x,
					y: y,
					groupIds: []
				};
				evaluationGrid.push(square);
				index = index + 1;	
			}
		}
		let groupIds = [];
		this.props.groups.forEach((group) => {	
			if (
				group.evaluations && group.evaluations[this.props.sessionId] && 
				group.evaluations[this.props.sessionId].x && group.evaluations[this.props.sessionId].y
			) {
				let x = group.evaluations[this.props.sessionId].x;
				let y = group.evaluations[this.props.sessionId].y;
				evaluationGrid[(evaluationData.maxVal - y) * evaluationData.maxVal + x - 1].groupIds.push(group.id);
			} else {
				groupIds.push(group.id);
			}
		});
		this.setState({
			paramXName,
			paramYName,
			groupIds,
			evaluationGrid,
			lastSavedParamXName: paramXName,
			lastSavedParamYName: paramYName
		});
		this.props.editEvaluationCallback('handleInput', {paramXName});
		this.props.editEvaluationCallback('handleInput', {paramYName});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		clearTimeout(this.timeout);
	}

	/**
	 * Set id of hover group
	 * @param {string} groupId 
	 */
	setHoverGroupId = (groupId) => {
		this.setState({hoverGroupId: groupId});
	}

	/**
	 * Focus on parameter input field
	 * @param {string} id 
	 */
	focusOnInput = (id) => {
		document.getElementById(id).focus();
	}

	/**
	 * Unfocus (blur) on parameter input field
	 * @param {string} id 
	 */
	unfocusInput = (id) => {
		document.getElementById(id).blur();
	}

	/**
	 * Update input field with custom name of evaluation parameter
	 * @param {object} event 
	 */
	handleInput = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		this.setState({
			[name]: value,
		});
		this.props.editEvaluationCallback('handleInput', {[name]: value});
	}

	/**
	 * Show save button for criteria when user clicks
	 * @param {object} event 
	 */
	handleInputFocus = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		let lastSavedValue = this.state['lastSaved' + name.charAt(0).toUpperCase() + name.slice(1)];
		if (value === lastSavedValue) {
			this.setState({
				[name]: '',
				['isEditing' + name.charAt(0).toUpperCase() + name.slice(1)]: true,
				['lastSaved' + name.charAt(0).toUpperCase() + name.slice(1)]: value,
			});
			this.props.editEvaluationCallback('criteria', {name: name, isEditing: true});
		}
	}

	/**
	 * When user clicks out of the input, make it go to initial value if it wasn't saved
	 * @param {object} event 
	 */
	handleInputBlur = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		let lastSavedValue = this.state['lastSaved' + name.charAt(0).toUpperCase() + name.slice(1)];
		if (value === '') {
			this.setState({
				[name]: lastSavedValue,
				['isEditing' + name.charAt(0).toUpperCase() + name.slice(1)]: false
			});
			this.props.editEvaluationCallback('criteria', {name: name, isEditing: false});
		}
	}

	/**
	 * Teacher moves group either within grid or to grid
	 */
	handleGridChanges = (grid) => {
		this.setState({isEditingEvaluation: true});
		this.props.editEvaluationCallback('groups', {grid});
	}

	/**
	 * Save custom name of evaluation parameter
	 * @param {object} paramId 
	 */
	handleSaveParamName = (paramId) => {
		this.setState({['isSaving' + paramId.charAt(0).toUpperCase() + paramId.slice(1)]: true});

		this.props.saveParamName(this.state[paramId], paramId)
			.then((lastSaved) => {
				if (this.props.game.code) {
					this.unfocusInput(paramId);
					this.setState({
						['isSaving' + paramId.charAt(0).toUpperCase() + paramId.slice(1)]: false,
						['isEditing' + paramId.charAt(0).toUpperCase() + paramId.slice(1)]: false,
						['lastSaved' + paramId.charAt(0).toUpperCase() + paramId.slice(1)]: lastSaved
					});
				} else {
					this.timeout = setTimeout(() => {
						this.unfocusInput(paramId);
						this.setState({
							['isSaving' + paramId.charAt(0).toUpperCase() + paramId.slice(1)]: false,
							['isEditing' + paramId.charAt(0).toUpperCase() + paramId.slice(1)]: false
						});
					}, 300);
				}
			});
	}

	/**
	 * Save the evaluated groups
	 * @param {array} squares 
	 */
	handleSaveEvaluation = (squares) => {
		if (this.props.sessionStatus !== 'ready') {
			this.setState({isSavingEvaluation: true}, () => {
				this.props.saveEvaluation(squares).then(() => {
					this.setState({
						isSavingEvaluation: false,
						isEditingEvaluation: false
					});
				});
			});
		} else {
			let popupData = JSON.parse(JSON.stringify(popupsData.evaluationSessionReadyWarning));
			let btnConfig = [{
				text: popupData.buttonTexts.ok,
				type: 'button',
				action: this.props.closePopup,
				parameters: []
			}];
			this.props.openPopup(popupData, btnConfig);
		}

	}

	/**
	 * Render component
	 */
	render = () => {
		if (this.state.evaluationGrid.length === 0) {
			return null;
		}
		return (
			<DndProvider backend={TouchBackend} options={{enableMouseEvents: true}}>
				<Evaluation
					isSavingEvaluation={this.state.isSavingEvaluation}
					isSavingParamXName={this.state.isSavingParamXName}
					isSavingParamYName={this.state.isSavingParamYName}
					isEditingEvaluation={this.state.isEditingEvaluation}
					isEditingParamXName={this.state.isEditingParamXName}
					isEditingParamYName={this.state.isEditingParamYName}
					paramXName={this.state.paramXName}
					paramYName={this.state.paramYName}
					groups={this.props.groups}
					groupIds={this.state.groupIds}
					evaluationGrid={this.state.evaluationGrid}
					hoverGroupId={this.state.hoverGroupId}
					setHoverGroupId={this.setHoverGroupId}
					handleInput={this.handleInput}
					handleSaveParamName={this.handleSaveParamName}
					handleSaveEvaluation={this.handleSaveEvaluation}
					focusOnInput={this.focusOnInput}
					handleInputFocus={this.handleInputFocus}
					handleInputBlur={this.handleInputBlur}
					handleGridChanges={this.handleGridChanges}
					pageTitle={this.props.pageTitle}
				/>
			</DndProvider>
		);
	}
}

EvaluationController.propTypes = {
	sessionId: PropTypes.string.isRequired,
	sessionStatus: PropTypes.string.isRequired,
	game: PropTypes.object.isRequired,
	groups: PropTypes.array.isRequired,
	editEvaluationCallback: PropTypes.func.isRequired,
	saveParamName: PropTypes.func.isRequired,
	saveEvaluation: PropTypes.func.isRequired,
	pageTitle: PropTypes.string.isRequired,
	closeSession: PropTypes.func.isRequired,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired
};

export default EvaluationController;
