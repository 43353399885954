const errorTexts = {
	unknownError: 'Ukendt fejl.',

	/* Browser */
	browserOld: 'Din browser er forældet. Opdater venligst din browser, eller brug en af de nedestående:',
	browserUnknown: 'Din browser er ikke understøttet. Brug venligst en af de nedestående:',

	/* Create user (teacher) */
	emptyFields: 'Du skal udfylde alle felter.',
	emailExists: 'Der findes allerede en bruger med denne email.',
	invalidPassword: 'Dit password skal være på mindst 8 tegn.',
	noPasswordMatch: 'Password skal være ens i begge felter.',

	/* Login / reset password (teacher) */
	emailNotFound: 'Der findes ingen bruger med denne email.',
	invalidEmail: 'Din email er ikke gyldig.',
	invalidEmailPasswordFormat: 'Fejl. Check om din email og password er korrekte.',
	
	/* Login (group) */
	noGameCodeMatch: 'Ugyldig spilkode.',
	
	/* Create game */
	invalidCharacters: 'Spilnavnet indeholder et eller flere ugyldige tegn.',
	groupNameTooLong: 'Spilnavnet må maks være på 15 tegn.',
	noGroupName: 'Husk at udfylde et spilnavn.',
	notEnoughGroups: 'Du skal vælge mindst 2 lande.',
	
	/* Add guest teacher */
	noEmailMatch: 'Email skal være ens i begge felter.',
	invalidEmailChars: 'Email indeholder et eller flere ugyldige tegn.',
	ownEmail: 'Du kan ikke invitere dig selv.',
	emailAlreadyAdded: 'Du har allerede inviteret denne lærer.',
	maxTeachersReached: 'Du kan ikke invitere flere lærere.',
	
	popups: {
		authError: {
			closeBtn: false,
			title: 'Dit login er udløbet',
			text: '<p>Dit login er udløbet.</p><p>Log ind igen for at spille videre.</p>',
			buttonTexts: { ok: 'Ok'}
		},
		serverError: {
			closeBtn: true,
			title: 'Serverfejl',
			text: '<p>Der skete en fejl på serveren.</p><p>Check at du har forbindelse til internettet og prøv igen.</p>',
			buttonTexts: { ok: 'Ok', reload: 'Genindlæs side'}
		}
	}
};

export default errorTexts;