import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {cyoaGamesData} from 'data/cyoa-data';
import CyoaGamesIntro from './cyoa-games-intro';
import RoverController from './rover/rover-controller';
import AtomReactorController from './atom-reactor/atom-reactor-controller';

class CyoaGamesController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showCyoa: false,
			cyoaGame: null
		};
	}

	/**
	 * Start new game
	 */
	componentDidMount() {
		let cyoaGame = null;
		if (this.props.cyoaGame) cyoaGame = this.loadCyoaGameData(this.props.cyoaGame.id);
		this.setState({cyoaGame: cyoaGame});
	}

	/**
	 * Load cyoa data from local json file
	 */
	loadCyoaGameData = (cyoaGameId) => {
		let cyoaGameData = null;
		if (cyoaGamesData.some((cyoaGameData) => {return cyoaGameId === cyoaGameData.id;})) {
			cyoaGameData = JSON.parse(JSON.stringify(cyoaGamesData)).filter((cyoaGameData) => {
				return cyoaGameId === cyoaGameData.id;
			})[0];
			if (this.props.cyoaGame.title && this.props.cyoaGame.title.length > 0) {
				cyoaGameData.title = this.props.cyoaGame.title;
			}
			if (this.props.cyoaGame.entries && this.props.cyoaGame.entries.length > 0) {
				cyoaGameData.entries.forEach((entryData, index) => {
					entryData.entryText = this.props.cyoaGame.entries[index].entryText;
				});
			}
			if (this.props.cyoaGame.instructions) {
				cyoaGameData.instructions = this.props.cyoaGame.instructions;
			}
		}
		return cyoaGameData;
	}

	/**
	 * Hide/show cyoa game popup
	 */
	toggleCyoaGame = (showCyoa) => {
		this.setState({showCyoa});
	}

	/**
	 * Get minigame status
	 */
	getCyoaGameStatus = () => {
		let cyoaGameStatus = 'locked';
		if (
			this.props.sessionStatus === 'open' && 
			this.props.game && 
			this.props.game.id !== null && 
			this.props.cyoaGame && 
			this.props.game.cyoaGames.some((minigame) => {return minigame.id === this.props.cyoaGame.id;})
		) {
			cyoaGameStatus = this.props.game.cyoaGames.filter((minigame) => {
				return minigame.id === this.props.cyoaGame.id;
			})[0].status;
		}
		return cyoaGameStatus;
	}

	/**
	 * Open / close cyoa game (teacher only)
	 * @param {string} cyoaGameStatus 
	 */
	setCyoaGameStatus = (cyoaGameStatus) => {
		let cyoaGames = JSON.parse(JSON.stringify(this.props.game.cyoaGames));
		let cyoaGameIndex = cyoaGames.findIndex((cyoaGame) => {return cyoaGame.id === this.props.cyoaGame.id;});
		if (cyoaGameIndex < 0) {
			cyoaGames.push({id: this.props.cyoaGame.id, status: cyoaGameStatus});
		} else {
			cyoaGames[cyoaGameIndex].status = cyoaGameStatus;
		}
		this.props.updateGame({cyoaGames: cyoaGames});
	}

	render() {
		let cyoaGameStatus = this.getCyoaGameStatus();

		if (!this.state.showCyoa) {
			return (
				<CyoaGamesIntro
					setCyoaGameStatus={this.setCyoaGameStatus}
					page={this.props.page}
					toggleCyoaGame={(this.state.cyoaGame ? this.toggleCyoaGame : null)}
					isTeacher={this.props.isTeacher}
					cyoaGameStatus={cyoaGameStatus}
				/>
			);
		}

		if (this.state.cyoaGame.id === 'cyoaGame-1') {
			return (
				<RoverController
					toggleCyoaGame={this.toggleCyoaGame}
					isTeacher={this.props.isTeacher}
					cyoaGame={this.state.cyoaGame}
					cyoaGameStatus={cyoaGameStatus}
					updateGroup={this.props.updateGroup}
					updateGame={this.props.updateGame}
					group={this.props.group}
					game={this.props.game}
					openPopup={this.props.openPopup}
				/>
			);
		}

		if (this.state.cyoaGame.id === 'cyoaGame-2') {
			return (
				<AtomReactorController
					toggleCyoaGame={this.toggleCyoaGame}
					cyoaGame={this.state.cyoaGame}
					updateGroup={this.props.updateGroup}
					updateGame={this.props.updateGame}
					group={this.props.group}
					game={this.props.game}
					isTeacher={this.props.isTeacher}
				/>
			);
		}
	}
}

CyoaGamesController.propTypes = {
	page: PropTypes.object.isRequired,
	game: PropTypes.object.isRequired,
	sessionStatus: PropTypes.string.isRequired,
	updateGame: PropTypes.func,
	cyoaGame: PropTypes.object,
	isTeacher: PropTypes.bool.isRequired,
	updateGroup: PropTypes.func,
	group: PropTypes.object,
	openPopup: PropTypes.func.isRequired
};

export default CyoaGamesController;