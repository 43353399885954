import firebase from 'firebase/app';
import 'firebase/firestore';
import config from 'config/app.config';

/* Log environment */
let env = config.env;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase: test */
if (env === 'development' || env === 'test') {
	firebaseConfig = {
		apiKey: 'AIzaSyDtsck8jL9qhpVNy0H8FyYfja_HGCfqvUA',
		authDomain: 'cgl-marsbasen-test.firebaseapp.com',
		databaseURL: 'https://cgl-marsbasen-test.firebaseio.com',
		projectId: 'cgl-marsbasen-test',
		storageBucket: 'gs://cgl-marsbasen-test.appspot.com',
		messagingSenderId: '150911632429',
		appId: '1:150911632429:web:e7b30f83b564cbd6b564a4'
	};
}

/* Connect to firebase: demo */
if (env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyAkrojjDK84keTIGRwTzgTqRu_F-iaen7o',
		authDomain: 'cgl-marsbasen-demo.firebaseapp.com',
		databaseURL: 'https://cgl-marsbasen-demo.firebaseio.com',
		projectId: 'cgl-marsbasen-demo',
		storageBucket: 'cgl-marsbasen-demo.appspot.com',
		messagingSenderId: '710909062977',
		appId: '1:710909062977:web:50223349695fc666977bc0'
	};
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyAdPqrWk16Oaiqh6bzhpRmgM9Mcx9pGn3s',
		authDomain: 'cgl-marsbasen-production.firebaseapp.com',
		projectId: 'cgl-marsbasen-production',
		storageBucket: 'cgl-marsbasen-production.appspot.com',
		messagingSenderId: '599744248692',
		appId: '1:599744248692:web:d427d58c9927989d848a21',
		measurementId: 'G-3BK698L2SZ'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);


export default firebase.firestore().enablePersistence()
	.then(() => {
		console.log('Firestore persistence enabled!');
		return firebase.firestore();
	})
	.catch((error) => {
		if (error.code === 'failed-precondition') {
			console.error('Multiple tabs open, persistence can only be enabled in one tab at a time.');
		} else if (error.code === 'unimplemented') {
			console.error('The current browser does not support all of the features required to enable persistence');
		} else {
			console.error('Unknown persistence error: ', error);
		}
		return firebase.firestore();
	});