import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import Tips from 'components/admin/tips';
import './cyoa-games.scss';

const CyoaGames = ({
	goToPage,
	cyoaGames,
	selectCyoaGame,
	isSaving,
	isEditing,
	selectedCyoaGame,
	errorMsg,
	updateCyoaGame,
	teacherInstructions,
	groupInstructions,
	updateInstructions,
	handleSaveCyoaGame
}) => {
	let saveBtnClass = 'CyoaGamesAdmin-saveBtn ' + (isSaving ? ' saving' : '');
	if (isEditing) saveBtnClass += ' editing';
	if (!isEditing) saveBtnClass += ' disabled';

	return (
		<div className="CyoaGamesAdmin">
			{/* Back button and title */}
			<p className="CyoaGamesAdmin-backBtn" onClick={() => {goToPage(null);}}>&lArr; tilbage</p>
			<div className="CyoaGamesAdmin-title">Rediger CYOA spil</div>

			{/* Select cyoa game */}
			<form className="CyoaGamesAdmin-navForm" onChange={(event) => {selectCyoaGame(event.target.value);}}>
				<select>
					{cyoaGames.map((cyoaGame, index) => {
						return (
							<option key={index} value={index}>{cyoaGame.title}</option>
						);
					})}
				</select>
			</form>

			<div>
				<form className="CyoaGamesAdmin-form">
					{/* Save */}
					<div
						className={saveBtnClass}
						onClick={(event) => {handleSaveCyoaGame(event);}}>Gem</div>
					{errorMsg && <div>{errorMsg}</div>}

					{/* Edit title */}
					<div className="CyoaGamesAdmin-inputRow">
						<span className="CyoaGamesAdmin-label">Titel:</span>
						<input
							className="CyoaGamesAdmin-input"
							name="title"
							value={selectedCyoaGame ? selectedCyoaGame.title : ''}
							placeholder="titel"
							onChange={(event) => {updateCyoaGame(event);}}
						/>
					</div>

					{/* Edit teacher instructions */}
					{(selectedCyoaGame && selectedCyoaGame.hasTeacherInstructions) &&
							<div className="CyoaGamesAdmin-inputRow">
								<p className="CyoaGamesAdmin-label">Lærerinstruktioner:</p>
								<textarea 
									className="CyoaGamesAdmin-textarea"
									name="teacherInstructions"
									value={teacherInstructions ? teacherInstructions : ''}
									placeholder=""
									onChange={(event) => {updateInstructions(event, 'teacher');}}
								/>
								<div className="CyoaGamesAdmin-preview">
									{renderMarkdown((teacherInstructions ? teacherInstructions : ''))}
								</div>
							</div>
					}

					{/* Edit group instructions */}
					{(selectedCyoaGame && selectedCyoaGame.hasGroupInstructions) &&
							<div className="CyoaGamesAdmin-inputRow">
								<p className="CyoaGamesAdmin-label">Gruppeinstruktioner:</p>
								<textarea 
									className="CyoaGamesAdmin-textarea"
									name="groupInstructions"
									value={groupInstructions ? groupInstructions : ''}
									placeholder=""
									onChange={(event) => {updateInstructions(event, 'group');}}
								/>
								<div className="CyoaGamesAdmin-preview">
									{renderMarkdown((groupInstructions ? groupInstructions : ''))}
								</div>
							</div>
					}
				</form>
			</div>

			{/* Markdown tips */}
			<Tips />
		</div>
	);
};

CyoaGames.propTypes = {
	goToPage: PropTypes.func.isRequired,
	cyoaGames: PropTypes.array.isRequired,
	selectCyoaGame: PropTypes.func.isRequired,
	isSaving: PropTypes.bool.isRequired,
	isEditing: PropTypes.bool.isRequired,
	selectedCyoaGame: PropTypes.object,
	errorMsg: PropTypes.string,
	updateCyoaGame: PropTypes.func.isRequired,
	teacherInstructions: PropTypes.string,
	groupInstructions: PropTypes.string,
	updateInstructions: PropTypes.func.isRequired,
	handleSaveCyoaGame: PropTypes.func.isRequired
};


export default CyoaGames;
