import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connectionStatusUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import './connection-status.scss';

class ConnectionStatus extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isOnline: true,
		};
		this.handleOfflineEvent = this.handleOfflineEvent.bind(this);
		this.handleOnlineEvent = this.handleOnlineEvent.bind(this);
	};

	componentDidMount() {
		window.addEventListener('offline', this.handleOfflineEvent);
		window.addEventListener('online', this.handleOnlineEvent);
	}

	componentWillUnmount() {
		window.removeEventListener('offline', this.handleOfflineEvent);
		window.removeEventListener('online', this.handleOnlineEvent);
	}

	handleOfflineEvent() {
		this.setState({isOnline: false});
	}

	handleOnlineEvent() {
		this.setState({isOnline: true});
	}

	render() {			
		let connectionStatusOverlayClass = 'ConnectionStatus-overlay';
		if (!this.state.isOnline) connectionStatusOverlayClass += ' offline';
		return (
			<React.Fragment>
				<div className={connectionStatusOverlayClass}>
					<div className="ConnectionStatus-body">
						<div className="ConnectionStatus-title">
							{connectionStatusUiTexts.title}
						</div>
						{renderMarkdown(connectionStatusUiTexts.text)}
					</div>
				</div>
				{this.props.children}
			</React.Fragment>
		);
	}
}

ConnectionStatus.propTypes = {
	children: PropTypes.any
};

export default ConnectionStatus;