import React from 'react';
import PropTypes from 'prop-types';
import GroupBadge from 'components/group-badge/group-badge';
import {minigamesUiTexts} from 'data/ui-texts';
import './minigame-group-statuses.scss';

const MinigameGroupStatuses = ({minigame, groups, minigameStatus, sessionStatus}) => {
	if (!minigame || !minigame.parts) return null;

	return (
		<div className="MinigameGroupStatuses">
			<div className="MinigameGroupStatuses-header">
				<span>{minigamesUiTexts.result}</span>
				<span>{minigamesUiTexts.point}</span>
			</div>
			{(minigameStatus === 'ready' || (minigameStatus === 'locked' && sessionStatus === 'ready'))
				? <div className="MinigameGroupStatuses-text">
					{minigamesUiTexts.minigameNotStarted}
				</div>
				: <div className="MinigameGroupStatuses-groups">
					{groups.map((group, groupIndex) => {
						if (!group.isPlaying) return null;
						return (
							<div className="MinigameGroupStatuses-group" key={groupIndex}>
								<GroupBadge group={group} showName={false} page="minigame-result" />
								<div className="MinigameGroupStatuses-groupStatus">
									{[...Array(minigame.parts.length)].map((_, index) => {
										let partCompleted = false;
										if (
											group.minigames && group.minigames[minigame.id] &&
											group.minigames[minigame.id].parts &&
											group.minigames[minigame.id].parts.length > index &&
											group.minigames[minigame.id].parts[index] !== null
										) {
											partCompleted = group.minigames[minigame.id].parts[index].completed;
										}
										return (
											<div
												key={index}
												className={'MinigameGroupStatuses-part' +
													(partCompleted ? ' MinigameGroupStatuses-part--completed' : '')}
											/>
										);
									})}
								</div>
								<div className="MinigameGroupStatuses-groupPoints">
									{(group.minigames && group.minigames[minigame.id] &&
									group.minigames[minigame.id].parts)
										? group.minigames[minigame.id].parts.reduce((sum, part) => {
											return sum + (part ? part.reward : 0);
										}, 0)
										: <span>-</span>}
								</div>
							</div>
						);
					})}
				</div>
			}
		</div>
	);
};

MinigameGroupStatuses.propTypes = {
	minigame: PropTypes.object,
	groups: PropTypes.array.isRequired,
	minigameStatus: PropTypes.string.isRequired,
	sessionStatus: PropTypes.string.isRequired
};

export default MinigameGroupStatuses;
