import React from 'react';
import PropTypes from 'prop-types';
import OverviewStories from './overview-stories';
import OverviewStory from './overview-story';
import './overview.scss';

const Overview = ({isTeacher, storyIndex, game, stories, handleSelectStory, goToSession, handleOpenIntroPopup}) => {

	return (
		<div className="Overview">
			<div className="Overview-text" onClick={handleOpenIntroPopup}>
				Introduktion
			</div>
			<OverviewStories 
				isTeacher={isTeacher}
				storyIndex={storyIndex} 
				game={game}
				stories={stories} 
				handleSelectStory={handleSelectStory}
			/>
			<OverviewStory 
				isTeacher={isTeacher}
				storyIndex={storyIndex} 
				game={game}
				stories={stories} 
				goToSession={goToSession}
			/>
		</div>
	);
};


Overview.propTypes = {
	isTeacher: PropTypes.bool.isRequired,
	storyIndex: PropTypes.number.isRequired,
	game: PropTypes.object.isRequired,
	stories: PropTypes.array.isRequired,
	handleSelectStory: PropTypes.func.isRequired,
	goToSession: PropTypes.func.isRequired,
	handleOpenIntroPopup: PropTypes.func.isRequired
};

export default Overview;
