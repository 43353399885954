import React from 'react';
import PropTypes from 'prop-types';
import {useDrop} from 'react-dnd';

const PuzzleDnDContainer = ({area, areaIndex, isDroppable, handleDrop, children}) => {
	const [{isOver}, drop] = useDrop({
		accept: 'puzzlePiece',
		collect: (monitor) => {
			return {
				isOver: monitor.isOver(),
				canDrop: monitor.canDrop(),
			};
		},
		drop(item) {
			if (isDroppable) {
				handleDrop(item.piece, item.fromAreaIndex, areaIndex, item.piece.type, area ? area.pieceType : null);
			}
		}
	});

	let className = 'Puzzle-pieces';
	if (area) {
		className = 'Puzzle-area Puzzle-area--' + area.id +
		(area.placed ? ' Puzzle-area--placed' : '') +
		(isOver ? ' Puzzle-area--active' : '');
	}

	return <div ref={drop} className={className}>{children}</div>;
};

PuzzleDnDContainer.propTypes = {
	area: PropTypes.object,
	areaIndex: PropTypes.number,
	isDroppable: PropTypes.bool.isRequired,
	handleDrop: PropTypes.func.isRequired,
	children: PropTypes.any
};


export default PuzzleDnDContainer;