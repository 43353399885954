import React from 'react';
import PropTypes from 'prop-types';
import './tab.scss';

const Tab = ({title, children}) => {
	return (
		<div className="Tab">
			<div className="Tab-titleContainer">
				<div className="Tab-title">{title}</div>
			</div>
			<div className="Tab-content">{children}</div>
		</div>
	);
};


Tab.propTypes = {
	title: PropTypes.any.isRequired,
	children: PropTypes.any.isRequired
};

export default Tab;
