let environments = ['earth', 'mars', 'space'];
let wavelengthRange = {
	min: {
		sound: 68, // cm
		light: 370 // nm
	},
	max: {
		sound: 343, // cm
		light: 791 // nm
	}
};
let frequencyRange = {
	min: {
		sound: { // hz
			earth: 100,
			mars: 69
		},
		light: 379 // Thz
	},
	max: {
		sound: { // hz
			earth: 504,
			mars: 347
		},
		light: 810 // Thz
	}
};

let temperatureRange = {
	min: 140, // K
	max: 325 // K
}

let data = {
	initialLightFrequency: 555, // THz
	initialLightWavelength: 540, // nm
	initialAudioFrequency: 224, // Hz
	initialAudioWavelength: 153, // cm
	velocity: {
		light: 299792458, // m/s
		sound: {
			earth: 343, // m/s
			mars: 236, // m/s
			space: 0 // ms/s
		}
	},
	initialTemperature: {
		light: {
			earth: 214, // K
			mars: 214, // K
			space: 2.7 // K
		},
		sound: {
			earth: Math.pow((343 / 20.05), 2), // K
			mars: Math.pow((236 / 15.73), 2), // K
			space: 2.7 // K
		}
	}
};

export { environments, wavelengthRange, frequencyRange, temperatureRange, data };