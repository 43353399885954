
function printContent(textHtml, title, divClass) {
	let documentTitle = (title ? title : 'Print');
	let myWindow = window.open('', '', 'width=700, height=400');

	myWindow.document.write('<html><head><title>' + documentTitle + '</title>');
	myWindow.document.write('<link rel="stylesheet" href="/print.css" type="text/css"/>');
	myWindow.document.write('</head><body>');
	myWindow.document.write('<div class="' + divClass + '">' + textHtml + '</div>');
	myWindow.document.write('</body></html>');
	myWindow.document.close();
	
	myWindow.focus();

	myWindow.addEventListener('load', function() {
		myWindow.print();
	});
};

export default printContent;