import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import config from 'config/app.config';
import {popupsData} from 'data/popups-game-data';
import popupsDataTeacher from 'data/popups-teacher-data';
import {cratersData} from 'data/groups-data';
import {sortArrayByProperty} from 'helpers/array-helper';
import {verifyTextInput} from 'helpers/input-helper';
import {errorFeedbackHelper} from 'helpers/error-feedback-helper';
import errorTexts from 'data/error-texts';
import Settings from './settings';

class SettingsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			isAdmin: false
		};
		this.handleSelectGame = this.handleSelectGame.bind(this);
		this.showCreateGamePopup = this.showCreateGamePopup.bind(this);
		this.handleCreateGame = this.handleCreateGame.bind(this);
		this.showDeleteGamePopup = this.showDeleteGamePopup.bind(this);
		this.handleDeleteGame = this.handleDeleteGame.bind(this);
		this.showVideosPopup = this.showVideosPopup.bind(this);
		this.showResourcesPopup = this.showResourcesPopup.bind(this);
	};

	/**
	 * Select game
	 * @param {string} gameId 
	 */
	handleSelectGame(gameId, isGuestGame = false, activeStoryIndex = null) {
		this.props.selectGame(gameId, isGuestGame);

		/** Go to overview page and show introduction popup, if it's hasn't been seen already */
		this.props.showPage('overview' + (activeStoryIndex !== null ? ('-' + activeStoryIndex) : '')).then(() => {
			if (!this.props.games.find((game) => {return game.id === gameId;}).introPopupSeen) {
				setTimeout(() => {
					const popupData = JSON.parse(JSON.stringify(popupsData.introduction));
					let btnConfig = [{
						text: '',
						type: 'icon',
						action: this.props.updateGame,
						parameters: [{introPopupSeen: true}]
					}];
					this.props.openPopup(popupData, btnConfig, 'intro');
				}, 1500);
			}
		});
	}

	/**
	 * Show create game popup
	 */
	showCreateGamePopup() {
		const popupData = JSON.parse(JSON.stringify(popupsDataTeacher.createGame));
		let formSubmitBtn = {
			text: popupData.buttonTexts.submit,
			type: 'button',
			action: this.handleCreateGame,
			parameters: [],
			showLoadingIcon: true
		}; 
		popupData.form.push({
			type: 'submit',
			text: popupData.buttonTexts.submit,
			button: formSubmitBtn
		});
		this.props.openPopup(popupData, [], 'createGame');	
	}

	/**
	 * Create game
	 */
	handleCreateGame() {
		if (this.state.isLoading) {return;}

		this.setState({isLoading: true}, () => {
			let formData = this.props.popupFormData;
			let error = verifyTextInput(formData.gameName, 20);
			if (error) {
				let errorMsg = errorTexts[errorFeedbackHelper(error)];
				this.props.updatePopupState({errorMessage: errorMsg, isLoading: false});
				this.setState({isLoading: false});
			} else {
				this.props.createGame(formData.gameName, cratersData).then(() => {
					this.props.closePopup();
					this.setState({isLoading: false});
				});
			}
		});
	}	

	/**
	 * Show delete game popup
	 * @param {string} gameId 
	 */
	showDeleteGamePopup(gameId) {
		const popupData = popupsDataTeacher.deleteGame;
		let btnConfig = [];
		let okBtn = {
			text: popupData.buttonTexts.ok,
			type: 'button',
			action: this.handleDeleteGame,
			parameters: [gameId],
			showLoadingIcon: true
		}; 
		btnConfig.push(okBtn);
		this.props.openPopup(popupData, btnConfig, 'game');
	}

	/**
	 * Delete game
	 * @param {string} gameId 
	 */
	handleDeleteGame(gameId) {
		if (this.state.isLoading) {return;}

		this.setState({isLoading: true});
		this.props.deleteGame(gameId).then(() => {
			this.props.closePopup();
			this.setState({isLoading: false});
		});
	}

	/**
	 * Show popup with videos
	 */
	showVideosPopup() {
		let popupData = popupsDataTeacher.showVideos;
		popupData.videos = this.props.videos;
		this.props.openPopup(popupData, [], 'videos');
	}

	/**
	 * Show popup with resources
	 */
	showResourcesPopup() {
		const popupData = popupsDataTeacher.showResources;
		this.props.openPopup(popupData, [], 'resources');
	}

	/**
	 * Render settings component
	 */
	render() {
		let games = this.props.games;
		if (games.length > 1) games = sortArrayByProperty(this.props.games, 'created', 'DESC');

		return (
			<Settings
				isAdmin={this.props.isAdmin}
				games={games}
				stories={this.props.stories}
				handleSelectGame={this.handleSelectGame} 
				showCreateGamePopup={this.showCreateGamePopup}
				showDeleteGamePopup={this.showDeleteGamePopup}
				showVideosPopup={this.showVideosPopup}
				showResourcesPopup={this.showResourcesPopup}
			/>
		);
	}
}


SettingsController.propTypes = {
	isAdmin: PropTypes.bool.isRequired,
	games: PropTypes.array.isRequired,
	stories: PropTypes.array.isRequired,
	popupFormData: PropTypes.object.isRequired,
	videos: PropTypes.array.isRequired,
	showPage: PropTypes.func.isRequired,
	createGame: PropTypes.func.isRequired,
	deleteGame: PropTypes.func.isRequired,
	selectGame: PropTypes.func.isRequired,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	updatePopupState: PropTypes.func.isRequired,
	updateGame: PropTypes.func.isRequired
};

export default SettingsController;