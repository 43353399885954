import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {investigationsData} from 'data/investigations-data';
import popupsData from 'data/popups-teacher-data';
import InvestigationIntro from './investigation-intro';
import InvestigationTutorial from './investigation-tutorial';
import GroupView from './group-view';
import TeacherView from './teacher-view';

class InvestigationController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			showInvestigation: false,
			showTutorial: false,
			investigationIndex: -1,
			investigationData: null,
			warningText: null
		};
		this.loadInvestigationData = this.loadInvestigationData.bind(this);
		this.toggleInvestigation = this.toggleInvestigation.bind(this);
		this.toggleIsOpenForSubmissions = this.toggleIsOpenForSubmissions.bind(this);
		this.toggleGroup = this.toggleGroup.bind(this);
		this.goToInvestigationStep = this.goToInvestigationStep.bind(this);
		this.saveInvestigation = this.saveInvestigation.bind(this);
		this.submitInvestigation = this.submitInvestigation.bind(this);
		this.updateInvestigation = this.updateInvestigation.bind(this);
		this.getInvestigationIndex = this.getInvestigationIndex.bind(this);
		this.showNoGroupsSelectedPopup = this.showNoGroupsSelectedPopup.bind(this);
		this.toggleTutorial = this.toggleTutorial.bind(this);
	};

	/**
	 * Component mounted
	 */
	componentDidMount() {
		let investigationData = null;
		let investigationIndex = -1;
		if (this.props.investigation) {
			/* Get investigation data */
			investigationData = this.loadInvestigationData(this.props.investigation.id);

			/* Get index of investigation in game state */
			investigationIndex = this.props.game.investigations.findIndex((investigation) => {
				return investigation.id === this.props.investigation.id;
			});
		}
	
		/* Update state */
		this.setState({
			investigationIndex: investigationIndex, 
			investigationData: investigationData, 
			isLoading: false
		});				
	}

	/**
	 * Component will receive props
	 * @param {object} prevProps 
	 */
	componentDidUpdate(prevProps) {
		/* User navigated to new investigation */
		if (this.props.page.id !== prevProps.page.id) {
			this.setState({isLoading: true, investigationIndex: -1, investigationData: null}, () => {
				let investigationData = null;
				let investigationIndex = -1;		
				if (this.props.investigation) {
					/* Get investigation data */
					investigationData = this.loadInvestigationData(this.props.investigation.id);
		
					/* Get index of investigation in game state */
					investigationIndex = this.props.game.investigations.findIndex((investigation) => {
						return investigation.id === this.props.investigation.id;
					});
				}		

				/* Update state */		
				this.setState({
					investigationData: investigationData, 
					investigationIndex: investigationIndex, 
					isLoading: false
				});		
			});
		}
	}

	/**
	 * Load investigation data from local json file and merge with DB data
	 */
	loadInvestigationData(investigationId) {
		let investigationData = null;
		if (investigationsData.some((investigationData) => {return investigationId === investigationData.id;}) 
		) {
			investigationData = JSON.parse(JSON.stringify(investigationsData)).filter((investigationData) => {
				return investigationId === investigationData.id;
			})[0];
			if (this.props.investigation.title && this.props.investigation.title.length > 0) {
				investigationData.title = this.props.investigation.title;
			}
			if (this.props.investigation.placeholders) {
				investigationData.inputPlaceholders = this.props.investigation.placeholders;
			}
			if (this.props.investigation.instructions) {
				investigationData.instructions = this.props.investigation.instructions;
			}
		}
		return investigationData;
	}

	/**
	 * Hide/show investigation
	 */
	toggleInvestigation(showInvestigation) {
		this.setState({showInvestigation});
	}

	/**
	 * Save investigation (group only)
	 */
	saveInvestigation(title, text) {
		let groupInvestigations = (this.props.group.investigations 
			? JSON.parse(JSON.stringify(this.props.group.investigations))
			: {}
		);

		let investigationId = this.props.investigation.id;
		let stepIndex = this.props.game.investigations[this.state.investigationIndex].stepIndex;
		let investigationPart = this.state.investigationData.steps[stepIndex].part;
		if (!groupInvestigations[investigationId]) groupInvestigations[investigationId] = {};
		if (!groupInvestigations[investigationId][investigationPart]) {
			groupInvestigations[investigationId][investigationPart] = {};
		}
		groupInvestigations[investigationId][investigationPart].title = title;
		groupInvestigations[investigationId][investigationPart].text = text;
		groupInvestigations[investigationId][investigationPart].submitted = false;

		return this.props.updateGroup({investigations: groupInvestigations});
	}

	/**
	 * Submit investigation (group only)
	 */
	submitInvestigation() {
		let groupInvestigations = JSON.parse(JSON.stringify(this.props.group.investigations));
		let investigationId = this.props.investigation.id;
		let stepIndex = this.props.game.investigations[this.state.investigationIndex].stepIndex;
		let investigationPart = this.state.investigationData.steps[stepIndex].part;
		groupInvestigations[investigationId][investigationPart].submitted = true;
		return this.props.updateGroup({investigations: groupInvestigations});
	}


	/**
	 * Open / close for group submissions (teacher only)
	 * @param {bool} isOpenForSubmissions 
	 */
	toggleIsOpenForSubmissions(isOpenForSubmissions) {
		this.updateInvestigation({isOpenForSubmissions: isOpenForSubmissions, status: 'open'});
	}

	/**
	 * Select / deselect group (teacher only)
	 * @param {string} groupId 
	 */
	toggleGroup(groupId) {
		let currentStepIndex = this.props.game.investigations[this.state.investigationIndex].stepIndex;
		let currentPart = this.state.investigationData.steps[currentStepIndex].part;
		let selectedGroups = [];
		if (
			this.props.game.investigations[this.state.investigationIndex][currentPart] &&
			this.props.game.investigations[this.state.investigationIndex][currentPart].selectedGroups
		) {
			selectedGroups = 
				this.props.game.investigations[this.state.investigationIndex][currentPart].selectedGroups;
		}
	
		let groupIndex = selectedGroups.findIndex((group) => {return group.id === groupId;});		
		if (groupIndex >= 0) {
			selectedGroups.splice(groupIndex, 1);
		} else {
			let groupObj = {id: groupId, title: '', text: ''};
			selectedGroups.push(groupObj);
		}
		this.updateInvestigation({[currentPart]: {selectedGroups: selectedGroups}});
	}

	/**
	 * Go to investigation step (teacher only)
	 * @param {number} stepIndex 
	 */
	goToInvestigationStep(stepIndex) {
		let investigationId = this.props.investigation.id;
		let currentStepIndex = this.props.game.investigations[this.state.investigationIndex].stepIndex;
		let currentPart = this.state.investigationData.steps[currentStepIndex].part;
		let selectedGroups = [];
		if (
			this.props.game.investigations[this.state.investigationIndex][currentPart] &&
			this.props.game.investigations[this.state.investigationIndex][currentPart].selectedGroups
		) {
			selectedGroups = 
				this.props.game.investigations[this.state.investigationIndex][currentPart].selectedGroups;
		}

		if (selectedGroups.length === 0 && stepIndex === 1 && currentStepIndex === 0) {
			this.showNoGroupsSelectedPopup();
		} else {
			if (this.state.investigationData.steps[currentStepIndex].type === 'write') {
				selectedGroups.forEach((selectedGroup) => {
					let group = this.props.groups.filter((group) => {return group.id === selectedGroup.id;})[0];
					if (
						group.investigations && 
						group.investigations[investigationId] &&
						group.investigations[investigationId][currentPart]
					) {
						selectedGroup.title  = group.investigations[investigationId][currentPart].title;
						selectedGroup.text  = group.investigations[investigationId][currentPart].text;
					}	else {
						selectedGroup.title = '';
						selectedGroup.text = '';
					}
				});
			}
	
			this.updateInvestigation({
				stepIndex: stepIndex,
				isOpenForSubmissions: stepIndex === 2 ? true : false,
				[currentPart]: {selectedGroups: selectedGroups}
			});
		}
	}

	/**
	 * Update investigation
	 * @param {object} update 
	 */
	updateInvestigation(update) {
		let investigations = JSON.parse(JSON.stringify(this.props.game.investigations));
		investigations[this.state.investigationIndex] = Object.assign(
			{}, investigations[this.state.investigationIndex], update);
		this.props.updateGame({investigations: investigations});
	}

	getInvestigationIndex() {
		let investigationIndex = this.props.game.investigations.findIndex((investigation) => {
			return investigation.id === this.props.investigation.id;
		});
		return investigationIndex;
	}

	/**
	 * Open popup with info about no group submissions selected
	 */
	showNoGroupsSelectedPopup() {
		const popupData = popupsData.noGroupsSelectedPopup;
		let btnConfig = [];
		let okBtn = {
			text: popupData.buttonTexts.ok,
			type: 'button',
			action: this.props.closePopup,
			parameters: [],
		}; 
		btnConfig.push(okBtn);
		this.props.openPopup(popupData, btnConfig, 'editInvestigation');	
	}

	/**
	 * Hide/show investigation tutorial (teacher)
	 */
	toggleTutorial(showTutorial) {
		this.setState({showTutorial});
	}

	/**
	 * Get investigation status
	 */
	getInvestigationStatus = () => {
		let investigationStatus = 'locked';
		if (
			// this.props.isActiveSession && 
			this.props.sessionStatus === 'open' &&
			this.props.game && 
			this.props.game.id !== null && 
			this.props.investigation && 
			this.props.game.investigations.some((investigation) => {
				return investigation.id === this.props.investigation.id;
			})
		) {
			
			investigationStatus = this.props.game.investigations.filter((investigation) => {
				return investigation.id === this.props.investigation.id;
			})[0].status;
		}
		return investigationStatus;
	}

	/**
	 * Open / close access for groups (teacher only)
	 * @param {string} investigationStatus 
	 */
	setInvestigationStatus = (investigationStatus) => {
		let investigations = JSON.parse(JSON.stringify(this.props.game.investigations));
		let investigationIndex = investigations.findIndex((investigation) => {
			return investigation.id === this.props.investigation.id;
		});
		if (investigationIndex < 0) {
			investigations.push({id: this.props.investigation.id, status: investigationStatus});
		} else {
			investigations[investigationIndex].status = investigationStatus;
		}
		this.props.updateGame({investigations: investigations});
	}

	/**
	 * Render investigation component
	 */
	render() {
		let investigationIsReady = (
			!this.state.isLoading && 
			this.state.investigationData && 
			this.state.investigationIndex >= 0
		);

		if (!this.state.showInvestigation || !investigationIsReady) {
			return (
				<InvestigationIntro
					page={this.props.page}
					isTeacher={this.props.isTeacher}
					toggleInvestigation={(investigationIsReady ? this.toggleInvestigation : null)}
					setInvestigationStatus={this.setInvestigationStatus}
					investigationStatus={this.getInvestigationStatus()}
					investigationId={this.props.investigation.id}
					sessionStatus={this.props.sessionStatus}
				/>
			);
		}

		let investigationState = this.props.game.investigations[this.state.investigationIndex];
		let stepIndex = (investigationState.hasOwnProperty('stepIndex') ? investigationState.stepIndex : 0);
		let nextStepIndex = null;
		let prevStepIndex = null;
		if (this.state.investigationData.steps.length > (stepIndex + 1)) nextStepIndex = stepIndex + 1;
		if (stepIndex > 0) prevStepIndex = stepIndex - 1;
		let investigationStep = this.state.investigationData.steps[stepIndex];
		let selectedGroups = [];
		if (investigationState[investigationStep.part] && investigationState[investigationStep.part].selectedGroups) {
			selectedGroups = investigationState[investigationStep.part].selectedGroups;
		}
		let prevSelectedGroups = [];
		if (prevStepIndex !== null) {
			let prevPart = this.state.investigationData.steps[prevStepIndex].part;
			if (prevPart && investigationState[prevPart] && investigationState[prevPart].selectedGroups) {
				prevSelectedGroups = investigationState[prevPart].selectedGroups;
			}
		}
		let isOpenForSubmissions = (investigationState.hasOwnProperty('isOpenForSubmissions')
			? (investigationState.isOpenForSubmissions && investigationStep.type === 'write')
			: false);

		// let teacherInstructions = null;
		let groupInstructions = null;
		if (this.state.investigationData.instructions && 
			this.state.investigationData.instructions[investigationStep.part]
		) {
			groupInstructions = this.state.investigationData.instructions[investigationStep.part].group;
			// teacherInstructions = this.state.investigationData.instructions[investigationStep.part].teacher;
		}

		if (this.props.isTeacher) {
			return (
				<React.Fragment>
					<TeacherView
						isOpenForSubmissions={isOpenForSubmissions}
						page={this.props.page}
						nextStepIndex={nextStepIndex}
						prevStepIndex={prevStepIndex}
						investigationStep={investigationStep}
						investigationId={this.props.investigation.id}
						investigationTitle={this.props.investigation.title}
						// teacherInstructions={teacherInstructions}
						groups={this.props.groups}
						selectedGroups={selectedGroups}
						toggleInvestigation={this.toggleInvestigation}
						toggleIsOpenForSubmissions={this.toggleIsOpenForSubmissions}
						toggleGroup={this.toggleGroup}
						updateGroups={this.props.updateGroups}
						goToInvestigationStep={this.goToInvestigationStep}
						openPopup={this.props.openPopup}
						closePopup={this.props.closePopup}
						game={this.props.game}
						updateGame={this.props.updateGame}
						nrOfSteps={this.state.investigationData.steps.length}
						toggleTutorial={this.toggleTutorial}
					/>
					{this.state.showTutorial &&
						<InvestigationTutorial
							toggleTutorial={this.toggleTutorial}
							investigation={this.state.investigationData}
							openPopup={this.props.openPopup}
							closePopup={this.props.closePopup}
							showNoGroupsSelectedPopup={this.showNoGroupsSelectedPopup}
						/>}
				</React.Fragment>
			);
		}

		return (
			<GroupView 
				isOpenForSubmissions={isOpenForSubmissions}
				page={this.props.page}
				instructions={groupInstructions}
				investigationId={this.props.investigation.id}
				investigationStep={investigationStep}
				investigationClues={this.state.investigationData.clues[investigationStep.part]}
				inputPlaceholder={this.state.investigationData.inputPlaceholders[investigationStep.part]}
				group={this.props.group}
				game={this.props.game}
				selectedGroups={prevSelectedGroups}
				toggleInvestigation={this.toggleInvestigation}
				saveInvestigation={this.saveInvestigation}
				submitInvestigation={this.submitInvestigation}
				updateGroup={this.props.updateGroup}
				updateGame={this.props.updateGame}
			/>
		);
	}
}

InvestigationController.propTypes = {
	isTeacher: PropTypes.bool.isRequired,
	page: PropTypes.object.isRequired,
	game: PropTypes.object.isRequired,
	investigation: PropTypes.object,
	sessionStatus: PropTypes.string,
	groups: PropTypes.array,
	group: PropTypes.object,
	updateGame: PropTypes.func,
	updateGroup: PropTypes.func,
	updateGroups: PropTypes.func,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired
};

export default InvestigationController;