import React from 'react';
import PropTypes from 'prop-types';
import './logo.scss';

const Logo = ({gameSection, onClick}) => {
	let logoClass = 'Logo Logo--' + gameSection;
	if (onClick) logoClass = logoClass + ' Logo--link';
	if (onClick) return <div className={logoClass} onClick={onClick} />;
	return <div className={logoClass} />;
};

Logo.propTypes = {
	gameSection: PropTypes.string.isRequired,
	onClick: PropTypes.func
};

export default Logo;
