import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from 'data/ui-texts';
import Tab from 'components/layout/tab';
import Button from 'components/button/button';

const CreateUser = (props) => {
	const {
		isLoading,
		userCreated,
		email,
		password,
		passwordRepeat,
		feedback,
		handleInput,
		handleCreateUser,
		goToTeacherBox,
		invalidInputEmail,
		invalidInputPassword,
		invalidInputPasswordRepeat
	} = props;

	if (userCreated) {
		return (
			<Tab title={<div className="Login-title">{loginUiTexts.titleCreateUser}</div>} >
				<p>{loginUiTexts.createUserFeedback}</p>
				<Button 
					class="loginToggle"
					text={loginUiTexts.goToLoginBtn}
					onClick={()=>{goToTeacherBox('login');}}
				/>
			</Tab>
		);
	}
	
	return (
		<Tab title={<div className="Login-title">{loginUiTexts.titleCreateUser}</div>} >
			<form className="Login-form" onSubmit={handleCreateUser}>
				<input
					className={invalidInputEmail ? ' invalid' : ''}
					name="email"
					type="text"
					placeholder={loginUiTexts.placeholderEmail}
					autoComplete="section-createUser email"
					value={email ? email : ''}
					onChange={(event)=>{handleInput(event);}}
				/>
				<input
					className={invalidInputPassword ? ' invalid' : ''}
					name="password"
					type="password"
					placeholder={loginUiTexts.placeholderPassword}
					autoComplete="section-createUser password"
					value={password ? password : ''}
					onChange={(event)=>{handleInput(event);}}
				/>
				<input
					className={invalidInputPasswordRepeat ? ' invalid' : ''}
					name="passwordRepeat"
					type="password"
					placeholder={loginUiTexts.placeholderRepeatPassword}
					autoComplete="section-createUser repeatpassword"
					value={passwordRepeat ? passwordRepeat : ''}
					onChange={(event)=>{handleInput(event);}}
				/>
				<Button
					isLoading={isLoading}
					text={loginUiTexts.createUserBtn}
					onClick={(event) => {handleCreateUser(event);}}
				/>
				<p className="Login-errorMessage">{feedback}</p>
			</form>
			<Button
				class="loginToggle"
				text={loginUiTexts.goToLoginBtn}
				onClick={()=>{goToTeacherBox('login');}}
			/>
		</Tab>
	);	
};

CreateUser.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	userCreated: PropTypes.bool.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
	passwordRepeat: PropTypes.string,
	feedback: PropTypes.string,
	handleInput: PropTypes.func.isRequired,
	handleCreateUser: PropTypes.func.isRequired,
	goToTeacherBox: PropTypes.func.isRequired,
	invalidInputEmail: PropTypes.bool.isRequired,
	invalidInputPassword: PropTypes.bool.isRequired,
	invalidInputPasswordRepeat: PropTypes.bool.isRequired
};

export default CreateUser;