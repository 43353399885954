import React from 'react';

const ColorVisionSimulator = () => {
	return (
		<div className="WaveSimulator WaveSimulator--interference">
			<iframe
				title="PhetSimulator"
				style={{width: '90%', height: '90%', overflow: 'hidden'}}
				src="https://phet.colorado.edu/sims/html/color-vision/latest/color-vision_en.html"
				width="100"
				height="100"
				scrolling="no"
			>
				Iframes not supported
			</iframe>
		</div>
	);
};

export default ColorVisionSimulator;
