import React from 'react';
import PropTypes from 'prop-types';

const RoverChoice = ({choice, canPlay, selectChoice, choicesMade, toggleCyoaGame}) => {
	if ((choice.requiredPrevChoice !== undefined
			&& choice.requiredPrevChoice.includes(choicesMade[choicesMade.length - 1]))
		|| choice.requiredPrevChoice === undefined) {
		return <div
			key={choice.id}
			className={'Rover-choice Rover-choice--' + choice.id}
			onClick={() => {choice.endSimulation ? toggleCyoaGame(false) : canPlay && selectChoice(choice);}}
		>
			{choice.text}
		</div>;
	}
	return null;
};

RoverChoice.propTypes = {
	selectChoice: PropTypes.func.isRequired,
	choice: PropTypes.object.isRequired,
	canPlay: PropTypes.bool.isRequired,
	choicesMade: PropTypes.array.isRequired,
	toggleCyoaGame: PropTypes.func.isRequired
};

export default RoverChoice;
