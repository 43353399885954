import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NextStep from './next-step';

class NextStepController extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	};

	handleOpenNextSession = () => {
		// If first landing, close it and navigate to next page
		if (this.props.sessionData.id === 'session-0-1-1' &&
			this.props.sessionData.status !== 'closed'
		) {
			this.props.closeSession('session-0-1-1');
			this.props.showPage(this.props.nextSessionData.pageIds[0], true);
		} else {
			// Else, check if tagging popup should be shown
			let session = this.props.game.sessions.find((s) => { return s.id === this.props.sessionData.id; });
			if (this.props.sessionData.id !== 'session-0-1-1' &&
				this.props.sessionData.status === 'closed' &&
				this.props.sessionData.sessionIndex + 1 === this.props.sessionData.missionSessions.length &&
				!session.hasOwnProperty('winningGroupCanTag')
			) {
				this.props.showTaggingWarningPopup(this.props.nextSessionData.pageIds[0]);
			} else {
				// Else, show next page
				this.props.showPage(this.props.nextSessionData.pageIds[0], true);
			}
		}
	}

	/**
	 * Render component
	 */
	render() {
		let currentPageIndex = this.props.sessionData.pages.findIndex((page) => {
			return page.id === this.props.page.id;
		});

		/* Error, page not found */
		if (currentPageIndex < 0) return null;
		
		/* Last page of session */
		// Link to first page of next session
		if (currentPageIndex === (this.props.sessionData.pages.length - 1)) {
			if (this.props.nextSessionData && this.props.nextSessionData.pageIds.length > 0) {
				return (
					<NextStep
						type="next-session"
						nextStepData={{
							text: 'Gå til næste spilgang',
							action: this.handleOpenNextSession,
							parameters: []
						}}
					/>
				);
			}
			return null;
		}

		/* Link to next page */
		let nextPageId = this.props.sessionData.pages[currentPageIndex + 1].id;
		return (
			<NextStep
				type="game"
				nextStepData={{
					text: 'Næste skridt',
					action: this.props.showPage,
					parameters: [nextPageId, true]
				}}
			/>
		);
	}
}


NextStepController.propTypes = {
	page: PropTypes.object.isRequired,
	sessionData: PropTypes.object.isRequired,
	showPage: PropTypes.func.isRequired,
	nextSessionData: PropTypes.object.isRequired,
	closeSession: PropTypes.func.isRequired,
	game: PropTypes.object.isRequired,
	showTaggingWarningPopup: PropTypes.func.isRequired
};

export default NextStepController;
