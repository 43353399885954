import React from 'react';
import PropTypes from 'prop-types';
import Clues from './clues';
import GroupSubmissions from './group-submissions';
import WriteSubmission from './write-submission';
import InstructionsController from 'components/instructions/instructions-controller';
import './investigation.scss';
import './group-view.scss';

const GroupView = (props) => {
	let {
		isOpenForSubmissions,
		page,
		instructions,
		investigationId, 
		investigationStep,
		investigationClues,
		group,
		game,
		inputPlaceholder,
		selectedGroups,
		toggleInvestigation, 
		saveInvestigation,
		submitInvestigation,
		updateGroup,
		updateGame
	} = props;

	return (
		<div className={'Investigation Investigation--group Investigation--' + investigationId}>
			<div className="Investigation-close" onClick={() => {toggleInvestigation(false);}} />
			{instructions &&
				<InstructionsController
					isTeacher={false}
					page={{type: page.type, instructions: instructions, id: investigationId}}
					iconType="info"
					updateGroup={updateGroup}
					updateGame={updateGame}
					group={group}
					game={game}
					type='popup'
				/>}
			{(investigationStep.startingPoint === 'clues' && investigationClues) &&
				<Clues 
					investigationId={investigationId}
					clueIndex={0}
					investigationClues={investigationClues}
				/>}

			{(investigationStep.startingPoint === 'selectedGroups' && selectedGroups.length > 0) &&
				<GroupSubmissions
					groupIndex={0}
					selectedGroups = {selectedGroups}
				/>}
			
			<WriteSubmission 
				isOpenForSubmissions={isOpenForSubmissions && investigationStep.type === 'write'} 
				investigationId={investigationId}
				investigationPart={investigationStep.part}
				group={group} 
				inputPlaceholder={inputPlaceholder}
				saveInvestigation={saveInvestigation}
				submitInvestigation={submitInvestigation}
			/>
		</div>
	);
};


GroupView.propTypes = {
	isOpenForSubmissions: PropTypes.bool.isRequired,
	page: PropTypes.object.isRequired,
	instructions: PropTypes.string,
	investigationId: PropTypes.string.isRequired,
	investigationStep: PropTypes.object.isRequired,
	investigationClues: PropTypes.array,
	group: PropTypes.object.isRequired,
	game: PropTypes.object.isRequired,
	inputPlaceholder: PropTypes.string,
	selectedGroups: PropTypes.array.isRequired,
	toggleInvestigation: PropTypes.func.isRequired,
	saveInvestigation: PropTypes.func.isRequired,
	submitInvestigation: PropTypes.func.isRequired,
	updateGroup: PropTypes.func,
	updateGame: PropTypes.func
};

export default GroupView;
