import {teacherPagesData} from 'data/content-data';
import {baseData} from 'data/base-data';
import {sortArrayByProperty, shuffleArray} from 'helpers/array-helper';
import {evaluationData} from 'data/evaluation-data';

/**
 * Get session menu
 * @param {bool} isTeacher 
 * @param {object} page
 * @param {object} sessionData
 * @param {object} game
 */
function getSessionMenu(isTeacher, page, sessionData, game, groups) {
	let menu = null;

	if (sessionData.pages.length > 0) {
		menu = [];
		sessionData.pages.forEach((pageData, pageIndex) => {
			let teacherOnly = pageIsTeacherOnly(pageData.type, pageData.id);
			if (isTeacher || !teacherOnly) { 
				let pageStatus = 'ready';
				if (sessionData.status === 'closed') {pageStatus = 'open';}
				if (sessionData.status === 'open') {
					if (pageIndex === 0) {
						/* Intro page */
						pageStatus = 'open';
					} else {
						/* Group login statuses page */
						if (pageData.type === 'groupLogin') {
							if (groups.some((group) => {return group.isPlaying === true;})) pageStatus = 'open';
						}

						/* Minigame page */
						if (pageData.type === 'minigame' && pageData.minigameId) {
							let minigameIndex = game.minigames.findIndex((minigame) => {
								return minigame.id === pageData.minigameId;
							});
							if (minigameIndex >= 0 && game.minigames[minigameIndex].status !== 'ready') {
								pageStatus = 'open';
							}
						}

						/* Investigation page */
						if (pageData.type === 'investigation' && pageData.investigationId) {
							let investigationIndex = game.investigations.findIndex((investigation) => {
								return investigation.id === pageData.investigationId;
							});
							if (investigationIndex >= 0 && game.investigations[investigationIndex].status !== 'ready') {
								pageStatus = 'open';
							}
						}

						/* CYOA page */
						if (pageData.type === 'cyoaGame' && pageData.cyoaGameId) {
							let cyoaGameIndex = game.cyoaGames.findIndex((cyoaGame) => {
								return cyoaGame.id === pageData.cyoaGameId;
							});
							if (cyoaGameIndex >= 0 && game.cyoaGames[cyoaGameIndex].status !== 'ready') {
								pageStatus = 'open';
							}
						}

						/* Wave simulator page */
						if (pageData.type === 'waveSimulator' && pageData.waveSimulatorId) {
							let waveSimulatorIndex = game.waveSimulators.findIndex((waveSimulator) => {
								return waveSimulator.id === pageData.waveSimulatorId;
							});
							if (waveSimulatorIndex >= 0 && game.waveSimulators[waveSimulatorIndex].status !== 'ready') {
								pageStatus = 'open';
							}
						}

						/* Evaluation page */
						if (pageData.type === 'evaluation') {
							let oneOrMoreGroupsEvaluated = false;
							groups.forEach((group) => {
								if (!oneOrMoreGroupsEvaluated) {
									if (group.evaluations.hasOwnProperty(sessionData.id)) {
										oneOrMoreGroupsEvaluated = true;
									}
								}
							});
							if (oneOrMoreGroupsEvaluated) pageStatus = 'open';
						}
					}

					/* Text page (NB! Place this after all other conditions!) */
					if ((pageData.type === 'text' || pageData.type === 'presentation') && pageIndex > 0) {
						if (
							game.instructions &&
							game.instructions.hasOwnProperty(pageData.id) &&
							game.instructions[pageData.id] === true
						) {
							if (menu[menu.length - 1].status === 'open') pageStatus = 'open';	
						}
					}
						
				}
				let menuItem = {
					pageId: pageData.id,					
					title: (pageData.menuTitle ? pageData.menuTitle : pageData.title),
					selected: (page.id === pageData.id),
					status: pageStatus
				};
				menu.push(menuItem);
			}
		});
	}
	return menu;	
}

function getStatusOfSession(sessionId, game) {
	let sessionStatus = 'ready';
	if (game.sessions.some((gameSession) => {return gameSession.id === sessionId;})) {
		sessionStatus = game.sessions.filter((gameSession) => {return gameSession.id === sessionId;})[0].status;
	}
	// Temporary fix for old games
	if (sessionStatus === 'active') sessionStatus = 'open';
	return sessionStatus;
}

/**
 * Get status of story
 * @param {object} story 
 */
function getStatusOfStory(game, story) {
	let storyStatus = 'ready';
	let missionStatuses = [];
	story.missions.forEach((mission) => {missionStatuses.push(getStatusOfMission(game, mission));});
	if (missionStatuses.length > 0) {
		if (missionStatuses.indexOf('open') >= 0 || missionStatuses.indexOf('closed') >= 0) {storyStatus = 'open';}
		if (game.activeSessionId && (game.activeSessionId.split('-')[1] === story.id.split('-')[1])) {
			storyStatus = 'active';
		}
		if (missionStatuses.filter((status) => {return status === 'closed';}).length === missionStatuses.length) {
			storyStatus = 'closed';
		}
	}
	return storyStatus;
}


/**
 * Get status of tier
 * @param {object} tier
 */
function getStatusOfTier(game, tier, stories) {
	let tierStatus = 'ready';
	let storyStatuses = [];
	tier.storyIds.forEach((storyId) => {
		let story = stories.filter((story) => {return story.id === storyId;})[0];
		storyStatuses.push(getStatusOfStory(game, story));
	});
	if (storyStatuses.length > 0) {
		if (storyStatuses.filter((status) => {return status === 'closed';}).length === storyStatuses.length) {
			tierStatus = 'closed';
		}
		if (storyStatuses.indexOf('open') >= 0) {tierStatus = 'open';}
	}
	return tierStatus;
}

function getStatusOfMission(game, mission) {
	/* Mission contains a session flagged as active */
	// if (
	// 	game.activeSessionId && game.activeSessionId !== null &&
	// 	mission.sessions.some((session) => {return session.id === game.activeSessionId;})
	// ) return 'active';

	let missionStatus = 'ready';
	let sessionStatuses = [];
	mission.sessions.forEach((session) => {
		if (game.sessions.some((gameSession) => {return gameSession.id === session.id;})) {
			let sessionData = game.sessions.filter((gameSession) => {
				return gameSession.id === session.id;
			})[0];
			if (sessionData.hasOwnProperty('status')) sessionStatuses.push(sessionData.status);
		}
	});

	if (sessionStatuses.length > 0) {
		if (sessionStatuses.filter((status) => {return status === 'closed';}).length === sessionStatuses.length) {
			missionStatus = 'closed';
		} else {
			if (sessionStatuses.indexOf('open') >= 0
			|| sessionStatuses.filter((status) => {return status === 'closed';}).length > 0) {
				missionStatus = 'open';
			}
		}
		// if (sessionStatuses.indexOf('active') >= 0) {missionStatus = 'active';} 
	}
	return missionStatus;
}

function getNextSession(sessionData, stories, game) {
	let nextStoryIndex = null;
	let nextMissionIndex = null;
	let nextSessionIndex = null;
	if (stories[sessionData.storyIndex].missions[sessionData.missionIndex].sessions.length > 
		sessionData.sessionIndex + 1
	) {
		/* Next session, same mission, same story */
		nextStoryIndex = sessionData.storyIndex;
		nextMissionIndex = sessionData.missionIndex;
		nextSessionIndex = sessionData.sessionIndex + 1;
	} else if (stories[sessionData.storyIndex].missions.length > sessionData.missionIndex + 1) {
		/* First session of next mission, same story */
		nextStoryIndex = sessionData.storyIndex;
		nextMissionIndex = sessionData.missionIndex + 1;
		nextSessionIndex = 0;
	} else if (stories.length > sessionData.storyIndex + 1) {
		/* First session of first mission of next story */
		nextStoryIndex = sessionData.storyIndex + 1;
		nextMissionIndex = 0;
		nextSessionIndex = 0;
	}

	let nextSession = null;
	if (nextStoryIndex !== null && nextMissionIndex !== null && nextSessionIndex !== null) {
		nextSession = stories[nextStoryIndex].missions[nextMissionIndex].sessions[nextSessionIndex];
		let sessionStatus = 'ready';
		if (game.sessions.some((gameSession) => {
			return gameSession.id === nextSession.id;
		})) {
			sessionStatus = game.sessions.filter((gameSession) => {
				return gameSession.id === nextSession.id;
			})[0].status;
		}
		nextSession.status = sessionStatus;	
	}

	return nextSession;
}


function pageIsTeacherOnly(pageType, pageId) {
	let teacherOnly = (teacherPagesData.indexOf(pageType) >= 0 && pageId !== 'page-3-2-1-presentation');
	return teacherOnly;
}

function getBasePopupData(storyIndex, missionIndex, stories, game) {
	let popupData = {};
	let story = stories.filter((story) => {return story.storyIndex === storyIndex;})[0];
	let mission = story.missions.filter((mission) => {
		return mission.missionIndex === missionIndex;
	})[0];
	popupData.missionTitle = mission.title;
	popupData.baseLocation = baseData.filter((mission) => {
		return mission.id === 'mission-' + storyIndex + '-' + (missionIndex + 1);
	})[0].location;
	popupData.missionGoal = mission.goal;
	popupData.missionStatus = getStatusOfMission(game, mission);
	return popupData;
}


function getRankedGroups(groups, sessionData, game) {
	/* Get game session status */
	let sessionStatus = null;
	if (game.sessions && 
		game.sessions.some((session) => {return session.id === sessionData.id;})
	) {
		sessionStatus = game.sessions.filter((session) => {
			return session.id === sessionData.id;
		})[0].status;
	}
	/* Calculate points of groups */
	let activeGroups = groups.filter((group) => {return group.isPlaying === true;});
	let rankedGroups = [];
	let sessions = [];
	if (activeGroups.length > 0 && sessionStatus !== 'ready') {
		activeGroups.forEach((group) => {
			rankedGroups.push({id: group.id, craterId: group.craterId, points: {}, totalPoints: 0});
		});
		/* Loop over mission sessions up to and including this session */
		sessionData.missionSessions.forEach((session, sessionIndex) => {
			if (sessionIndex <=	sessionData.sessionIndex) {
				sessions.push({id: session.id, title: session.title});
				let status = null;
				if (game.sessions.some((gameSession) => {return session.id === gameSession.id;})) {
					status = game.sessions.filter((gameSession) => {
						return session.id === gameSession.id;
					})[0].status;
				}
				if (status !== 'ready') {
				/* Loop over active groups */
					activeGroups.forEach((group, groupIndex) => {
						let points = 0;
						session.pages.forEach((page) => {
						/* Points from minigames */
							if (page.type === 'minigame' && page.minigameId) {
								if (group.minigames && group.minigames[page.minigameId] && 
								group.minigames[page.minigameId].parts
								) {
									points = group.minigames[page.minigameId].parts.reduce((sum, part) => {
										return sum + (part ? part.reward : 0);
									}, 0);
								}
							}
							/* Points from evaluation */
							if (page.type === 'evaluation') {
								if (group.evaluations && group.evaluations[session.id]) {
									points = points + 
									evaluationData[group.evaluations[session.id].x] + 
									evaluationData[group.evaluations[session.id].y];
								}
							}
						});
						rankedGroups[groupIndex].points[session.id] = points;
						rankedGroups[groupIndex].totalPoints = rankedGroups[groupIndex].totalPoints + points;
					});
				}
			}
		});
		
		/* Ties: groups are placed randomly */
		rankedGroups = shuffleArray(rankedGroups);
		/* Sort by points */
		rankedGroups = sortArrayByProperty(rankedGroups, 'totalPoints', 'DESC');
	}
	return rankedGroups;
}


export {
	getStatusOfSession,
	getStatusOfMission,
	getStatusOfStory,
	getStatusOfTier,
	getSessionMenu,
	getNextSession,
	pageIsTeacherOnly,
	getBasePopupData,
	getRankedGroups
};