import React from 'react';
import PropTypes from 'prop-types';
import './backup.scss';

const Backup = (props) => {	
	let {
		db,
		goToPage,
		isDownloading,
		isUploading,
		downloadDBData,
		downloadInstructions,
		downloadInvestigations,
		downloadPages,
		selectFileToUpload,
		uploadFileToDatabase,
		feedbackMsg
	} = props;
	return (
		<div className="BackupAdmin">
			<p 
				className="BackupAdmin-backBtn" 
				onClick={() => {goToPage(null);}}
			>&lArr; tilbage</p>

			<div className="BackupAdmin-section">
				<p>You are using the <b>{db}</b>-database.</p>
				<p>To upload to/download from another database, please navigate to that project's admin panel.</p>
			</div>

			<div className="BackupAdmin-section">
				<div className="BackupAdmin-title">Download data from database</div>
				<p
					className={'BackupAdmin-action' + (isDownloading === 'storiesData' ? ' loading' : '')} 
					onClick={() => {downloadDBData('stories');}}
				>Download stories data (file: 'storiesData_db-name.json')</p>
				<p
					className={'BackupAdmin-action' + (isDownloading === 'missionsData' ? ' loading' : '')} 
					onClick={() => {downloadDBData('missions');}}
				>Download missions data (file: 'missionsData_db-name.json')</p>
				<p
					className={'BackupAdmin-action' + (isDownloading === 'sessionsData' ? ' loading' : '')} 
					onClick={() => {downloadDBData('sessions');}}
				>Download sessions data (file: 'sessionsData_db-name.json')</p>
				<p
					className={'BackupAdmin-action' + (isDownloading === 'pagesData' ? ' loading' : '')} 
					onClick={() => {downloadDBData('pages');}}
				>Download pages data (file: 'pagesData_db-name.json')</p>				
				<p
					className={'BackupAdmin-action' + (isDownloading === 'minigamesData' ? ' loading' : '')} 
					onClick={() => {downloadDBData('minigames');}}
				>Download minigames data (file: 'minigamesData_db-name.json')</p>
				<p
					className={'BackupAdmin-action' + (isDownloading === 'investigationsData' ? ' loading' : '')} 
					onClick={() => {downloadDBData('investigations');}}
				>Download investigations data (file: 'investigationsData_db-name.json')</p>
				<p
					className={'BackupAdmin-action' + (isDownloading === 'cyoaGamesData' ? ' loading' : '')} 
					onClick={() => {downloadDBData('cyoaGames');}}
				>Download CYOA games data (file: 'cyoaGamesData_db-name.json')</p>
				<p
					className={'BackupAdmin-action' + (isDownloading === 'waveSimulatorsData' ? ' loading' : '')} 
					onClick={() => {downloadDBData('waveSimulators');}}
				>Download wave simulators data (file: 'waveSimulatorsData_db-name.json')</p>
				<p
					className={'BackupAdmin-action' + (isDownloading === 'videosData' ? ' loading' : '')} 
					onClick={() => {downloadDBData('videos');}}
				>Download videos data (file: 'videos_db-name.json')</p>
				<p>&nbsp;</p>
			</div>

			<div className="BackupAdmin-section">
				<div className="BackupAdmin-title">Download files from storage</div>
				<p 
					className={'BackupAdmin-action' + (isDownloading === 'teacherInstructionsFiles' ? ' loading' : '')} 
					onClick={() => {downloadInstructions('teacher');}}
				>Download teacher instructions files (file: 'teacherInstructions.zip')</p>
				<p 
					className={'BackupAdmin-action' + (isDownloading === 'groupInstructionsFiles' ? ' loading' : '')} 
					onClick={() => {downloadInstructions('group');}}
				>Download group instructions files (file: 'groupInstructions.zip')</p>
				<p 
					className={'BackupAdmin-action' + (isDownloading === 'pageFiles' ? ' loading' : '')} 
					onClick={() => {downloadPages();}}
				>Download pages files (file: 'pages.zip')</p>
				<p 
					className={'BackupAdmin-action' + (isDownloading === 'investigationFiles' ? ' loading' : '')} 
					onClick={() => {downloadInvestigations();}}
				>Download investigation instructions files (file: 'investigations.zip')</p>
				<p>&nbsp;</p>
			</div>

			<div className="BackupAdmin-section">
				<div className="BackupAdmin-title">Upload</div>
				<form>
					<input
						type="file"
						accept="application/json"
						name="json"
						className="BackupAdmin-selectFile"
						onChange={(event)=>{selectFileToUpload(event);}}
					/>
					<div
						className={'BackupAdmin-uploadFile' + (isUploading ? ' loading' : '')}
						onClick={()=>{uploadFileToDatabase();}}
					>Upload file</div>
				</form>
			</div>
			
			<p className="BackupAdmin-error">{feedbackMsg}</p>
		</div>
	);
};

Backup.propTypes = {
	db: PropTypes.string.isRequired,
	goToPage: PropTypes.func.isRequired,
	isDownloading: PropTypes.string,
	isUploading: PropTypes.bool.isRequired,
	downloadDBData: PropTypes.func.isRequired,
	downloadInstructions: PropTypes.func.isRequired,
	downloadInvestigations: PropTypes.func.isRequired,
	downloadPages: PropTypes.func.isRequired,
	selectFileToUpload: PropTypes.func.isRequired,
	uploadFileToDatabase: PropTypes.func.isRequired,
	feedbackMsg: PropTypes.string
};

export default Backup;