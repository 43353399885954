import React, { Component } from 'react';
import PropTypes from 'prop-types';
import popupsData from 'data/popups-teacher-data';
import Button from 'components/button/button';
import GroupBadge from 'components/group-badge/group-badge';
import NextStep from 'components/next-step/next-step';
import {investigationUiTexts as uiTexts} from 'data/ui-texts';
import {addParagraph} from 'helpers/text-helper';
import './manage-submissions.scss';

class ManageSubmissions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isSavingGroupId: null,
			groups: []
		};
		this.rejectGroupSubmission = this.rejectGroupSubmission.bind(this);
		this.showEditPopup = this.showEditPopup.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.saveGroupSubmission = this.saveGroupSubmission.bind(this);
		this.toggleSubmissionText = this.toggleSubmissionText.bind(this);
	};

	/**
	 * Component mounted
	 */
	componentDidMount() {
		this.setState({
			groups: this.props.groups,
			isLoading: false
		});
	}

	/**
	 * Component updated
	 * @param {object} prevProps 
	 */
	componentDidUpdate(prevProps) {
		/* Teacher closed submissions */
		if (this.props.isOpenForSubmissions === false && prevProps.isOpenForSubmissions === true) {
			this.setState({groups: this.props.groups});
		}

		/* Teacher opened submission */ 
		if (this.props.isOpenForSubmissions === true && prevProps.isOpenForSubmissions === false) {
			let groupUpdates = [];
			this.state.groups.forEach((group) => {
				if (group.updated) {
					groupUpdates.push({
						id: group.id,
						updates: {investigations: group.investigations}
					});
				}
			});
			if (groupUpdates.length > 0) {
				this.props.updateGroups(groupUpdates);
			}
		}
	}


	/**
	 * Reject a group submission
	 * @param {string} groupId 
	 */
	rejectGroupSubmission(groupId) {
		let groups = (this.props.isOpenForSubmissions 
			? this.props.groups 
			: JSON.parse(JSON.stringify(this.state.groups)));

		let groupIndex = groups.findIndex((group) => {return group.id === groupId;});
		let investigationId = this.props.investigationId;
		let investigationPart = this.props.investigationStep.part;
		if (this.props.isTutorial) {
			this.props.rejectTutorialGroupSubmission(groupId);
		} else {
			if (
				groups[groupIndex].investigations && 
				groups[groupIndex].investigations[investigationId] &&
				groups[groupIndex].investigations[investigationId][investigationPart]
			) {
				let investigations = JSON.parse(JSON.stringify(groups[groupIndex].investigations));
				investigations[investigationId][investigationPart].submitted = false;
	
				if (this.props.isOpenForSubmissions) {
					/* Update DB */
					this.props.updateGroups([{id: groupId, updates: {investigations: investigations}}]);
				} else {
					/* Update state */
					groups[groupIndex].investigations = investigations;
					groups[groupIndex].updated = true;
					this.setState({groups: groups});
				}
	
				/* De-select group (if selected) */
				if (this.props.selectedGroups.some((group) => {return group.id === groupId;})) {
					this.props.toggleGroup(groupId);
				}
			}
		}
	}

	/**
	 * Open popup with info about editing group submissions
	 */
	showEditPopup() {
		const popupData = popupsData.editInvestigationPopup;
		let btnConfig = [];
		let okBtn = {
			text: popupData.buttonTexts.ok,
			type: 'button',
			action: this.props.closePopup,
			parameters: [],
		}; 
		btnConfig.push(okBtn);
		this.props.openPopup(popupData, btnConfig, 'editInvestigation');	
	}

	/**
	 * Update group submissions
	 * @param {string} groupId 
	 * @param {object} event 
	 */
	handleInput(groupId, event) {
		let groupIndex = this.state.groups.findIndex((group) => {return group.id === groupId;});

		if (groupIndex >= 0) {
			let value = event.target.value;
			let property = event.target.name;
			let groups = JSON.parse(JSON.stringify(this.state.groups));	
			
			let investigationId = this.props.investigationId;
			let investigationPart = this.props.investigationStep.part;
			if (!groups[groupIndex].investigations) groups[groupIndex].investigations = {};
			if (!groups[groupIndex].investigations[investigationId]) {
				groups[groupIndex].investigations[investigationId] = {};
			}
			if (!groups[groupIndex].investigations[investigationId][investigationPart]) {
				groups[groupIndex].investigations[investigationId][investigationPart] = {};
			}

			groups[groupIndex].investigations[investigationId][investigationPart][property] = value;
			groups[groupIndex].updated = true;
			groups[groupIndex].isEditing = true;
			this.setState({groups: groups});
		}
	}

	/**
	 * Save edited group submission
	 * @param {string} groupId 
	 */
	saveGroupSubmission(groupId) {
		let groupIndex = this.state.groups.findIndex((group) => {return group.id === groupId;});
		if (groupIndex >= 0) {
			this.setState({isSavingGroupId: groupId}, () => {
				let investigations = this.state.groups[groupIndex].investigations;
				this.props.updateGroups([{id: groupId, updates: {investigations: investigations}}]).then(() => {
					let groups = JSON.parse(JSON.stringify(this.state.groups));
					groups[groupIndex].isEditing = false;
					this.setState({groups: groups, isSavingGroupId: null});
				}, (error) => {
					console.error(error);
				});
			});
		}
	}

	/**
	 * Expand / collapse the text of a submission
	 * @param {string} groupId 
	 */
	toggleSubmissionText(groupId) {
		let groups = JSON.parse(JSON.stringify(this.state.groups));
		let groupIndex = this.state.groups.findIndex((group) => {return group.id === groupId;});
		groups[groupIndex].isExpanded = !groups[groupIndex].isExpanded;
		this.setState({groups});
	}

	/**
	 * Render component
	 */
	render() {
		/* Get groups from props / state */
		let groups = this.props.groups;
		if (!this.props.isOpenForSubmissions) groups = this.state.groups;

		/* Filter by active groups */
		let activeGroups = groups.filter((group) => {return group.isPlaying;});

		/* Filter by groups that have submitted their investigation  */
		/* Also filter by selected depending on the step */
		let investigationId = this.props.investigationId;
		let investigationPart = this.props.investigationStep.part;
		let submittedGroups = [];
		if (this.props.isTutorial) {
			submittedGroups = activeGroups.map((group) => {
				let investigation = Object.keys(group.investigations).reduce((a, b) => {
					a[investigationId] = group.investigations[b];
					return a;
				}, {});
				return {...group, investigations: investigation};
			});
		} else {
			submittedGroups = activeGroups.filter((group) => {
				let isSelected = (this.props.selectedGroups.findIndex((selectedGroup) => {
					return group.id === selectedGroup.id;
				}) >= 0);			
				return (
					group.investigations && 
					group.investigations[investigationId] &&
					group.investigations[investigationId][investigationPart] &&
					group.investigations[investigationId][investigationPart].submitted &&
					(this.props.investigationStep.type !== 'review' || isSelected)
				);
			});
		}

		let defaultText = null;
		if (submittedGroups.length === 0) {
			if (this.props.investigationStep.type === 'write') {
				if (this.props.investigationStep.part === 'hypothesis') {
					defaultText = uiTexts.noSubmissionsPart1;
				} else if (this.props.investigationStep.part === 'experiment') {
					defaultText = uiTexts.noSubmissionsPart2;
				}
			} else {
				defaultText = uiTexts.noSubmissionsSelected;
			}
		}


		return (
			<div className="ManageSubmissions">
				{submittedGroups.length === 0
					? <div className="ManageSubmissions-noSubmissions">{defaultText}</div>
					: <div className="ManageSubmissions-groups">
						{submittedGroups.map((group) => {				
							let isSelected = (this.props.selectedGroups.findIndex((selectedGroup) => {
								return group.id === selectedGroup.id;
							}) >= 0);
							let isEditing = (!this.props.isOpenForSubmissions && group.isEditing);
							let	title = group.investigations[investigationId][investigationPart].title;
							let	text = group.investigations[investigationId][investigationPart].text;
							let isExpanded = group.isExpanded || 
								(this.state.groups.length > 0 && this.state.groups.find((g) => {return g.id === group.id;}).isExpanded);
							return (
								<div key={group.id}
									className={'ManageSubmissions-group' + (isExpanded ? ' expanded' : '')}>
									<div className="ManageSubmissions-groupHeader">
										<GroupBadge group={group} showName={false} page="investigation" />
										<div
											
											className="ManageSubmissions-submissionTitle">
											{(this.props.isOpenForSubmissions 
												? (title ? title : uiTexts.title)
												: <input 
													className="ManageSubmissions-input"
													name="title" 
													type="text"
													placeholder={uiTexts.title}
													value={title} 
													onChange={(event)=>{this.handleInput(group.id, event);}}
												/>
											)}
										</div>
										<div className="ManageSubmissions-teacherActions">
											<div className={'ManageSubmissions-saveGroupBtn ' + (isEditing ? ' show' : '')}>
												<Button
													text={uiTexts.save}
													isLoading={(this.state.isSavingGroupId === group.id)}
													onClick={() => {this.saveGroupSubmission(group.id);}}
												/>
											</div>
											<div 
												className={'ManageSubmissions-expandSubmissionBtn' + 
													(isExpanded ? ' expanded' : '')}
												onClick={() => {this.toggleSubmissionText(group.id);}}
											/>
											<div 
												className={'ManageSubmissions-toggleGroupBtn' + 
														(isSelected ? ' selected' : '')}
												onClick={() => {this.props.toggleGroup(group.id);}}
											/>
											<div 
												className="ManageSubmissions-editGroupBtn"
												onClick={() => {if (this.props.isOpenForSubmissions) this.showEditPopup();}}
											/>
											{this.props.investigationStep.type === 'write' && <div 
												onClick={() => {this.rejectGroupSubmission(group.id);}} 
												className="ManageSubmissions-removeGroupBtn"
											/>}
										</div>
									</div>
									<div className={'ManageSubmissions-groupText' + (this.props.isOpenForSubmissions ? ' submissionOpen' : '')}>
										{this.props.isOpenForSubmissions 
											? (text ? addParagraph(text.split(/\n\r?/g)) : uiTexts.text)
											: <textarea
												className="ManageSubmissions-textarea"
												name="text"
												placeholder={uiTexts.text}
												value={(text ? text : '')}
												onChange={(event)=>{this.handleInput(group.id, event);}}
											/>
										}
									</div>
								</div>
							);
						})}
					</div>
				}

				{this.props.toggleTutorial &&
					<Button text="Se tutorial" onClick={() => {this.props.toggleTutorial(true);}} />}

				{this.props.investigationStep.type === 'write' &&
					<div 
						className="ManageSubmissions-closeSubmissions" 
						onClick={() => {this.props.toggleIsOpenForSubmissions(!this.props.isOpenForSubmissions);}}
					>{(this.props.isOpenForSubmissions ? uiTexts.closeInput : uiTexts.openInput)}</div>
				}
				

				{this.props.investigationStep.type === 'write' &&
					<div className="ManageSubmissions-groupsSelected">
						{submittedGroups.length} / {activeGroups.length} {uiTexts.groups}
					</div>
				}

				{this.props.prevStepIndex !== null &&
					<div className="ManageSubmissions-backBtn"
						onClick={() => {this.props.goToInvestigationStep(this.props.prevStepIndex);}} />
				}
				{!this.props.isTutorial && <div className="ManageSubmissions-stepNumber">
					{this.props.nextStepIndex
						? this.props.nextStepIndex
						: this.props.nrOfSteps} / {this.props.nrOfSteps}
				</div>}

				{this.props.nextStepIndex !== null && !this.props.isTutorial
					&& <NextStep
						type="investigation"
						nextStepData={{
							text: uiTexts.next,
							action: this.props.goToInvestigationStep,
							parameters: [this.props.nextStepIndex]
						}}
					/>}
			</div>
		);
	}
}

ManageSubmissions.propTypes = {
	isOpenForSubmissions: PropTypes.bool.isRequired,
	investigationStep: PropTypes.object.isRequired,
	nextStepIndex: PropTypes.number,
	prevStepIndex: PropTypes.number,
	investigationId: PropTypes.string.isRequired,
	groups: PropTypes.array.isRequired,
	selectedGroups: PropTypes.array.isRequired,
	toggleIsOpenForSubmissions: PropTypes.func,
	toggleGroup: PropTypes.func.isRequired,
	updateGroups: PropTypes.func.isRequired,
	goToInvestigationStep: PropTypes.func,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	nrOfSteps: PropTypes.number.isRequired,
	toggleTutorial: PropTypes.func,
	isTutorial: PropTypes.bool,
	rejectTutorialGroupSubmission: PropTypes.func
};

export default ManageSubmissions;