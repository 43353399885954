const energyPieces = [
	{id: 1, name: 'Potentiel energi', icon: 'potential-energy'},
	{id: 2, name: 'Kerneenergi', icon: 'nuclear-energy'},
	{id: 3, name: 'Elektrisk energi', icon: 'electric-energy'},
	{id: 4, name: 'Termisk energi', icon: 'thermal-energy'},
	{id: 5, name: 'Kemisk energi', icon: 'chemical-energy'},
	{id: 6, name: 'Strålingsenergi', icon: 'radiation-energy'},
	{id: 7, name: 'Kinetisk energi', icon: 'kinetic-energy'},
];

const energyPiecesIcons = [
	{id: 1, type: 'iconOnly', textLabel: 'Potentiel energi', icon: 'potential-energy'},
	{id: 2, type: 'iconOnly', textLabel: 'Kerneenergi', icon: 'nuclear-energy'},
	{id: 3, type: 'iconOnly', textLabel: 'Elektrisk energi', icon: 'electric-energy'},
	{id: 4, type: 'iconOnly', textLabel: 'Termisk energi', icon: 'thermal-energy'},
	{id: 5, type: 'iconOnly', textLabel: 'Kemisk energi', icon: 'chemical-energy'},
	{id: 6, type: 'iconOnly', textLabel: 'Strålingsenergi', icon: 'radiation-energy'},
	{id: 7, type: 'iconOnly', textLabel: 'Kinetisk energi', icon: 'kinetic-energy'},
];

const foodPieces = [
	{id: 1, name: 'Frysepizza 320 g', imageLabel: 'pizza'},
	{id: 2, name: 'Æble 100 g', imageLabel: 'apple'},
	{id: 3, name: 'Tun på dåse 185 g', imageLabel: 'tuna'},
	{id: 4, name: 'Kaffe 1 dl', imageLabel: 'coffee'},
	{id: 5, name: 'Smarties 50 g', imageLabel: 'smarties'},
	{id: 6, name: 'Capri Sonne 200 ml', imageLabel: 'capri-sonne'},
	{id: 7, name: 'Hasselnødder 50 g', imageLabel: 'hazelnuts'},
	{id: 8, name: 'Tortilla 100 g', imageLabel: 'tortilla'},
	{id: 9, name: 'Mariekiks 50 g', imageLabel: 'crackers'},
	{id: 10, name: 'Kartoffel 200 g', imageLabel: 'potato'},
	{id: 11, name: 'Vand 1/2 L', imageLabel: 'water'},
	{id: 12, name: 'Spaghetti bolognese 200 g', imageLabel: 'spaghetti'},
	{id: 13, name: 'Marsbar 51 g', imageLabel: 'marsbar'},
	{id: 14, name: 'Ost 25 g', imageLabel: 'cheese'},
	{id: 15, name: 'Salathoved 100 g', imageLabel: 'salat'}
]

const foodPieces2 = [
	{id: 1, name: 'Kaffe', imageLabel: 'coffee'},
	{id: 2, name: 'Æble', imageLabel: 'apple'},
	{id: 3, name: 'Tun på dåse', imageLabel: 'tuna'},
	{id: 4, name: 'Frysepizza', imageLabel: 'pizza'},
	{id: 5, name: 'Smarties', imageLabel: 'smarties'},
	{id: 6, name: 'Capri Sonne', imageLabel: 'capri-sonne'},
	{id: 7, name: 'Kartoffel', imageLabel: 'potato'},
	{id: 8, name: 'Tortilla', imageLabel: 'tortilla'},
	{id: 9, name: 'Mariekiks', imageLabel: 'crackers'},
	{id: 10, name: 'Hasselnødder', imageLabel: 'hazelnuts'},
]

const soundwavePieces = [
	{id: 1, type: 'iconOnly', icon: 'arrows-to-right'},
	{id: 2, type: 'iconOnly', icon: 'arrows-downwards'},
	{id: 3, type: 'iconOnly', icon: 'arrows-without-fluctuations'},
	{id: 4, type: 'iconOnly', icon: 'arrows-towards-center'},
	{id: 5, type: 'iconOnly', icon: 'arrows-to-left'},
	{id: 6, type: 'iconOnly', icon: 'arrows-upwards'},
	{id: 7, type: 'iconOnly', icon: 'arrows-with-fluctuations'},
	{id: 8, type: 'iconOnly', icon: 'arrows-from-center'},
	{id: 9, name: 'Højere tryk'},
	{id: 10, name: 'Lavere tryk'},
	{id: 11, name: 'Højere densitet'},
	{id: 12, name: 'Lavere densitet'},
	{id: 13, icon: 'graph-1'},
	{id: 14, icon: 'graph-2'},
	{id: 15, icon: 'graph-3'},
	{id: 16, icon: 'graph-4'},
	{id: 17, icon: 'graph-5'},
	{id: 18, name: '25 cm'},
	{id: 19, name: '150 cm'},
	{id: 20, name: '75 cm'},
	{id: 21, name: '100 cm'},
	{id: 22, name: '50 cm'},
	{id: 23, name: '200 cm'},
	{id: 24, name: '460', textLabel: 'Resultat'},
	{id: 25, name: '0,0022'},
	{id: 26, name: '0,022'},
	{id: 27, name: '2,2'},
	{id: 28, name: '4,6'},
	{id: 29, type: 'unit', name: 's', textLabel: 'Enhed'},
	{id: 30, type: 'unit', name: 'ms'},
	{id: 31, type: 'unit', name: 'Hz'},
	{id: 32, type: 'unit', name: '1/s'},
	{id: 33, type: 'unit', name: 's <sup>-1</sup>'},
	{id: 34, name: '345', textLabel: 'Tal'},
	{id: 35, name: '3,45'},
	{id: 36, name: '34,5'},
	{id: 37, name: '290'},
	{id: 38, name: '0,29'},
	{id: 39, type: 'unit', name: '<sup>km</sup><font size="+1">/</font><sub>t</sub>', textLabel: 'Enhed'},
	{id: 40, type: 'unit', name: '<sup>km</sup><font size="+1">/</font><sub>s</sub>'},
	{id: 41, type: 'unit', name: '<sup>m</sup><font size="+1">/</font><sub>s</sub>'},
	{id: 42, type: 'unit', name: '<sup>m</sup><font size="+1">/</font><sub>Hz</sub>'},
	{id: 43, type: 'unit', name: '<sup>km</sup><font size="+1">/</font><sub>Hz</sub>'},
	{id: 44, type: 'formulaWord', name: 'Fart', textLabel: 'Ord'},
	{id: 45, type: 'formulaWord', name: 'Frekvens'},
	{id: 46, type: 'formulaWord', name: 'Bølgelængde'},
	{id: 47, type: 'formulaWord', name: 'Periode'},
	{id: 48, type: 'formulaSymbol', name: 'v', textLabel: 'Formel'},
	{id: 49, type: 'formulaSymbol', name: 'T'},
	{id: 50, type: 'formulaSymbol', name: 'f'},
	{id: 51, type: 'formulaSymbol', name: 'λ'},
];

const soundwave2 = [
	{id: 1, type: 'iconOnly', icon: 'molecule', name: 'Molekyle'},
	{id: 2, type: 'iconOnly', icon: 'dna', name: 'DNA-streng'},
	{id: 3, type: 'iconOnly', icon: 'family', name: 'Menneske'},
	{id: 4, type: 'iconOnly', icon: 'virus', name: 'Virus'},
	{id: 5, type: 'iconOnly', icon: 'football-field', name: 'Fodboldbane'},
	{id: 6, type: 'iconOnly', icon: 'bacteria', name: 'Bakterie'},
	{id: 7, type: 'iconOnly', icon: 'needle', name: 'Nål'},
	{id: 8, name: '10<sup>3</sup>'},
	{id: 9, name: '10<sup>15</sup>'},
	{id: 10, name: '10<sup>0</sup>'},
	{id: 11, name: '10<sup>9</sup>'},
	{id: 12, name: '10<sup>6</sup>'},
	{id: 13, name: '10<sup>12</sup>'},
	{id: 14, name: '10<sup>18</sup>'},
	{id: 15, name: '10<sup>21</sup>'},
	{id: 16, name: '4*10<sup>-12</sup>'},
	{id: 17, name: '4*10<sup>-9</sup>'},
	{id: 18, name: '4*10<sup>9</sup>'},
	{id: 19, name: '4*10<sup>0</sup>'},
	{id: 20, name: '4*10<sup>12</sup>'},
	{id: 21, name: '4*10<sup>-6</sup>'},
	{id: 22, name: '4*10<sup>6</sup>'},
	{id: 23, name: '4*10<sup>-3</sup>'},
	{id: 24, name: '4*10<sup>3</sup>'},
	{id: 25, name: '4*10<sup>7</sup>'},
	{id: 26, name: '1*10<sup>9</sup>'},
	{id: 27, name: '1*10<sup>7</sup>'},
	{id: 28, name: '1*10<sup>8</sup>'},
	{id: 29, name: '1*10<sup>10</sup>'},
	{id: 30, name: '1*10<sup>6</sup>'},
	{id: 31, name: '7*10<sup>7</sup>'},
	{id: 32, name: '3*10<sup>8</sup>'},
	{id: 33, name: '5*10<sup>8</sup>'},
	{id: 34, name: 'X-rays'},
	{id: 35, name: 'Ultraviolet'},
	{id: 36, name: 'Gamma rays'},
	{id: 37, name: 'Visible light'},
	{id: 38, name: 'Radio waves'},
	{id: 39, name: 'Infrared waves'},
	{id: 40, name: 'Micro waves'},
	{id: 41, type: 'iconOnly', icon: 'x-rays'},
	{id: 42, type: 'iconOnly', icon: 'microwaves'},
	{id: 43, type: 'iconOnly', icon: 'gamma-rays'},
	{id: 44, type: 'iconOnly', icon: 'infrared-waves'},
	{id: 45, type: 'iconOnly', icon: 'radio-waves-router'},
	{id: 46, type: 'iconOnly', icon: 'ultraviolet'},
	{id: 47, type: 'iconOnly', icon: 'radio-waves-radio'},
]

const soundwaveMathPieces = [
	{id: 1, type: 'iconOnly', icon: 'wave-1'},
	{id: 2, type: 'iconOnly', icon: 'wave-2'},
	{id: 3, type: 'iconOnly', icon: 'wave-3'},
	{id: 4, type: 'iconOnly', icon: 'wave-4'},
	{id: 5, type: 'iconOnly', icon: 'wave-5'},
	{id: 6, type: 'iconOnly', icon: 'wave-6'},
	{id: 7, type: 'iconOnly', icon: 'wave-7'},
	{id: 8, type: 'iconOnly', icon: 'wave-8'},
	{id: 9, type: 'iconOnly', icon: 'wave-9'},
	{id: 10, type: 'iconOnly', icon: 'wave-10'},
	{id: 11, type: 'iconOnly', icon: 'wave-11'},
]

const timePiecesIcons = [
	{id: 0, type: 'd2', textLabel: 'Det er så lang tid, det tager Jorden at dreje rundt om sig selv'},
	{id: 1, type: 'd2', textLabel: 'Det er to gange så lang tid, som Jorden er i sin månes skygge'},
	{id: 2, type: 'd2', textLabel: 'Det er så lang tid, det tager Mars at dreje rundt om sig selv'},
	{id: 3, type: 'd2', textLabel: "Det er så lang tid, det tager Mars' sol at bevæge sig en gang rundt om Mars"},
	{id: 4, type: 'd2', textLabel: 'Det er to gange så lang tid, som Mars er i sin månes skygge'},
	{id: 5, type: 'd2', textLabel: 'Det er så lang tid, det tager Jordens sol at bevæge sig en gang rundt om Jorden'},
	{id: 6, type: 'd1', textLabel: '24 timer og 39 minutter'},
	{id: 7, type: 'd1', textLabel: '12 timer'},
	{id: 8, type: 'd1', textLabel: '1 time'},
	{id: 9, type: 'd1', textLabel: '12 timer og 20 minutter'},
	{id: 10, type: 'd1', textLabel: 'Svært at definere'},
	{id: 11, type: 'd1', textLabel: '24 timer'},
	{id: 12, type: 'm2', textLabel: 'Det er så lang tid, det tager månen at dreje rundt om Jorden'},
	{id: 13, type: 'm2', textLabel: 'Det er så lang tid, det tager månen at rotere omkring sig selv'},
	{id: 14, type: 'm2', textLabel: 'Det er så lang tid, det tager månen at dreje rundt om solen'},
	{id: 15, type: 'm2', textLabel: 'Mars har to måner, og de har ikke de samme faser'},
	{id: 16, type: 'm2', textLabel: 'Mars har ingen måner'},
	{id: 17, type: 'm2', textLabel: 'Mars har to måner, så det tager ca. to gange så lang tid for dem at komme rundt om Mars'},
	{id: 18, type: 'm1', textLabel: 'Omkring 30 dage'},
	{id: 19, type: 'm1', textLabel: 'Omkring 24 dage'},
	{id: 20, type: 'm1', textLabel: 'Omkring 57 dage'},
	{id: 21, type: 'm1', textLabel: 'Omkring 45 dage'},
	{id: 22, type: 'm1', textLabel: 'Svært at definere'},
	{id: 23, type: 'm1', textLabel: 'Omkring 365 dage'},
	{id: 24, type: 'å2', textLabel: 'Det er den tid, det tager Jorden at bevæge sig en gang rundt om solen'},
	{id: 25, type: 'å2', textLabel: 'Det er den tid, det tager Mars at bevæge sig en gang rundt om solen'},
	{id: 26, type: 'å2', textLabel: 'Det er så lang tid, det tager Jordens sol at bevæge sig en gang rundt om Jorden'},
	{id: 27, type: 'å2', textLabel: 'Det er så lang tid, det tager Mars sol at bevæge sig en gang rundt om Mars'},
	{id: 28, type: 'å2', textLabel: 'Det er så lang tid, det tager hele solsystemet at dreje rundt om sig selv'},
	{id: 29, type: 'å1', textLabel: 'Omkring 365 dage'},
	{id: 30, type: 'å1', textLabel: 'Omkring 730 dage'},
	{id: 31, type: 'å1', textLabel: 'Omkring 122 dage'},
	{id: 32, type: 'å1', textLabel: 'Omkring 1374 dage'},
	{id: 33, type: 'å1', textLabel: 'Omkring 687 dage'},
	{id: 34, type: 'å1', textLabel: 'Svært at definere'},
	{id: 35, type: 'å2', textLabel: 'Mars’ rotationsakse ikke er tippet i forhold til dens omløbsbane'},
	{id: 36, type: 'å2', textLabel: 'Jordens rotationsakse er tippet i forhold til dens omløbsbane om solen'},
	{id: 37, type: 'å2', textLabel: 'Mars bevæger sig i en cirkelbane rundt om Jorden i modsætning til Jorden'},
	{id: 38, type: 'å2', textLabel: 'Mars rotationsakse er tippet i forhold til dens omløbsbane om solen'},
	{id: 39, type: 'å2', textLabel: 'Jorden om vinteren er længere væk fra solen end om sommeren'},
	{id: 40, type: 'å2', textLabel: 'Mars om vinteren er længere væk fra solen end om sommeren'},
	{id: 41, type: 'å1', textLabel: 'Omkring 91 dage'},
	{id: 42, type: 'å1', textLabel: 'Omkring 229 dage'},
	{id: 43, type: 'å1', textLabel: 'Omkring 365 dage'},
	{id: 44, type: 'å1', textLabel: 'Omkring 122 dage'},
	{id: 45, type: 'å1', textLabel: 'Omkring 687 dage'},
	{id: 46, type: 'å1', textLabel: 'Omkring 170 dage'}
];


const puzzlesData = [
	/* Minigame 1: Energy chains */
	{
		id: 1,
		name: 'Energi fra vindmøller til belysning',
		reward: 100,
		inifiniteNumberOfPieces: true,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: energyPieces,
		areas: [
			{id: 1, pieceIds: [7], placed: null},
			{id: 2, pieceIds: [7], placed: null},
			{id: 3, pieceIds: [3], placed: null},
			{id: 4, pieceIds: [6], placed: null}
		]
	},
	{
		id: 2,
		name: 'Energi fra solceller til at køre en rover',
		reward: 100,
		inifiniteNumberOfPieces: true,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: energyPieces,
		areas: [
			{id: 1, pieceIds: [6], placed: null},
			{id: 2, pieceIds: [3], placed: null},
			{id: 3, pieceIds: [7], placed: null}
		]
	},
	{
		id: 3,
		name: 'Energi fra atomreaktor',
		reward: 100,
		inifiniteNumberOfPieces: true,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: energyPieces,
		areas: [
			{id: 1, pieceIds: [2], placed: null},
			{id: 2, pieceIds: [4], placed: null}
		]
	},
	{
		id: 4,
		name: 'Energi fra mad til at bevæge sig og holde varmen',
		reward: 100,
		inifiniteNumberOfPieces: true,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: energyPieces,
		areas: [
			{id: 1, pieceIds: [5], placed: null},
			{id: 2, pieceIds: [4], placed: null},
			{id: 3, pieceIds: [7], placed: null}
		]
	},
	{
		id: 5,
		name: 'Batteri, der får en pære til at lyse',
		reward: 100,
		inifiniteNumberOfPieces: true,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: energyPieces,
		areas: [
			{id: 1, pieceIds: [5], placed: null},
			{id: 2, pieceIds: [3], placed: null},
			{id: 3, pieceIds: [6], placed: null}
		]
	},
	{
		id: 6,
		name: 'En stor sten, der ruller ned ad en bakke og rammer noget i bunden',
		reward: 100,
		inifiniteNumberOfPieces: true,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: energyPieces,
		areas: [
			{id: 1, pieceIds: [1], placed: null},
			{id: 2, pieceIds: [7], placed: null},
			{id: 3, pieceIds: [4], placed: null}
		]
	},
	{
		id: 7,
		name: 'En plante, som står i lys, der høstes for at blive spist',
		reward: 100,
		inifiniteNumberOfPieces: true,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: energyPieces,
		areas: [
			{id: 1, pieceIds: [6], placed: null},
			{id: 2, pieceIds: [5], placed: null},
			{id: 3, pieceIds: [4], placed: null}
		]
	},
	{
		id: 8,
		name: 'Energikvalitet',
		reward: 100,
		inifiniteNumberOfPieces: true,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: energyPieces,
		areas: [
			{id: 1, pieceIds: [4], placed: null},
			{id: 2, pieceIds: [6], placed: null},
			{id: 3, pieceIds: [5], placed: null},
			{id: 4, pieceIds: [2], placed: null},
			{id: 5, pieceIds: [3], placed: null},
			{id: 6, pieceIds: [7], placed: null}
		]
	},
	/* Minigame 3: Food and energy */
	{
		id: 9,
		name: '',
		// name: 'Placér maden i den rigtige rækkefølge efter hvad der indeholder mest energi.',
		reward: 100,
		inifiniteNumberOfPieces: false,
		piecesHaveLabels: true,
		areasHaveLabels: true,
		pieces: foodPieces.slice(0, 5),
		areas: [
			{id: 1, pieceIds: [4], placed: null, textLabel: '1 kcal'},
			{id: 2, pieceIds: [2], placed: null, textLabel: '52 kcal'},
			{id: 3, pieceIds: [5], placed: null, textLabel: '100,5 kcal'},
			{id: 4, pieceIds: [3], placed: null, textLabel: '198 kcal'},
			{id: 5, pieceIds: [1], placed: null, textLabel: '602 kcal'},
		],
	},
	{
		id: 10,
		name: '',
		// name: 'Placér maden i den rigtige rækkefølge efter hvad der indeholder mest energi.',
		reward: 100,
		inifiniteNumberOfPieces: false,
		piecesHaveLabels: true,
		areasHaveLabels: true,
		pieces: foodPieces.slice(5, 10),
		areas: [
			{id: 1, pieceIds: [6],  placed: null, textLabel: '84 kcal'},
			{id: 2, pieceIds: [10], placed: null, textLabel: '160 kcal'},
			{id: 3, pieceIds: [9],  placed: null, textLabel: '216 kcal'},
			{id: 4, pieceIds: [8],  placed: null, textLabel: '237 kcal'},
			{id: 5, pieceIds: [7],  placed: null, textLabel: '315 kcal'},
		],
	},
	{
		id: 11,
		name: '',
		// name: 'Placér maden i den rigtige rækkefølge efter hvad der indeholder mest energi.',
		reward: 100,
		inifiniteNumberOfPieces: false,
		piecesHaveLabels: true,
		areasHaveLabels: true,
		pieces: foodPieces.slice(10, 15),
		areas: [
			{id: 1, pieceIds: [11], placed: null, textLabel: '0 kcal'},
			{id: 2, pieceIds: [15], placed: null, textLabel: '86 kcal'},
			{id: 3, pieceIds: [14], placed: null, textLabel: '100 kcal'},
			{id: 4, pieceIds: [13], placed: null, textLabel: '228 kcal'},
			{id: 5, pieceIds: [12], placed: null, textLabel: '292 kcal'},
		],
	},
	{
		id: 12,
		name: '',
		// name: 'Placér mængderne i de rigtige enheder.',
		info: {
			text: 'Capri Sonne',
			image: 'capri-sonne'
		},
		reward: 100,
		inifiniteNumberOfPieces: false,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: [
			{id: 1, name: '84,00'},
			{id: 2, name: '35,15'},
			{id: 3, name: '351.500'},
			{id: 4, name: '3,515'},
			{id: 5, name: '351,5'},
			{id: 6, name: '840,0'},
			{id: 7, name: '0,3515'}
		],
		areas: [
			{id: 1, pieceIds: [3], placed: null},
			{id: 2, pieceIds: [5], placed: null},
			{id: 3, pieceIds: [7], placed: 7},
			{id: 4, pieceIds: [1], placed: null},
		],
	},
	{
		id: 13,
		name: '',
		// name: 'Placér mængderne i de rigtige enheder.',
		info: {
			text: 'Æble',
			image: 'apple'
		},
		reward: 100,
		inifiniteNumberOfPieces: false,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: [
			{id: 1, name: '21,80'},
			{id: 2, name: '5,210'},
			{id: 3, name: '0,2180'},
			{id: 4, name: '2,180'},
			{id: 5, name: '52,10'},
			{id: 6, name: '218,0'},
			{id: 7, name: '218.000'}
		],
		areas: [
			{id: 1, pieceIds: [7], placed: 7},
			{id: 2, pieceIds: [6], placed: null},
			{id: 3, pieceIds: [3], placed: null},
			{id: 4, pieceIds: [5], placed: null},
		],
	},
	{
		id: 14,
		name: '',
		// name: 'Placér mængderne i de rigtige enheder.',
		info: {
			text: 'Marsbar',
			image: 'marsbar'
		},
		reward: 100,
		inifiniteNumberOfPieces: false,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: [
			{id: 1, name: '0,9540'},
			{id: 2, name: '9,540'},
			{id: 3, name: '954,0'},
			{id: 4, name: '95,40'},
			{id: 5, name: '954.000'},
			{id: 6, name: '228,0'},
		],
		areas: [
			{id: 1, pieceIds: [5], placed: null},
			{id: 2, pieceIds: [3], placed: null},
			{id: 3, pieceIds: [1], placed: null},
			{id: 4, pieceIds: [6], placed: 6},
		],
	},
	{
		id: 15,
		name: '',
		reward: 100,
		inifiniteNumberOfPieces: false,
		piecesHaveLabels: true,
		areasHaveLabels: true,
		pieces: foodPieces2.slice(0, 5),
		areas: [
			{id: 1, pieceIds: [4], placed: null, textLabel: '787.1 kJ/100g'},
			{id: 2, pieceIds: [2], placed: null, textLabel: '217.6 kJ/100g'},
			{id: 3, pieceIds: [3], placed: null, textLabel: '447.8 kJ/100g'},
			{id: 4, pieceIds: [5], placed: null, textLabel: '841.0 kJ/100g'},
			{id: 5, pieceIds: [1], placed: null, textLabel: '4.2 kJ/100g'},
		],
	},
	{
		id: 27,
		name: '',
		reward: 100,
		inifiniteNumberOfPieces: false,
		piecesHaveLabels: true,
		areasHaveLabels: true,
		pieces: foodPieces2.slice(5, 10),
		areas: [
			{id: 1, pieceIds: [7], placed: null, textLabel: '334.7 kJ/100g'},
			{id: 2, pieceIds: [9], placed: null, textLabel: '1807.5 kJ/100g'},
			{id: 3, pieceIds: [6], placed: null, textLabel: '175.7 kJ/100g'},
			{id: 4, pieceIds: [10], placed: null, textLabel: '2635.9 kJ/100g'},
			{id: 5, pieceIds: [8], placed: null, textLabel: '991.6 kJ/100g'},
		],
	},
	/* Minigame 4: Sound wave */
	{
		id: 16,
		name: 'Hvad er lyd?',
		reward: 100,
		inifiniteNumberOfPieces: false,
		piecesHaveLabels: false,
		areasHaveLabels: true,
		pieces: soundwavePieces.slice(0, 12),
		areas: [
			{id: 1, pieceIds: [8], placed: null, textLabel: 'Lydbølgens retning', pieceType: 'iconOnly'},
			{id: 2, pieceIds: [7], placed: null, textLabel: 'Luftpartiklernes bevægelse', pieceType: 'iconOnly'},
			{id: 3, pieceIds: [10, 12], placed: null},
			{id: 4, pieceIds: [9, 11], placed: null},
			{id: 5, pieceIds: [10, 12], placed: null},
			{id: 6, pieceIds: [9, 11], placed: null},
		],
	},
	{
		id: 17,
		name: 'Indsæt den rigtige graf for trykket',
		reward: 100,
		inifiniteNumberOfPieces: false,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: soundwavePieces.slice(12, 17),
		areas: [
			{id: 1, pieceIds: [13], placed: null},
		],
	},
	{
		id: 18,
		name: 'Hvad er bølgelængden?',
		reward: 100,
		inifiniteNumberOfPieces: true,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: soundwavePieces.slice(17, 23),
		areas: [
			{id: 1, pieceIds: [19], placed: null},
			{id: 2, pieceIds: [20], placed: null},
			{id: 3, pieceIds: [21], placed: null},
			{id: 4, pieceIds: [21], placed: null}
		],
	},
	{
		id: 19,
		name: 'Hvad er frekvensen?',
		reward: 100,
		inifiniteNumberOfPieces: false,
		piecesHaveLabels: true,
		areasHaveLabels: true,
		pieces: soundwavePieces.slice(23, 33),
		areas: [
			{id: 1, pieceIds: [24], placed: null, textLabel: 'Frekvens:'},
			{id: 2, pieceIds: [31, 32, 33], placed: null, pieceType: 'unit'},
		],
	},
	{
		id: 20,
		name: 'Hvad er lydens hastighed i dette?',
		reward: 100,
		inifiniteNumberOfPieces: false,
		piecesHaveLabels: true,
		areasHaveLabels: true,
		pieces: soundwavePieces.slice(33, 51),
		areas: [
			{id: 1, pieceIds: [34], placed: null, textLabel: 'Lydens hastighed:'},
			{id: 2, pieceIds: [41], placed: null, pieceType: 'unit'},
			{id: 3, pieceIds: [44], placed: null, textLabel: 'Ordformel:', pieceType: 'formulaWord'},
			{id: 4, pieceIds: [45, 46], placed: null, textLabel: '=', pieceType: 'formulaWord'},
			{id: 5, pieceIds: [45, 46], placed: null, textLabel: 'x', pieceType: 'formulaWord'},
			{id: 6, pieceIds: [48], placed: null, textLabel: 'Formel:', pieceType: 'formulaSymbol'},
			{id: 7, pieceIds: [50, 51], placed: null, textLabel: '=', pieceType: 'formulaSymbol'},
			{id: 8, pieceIds: [50, 51], placed: null, textLabel: 'x', pieceType: 'formulaSymbol'},
		],
	},
	/* Minigame 2: Energy Consumption */
	{
		id: 21,
		name: 'Gletsjeren',
		reward: 100,
		inifiniteNumberOfPieces: true,
		piecesHaveLabels: true,
		areasHaveLabels: false,
		pieces: energyPiecesIcons,
		areas: [
			{id: 1, pieceIds: [6], placed: null, pieceType: 'iconOnly'},
			{id: 2, pieceIds: [3], placed: null, pieceType: 'iconOnly'},
			{id: 3, pieceIds: [2], placed: null, pieceType: 'iconOnly'},
			{id: 4, pieceIds: [4], placed: null, pieceType: 'iconOnly'},
			{id: 5, pieceIds: [7], placed: null, pieceType: 'iconOnly'},
			{id: 6, pieceIds: [5], placed: null, pieceType: 'iconOnly'},
			{id: 7, pieceIds: [7], placed: null, pieceType: 'iconOnly'}
		]
	},
	{
		id: 22,
		name: 'Krateret',
		reward: 100,
		inifiniteNumberOfPieces: true,
		piecesHaveLabels: true,
		areasHaveLabels: false,
		pieces: energyPiecesIcons,
		areas: [
			{id: 1, pieceIds: [6], placed: null, pieceType: 'iconOnly'},
			{id: 2, pieceIds: [3], placed: null, pieceType: 'iconOnly'},
			{id: 3, pieceIds: [4], placed: null, pieceType: 'iconOnly'},
			{id: 4, pieceIds: [7], placed: null, pieceType: 'iconOnly'},
			{id: 5, pieceIds: [5], placed: null, pieceType: 'iconOnly'},
			{id: 6, pieceIds: [1], placed: null, pieceType: 'iconOnly'},
			{id: 7, pieceIds: [7], placed: null, pieceType: 'iconOnly'}
		]
	},
	{
		id: 23,
		name: 'Et døgn på Mars',
		reward: 100,
		inifiniteNumberOfPieces: true,
		piecesHaveLabels: true,
		areasHaveLabels: false,
		pieces: timePiecesIcons.slice(0, 12),
		areas: [
			{id: 1, pieceIds: [11], placed: null, pieceType: 'd1'},
			{id: 2, pieceIds: [0], placed: null, pieceType: 'd2'},
			{id: 3, pieceIds: [6], placed: null, pieceType: 'd1'},
			{id: 4, pieceIds: [2], placed: null, pieceType: 'd2'},
		]
	},
	{
		id: 24,
		name: 'En måned på Mars',
		reward: 100,
		inifiniteNumberOfPieces: true,
		piecesHaveLabels: true,
		areasHaveLabels: false,
		pieces: timePiecesIcons.slice(12, 24),
		areas: [
			{id: 1, pieceIds: [18], placed: null, pieceType: 'm1'},
			{id: 2, pieceIds: [12], placed: null, pieceType: 'm2'},
			{id: 3, pieceIds: [22], placed: null, pieceType: 'm1'},
			{id: 4, pieceIds: [15], placed: null, pieceType: 'm2'},
		]
	},
	{
		id: 25,
		name: 'Et år på Mars',
		reward: 100,
		inifiniteNumberOfPieces: true,
		piecesHaveLabels: true,
		areasHaveLabels: false,
		pieces: timePiecesIcons.slice(24, 35),
		areas: [
			{id: 1, pieceIds: [29], placed: null, pieceType: 'å1'},
			{id: 2, pieceIds: [24], placed: null, pieceType: 'å2'},
			{id: 3, pieceIds: [33], placed: null, pieceType: 'å1'},
			{id: 4, pieceIds: [25], placed: null, pieceType: 'å2'},
		]
	},
	{
		id: 26,
		name: 'En årstid på Mars',
		reward: 100,
		inifiniteNumberOfPieces: true,
		piecesHaveLabels: true,
		areasHaveLabels: false,
		pieces: timePiecesIcons.slice(35, 47),
		areas: [
			{id: 1, pieceIds: [41], placed: null, pieceType: 'å1'},
			{id: 2, pieceIds: [36], placed: null, pieceType: 'å2'},
			{id: 3, pieceIds: [46], placed: null, pieceType: 'å1'},
			{id: 4, pieceIds: [38], placed: null, pieceType: 'å2'},
		]
	},
	/* Minigame 6: Soundwave "Math" */
	{
		id: 28,
		name: 'OPGAVE 1',
		reward: 100,
		inifiniteNumberOfPieces: false,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: soundwaveMathPieces.slice(0, 6),
		areas: [
			{id: 1, pieceIds: [2], placed: null, pieceType: 'iconOnly'},
		]
	},
	{
		id: 29,
		name: 'OPGAVE 2',
		reward: 100,
		inifiniteNumberOfPieces: false,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: soundwaveMathPieces.slice(0, 6),
		areas: [
			{id: 1, pieceIds: [6], placed: null, pieceType: 'iconOnly'},
		]
	},
	{
		id: 30,
		name: 'OPGAVE 3',
		reward: 100,
		inifiniteNumberOfPieces: false,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: [
			soundwaveMathPieces[6],
			soundwaveMathPieces[4],
			soundwaveMathPieces[2],
			soundwaveMathPieces[1],
			soundwaveMathPieces[0],
			soundwaveMathPieces[5]
		],
		areas: [
			{id: 1, pieceIds: [5], placed: null, pieceType: 'iconOnly'},
		]
	},
	{
		id: 32,
		name: 'OPGAVE 4',
		reward: 100,
		inifiniteNumberOfPieces: true,
		piecesHaveLabels: false,
		areasHaveLabels: true,
		pieces: [
			soundwaveMathPieces[1],
			soundwaveMathPieces[5],
		],
		areas: [
			{id: 1, pieceIds: [6], placed: null, pieceType: 'iconOnly', textLabel: 'Rød'},
			{id: 2, pieceIds: [2], placed: null, pieceType: 'iconOnly', textLabel: 'Blå'},
		]
	},
	{
		id: 33,
		name: 'OPGAVE 5',
		reward: 100,
		inifiniteNumberOfPieces: false,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: soundwaveMathPieces.slice(7, 11),
		areas: [
			{id: 1, pieceIds: [8], placed: null, pieceType: 'iconOnly'},
		]
	},
	/* Minigame 6: Sound and light waves */
	{
		id: 34,
		name: '',
		reward: 100,
		inifiniteNumberOfPieces: true,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: soundwave2.slice(0, 7),
		areas: [
			{id: 1, pieceIds: [5], placed: null, pieceType: 'iconOnly'},
			{id: 2, pieceIds: [3], placed: null, pieceType: 'iconOnly'},
			{id: 3, pieceIds: [7], placed: null, pieceType: 'iconOnly'},
			{id: 4, pieceIds: [6], placed: null, pieceType: 'iconOnly'},
			{id: 5, pieceIds: [4], placed: null, pieceType: 'iconOnly'},
			{id: 6, pieceIds: [2], placed: null, pieceType: 'iconOnly'},
			{id: 7, pieceIds: [1], placed: null, pieceType: 'iconOnly'},
		]
	},
	{
		id: 35,
		name: '',
		reward: 100,
		inifiniteNumberOfPieces: true,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: soundwave2.slice(7, 15),
		areas: [
			{id: 1, pieceIds: [12], placed: null},
			{id: 2, pieceIds: [11], placed: null},
			{id: 3, pieceIds: [13], placed: null},
			{id: 4, pieceIds: [9], placed: null},
			{id: 5, pieceIds: [14], placed: null},
		]
	},
	{
		id: 36,
		name: '',
		reward: 100,
		inifiniteNumberOfPieces: true,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: soundwave2.slice(15, 24),
		areas: [
			{id: 1, pieceIds: [17], placed: null},
			{id: 2, pieceIds: [21], placed: null},
			{id: 3, pieceIds: [23], placed: null},
			{id: 4, pieceIds: [19], placed: null},
			{id: 5, pieceIds: [24], placed: null},
		]
	},
	{
		id: 37,
		name: '',
		reward: 100,
		inifiniteNumberOfPieces: true,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: soundwave2.slice(24, 33),
		areas: [
			{id: 1, pieceIds: [32], placed: null},
			{id: 2, pieceIds: [32], placed: null},
			{id: 3, pieceIds: [32], placed: null},
			{id: 4, pieceIds: [32], placed: null},
			{id: 5, pieceIds: [32], placed: null},
		]
	},
	{
		id: 38,
		name: '',
		reward: 100,
		inifiniteNumberOfPieces: false,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: soundwave2.slice(33, 40),
		areas: [
			{id: 1, pieceIds: [38], placed: null},
			{id: 2, pieceIds: [40], placed: null},
			{id: 3, pieceIds: [39], placed: null},
			{id: 4, pieceIds: [37], placed: null},
			{id: 5, pieceIds: [35], placed: null},
			{id: 6, pieceIds: [34], placed: null},
			{id: 7, pieceIds: [36], placed: null},
		]
	},
	{
		id: 39,
		name: '',
		reward: 100,
		inifiniteNumberOfPieces: false,
		piecesHaveLabels: false,
		areasHaveLabels: false,
		pieces: soundwave2.slice(40, 47),
		areas: [
			{id: 1, pieceIds: [47], placed: null, pieceType: 'iconOnly'},
			{id: 2, pieceIds: [45], placed: null, pieceType: 'iconOnly'},
			{id: 3, pieceIds: [42], placed: null, pieceType: 'iconOnly'},
			{id: 4, pieceIds: [44], placed: null, pieceType: 'iconOnly'},
			{id: 5, pieceIds: [46], placed: null, pieceType: 'iconOnly'},
			{id: 6, pieceIds: [41], placed: null, pieceType: 'iconOnly'},
			{id: 7, pieceIds: [43], placed: null, pieceType: 'iconOnly'},
		]
	},
];



module.exports = {
	puzzlesData
};