import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import Tips from 'components/admin/tips';
import './minigames.scss';

class Minigames extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	};

	render() {
		let {
			isEditing,
			isSaving,
			minigames,
			selectedMinigame,
			teacherInstructions,
			groupInstructions,
			errorMsg,
			selectMinigame,
			updateMinigame,
			updateInstructions,
			handleSaveMinigame,
			goToPage
		} = this.props;
	
		let saveBtnClass = 'MinigamesAdmin-saveBtn ' + (isSaving ? ' saving' : '');
		if (isEditing) saveBtnClass += ' editing';
		if (!isEditing) saveBtnClass += ' disabled';
	
		return (
			<div className="MinigamesAdmin">
				<div className="MinigamesAdmin-title">Rediger minispil / info-minispil</div>
				<p className="MinigamesAdmin-backBtn" onClick={() => {goToPage(null);}}>&lArr; tilbage</p>
	
				{/* Select minigame */}
				<form className="MinigamesAdmin-navForm" onChange={(event) => {selectMinigame(event);}}>
					<select>
						{minigames.map((minigame, index) => {
							return (
								<option key={index} value={index}>{minigame.title}</option>
							);
						})}
					</select>
				</form>

	
				<div>
					<form className="MinigamesAdmin-form">
						{/* Save */}
						<div 
							className={saveBtnClass}
							onClick={(event) => {handleSaveMinigame(event);}}>Gem</div>
						{errorMsg && <div>{errorMsg}</div>}

						{/* Edit title */}
						<div className="MinigamesAdmin-inputRow">
							<span className="MinigamesAdmin-label">Titel:</span>
							<input 
								className="MinigamesAdmin-input"
								name="title"
								value={selectedMinigame ? selectedMinigame.title : ''}
								placeholder="titel"
								onChange={(event) => {updateMinigame(event);}}
							/>
						</div>
						
	
						{/* Edit teacher instructions */}
						{(selectedMinigame && selectedMinigame.hasTeacherInstructions) &&
							<div className="MinigamesAdmin-inputRow">
								<p className="MinigamesAdmin-label">Lærerinstruktioner:</p>
								<textarea 
									className="MinigamesAdmin-textarea"
									name="teacherInstructions"
									value={teacherInstructions ? teacherInstructions : ''}
									placeholder=""
									onChange={(event) => {updateInstructions(event, 'teacher');}}
								/>
								<div className="MinigamesAdmin-preview">
									{renderMarkdown((teacherInstructions ? teacherInstructions : ''))}
								</div>
							</div>
						}

						{/* Edit group instructions */}
						{(selectedMinigame && selectedMinigame.hasGroupInstructions) &&
							<div className="MinigamesAdmin-inputRow">
								<p className="MinigamesAdmin-label">Gruppeinstruktioner:</p>
								<textarea 
									className="MinigamesAdmin-textarea"
									name="groupInstructions"
									value={groupInstructions ? groupInstructions : ''}
									placeholder=""
									onChange={(event) => {updateInstructions(event, 'group');}}
								/>
								<div className="MinigamesAdmin-preview">
									{renderMarkdown((groupInstructions ? groupInstructions : ''))}
								</div>
							</div>
						}

						
						
						
					</form>
				</div>
	
	
				{/* Markdown tips */}
				<Tips />
			</div>
		);
	}
};

Minigames.propTypes = {
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	minigames: PropTypes.array.isRequired,
	selectedMinigame: PropTypes.object,
	teacherInstructions: PropTypes.string, 
	groupInstructions: PropTypes.string, 
	errorMsg: PropTypes.string,
	selectMinigame: PropTypes.func.isRequired,
	updateMinigame: PropTypes.func.isRequired,
	updateInstructions: PropTypes.func.isRequired,
	handleSaveMinigame: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default Minigames;