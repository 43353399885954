import React from 'react';
import firestore from 'firestore';
import {checkIfFullscreen, toggleFullscreen} from 'helpers/fullscreen-helper';
import {getBrowserInfo} from 'helpers/browser-helper';
import AuthController from 'components/login/auth-controller';
import ConnectionStatus from 'components/connection-status/connection-status';
import PopupController from 'components/popup/popup-controller';
import GameController from 'components/game/game-controller';
import AdminController from 'components/admin/admin-controller';
import WaveInterference from 'components/wave-simulator/wave-interference';
import ColorVisionSimulator from 'components/wave-simulator/color-vision-simulator';
import './app.scss';
import LandingPage from './landing-page/landing-page';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOnline: true,
			isFullscreen: false,
			fullscreenAllowed: true,
			browserInfo: null,
		};
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Check if the browser is supported */
		const browserInfo = getBrowserInfo();

		/* Initialize firestore */
		firestore.then(() => {console.log('Firestore initialized');});

		/* Check fullscreen status */
		let isFullscreen = checkIfFullscreen();
		let fullscreenAllowed = true;
		/* if (browserInfo.clientBrowser && browserInfo.clientBrowser.os && browserInfo.clientBrowser.os === 'iOS') {
			fullscreenAllowed = false;
		} */
		this.setState({
			isFullscreen,
			fullscreenAllowed,
			browserInfo
		});

		/* Add event listener for fullscreen status */
		if (document.addEventListener) {
			document.addEventListener('webkitfullscreenchange', this.updateFullscreenStatus, false);
			document.addEventListener('mozfullscreenchange', this.updateFullscreenStatus, false);
			document.addEventListener('fullscreenchange', this.updateFullscreenStatus, false);
			document.addEventListener('MSFullscreenChange', this.updateFullscreenStatus, false);
		}

		/* Add network status listeners */
		window.addEventListener('online', this.handleNetworkChange);
		window.addEventListener('offline', this.handleNetworkChange);

	}

	/**
	 * Component will unmount, remove event listeners
	 */
	componentWillUnmount() {
		document.removeEventListener('webkitfullscreenchange', this.updateFullscreenStatus, false);
		document.removeEventListener('mozfullscreenchange', this.updateFullscreenStatus, false);
		document.removeEventListener('fullscreenchange', this.updateFullscreenStatus, false);
		document.removeEventListener('MSFullscreenChange', this.updateFullscreenStatus, false);
		window.removeEventListener('online', this.handleNetworkChange);
		window.removeEventListener('offline', this.handleNetworkChange);
	}

	/**
	 * Toggle fullscreen
	 */
	handleToggleFullscreen = () => {
		let isFullscreen = toggleFullscreen();
		this.setState({isFullscreen: isFullscreen});
	}

	/**
	 * Update fullscreen status
	 */
	updateFullscreenStatus = () => {
		let isFullscreen = checkIfFullscreen();
		this.setState({isFullscreen: isFullscreen});
	}

	/**
	 * Handle change in network status (online/offline)
	 */
	handleNetworkChange = () => {
		let isOnline = navigator.onLine;
		if (isOnline !== this.state.isOnline) this.setState({isOnline});
	}
	
	/**
	 * Render component
	 */
	render = () => {
		if (this.state.browserInfo && this.state.browserInfo.status !== 'ok') {
			return (
				<div className="App">
					<div className="App-letterBoxWrap">
						<LandingPage browserInfo={this.state.browserInfo}/>
					</div>
				</div>
			);
		}

		let SectionComponent = GameController;
		if (window.location.pathname.toLowerCase().includes('admin')) SectionComponent = AdminController;
		if (window.location.pathname.toLowerCase().includes('wave-interference')) SectionComponent = WaveInterference;
		if (window.location.pathname.toLowerCase().includes('color-vision')) SectionComponent = ColorVisionSimulator;
		
		return (
			<div className="App">
				<div className="App-letterBoxWrap">
					<AuthController>
						<ConnectionStatus>
							<PopupController>
								<SectionComponent 
									isOnline={this.state.isOnline}
									isFullscreen={this.state.isFullscreen}
									fullscreenAllowed={this.state.fullscreenAllowed}
									handleToggleFullscreen={this.handleToggleFullscreen}
								/>
							</PopupController>
						</ConnectionStatus>
					</AuthController>
				</div>
			</div>
		);
	}
}
export default App;