import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import MissionControlMessage from 'components/layout/mission-control-message';
import Button from 'components/button/button';
import {minigamesUiTexts} from 'data/ui-texts';
import './investigation-intro.scss';

const InvestigationIntro = ({
	page,
	toggleInvestigation,
	isTeacher,
	investigationStatus,
	setInvestigationStatus,
	investigationId,
	sessionStatus
}) => {
	let playBtnText = isTeacher && (sessionStatus === 'ready' || sessionStatus === 'closed') ? 'Gennemse' : 'Spil!';
	if (investigationId === 'investigation-3') {
		playBtnText = isTeacher && (sessionStatus === 'ready' || sessionStatus === 'closed')
			? 'Gennemse Spil 2' : 'Spil 2';
	}
	if (investigationId === 'investigation-4') playBtnText = 'Afstemning';

	return (
		/* Investigations are found on two page types: 'minigameAndInvestigation' and 'investigation' */
		<div className={'InvestigationIntro InvestigationIntro--' + page.type}>
			{page.type === 'investigation' && <div className="InvestigationIntro-title">{page.title}</div>}
			{page.type === 'investigation'
				&& page.storyText && <MissionControlMessage message={page.storyText} type={page.storyType} />}
			<div className="InvestigationIntro-text">
				{page.type === 'investigation' && renderMarkdown(page.text ? page.text : '')}
				{page.type === 'minigameAndInvestigation' && renderMarkdown(page.text2 ? page.text2 : '')}
			</div>
			{toggleInvestigation
					&& <Button
						class={isTeacher ? '' : 'wide'}
						text={playBtnText}
						onClick={() => {toggleInvestigation(true);}}
						disabled={!isTeacher && ['ready', 'locked', 'closed'].includes(investigationStatus)}
					/>}
			{(isTeacher && (investigationStatus === 'ready' || investigationStatus === 'closed')) && 
				<Button
					text={minigamesUiTexts.startMinigame}
					onClick={() => {setInvestigationStatus('open');}}
				/>
			}
			{(isTeacher && investigationStatus === 'open') &&
				<Button
					text={minigamesUiTexts.stopMinigame}
					onClick={() => {setInvestigationStatus('closed');}}
				/>
			}
		</div>
	);
};


InvestigationIntro.propTypes = {
	page: PropTypes.object.isRequired,
	toggleInvestigation: PropTypes.func,
	isTeacher: PropTypes.bool.isRequired,
	investigationStatus: PropTypes.string,
	setInvestigationStatus: PropTypes.func,
	investigationId: PropTypes.string,
	sessionStatus: PropTypes.string
};

export default InvestigationIntro;
