import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {storyTypesData} from 'data/content-data';
import Tips from 'components/admin/tips';
import './pages.scss';


class Pages extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pdfWidth: 10,
			pdfOrientations: []
		};
	};

	render() {
		let {
			isEditing,
			isSaving,
			stories,
			pages,
			storyIndex,
			selectStory,
			selectedPage,
			pageInstructions,
			pageText,
			pageText2,
			pageStoryText,
			errorMsg,
			selectPage,
			updatePage,
			updatePageInstructions,
			updatePageText,
			handleSavePage,
			goToPage
		} = this.props;
	
		let saveBtnClass = 'PagesAdmin-saveBtn ' + (isSaving ? ' saving' : '');
		if (isEditing) saveBtnClass += ' editing';
		if (!isEditing) saveBtnClass += ' disabled';
	
		return (
			<div className="PagesAdmin">
				<div className="PagesAdmin-title">Rediger sider</div>
				<p className="PagesAdmin-backBtn" onClick={() => {goToPage(null);}}>&lArr; tilbage</p>
	
				{/* Select story */}
				<form className="PagesAdmin-navForm" onChange={(event) => {selectStory(event);}}>
					<select>
						{stories.map((story, index) => {
							return (
								<option key={index} value={index}>{story.title}</option>
							);
						})}
					</select>
				</form>

				{/* Select page  */}
				{stories.length > 0 && <form className="PagesAdmin-navForm" onChange={(event) => {selectPage(event);}}>
					<select>
						{stories[storyIndex].missions.map((mission, missionIndex) => {
							return (
								<React.Fragment key={missionIndex}>
									<option disabled>Mission {missionIndex + 1}</option>
									{mission.sessions.map((session, sessionIndex) => {
										return (
											<React.Fragment key={sessionIndex}>
												<option disabled>Spilgang {sessionIndex + 1}</option>
												{session.pageIds && session.pageIds.map((pageId) => {
													let pageIndex = pages.findIndex((page) => {
														return page.id === pageId;
													});
													if (pageIndex >= 0) {
														let pageTitle = pages[pageIndex].title;
														return (
															<option key={pageId} value={pageIndex}>{pageTitle}</option>
														);
													} 
													return null;
												})}
											</React.Fragment>
										);
									})}
								</React.Fragment>
							);
						})}
					</select>
				</form>}
	
				<div>
					<form className="PagesAdmin-form">
						{/* Save */}
						<div 
							className={saveBtnClass}
							onClick={(event) => {handleSavePage(event);}}>Gem</div>
						{errorMsg && <div>{errorMsg}</div>}
						{/* Edit page title and menu title */}
						<div className="PagesAdmin-inputRow">
							<span className="PagesAdmin-label">Titel:</span>
							<input 
								className="PagesAdmin-input"
								name="title"
								value={selectedPage ? selectedPage.title : ''}
								placeholder="titel"
								onChange={(event) => {updatePage(event);}}
							/>
						</div>
						<div className="PagesAdmin-inputRow">
							<span className="PagesAdmin-label">Menu-titel:</span>
							<input 
								className="PagesAdmin-input"
								name="menuTitle"
								value={(selectedPage && selectedPage.menuTitle) ? selectedPage.menuTitle : ''}
								placeholder="menu"
								onChange={(event) => {updatePage(event);}}
							/>
						</div>
	
						{/* Edit teacher instructions */}
						{(selectedPage &&  selectedPage.hasTeacherInstructions) && <div className="PagesAdmin-inputRow">
							<p className="PagesAdmin-label">Lærerinstruktioner:</p>
							<textarea 
								className="PagesAdmin-textarea"
								name="text"
								value={pageInstructions.teacher ? pageInstructions.teacher : ''}
								placeholder=""
								onChange={(event) => {updatePageInstructions(event, 'teacher');}}
							/>
							<div className="PagesAdmin-preview">
								{renderMarkdown((pageInstructions.teacher !== null ? pageInstructions.teacher : ''))}
							</div>
						</div>}
						

						{/* Edit group instructions */}
						{(selectedPage && selectedPage.hasGroupInstructions) &&
							<div className="PagesAdmin-inputRow">
								<span className="PagesAdmin-label">Elevinstruktioner:</span><br />
								<textarea 
									className="PagesAdmin-textarea"
									name="text"
									value={pageInstructions.group ? pageInstructions.group : ''}
									placeholder=""
									onChange={(event) => {updatePageInstructions(event, 'group');}}
								/>
								<div className="PagesAdmin-preview">
									{renderMarkdown(pageInstructions.group ? pageInstructions.group : '')}
								</div>
							</div>
						}

						{/* Edit page content text */}
						{(selectedPage && selectedPage.hasText) &&
							<div className="PagesAdmin-inputRow">
								<span className="PagesAdmin-label">Sidetekst:</span><br />
								<textarea 
									className="PagesAdmin-textarea"
									name="pageText"
									value={pageText ? pageText : ''}
									placeholder=""
									onChange={(event) => {updatePageText(event);}}
								/>
								<div className="PagesAdmin-preview">
									{renderMarkdown(pageText ? pageText : '')}
								</div>
							</div>
						}

						{/* Edit page content text 2 (for minigame + investigation page)*/}
						{(selectedPage && selectedPage.hasText2) &&
							<div className="PagesAdmin-inputRow">
								<span className="PagesAdmin-label">Sidetekst 2:</span><br />
								<textarea 
									className="PagesAdmin-textarea"
									name="pageText2"
									value={pageText2 ? pageText2 : ''}
									placeholder=""
									onChange={(event) => {updatePageText(event);}}
								/>
								<div className="PagesAdmin-preview">
									{renderMarkdown(pageText2 ? pageText2 : '')}
								</div>
							</div>
						}

						{/* Edit story text */}
						{(selectedPage && selectedPage.hasStoryText) &&
							<React.Fragment>
								<div className="PagesAdmin-inputRow">
									<span className="PagesAdmin-label">Historietype:</span><br />
									<div className="PagesAdmin-radioButtons">
										{storyTypesData.map((storyType, index) => {
											let checked = (selectedPage.hasOwnProperty('storyType') && 
												selectedPage.storyType === storyType
											);
											return (
												<div key={index} className="PagesAdmin-radioButtonWrap">
													<input 
														className="PagesAdmin-radio"
														type="radio" 
														name="storyType" 
														value={storyType}
														checked={checked}
														onChange={(event) => {updatePage(event);}}
													/>{storyType}
												</div>
											);
										})}
									</div>
								</div>
								<div className="PagesAdmin-inputRow">
									<span className="PagesAdmin-label">Historietekst:</span><br />
									<textarea 
										className="PagesAdmin-textarea"
										name="pageStoryText"
										value={pageStoryText ? pageStoryText : ''}
										placeholder=""
										onChange={(event) => {updatePageText(event);}}
									/>
									<div className="PagesAdmin-preview">
										{renderMarkdown(pageStoryText ? pageStoryText : '')}
									</div>
								</div>

							</React.Fragment>
						}	
					</form>
				</div>
	
	
				{/* Markdown tips */}
				<Tips />
			</div>
		);
	}
};

Pages.propTypes = {
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	stories: PropTypes.array.isRequired,
	pages: PropTypes.array.isRequired,
	storyIndex: PropTypes.number.isRequired,
	selectedPage: PropTypes.object,
	pageText: PropTypes.string,
	pageText2: PropTypes.string,
	pageStoryText: PropTypes.string,
	pageInstructions: PropTypes.object,
	errorMsg: PropTypes.string,
	selectStory: PropTypes.func.isRequired,
	selectPage: PropTypes.func.isRequired,
	updatePage: PropTypes.func.isRequired,
	updatePageInstructions: PropTypes.func.isRequired,
	updatePageText: PropTypes.func.isRequired,
	handleSavePage: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default Pages;