import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {investigationUiTexts as uiTexts} from 'data/ui-texts';
import Button from 'components/button/button';
import './write-submission.scss';

class WriteSubmission extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isEditing: false,
			isSaving: false,
			isSubmitting: false,
			saved: false,
			submitted: false,
			investigationTitle: '',
			investigationText: ''
		};
		this.handleInput = this.handleInput.bind(this);
		this.handleSaveInvestigation = this.handleSaveInvestigation.bind(this);
		this.handleSubmitInvestigation = this.handleSubmitInvestigation.bind(this);
	};

	/**
	 * Component mounted
	 */
	componentDidMount() {
		let investigationTitle = '';
		let investigationText = '';
		if (
			this.props.group.investigations && 
			this.props.group.investigations[this.props.investigationId] &&
			this.props.group.investigations[this.props.investigationId][this.props.investigationPart]
		) {
			investigationTitle = 
				this.props.group.investigations[this.props.investigationId][this.props.investigationPart].title;
			investigationText = 
				this.props.group.investigations[this.props.investigationId][this.props.investigationPart].text;
		}
		this.setState({
			isLoading: false,
			investigationTitle,
			investigationText
		});
	}

	handleSaveInvestigation() {
		let title = this.state.investigationTitle;
		let text = this.state.investigationText;
		this.setState({isSaving: true}, () => {
			this.props.saveInvestigation(title, text).then(() => {
				this.setState({isEditing: false, isSaving: false, saved: true, submitted: false});
			});
		});
	}

	handleSubmitInvestigation() {
		this.setState({isSubmitting: true}, () => {
			this.props.submitInvestigation().then(() => {
				this.setState({isSubmitting: false, submitted: true});
			});
		});
	}

	/**
	 * Component will receive props
	 * @param {object} prevProps 
	 */
	componentDidUpdate(prevProps) {
		/* Content will automatically update after saving */
		if (this.state.isSaving || this.state.isSubmitting) return;
		
		/* Different investigation or part */
		if (
			prevProps.investigationId !== this.props.investigationId ||
			prevProps.investigationPart !== this.props.investigationPart
		) {
			let investigationTitle = '';
			let investigationText = '';
			if (
				this.props.group.investigations && 
				this.props.group.investigations[this.props.investigationId] &&
				this.props.group.investigations[this.props.investigationId][this.props.investigationPart]
			) {
				investigationTitle = 
					this.props.group.investigations[this.props.investigationId][this.props.investigationPart].title;
				investigationText = 
					this.props.group.investigations[this.props.investigationId][this.props.investigationPart].text;
			}
			this.setState({investigationTitle, investigationText});
			return;
		}


		/* No saved data */
		if (
			!this.props.group.investigations || 
			!this.props.group.investigations[this.props.investigationId] ||
			!this.props.group.investigations[this.props.investigationId][this.props.investigationPart]
		) return;


		let updateState = false;
		let investigationTitle = this.state.investigationTitle;
		let investigationText = this.state.investigationText;

		/* Title updated */
		let investigationId = this.props.investigationId;
		let investigationPart = this.props.investigationPart;
		if (
			prevProps.group.investigations[investigationId][investigationPart].title !== 
				this.props.group.investigations[investigationId][investigationPart].title
		) {
			investigationTitle = 
			this.props.group.investigations[investigationId][investigationPart].title;
			updateState = true;
		}
		/* Text updated */
		if (prevProps.group.investigations[investigationId][investigationPart].text !== 
			this.props.group.investigations[investigationId][investigationPart].text
		) {
			investigationText = this.props.group.investigations[investigationId][investigationPart].text;
			updateState = true;
		}
		
		/* Update state */
		if (updateState) {
			this.setState({investigationTitle, investigationText});
		}
	}




	/**
	 * Input title / text
	 * @param {object} event 
	 */
	handleInput(event) {
		if (!this.props.isOpenForSubmissions) return;

		let value = event.target.value;
		let property = event.target.name;

		this.setState({
			[property]: value,
			isEditing: true,
			saved: false,
			feedback: null
		});
	}

	/**
	 * Render component
	 */
	render() {
		let title = this.state.investigationTitle;
		let text = this.state.investigationText;
		return (
			<div className="WriteSubmission">
				<div className="WriteSubmission-header">
					<div className="WriteSubmission-title">
						<input 
							disabled={!this.props.isOpenForSubmissions}
							className="WriteSubmission-input"
							name="investigationTitle" 
							type="text"
							placeholder={uiTexts.title}
							value={title} 
							onChange={(event)=>{this.handleInput(event);}}
						/>
					</div>
					<div className="WriteSubmission-tools" />
				</div>
				<div className="WriteSubmission-text">
					<textarea
						disabled={!this.props.isOpenForSubmissions}
						className="WriteSubmission-textarea"
						name="investigationText"
						placeholder={this.props.inputPlaceholder ? this.props.inputPlaceholder : uiTexts.text}
						value={text}
						onChange={(event)=>{this.handleInput(event);}}
					/>
				</div>	

				<div className="WriteSubmission-actions">
					<div className="WriteSubmission-saveBtn">
						<Button 
							text={uiTexts.save}
							class="dark"
							isLoading={this.state.isSaving}
							disabled={
								!this.state.isEditing ||
								!this.props.isOpenForSubmissions ||
								this.props.isTutorial
							}
							onClick={() => {this.handleSaveInvestigation();}}
						/>
					</div>
					<div className="WriteSubmission-submitBtn">
						<Button
							// text={this.props.investigationPart === 'hypothesis' ? 'Upload' : 'Download'}
							text={uiTexts.upload}
							class="dark"
							isLoading={this.state.isSubmitting}
							disabled={
								(!this.state.investigationTitle && !this.state.investigationText) ||
								this.state.isEditing ||
								!this.props.isOpenForSubmissions ||
								this.props.isTutorial
							}
							onClick={() => {this.handleSubmitInvestigation();}}
						/>
					</div>
				</div>
			</div>			
		);
	}
}

WriteSubmission.propTypes = {
	isOpenForSubmissions: PropTypes.bool.isRequired,
	investigationId: PropTypes.string.isRequired,
	investigationPart: PropTypes.string.isRequired,
	group: PropTypes.object.isRequired,
	inputPlaceholder: PropTypes.string,
	saveInvestigation: PropTypes.func,
	submitInvestigation: PropTypes.func,
	isTutorial: PropTypes.bool
};

export default WriteSubmission;