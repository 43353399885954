import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import './presentation.scss';

const Presentation = ({page}) => {
	return (
		<div className="Presentation">
			<div className="Presentation-title">{page.title}</div>
			<div className="Presentation-text">
				{renderMarkdown(page.text ? page.text : '')}
			</div>
		</div>
	);
};


Presentation.propTypes = {
	page: PropTypes.object.isRequired
};

export default Presentation;
