import React from 'react';
import PropTypes from 'prop-types';
import './base-element.scss';

const BaseElement = ({element}) => {
	return (
		<div className={
			'BaseElement'
			+ ' BaseElement--' + element.id
			+ (element.status === 'repaired' ? ' BaseElement--' + element.id + '--repaired' : '')} />
	);
};

BaseElement.propTypes = {
	element: PropTypes.object.isRequired
};

export default BaseElement;
