import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {getMinigameInstructions, saveMinigameInstructions} from 'helpers/game-content-helper';
import Minigames from './minigames';

class MinigamesController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			minigames: [],
			selectedMinigameIndex: 0,
			selectedMinigame: null,
			teacherInstructions: null,
			groupInstructions: null,
			isEditingMinigame: false,
			isEditingTeacherInstructions: false,
			isEditingGroupInstructions: false,
			isSaving: false,
			errorMsg: null
		};
		this.subscribeToMinigames = this.subscribeToMinigames.bind(this);
		this.unsubscribeMinigames = null;
		this.selectMinigame = this.selectMinigame.bind(this);
		this.updateMinigame = this.updateMinigame.bind(this);
		this.updateInstructions = this.updateInstructions.bind(this);
		this.handleSaveMinigame = this.handleSaveMinigame.bind(this);
		this.saveMinigame = this.saveMinigame.bind(this);
	};

	/**
	 * Component mounted
	 */
	componentDidMount() {
		this.subscribeToMinigames().then((response) => {
			let minigames = (response.status === 'ok' ? response.minigames : []);
			let selectedMinigameIndex = 0;
			let selectedMinigame = minigames[0];
			let groupInstructions = null;
			let teacherInstructions = null;
			let promises = [];

			if (selectedMinigame.hasTeacherInstructions) {
				promises.push(getMinigameInstructions(selectedMinigame.id, 'teacher'));
			}
			if (selectedMinigame.hasGroupInstructions) {
				promises.push(getMinigameInstructions(selectedMinigame.id, 'group'));
			}

			if (promises.length > 0) {
				Promise.all(promises).then((responses) => {
					let responseIndex = 0;
					if (selectedMinigame.hasTeacherInstructions) {
						if (responses[responseIndex].status === 'ok') {
							teacherInstructions = responses[responseIndex].instructions;
						}
						responseIndex = responseIndex + 1;
					}
					if (selectedMinigame.hasGroupInstructions) {
						if (responses[responseIndex].status === 'ok') {
							groupInstructions = responses[responseIndex].instructions;
						}
					}

					this.setState({
						selectedMinigameIndex: selectedMinigameIndex,
						selectedMinigame: selectedMinigame,
						teacherInstructions: teacherInstructions,
						groupInstructions: groupInstructions
					});
				});	
			} else {
				this.setState({
					selectedMinigameIndex: selectedMinigameIndex,
					selectedMinigame: selectedMinigame,
					teacherInstructions: teacherInstructions,
					groupInstructions: groupInstructions
				});
			}
		});
	}


	/**
	 * Subscribe to minigames
	 */
	subscribeToMinigames() {
		if (this.unsubscribeMinigames !== null) this.unsubscribeMinigames();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeMinigames = db.collection('minigames').onSnapshot((querySnapshot) => {
				let minigames = [];
				if (!querySnapshot.empty) {
					minigames = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = doc.id;
						return data;						
					});
				}
				this.setState({minigames: minigames}, () => {
					resolve({status: 'ok', minigames: minigames});
				});
			}, (error) => {resolve({status: 'error', error: error});});
		});
	}



	/**
	 * Select page
	 * @param {object} event 
	 */
	selectMinigame(event) {
		let selectedMinigameIndex = parseInt(event.target.value);
		let selectedMinigame = this.state.minigames[selectedMinigameIndex];
		let promises = [];
		let groupInstructions = null;
		let teacherInstructions = null;

		if (selectedMinigame.hasTeacherInstructions) {
			promises.push(getMinigameInstructions(selectedMinigame.id, 'teacher'));
		}
		if (selectedMinigame.hasGroupInstructions) {
			promises.push(getMinigameInstructions(selectedMinigame.id, 'group'));
		}

		if (promises.length > 0) {
			Promise.all(promises).then((responses) => {
				let responseIndex = 0;
				if (selectedMinigame.hasTeacherInstructions) {
					if (responses[responseIndex].status === 'ok') {
						teacherInstructions = responses[responseIndex].instructions;
					}
					responseIndex = responseIndex + 1;
				}
				if (selectedMinigame.hasGroupInstructions) {
					if (responses[responseIndex].status === 'ok') {
						groupInstructions = responses[responseIndex].instructions;
					}
				}

				this.setState({
					selectedMinigameIndex: selectedMinigameIndex,
					selectedMinigame: selectedMinigame,
					teacherInstructions: teacherInstructions,
					groupInstructions: groupInstructions
				});
			});	
		} else {
			this.setState({
				selectedMinigameIndex: selectedMinigameIndex,
				selectedMinigame: selectedMinigame,
				teacherInstructions: teacherInstructions,
				groupInstructions: groupInstructions
			});
		}
	}
		
	/**
	 * Update minigame DB data
	 * @param {object} event 
	 */
	updateMinigame(event) {
		let selectedMinigame = JSON.parse(JSON.stringify(this.state.selectedMinigame));
		if (event.target.name === 'title') selectedMinigame[event.target.name] = event.target.value;
		this.setState({
			selectedMinigame: selectedMinigame,
			isEditingMinigame: true
		});
	}

	/**
	 * Update instructions
	 * @param {object} event 
	 * @param {string} role
	 */
	updateInstructions(event, role) {
		let isEditingTeacherInstructions = this.state.isEditingTeacherInstructions;
		let isEditingGroupInstructions = this.state.isEditingGroupInstructions;
		if (role === 'teacher') isEditingTeacherInstructions = true;
		if (role === 'group') isEditingGroupInstructions = true;
		
		this.setState({
			[event.target.name]: event.target.value,
			isEditingTeacherInstructions: isEditingTeacherInstructions,
			isEditingGroupInstructions: isEditingGroupInstructions,
			errorMsg: null
		});
	}



	/**
	 * Handle save minigame
	 */
	handleSaveMinigame() {		
		if (
			(
				!this.state.isEditingMinigame && 
				!this.state.isEditingTeacherInstructions && 
				!this.state.isEditingGroupInstructions
			) || this.state.isSaving
		) return;
		
		this.setState({isSaving: true});

		let promises = [];
		let selectedMinigame = JSON.parse(JSON.stringify(this.state.selectedMinigame));
		
		/* Save minigame in DB */
		if (this.state.isEditingMinigame) {
			promises.push(this.saveMinigame(selectedMinigame));
		}
		
		/* Save instructions in storage */
		if (this.state.isEditingTeacherInstructions) {
			promises.push(saveMinigameInstructions(selectedMinigame.id, 'teacher', this.state.teacherInstructions));
		}

		if (this.state.isEditingGroupInstructions) {
			promises.push(saveMinigameInstructions(selectedMinigame.id, 'group', this.state.groupInstructions));
		}

		Promise.all(promises).then(
			() => {
				this.setState({
					isEditingMinigame: false, 
					isEditingTeacherInstructions: false, 
					isEditingGroupInstructions: false,
					isSaving: false
				});
			}, (error) => {console.error(error);this.setState({isSaving: false, errorMsg: 'save error'});}
		);		
	}

	/* Save minigame (database) */
	saveMinigame(selectedMinigame) {
		let minigameData = {
			title: selectedMinigame.title,
			// loadTeacherInstructions: (this.state.teacherInstructions && this.state.teacherInstructions.length > 0),
			// loadGroupInstructions: (this.state.groupInstructions && this.state.groupInstructions.length > 0)
		};
		const db = firebase.firestore();
		return db.collection('minigames').doc(selectedMinigame.id).update(minigameData);
	}


	/**
	 * Render component
	 */
	render() {
		return (
			<Minigames 
				isEditing={this.state.isEditingMinigame || this.state.isEditingTeacherInstructions || 
					this.state.isEditingGroupInstructions}
				isSaving={this.state.isSaving}
				minigames={this.state.minigames}
				selectedMinigame={this.state.selectedMinigame}
				teacherInstructions={this.state.teacherInstructions}
				groupInstructions={this.state.groupInstructions}
				errorMsg={this.state.errorMsg}
				selectMinigame={this.selectMinigame}
				updateMinigame={this.updateMinigame}
				updateInstructions={this.updateInstructions}
				handleSaveMinigame={this.handleSaveMinigame}
				goToPage={this.props.goToPage}
			/>
		);
	}
}

MinigamesController.propTypes = {
	goToPage: PropTypes.func.isRequired
};

export default MinigamesController;