/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {cyoaUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import InstructionsController from 'components/instructions/instructions-controller';
import {popupsData} from 'data/popups-game-data';
import Graph from 'components/graph/graph';
import RoverChoice from './rover-choice';
import './rover.scss';

const Rover = ({
	toggleCyoaGame,
	entry,
	selectChoice,
	choicesMade,
	time,
	oxygen,
	energy,
	distance,
	isTeacher,
	cyoaGameStatus,
	cyoaGame,
	updateGroup,
	updateGame,
	group,
	game,
	currentTimeStep,
	sunCycles,
	openPopup,
	showBlur
}) => {
	let canPlay = isTeacher || (!isTeacher && cyoaGameStatus === 'open');
	let popupData = JSON.parse(JSON.stringify(popupsData.roverStaticData));
	return (
		<div className={'Rover Rover--' + (showBlur ? 'showBlur' : '')}>
			{cyoaGame.instructions &&
				<InstructionsController
					isTeacher={isTeacher}
					page={{type: 'cyoa', instructions: cyoaGame.instructions, id: cyoaGame.id}}
					iconType="info"
					updateGroup={updateGroup}
					updateGame={updateGame}
					group={group}
					game={game}
					type='popup'
				/>}
			<div className="Rover-stats">
				<div><span>{cyoaUiTexts.time}</span> {time[currentTimeStep].toFixed(1) + ' ' + cyoaUiTexts.timeMeasure}</div>
				<div><span>{cyoaUiTexts.oxygen}</span> {Math.round(oxygen[currentTimeStep]) + ' ' + cyoaUiTexts.oxygenMeasure}</div>
				<div><span>{cyoaUiTexts.energy}</span> {Math.round(energy[currentTimeStep]) + ' ' + cyoaUiTexts.energyMeasure}</div>
				{distance !== null && <div>
					<span>{cyoaUiTexts.distance}</span> {distance[currentTimeStep].toFixed(1) + ' ' + cyoaUiTexts.distanceMeasure}
				</div>}
			</div>

			<div className="Rover-staticData" onClick={() => {openPopup(popupData, null, 'cyoa');}}>Roverdata</div>

			{/* Graphs */}
			{/* Graph - Oxygen */}
			<Graph
				yValues={oxygen.slice(0, currentTimeStep).map((e) => {return e.toFixed(1);})}
				xValues={time.map((e) => {return e.toFixed(1);})}
				yTitle={'Oxygen (liters)'}
				xTitle={'Timer'}
				dataType={'oxygen'}
				yMin={0}
				yMax={2000}
				yTickStepSize={500}
			/>

			{/* Graph - Energy */}
			<Graph
				yValues={energy.slice(0, currentTimeStep).map((e) => {return e.toFixed(1);})}
				xValues={time.map((e) => {return e.toFixed(1);})}
				yTitle={'Energi (kWh)'}
				xTitle={'Timer'}
				dataType={'energy'}
				yMin={0}
				yMax={200}
				yTickStepSize={50}
				sunCycles={sunCycles}
			/>

			{/* Graph - Distance */}
			<Graph
				yValues={distance ? distance.slice(0, currentTimeStep).map((e) => {return e.toFixed(1);}) : []}
				xValues={time.map((e) => {return e.toFixed(1);})}
				yTitle={'Afstand til basen (km)'}
				xTitle={'Timer'}
				yMin={0}
				yMax={choicesMade[0] ? choicesMade[0] === 1 ? 600 : choicesMade[0] === 2 ? 150 : 280 : 0}
				yTickStepSize={100}
				dataType={'distance'}
			/>
		
			<div className={'Rover-entry Rover-entry--' + cyoaGame.id}>
				<div className="Rover-entry-text">
					{entry.id === 'life-support-dessert-canyon'
						? renderMarkdown(entry.texts[choicesMade[entry.requiredChoiceIdIndex] - 1])
						: entry.texts.length > 1
							? renderMarkdown(entry.texts[choicesMade[choicesMade.length - 1] - 1])
							: renderMarkdown(entry.texts[0])
					}
				</div>
				<div className="Rover-choices">
					{entry.choices.map((choice) => {
						return <RoverChoice
							key={choice.id}
							choice={choice}
							selectChoice={selectChoice}
							canPlay={canPlay}
							choicesMade={choicesMade}
							toggleCyoaGame={toggleCyoaGame}
						/>;
					})}
				</div>
			</div>

			<div className="Rover-close" onClick={() => {toggleCyoaGame(false);}} />
		</div>
	);
};

Rover.propTypes = {
	toggleCyoaGame: PropTypes.func.isRequired,
	entry: PropTypes.object,
	selectChoice: PropTypes.func.isRequired,
	choicesMade: PropTypes.array.isRequired,
	time: PropTypes.array.isRequired,
	oxygen: PropTypes.array.isRequired,
	energy: PropTypes.array.isRequired,
	distance: PropTypes.array,
	isTeacher: PropTypes.bool.isRequired,
	cyoaGameStatus: PropTypes.string.isRequired,
	cyoaGame: PropTypes.object.isRequired,
	updateGroup: PropTypes.func,
	updateGame: PropTypes.func,
	group: PropTypes.object,
	game: PropTypes.object.isRequired,
	currentTimeStep: PropTypes.number.isRequired,
	sunCycles: PropTypes.array.isRequired,
	openPopup: PropTypes.func.isRequired,
	showBlur: PropTypes.bool.isRequired
};


export default Rover;
