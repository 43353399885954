import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts as uiTexts} from 'data/ui-texts';
import Tab from 'components/layout/tab';
import Button from 'components/button/button';

const ResetPassword = (props) => {
	let {
		isLoading,
		passwordIsReset,
		email,
		feedback,
		handleInput,
		handleSubmit,
		goToTeacherBox,
		invalidEmail
	} = props;

	if (passwordIsReset) {
		return (
			<Tab title={<div className="Login-title">{uiTexts.titleResetPassword}</div>} >
				<p>{uiTexts.passwordResetFeedback}</p>
				<Button
					class="loginToggle"
					text={uiTexts.goToLoginBtn}
					onClick={() =>{goToTeacherBox('login');}}
				/>
			</Tab>
		);
	}

	return (
		<Tab title={<div className="Login-title">{uiTexts.titleResetPassword}</div>} >
			<p>{uiTexts.newPassword}</p>
			<form className="Login-form" onSubmit={handleSubmit}>
				<input
					className={invalidEmail ? ' invalid' : ''}
					name="email"
					type="text"
					placeholder={uiTexts.placeholderEmail}
					autoComplete="section-resetpasswrd email"
					value={email ? email : ''}
					onChange={(event)=>{handleInput(event);}}
				/>
				<Button
					isLoading={isLoading}
					text={uiTexts.newPasswordBtn}
					onClick={() =>{handleSubmit();}}
				/>
				<p className="Login-errorMessage">{feedback}</p>
			</form>
			<Button
				class="loginToggle"
				text={uiTexts.goToLoginBtn}
				onClick={() =>{goToTeacherBox('login');}}
			/>
		</Tab>
	);
};

ResetPassword.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	passwordIsReset: PropTypes.bool.isRequired,
	email: PropTypes.string,
	feedback: PropTypes.string,
	handleInput: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	goToTeacherBox: PropTypes.func.isRequired,
	invalidEmail: PropTypes.bool.isRequired
};

export default ResetPassword;