import React from 'react';
import PropTypes from 'prop-types';
import {minigamesUiTexts} from 'data/ui-texts';
import MinigameScore from './minigame-score';
import InstructionsController from 'components/instructions/instructions-controller';
import PuzzleController from './puzzle/puzzle-controller';
import './minigame.scss';

const Minigame = (props) => {
	let {
		isTeacher, 
		minigameStatus,
		minigame, 
		minigamePartIndex, 
		minigameScore,
		minigameMaxScore,
		toggleMinigame, 
		goToMinigamePart, 
		updateMinigamePart,
		updateGroup,
		updateGame,
		openPopup,
		closePopup,
		group,
		game,
		showMinigameCompletedPopup
	} = props;

	let showNextPartBtn = false;
	if (minigame.parts[minigamePartIndex].completed || isTeacher) {
		if (minigamePartIndex < (minigame.parts.length - 1)) {
			showNextPartBtn = true;
		}
	}

	let showExitBtn = false;
	if (minigamePartIndex === (minigame.parts.length - 1) && (minigame.parts[minigamePartIndex].completed || isTeacher)
	) {
		showExitBtn = true;
	}

	return (
		<div className={'Minigame Minigame--' + minigame.id}>
			<div className="Minigame-close" onClick={() => {toggleMinigame(false);}} />
			{minigamePartIndex > 0 && 
				<div className="Minigame-back" onClick={() => {goToMinigamePart(minigamePartIndex - 1);}} />
			}
			<div className="Minigame-part">{(minigamePartIndex + 1) + '/' + minigame.parts.length}</div>
			<MinigameScore score={minigameScore} maxScore={minigameMaxScore} />

			{minigame.instructions && 
				<InstructionsController
					isTeacher={isTeacher}
					page={{type: 'puzzle', instructions: minigame.instructions, id: minigame.id}}
					iconType="info"
					updateGroup={updateGroup}
					updateGame={updateGame}
					group={group}
					game={game}
					type='popup'
				/>}

			<div className="Minigame-content">
				<PuzzleController 
					isTeacher={isTeacher}
					minigameStatus={minigameStatus}
					puzzle = {minigame.parts[minigamePartIndex]}
					minigamePartIndex = {minigamePartIndex}
					goToMinigamePart = {goToMinigamePart}
					updateMinigamePart = {updateMinigamePart}
					openPopup={openPopup}
					closePopup={closePopup}
				/>

			</div>

			{showNextPartBtn && <div 
				className="Minigame-nextPartBtn"
				onClick={() => {goToMinigamePart(minigamePartIndex + 1);}}
			>{minigamesUiTexts.next}</div>}

			{showExitBtn && <div
				className="Minigame-exitMinigameBtn"
				onClick={() => {showMinigameCompletedPopup(minigame);}}
			>{minigamesUiTexts.finish}</div>}
			
		</div>
	);
};


Minigame.propTypes = {
	isTeacher: PropTypes.bool.isRequired,
	minigameStatus: PropTypes.string.isRequired,
	minigame: PropTypes.object.isRequired,
	minigamePartIndex: PropTypes.number.isRequired,
	minigameScore: PropTypes.number.isRequired,
	minigameMaxScore: PropTypes.number.isRequired,
	toggleMinigame: PropTypes.func.isRequired,
	goToMinigamePart: PropTypes.func.isRequired,
	updateMinigamePart: PropTypes.func.isRequired,
	updateGroup: PropTypes.func,
	updateGame: PropTypes.func,
	group: PropTypes.object,
	game: PropTypes.object.isRequired,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	showMinigameCompletedPopup: PropTypes.func.isRequired
};

export default Minigame;
