import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {puzzlesData} from 'data/puzzles-data';
import {popupsData} from 'data/popups-game-data';
import MinigameIntro from './minigame-intro';
import Minigame from './minigame';

class MinigameController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			showMinigame: false,
			minigame: null,
			minigamePartIndex: 0,
		};
	};

	/**
	 * Component mounted
	 */
	componentDidMount() {
		let minigame = null;
		if (this.props.minigame) {
			minigame = this.loadMinigame();
			if (!this.props.isTeacher) minigame = this.syncMinigameWithGroup(minigame, this.props.group);
		}
		this.setState({minigame: minigame, isLoading: false});				
	}

	/**
	 * Component will receive props
	 * @param {object} prevProps 
	 */
	componentDidUpdate(prevProps) {
		/* User navigated to new minigame */
		if (this.props.page.id !== prevProps.page.id) {
			this.setState({
				isLoading: true, minigame: null, minigamePage: 'start', minigamePartIndex: null
			}, () => {
				let minigame = null;
				if (this.props.minigame) {
					minigame = this.loadMinigame();
					if (!this.props.isTeacher) minigame = this.syncMinigameWithGroup(minigame, this.props.group);
				}
				this.setState({minigame: minigame, isLoading: false, showMinigame: false, minigamePartIndex: 0});
			});
		}

		/* Resync minigame with group (group only) */
		if (!this.props.isTeacher && this.props.page.id === prevProps.page.id) {
			let group = this.props.group;
			let prevGroup = prevProps.group;
			if (
				this.state.minigame &&
				group.hasOwnProperty('minigames') && group.minigames.hasOwnProperty(this.state.minigame.id) &&
				prevGroup.hasOwnProperty('minigames') && prevGroup.minigames.hasOwnProperty(this.state.minigame.id)
			) {
				let groupCompletedParts = group.minigames[this.state.minigame.id].parts.filter((part) => {
					return (part !== null && part.completed === true);
				});
				let prevGroupCompletedParts = prevGroup.minigames[this.state.minigame.id].parts.filter((part) => {
					return (part !== null && part.completed === true);
				});
				if (groupCompletedParts.length !== prevGroupCompletedParts.length) {
					let minigame = this.syncMinigameWithGroup(this.state.minigame, group);
					this.setState({minigame: minigame});
				}
			}
		}
	}

	/**
	 * Load minigame
	 */
	loadMinigame = () => {
		let minigame = JSON.parse(JSON.stringify(this.props.minigame));
		if (minigame && minigame.parts && minigame.parts.length > 0) {
			minigame.parts.forEach((part) => {
				part.completed = false;

				/* Puzzle */
				if (part.type === 'puzzle') {
					if (puzzlesData.some((puzzle) => {
						return puzzle.id === part.puzzleId;
					})) {
						let puzzleData = JSON.parse(JSON.stringify(puzzlesData)).filter((puzzle) => {
							return puzzle.id === part.puzzleId;
						})[0];
						part.moves = 0;
						part.name = puzzleData.name;
						part.info = (puzzleData.info ? puzzleData.info : null);
						part.reward = puzzleData.reward;
						part.inifiniteNumberOfPieces = puzzleData.inifiniteNumberOfPieces;
						part.piecesHaveLabels = (puzzleData.piecesHaveLabels ? true : false);
						part.areasHaveLabels = (puzzleData.areasHaveLabels ? true : false);
						part.pieces = puzzleData.pieces;
						part.areas = puzzleData.areas;
					}
				}
			});
		} else {
			minigame = null;
		}
		return minigame;
	}


	/**
	 * Sync minigame with group (group only)
	 * @param {object} minigame 
	 */
	syncMinigameWithGroup = (loadedMinigame, group) => {
		let minigame = JSON.parse(JSON.stringify(loadedMinigame));
		if (group.hasOwnProperty('minigames') && group.minigames.hasOwnProperty(minigame.id)) {
			if (group.minigames[minigame.id].parts.length === minigame.parts.length) {
				group.minigames[minigame.id].parts.forEach((part, index) => {
					if (part !== null) {
						if (part.hasOwnProperty('type') && part.type === minigame.parts[index].type) {
							minigame.parts[index].completed = part.completed;
							minigame.parts[index].reward = part.reward;
							if (part.completed && part.areas) {
								minigame.parts[index].areas = part.areas;
							}
						} else {
							console.error('minigame types do not match');		
						}
					}
				});
			} else {
				console.error('number of parts do not match');
			}
		}
		return minigame;
	}

	/**
	 * Get minigame status
	 */
	getMinigameStatus = () => {
		let minigameStatus = 'locked';
		if (
			// this.props.isActiveSession && 
			this.props.sessionStatus === 'open' &&
			this.props.game && 
			this.props.game.id !== null && 
			this.props.minigame && 
			this.props.game.minigames.some((minigame) => {return minigame.id === this.props.minigame.id;})
		) {
			minigameStatus = this.props.game.minigames.filter((minigame) => {
				return minigame.id === this.props.minigame.id;
			})[0].status;
		}
		return minigameStatus;
	}

	/**
	 * Open / close minigame (teacher only)
	 * @param {string} minigameStatus 
	 */
	setMinigameStatus = (minigameStatus) => {
		let minigames = JSON.parse(JSON.stringify(this.props.game.minigames));
		let minigameIndex = minigames.findIndex((minigame) => {return minigame.id === this.props.minigame.id;});
		if (minigameIndex < 0) {
			minigames.push({id: this.props.minigame.id, status: minigameStatus});
		} else {
			minigames[minigameIndex].status = minigameStatus;
		}
		this.props.updateGame({minigames: minigames});
	}

	/**
	 * Hide / show minigame
	 * @param {bool} showMinigame 
	 */
	toggleMinigame = (showMinigame) => {
		this.setState({showMinigame, minigamePartIndex: 0});
	}

	/**
	 * Navigate between minigame parts
	 * @param {string} page 
	 * @param {number} index 
	 */
	goToMinigamePart = (index) => {
		this.setState({minigamePartIndex: index});
	}

	/**
	 * Update minigame part
	 * @param {number} minigamePartIndex 
	 * @param {object} minigamePart 
	 */
	updateMinigamePart = (minigamePartIndex, minigamePart) => {
		return new Promise((resolve, reject) => {
			let minigame = JSON.parse(JSON.stringify(this.state.minigame));
			minigame.parts[minigamePartIndex] = minigamePart;


			/* Update minigame state */
			if (this.props.isTeacher || !minigamePart.completed) {
				this.setState({minigame: minigame}, () => {
					resolve();
				});
				
			/* Submit completed part (group only) */
			} else {
				this.props.submitMinigamePart(
					this.state.minigame.id, 
					minigamePartIndex, 
					minigamePart, 
					minigame.parts.length
				).then(
					(response) => {
						this.setState({minigame: minigame}, () => {
							resolve();
						});
					},
					(error) => {
						console.error(error);
						reject(error);
					}
				);
			}
		});
	}

	/**
	 * Show minigame completed popup
	 * @param {object} minigame
	 */
	showMinigameCompletedPopup = (minigame) => {
		if (this.props.isTeacher) {
			this.toggleMinigame(false);
		} else {
			let minigameScore = minigame.parts.reduce((sum, part) => {
				if (part.completed === true && part.hasOwnProperty('reward')) return sum + part.reward;
				return sum;
			}, 0);
	
			let popupData = JSON.parse(JSON.stringify(popupsData.minigameCompleted));
			popupData.text = popupData.text.replace('%points%', minigameScore);
			let btnConfig = [{
				text: popupData.buttonTexts.ok, 
				type: 'button', 
				action: this.handleCloseMinigamePopup, 
				parameters: []
			}];
			this.props.openPopup(popupData, btnConfig);
		}
	}

	/**
	 * Close minigame completed popup and close minigame popup
	 */
	handleCloseMinigamePopup = () => {
		this.props.closePopup();
		this.toggleMinigame(false);
	}

	/**
	 * Render minigame component
	 */
	render() {
		let minigameStatus = this.getMinigameStatus();

		if (!this.state.showMinigame || this.state.isLoading || !this.state.minigame) {
			let minigameIsReady = false;
			if (!this.state.isLoading && this.state.minigame) {
				if (this.state.minigame.parts && this.state.minigame.parts.length > 0) {
					if (
						this.state.minigame.parts[0].type === 'puzzle' &&
						this.state.minigame.parts[0].pieces && this.state.minigame.parts[0].pieces.length > 0
					) {
						minigameIsReady = true;
					}
				};
			}
			return (
				<MinigameIntro 
					isTeacher={this.props.isTeacher}
					page={this.props.page}
					minigame={this.state.minigame}
					groups={this.props.groups}
					minigameStatus={minigameStatus}
					setMinigameStatus={this.setMinigameStatus}
					toggleMinigame={minigameIsReady ? this.toggleMinigame : null}
					sessionStatus={this.props.sessionStatus}
					minigameNumber={this.props.minigameNumber}
				/>
			);
		}


		if (this.state.minigame) {
			/* Score */
			let minigameScore = this.state.minigame.parts.reduce((sum, part) => {
				if (part.completed === true && part.hasOwnProperty('reward')) return sum + part.reward;
				return sum;
			}, 0);
			let currentMinigamePartsData = JSON.parse(JSON.stringify(puzzlesData)).filter((puzzle) => {
				return this.state.minigame.parts.some((part) => {
					return puzzle.id === part.puzzleId;
				});
			});
			let minigameMaxScore = currentMinigamePartsData.reduce((sum, part) => {
				if (part.hasOwnProperty('reward')) return sum + part.reward;
				return sum;
			}, 0);

			return (
				<Minigame 
					isTeacher={this.props.isTeacher}
					minigameStatus={minigameStatus}
					minigame={this.state.minigame}
					minigamePartIndex={this.state.minigamePartIndex}
					minigameScore={minigameScore}
					minigameMaxScore={minigameMaxScore}
					toggleMinigame={this.toggleMinigame}
					goToMinigamePart={this.goToMinigamePart}
					updateMinigamePart={this.updateMinigamePart}
					updateGroup={this.props.updateGroup}
					updateGame={this.props.updateGame}
					group={this.props.group}
					game={this.props.game}
					openPopup={this.props.openPopup}
					closePopup={this.props.closePopup}
					showMinigameCompletedPopup={this.showMinigameCompletedPopup}
				/>
			);
		}
	}
}

MinigameController.propTypes = {
	isTeacher: PropTypes.bool.isRequired,
	sessionStatus: PropTypes.string.isRequired,
	page: PropTypes.object.isRequired,
	minigame: PropTypes.object,
	game: PropTypes.object.isRequired,
	group: PropTypes.object,
	groups: PropTypes.array,
	updateGame: PropTypes.func,
	updateGroup: PropTypes.func,
	submitMinigamePart: PropTypes.func,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	minigameNumber: PropTypes.number
};

export default MinigameController;