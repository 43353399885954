import React from 'react';
import PropTypes from 'prop-types';
import GroupBadge from 'components/group-badge/group-badge';
import {useDrag} from 'react-dnd';

const EvaluationDnDItem = (props) => {
	let {
		squareIndex,
		isPlaced,
		isDraggable,
		showHoverEffect,
		group,
		findGroup,
		setHoverGroupId
	} = props;


	const [{ canDrag, isDragging }, drag] = useDrag({
		item: {type: 'groupId', groupId: group.id, originalSquareIndex: findGroup(group.id)},
		canDrag() {return (isDraggable);},
		isDragging(monitor) {return (group.id === monitor.getItem().groupId);},
		collect: (monitor) => {return {canDrag: monitor.canDrag(), isDragging: monitor.isDragging()};}
	});
	
	const opacity = (isDragging || (isPlaced && squareIndex === -1) ? 0.4 : 1);
	let classRootName = 'EvaluationGrid-group';
	if (squareIndex < 0) classRootName = 'EvaluationGroups-group';
	let className = classRootName +
		(canDrag ? ' ' + classRootName + '--draggable' : '') + 
		(isDragging ? ' ' + classRootName + '--dragging' : '') +
		(showHoverEffect ? ' ' + classRootName + '--hover' : '') +
		(isPlaced && squareIndex === -1 ? ' ' + classRootName + '--isPlaced' : '');

	return (
		<div 
			ref={(node) => {return drag(node);}} 
			className={className} 
			style={{opacity}}
			onMouseEnter={() => {if (setHoverGroupId) setHoverGroupId(group.id);}} 
			onMouseLeave={() => {if (setHoverGroupId) setHoverGroupId(null);}} 
		>
			<GroupBadge group={group} showName={squareIndex === -1} page="evaluation" />
		</div>
	);

};

EvaluationDnDItem.propTypes = {
	squareIndex: PropTypes.number.isRequired,
	isPlaced: PropTypes.bool.isRequired,
	isDraggable: PropTypes.bool.isRequired,
	showHoverEffect: PropTypes.bool.isRequired,
	group: PropTypes.object.isRequired,
	findGroup: PropTypes.func.isRequired,
	setHoverGroupId: PropTypes.func
};

export default EvaluationDnDItem;