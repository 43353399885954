import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Overview from './overview';
import {popupsData} from 'data/popups-game-data';

/* 
hollow cyan: ongoing
full cyan: finished (as long as the user is still in that story/session)
full dark: done
hollow dark: not done / waiting
*/

class OverviewController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			storyIndex: this.props.storyIndex
		};
		this.handleSelectStory = this.handleSelectStory.bind(this);
		this.handleOpenIntroPopup = this.handleOpenIntroPopup.bind(this);
	};


	/**
	 * Select game
	 * @param {string} gameId 
	 */
	handleSelectStory(storyIndex) {
		this.setState({storyIndex});
	}

	handleOpenIntroPopup() {
		const popupData = JSON.parse(JSON.stringify(popupsData.introduction));
		this.props.openPopup(popupData, null, 'intro');
	}

	/**
	 * Render settings component
	 */
	render() {
		return (
			<Overview
				isTeacher={this.props.isTeacher}
				storyIndex={this.state.storyIndex}
				game={this.props.game}
				stories={this.props.stories}
				handleSelectStory={this.handleSelectStory}
				goToSession={this.props.goToSession}
				handleOpenIntroPopup={this.handleOpenIntroPopup}
			/>
		);
	}
}

OverviewController.defaultProps = {
	storyIndex: 0
};


OverviewController.propTypes = {
	isTeacher: PropTypes.bool.isRequired,
	storyIndex: PropTypes.number,
	game: PropTypes.object.isRequired,
	stories: PropTypes.array.isRequired,
	goToSession: PropTypes.func.isRequired,
	openPopup: PropTypes.func.isRequired
};

export default OverviewController;
