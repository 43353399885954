import React from 'react';
import PropTypes from 'prop-types';
import CameraBase from './camera-base';
import printContent from '../../helpers/print-helper';
import {checkIfFullscreen, toggleFullscreen} from 'helpers/fullscreen-helper';
import {baseData} from '../../data/base-data';
import './camera-popup.scss';

const CameraPopup = ({cameraData, showCamIndex, elements, page}) => {
	let cameraLocation = baseData.find((mission) => {
		return mission.id === 'mission-' + page.storyIndex + '-' + (page.missionIndex + 1);
	}).tagId;
	return (
		<div
			className={'CameraPopup CameraPopup--' + cameraData.id
			+ ' CameraPopup--' + cameraLocation}
			onClick={() => {showCamIndex(null);}}>
			<div className="CameraPopup-frame">
				{cameraData.id === 'cam1' && cameraData.type !== 'waveSimulator'
					? <CameraBase
						type="CameraPopup"
						elements={elements}
						missionId={'mission-' + page.storyIndex + '-' + (page.missionIndex + 1)}
					/>
					: <div className="CameraPopup-image" style={cameraData.style} />}
				{cameraData.overlay && <div className="CameraPopup-overlay"/>}
				<div className="CameraPopup-info1">
					{cameraData.title}<span>{cameraData.source}</span>
				</div>
				<div className="CameraPopup-info2">
					{cameraData.name}<span>{cameraData.status}</span>
				</div>
				{cameraData.type === 'morseCode' && <React.Fragment>
					<div className="CameraPopup-printIcon"
						onClick={(event) => {
							event.stopPropagation();
							if (checkIfFullscreen()) {
								toggleFullscreen();
								setTimeout(() => {
									printContent(
										'<img class="CameraPopup-printImage" alt="morse" src="'
											+ require('../../assets/images/morse-code-print.svg') + '"/>',
										'Print',
										'MorseCode'
									);
								}, 1500);
							} else {
								printContent(
									'<img class="CameraPopup-printImage" alt="morse" src="'
										+ require('../../assets/images/morse-code-print.svg') + '"/>',
									'Print',
									'MorseCode'
								);
							}
						}}
					/>
				</React.Fragment> }
			</div>
		</div>
	);
};

CameraPopup.propTypes = {
	cameraData: PropTypes.object.isRequired,
	showCamIndex: PropTypes.func.isRequired,
	elements: PropTypes.array,
	page: PropTypes.object
};


export default CameraPopup;
