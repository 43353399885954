import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './button.scss';

class Button extends Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.handleTouchStart = this.handleTouchStart.bind(this);
	};

	handleClick(event) {
		if (this.props.disabled || this.props.hidden) event.preventDefault();
		else if (this.props.onClick) {
			event.persist();
			this.props.onClick(event);
		}
	}

	handleTouchStart(event) {
		if (this.props.disabled || this.props.hidden) event.preventDefault();
		else if (this.props.onClick) {
			event.persist();
			this.props.onClick(event);
		}
	}
		
	
	render() {
		let className = 'Button';
		if (this.props.class) {className += ' Button--' + this.props.class;}
		if (this.props.isLoading) {className += ' Button--loading';}
		if (this.props.disabled) {className += ' Button--disabled';}
		if (this.props.hidden) {className += ' Button--hidden';}

		if (this.props.type === 'a' && this.props.href) {
			return (
				<a className={className} target={this.props.target} rel='noopener noreferrer' href={this.props.href}>
					{this.props.text}
				</a>
			);
		}

		if (this.props.onClick) {
			return <div className={className} onClick={this.handleClick}>{this.props.text}</div>;
		}

		return null;
		
	}
}

Button.defaultProps = {
	disabled: false,
	hidden: false,
	isLoading: false,
	class: null,
	type: 'button',
	target: '_self'
};

Button.propTypes = {
	isLoading: PropTypes.bool,
	disabled: PropTypes.bool,
	hidden: PropTypes.bool,
	text: PropTypes.string.isRequired,
	class: PropTypes.string,
	type: PropTypes.string,
	href: PropTypes.string,
	onClick: PropTypes.func,
	target: PropTypes.string
};


export default Button;