import React from 'react';
import PropTypes from 'prop-types';
import BackupController from 'components/admin/backup/backup-controller';
import StoriesController from 'components/admin/stories/stories-controller';
import PagesController from 'components/admin/pages/pages-controller';
import MinigamesController from 'components/admin/minigames/minigames-controller';
import InvestigationsController from 'components/admin/investigations/investigations-controller';
import CyoaGamesController from 'components/admin/cyoa-games/cyoa-games-controller';
import WaveSimulatorsController from './wave-simulators/wave-simulators-controller';
import StatsController from 'components/admin/stats/stats-controller';
import config from 'config/app.config';
import './admin.scss';


const Admin = ({isAuthorized, userTokenId, page, goToPage, handleLogout}) => {
	if (!isAuthorized) {
		return (
			<div className="Admin">Du har ikke adgang til denne side.</div>
		);
	}

	switch (page) {
	case 'backup':
		return <BackupController goToPage={goToPage} userTokenId={userTokenId} />;
	case 'stories':
		return <StoriesController goToPage={goToPage} />;
	case 'pages':
		return <PagesController goToPage={goToPage} />;
	case 'minigames':
		return <MinigamesController goToPage={goToPage} />;
	case 'investigations':
		return <InvestigationsController goToPage={goToPage} />;
	case 'cyoa-games':
		return <CyoaGamesController goToPage={goToPage} />;
	case 'wave-simulators':
		return <WaveSimulatorsController goToPage={goToPage} />;
	case 'stats':
		return <StatsController goToPage={goToPage} />;
	default:
		let server = 'test';
		if (config.env === 'demo') server = 'demo';
		if (config.env === 'production') server = 'produktion';
		return (
			<div className="Admin">
				<h2>Velkommen til kontrolpanelet!</h2>
				<p>Du er logget ind på {server}-serveren!</p>

				{/* Link to backup */}
				<p className="Admin-link" onClick={() => {goToPage('backup');}}>Backup data</p>
				
				{/* Edit content (only on test server) */}
				{server === 'test' && <React.Fragment>
					<p>&nbsp;</p>
					<p className="Admin-link" 
						onClick={() => {goToPage('stories');}}>Rediger historier / missioner / spilgange</p>
					<p className="Admin-link" onClick={() => {goToPage('pages');}}>Rediger sider</p>
					<p className="Admin-link" onClick={() => {goToPage('minigames');}}>Rediger minispil / info-minispil</p>
					<p className="Admin-link" onClick={() => {goToPage('investigations');}}>Rediger investigations</p>
					<p className="Admin-link" onClick={() => {goToPage('cyoa-games');}}>Rediger CYOA spil</p>
					<p className="Admin-link" onClick={() => {goToPage('wave-simulators');}}>Rediger Bølgesimulator spil</p>
					<p className="Admin-link" onClick={() => {goToPage('stats');}}>Se Statistik</p>
				</React.Fragment>}

				{/* Back to game or log out */}
				<p>&nbsp;</p>
				<p className="Admin-link" onClick={() => {window.location.href = '../';}}>Tilbage til spillet</p>
				<p className="Admin-link" onClick={() => {handleLogout();}}>Log ud</p>
			</div>
		);
	}
};

Admin.propTypes = {
	isAuthorized: PropTypes.bool.isRequired,
	userTokenId: PropTypes.string.isRequired,
	page: PropTypes.string,
	goToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default Admin;