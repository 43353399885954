import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {basicPageTables, basicPageAudios} from 'data/basic-page-data';
import MissionControlMessage from 'components/layout/mission-control-message';
import Audio from 'components/audio/audio';
import './basic-page.scss';

const BasicPage = ({page}) => {
	return (
		<div className={'BasicPage BasicPage--' + page.id}>
			{/* Page title */}
			<div className="BasicPage-title">{page.title}</div>

			{/* Page story text (optional) */}
			{page.storyText && <MissionControlMessage message={page.storyText} type={page.storyType} />}

			{/* Page audio file (optional) - before text */}
			{Object.keys(basicPageAudios).includes(page.id) && basicPageAudios[page.id].map((audio, i) => {
				if (audio.location === 'after-text') return null;
				return <div key={i}>
					{audio.label && <div>{renderMarkdown(audio.label)}</div>}
					<Audio audioFile={audio.filename} audioType={audio.audioType}/>
				</div>;
			})}

			{/* Page text */}
			<div className="BasicPage-text">
				{renderMarkdown(page.text ? page.text : '')}
			</div>

			{/* Page audio file (optional) - after text */}
			{Object.keys(basicPageAudios).includes(page.id) && basicPageAudios[page.id].map((audio, i) => {
				if (audio.location === 'before-text') return null;
				return <React.Fragment key={i}>
					{audio.label && <div>{renderMarkdown(audio.label)}</div>}
					<Audio audioFile={audio.filename} audioType={audio.audioType}/>
				</React.Fragment>;
			})}

			{/* Page table */}
			{Object.keys(basicPageTables).includes(page.id)
			&& <div className={'BasicPage-tables BasicPage-tables--' + page.id}>
				{basicPageTables[page.id].map((table) => {
					return <div key={table.id}>
						{table.title}
						<table className="BasicPage-table" cellSpacing="0" cellPadding="0">
							<thead><tr>
								{table.header.map((item, index) => {return <th key={index}>{item}</th>;})}
							</tr></thead>
							<tbody>
								{table.content.map((row, rowIndex) => {
									return (
										<tr key={rowIndex}>
											{row.map((column, columnIndex) => {
												/* Special case for equation inside table on this page */
												if (page.id === 'page-2-2-1-energyConsumption' &&
													table.id === 'table-2' &&
													columnIndex === row.length - 1
												) {
													return <td key={columnIndex} className="equation">
														<span className="paranthesis left">(</span>
														<div className="fraction">
															<span className="top">{column[0]}</span>
															<span className="symbol">/</span>
															<span className="bottom">kg</span>
														</div>
														<span>* m + {column[1]}</span>
														<span className="paranthesis right">)</span>
														<div className="fraction">
															<span className="top">MJ</span>
															<span className="symbol">/</span>
															<span className="bottom">d</span>
														</div>
													</td>;
												}
												return <td key={columnIndex}>{column}</td>;
											})}
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>;
				})}
			</div>}
		</div>
	);
};


BasicPage.propTypes = {
	page: PropTypes.object.isRequired
};

export default BasicPage;
