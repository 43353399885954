/**
 * An investigation can have multiple parts, and some investigations use only the first hypothesis part
 *		- hypothesis: students write hypothesis based on clues and save them and upload them to the teacher screen
 *		- experiment: teacher selects some of the hypothesis, and students make experiments based on them
 * Clues types:
 *  	- image: contains filename, imageCaption (optional) and title (optional)
 * 		- text: contains the text and textHasWrapper (optional, applies striped background to the text and fancy scroll)
 * 		- sound: contains title and soundFiles. soundFiles contains the title and the filename for each sound file
 */


const investigationsData = [
	{
		id: 'investigation-1',
		title: 'investigation 1',
		parts: ['hypothesis', 'experiment'],
		instructions: {
			hypothesis: {
				hasTeacherInstructions: false,
				hasGroupInstructions: true,
			},
			experiment: {
				hasTeacherInstructions: false,
				hasGroupInstructions: true,
			}
		},
		clues: {
			hypothesis: [
				{type: 'image', filename: 'clue-1.jpg'},
				{type: 'image',filename: 'clue-2.jpg'},
				{type: 'image', filename: 'clue-3.jpg'},
			],
			experiment: null
		},
		steps: [
			{
				id: 'hypothesis1',
				part: 'hypothesis',
				type: 'write',
				startingPoint: 'clues',
			}, 
			{
				id: 'hypothesis2',
				part: 'hypothesis',
				type: 'review',
				startingPoint: 'clues',
			},
			{
				id: 'experiment1',
				part: 'experiment',
				type: 'write',
				startingPoint: 'selectedGroups',
			}
		]
	},
	{
		id: 'investigation-2',
		title: 'investigation 2',
		parts: ['hypothesis', 'experiment'],
		instructions: {
			hypothesis: {
				hasTeacherInstructions: false,
				hasGroupInstructions: true,
			},
			experiment: {
				hasTeacherInstructions: false,
				hasGroupInstructions: true,
			}
		},
		clues: {
			hypothesis: [
				{
					type: 'text',
					textHasWrapper: true,
					text: [
						'<p>Autotransskription af lydfil fra kommandorum — sol 451.</p>',
						'<p>P1: Skynd dig, stormen rammer lige om lidt. Vi skal have de der folk ind.</p>',
						'<p>P2: Radioen er nede og de kigger ikke på lysalarmen.</p>',
						'<p>...</p>',
						'<p>P1: Ok, jeg har en idé! Har du kontrol over slusedørene?</p>',
						'<p>P2: Ja, de ser ud til at virke.</p>',
						'<p>P1: Fint nok! Åben de to ydre slusedøre. Lad alarmen i sluserne køre og åben yderdørene. De burde kunne høre lyden selv med Mars’ lydreduktion.</p>',
						'<p>...</p>',
						'<p>P2: Ok, den er tændt. Kan du høre den?</p>',
						'<p>P1: Ja, den er ret tydelig i mikrofonerne i sluserne. Det lyder så højt som en ambulance...</p>',
						'<p>P2: Men hvorfor reagerer de ikke? De er jo kun 10 meter væk?</p>',
						'<p>P1: Måske på grund af rumdragterne?</p>',
						'<p>P2: Man plejer godt at kunne høre alarmen gennem hjelmen.</p>',
						'<p>P1: Hvad med interferensmønsteret. Måske står de i et knudepunkt?</p>',
						'<p>P2: JEG VED DET IKKE!??!</p>',
						'<p>...</p>',
						'<p>P2: Shit, nu kommer den. Hey! Løb væk. LØB VÆK. STORMEN KOMMER!!!</p>',
						'<p>P1: De kan ikke høre dig.</p>',
					]
				},
				{
					type: 'image',
					filename: 'clue-2.svg',
					title: 'VIDEO CAPTURE'
				},
				{
					type: 'image',
					filename: 'clue-3.svg',
					title: 'RAPPORTERINGSSKEMA FOR TEST AF INDERDØRS LYDALARMER'
				},
				{
					type: 'text',
					textHasWrapper: true,
					text: [
						'<p>Autotranskription af lydfil fra lagerum 2</p>',
						'<p>P1: Kan du høre den der lyd?</p>',
						'<p>P2: Ja, det lyder som noget der brummer inde ved siden af.</p>',
						'<p>P1: Det er ret regelmæssigt, det minder mig lidt om det rytme alarmen har.</p>',
						'<p>P2: Lidt ja, men det kan ikke være alarmen, den lyder helt anderledes.</p>',
						'<p>P1: Måske er det alarmen, men den bare lyder anderledes fordi lyden bliver transmitteret af væggen?</p>',
						'<p>P2: Sludder, lyd løber gennem luft og bliver bremset af vægge, hvis det var alarmen ville den lyde som alarmen bare lavere.</p>',
						'<p>P1: Jeg troede lyse toner kom nemmere gennem vægge fordi de er højere?</p>',
						'<p>P2: Jeg er temmelig sikker på at lyds evne til at trænge gennem materialer kun har noget at gøre med styrken.</p>',
						'<p>P1: Ja ok, du har sikkert ret. Lad os se at få færdiggjort optællingen her.</p>'
					]
				},
				{
					type: 'image',
					filename: 'clue-5.svg'
				},
				{
					type: 'audio',
					title: 'LYDOPTAGELSER',
					soundFiles: [
						{
							title: 'Lydfil fra køkkenet',
							filename: 'sound-from-kitchen.mp3'
						},
						{
							title: 'Lydfil fra kontoret',
							filename: 'sound-from-office.mp3'
						},
						{
							title: 'Lydfil fra soveværelset',
							filename: 'sound-from-bedroom.mp3'
						}
					]
				},
				{
					type: 'image',
					filename: 'clue-7.png'
				},
			],
			experiment: null
		},
		steps: [
			{
				id: 'hypothesis1',
				part: 'hypothesis',
				type: 'write',
				startingPoint: 'clues',
			}, 
			{
				id: 'hypothesis2',
				part: 'hypothesis',
				type: 'review',
				startingPoint: 'clues',
			},
			{
				id: 'experiment1',
				part: 'experiment',
				type: 'write',
				startingPoint: 'selectedGroups',
			}
		]
	},
	{
		id: 'investigation-3',
		title: 'investigation 3',
		parts: ['hypothesis'],
		instructions: {
			hypothesis: {
				hasTeacherInstructions: false,
				hasGroupInstructions: true,
			},
			experiment: {
				hasTeacherInstructions: false,
				hasGroupInstructions: false,
			}
		},
		clues: {
			hypothesis: [
				{
					type: 'image',
					filename: 'clue-1.svg',
					title: null
				},
			],
			experiment: null
		},
		steps: [
			{
				id: 'hypothesis1',
				part: 'hypothesis',
				type: 'write',
				startingPoint: 'clues',
			}
		]
	},
	{
		id: 'investigation-4',
		title: 'investigation 4',
		parts: ['hypothesis'],
		instructions: {
			hypothesis: {
				hasTeacherInstructions: false,
				hasGroupInstructions: true,
			},
			experiment: {
				hasTeacherInstructions: false,
				hasGroupInstructions: false,
			}
		},
		clues: {
			hypothesis: [
				{
					type: 'text',
					title: 'Afstemning',
					text: 'Hvad er den bedste løsning når vi skal skaffe mad til marsbasen?'
				},
			],
			experiment: null
		},
		steps: [
			{
				id: 'hypothesis1',
				part: 'hypothesis',
				type: 'write',
				startingPoint: 'clues',
			}
		]
	},
	{
		id: 'investigation-5',
		title: 'investigation 5',
		parts: ['hypothesis', 'experiment'],
		instructions: {
			hypothesis: {
				hasTeacherInstructions: false,
				hasGroupInstructions: true,
			},
			experiment: {
				hasTeacherInstructions: false,
				hasGroupInstructions: true,
			}
		},
		clues: {
			hypothesis: [
				{
					type: 'audio',
					title: 'LYDOPTAGELSER',
					soundFiles: [
						{
							title: 'Interview med beboer',
							filename: 'eksplosionen-interview.mp3'
						}
					]
				},
				{
					type: 'image',
					filename: 'clue-2.svg'
				},
				{
					type: 'text',
					textHasWrapper: true,
					text: [
						'Operations indstilling til 60+/-0.2 rpm',
						'Max tilladt vibration til 1.4 cm',
						`<table>
							<tr>
								<th>Time</th>
								<th>Rotor Velocity</th>
								<th>Rotor Vibrations</th>
							</tr>
							<tr>
								<td>kl 11:54:10</td>
								<td>60.04 rpm</td>
								<td>0.113 cm</td>
							</tr>
							<tr>
								<td>kl 11:54:15</td>
								<td>60.02 rpm</td>
								<td>0.113 cm</td>
							</tr>
							<tr>
								<td>kl 11:54:20</td>
								<td>59.98 rpm</td>
								<td>0.113 cm</td>
							</tr>
							<tr>
								<td>kl 11:54:25</td>
								<td>59.96 rpm</td>
								<td>0.113 cm</td>
							</tr>
							<tr>
								<td>kl 11:54:30</td>
								<td>60.03 rpm</td>
								<td>0.113 cm</td>
							</tr>
							<tr>
								<td>kl 11:54:35</td>
								<td>60.04 rpm</td>
								<td>0.113 cm</td>
							</tr>
							<tr>
								<td>kl 11:54:40</td>
								<td>60.01 rpm</td>
								<td>0.113 cm</td>
							</tr>
							<tr>
								<td>kl 11:54:45</td>
								<td>59.99 rpm</td>
								<td>0.113 cm</td>
							</tr>
							<tr>
								<td>kl 11:54:50</td>
								<td>59.97 rpm</td>
								<td>0.113 cm</td>
							</tr>
							<tr>
								<td>kl 11:54:55</td>
								<td>ERROR</td>
								<td>ERROR</td>
							</tr>
							<tr>
								<td>kl 11:55:00</td>
								<td>ERROR</td>
								<td>ERROR</td>
							</tr>
						</table>`

					]
				}
			],
			experiment: null
		},
		steps: [
			{
				id: 'hypothesis1',
				part: 'hypothesis',
				type: 'write',
				startingPoint: 'clues',
			}, 
			{
				id: 'hypothesis2',
				part: 'hypothesis',
				type: 'review',
				startingPoint: 'clues',
			},
			{
				id: 'experiment1',
				part: 'experiment',
				type: 'write',
				startingPoint: 'selectedGroups',
			}
		]
	},
	{
		id: 'investigation-6',
		title: 'investigation 6',
		parts: ['hypothesis', 'experiment'],
		instructions: {
			hypothesis: {
				hasTeacherInstructions: false,
				hasGroupInstructions: true,
			},
			experiment: {
				hasTeacherInstructions: false,
				hasGroupInstructions: true,
			}
		},
		clues: {
			hypothesis: [
				{
					type: 'image',
					filename: 'clue-1.svg'
				},
				{
					type: 'text',
					textHasWrapper: true,
					text: [
						`<table>
							<tr>
								<th>Gas</th>
								<th>Lydens hastighed ved stuetemperatur (m/s)</th>
							</tr>
							<tr>
								<td>Argon (Ar)</td>
								<td>319</td>
							</tr>
							<tr>
								<td>Helium (He)</td>
								<td>1007</td>
							</tr>
							<tr>
								<td>Krypton (Kr)</td>
								<td>221</td>
							</tr>
							<tr>
								<td>Xenon (Xe)</td>
								<td>178</td>
							</tr>
							<tr>
								<td>Hydrogen (H)</td>
								<td>1270</td>
							</tr>
							<tr>
								<td>Nitrogen (N)</td>
								<td>349</td>
							</tr>
							<tr>
								<td>Oxygen (O)</td>
								<td>326</td>
							</tr>
							<tr>
								<td>Carbon Dioxide (CO<sub>2</sub>)</td>
								<td>267</td>
							</tr>
							<tr>
								<td>Sulfur Dioxide (SO<sub>2</sub>)</td>
								<td>201</td>
							</tr>
							<tr>
								<td>Ethylene (C<sub>2</sub>H<sub>4</sub>)</td>
								<td>327</td>
							</tr>
							<tr>
								<td>Methane (CH<sub>4</sub>)</td>
								<td>446</td>
							</tr>
							<tr>
								<td>Propane (C<sub>3</sub>H<sub>8</sub>)</td>
								<td>258</td>
							</tr>
						</table>`
					]
				},
				{
					type: 'text',
					textHasWrapper: true,
					text: [
						`<table>
							<tr>
								<th>Jordens Atmosfære</th>
							</tr>
							<tr>
								<td>Gennemsnitligt tryk: 101325 Pa</td>
							</tr>
							<tr>
								<td>Nitrogen (N<sub>2</sub>) 78.05%</td>
							</tr>
							<tr>
								<td>Oxygen (O<sub>2</sub>) 20.95%</td>
							</tr>
							<tr>
								<td>Argon (Ar) 0.93%</td>
							</tr>
							<tr>
								<td>Carbon dioxide (CO<sub>2</sub>) 0.0414%</td>
							</tr>
							<tr>
								<td>Gennemsnitlig temperatur: 14°C</td>
							</tr>
						</table>`,
						`<table>
						<tr>
							<th>Mars Atmosfære</th>
						</tr>
						<tr>
							<td>Gennemsnitligt tryk: 610 Pa</td>
						</tr>
						<tr>
							<td>Carbon dioxide (CO<sub>2</sub>) 95.32%</td>
						</tr>
						<tr>
							<td>Nitrogen (N<sub>2</sub>) 2.6%</td>
						</tr>
						<tr>
							<td>Argon (Ar) 1.9%</td>
						</tr>
						<tr>
							<td>Oxygen (O<sub>2</sub>) 0.174%</td>
						</tr>
						<tr>
							<td>Gennemsnitlig temperatur: -61°C</td>
						</tr>
					</table>`
					]
				}
			],
			experiment: null
		},
		steps: [
			{
				id: 'hypothesis1',
				part: 'hypothesis',
				type: 'write',
				startingPoint: 'clues',
			}, 
			{
				id: 'hypothesis2',
				part: 'hypothesis',
				type: 'review',
				startingPoint: 'clues',
			},
			{
				id: 'experiment1',
				part: 'experiment',
				type: 'write',
				startingPoint: 'selectedGroups',
			}
		]
	},
	{
		id: 'investigation-7',
		title: 'investigation 7',
		parts: ['hypothesis', 'experiment'],
		instructions: {
			hypothesis: {
				hasTeacherInstructions: false,
				hasGroupInstructions: true,
			},
			experiment: {
				hasTeacherInstructions: false,
				hasGroupInstructions: true,
			}
		},
		clues: {
			hypothesis: [
				{
					type: 'image',
					filename: 'clue-1.svg',
					imageCaption: 'AM står for amplitude modulation, som betyder amplitude ændring. I en AM radio bruger man radiobølger til at sende information, og den mest normale information er i form af lyd. Der er to signaler som tilsammen giver AM signalet; et informationssignal og et bæresignal. Informationssignalet er det man gerne vil sende - fx en lydbølge med en bestemt tone. Bæresignalet gør at man kan finde signalet med sin radio. Konkret indstiller man radioen til kun at “lytte” til et bæresignal med en bestemt bølgelængde. Denne bærebølges amplitude moduleres af radiostationen efter hvad for et informations signal de gerne vil sende, og radioen oversætter denne modulering til lyd som den afspiller i dine højtalere.'
				},
			],
			experiment: null
		},
		steps: [
			{
				id: 'hypothesis1',
				part: 'hypothesis',
				type: 'write',
				startingPoint: 'clues',
			}, 
			{
				id: 'hypothesis2',
				part: 'hypothesis',
				type: 'review',
				startingPoint: 'clues',
			},
			{
				id: 'experiment1',
				part: 'experiment',
				type: 'write',
				startingPoint: 'selectedGroups',
			}
		]
	},
	{
		id: 'investigation-8',
		title: 'investigation 8',
		parts: ['hypothesis', 'experiment'],
		instructions: {
			hypothesis: {
				hasTeacherInstructions: false,
				hasGroupInstructions: true,
			},
			experiment: {
				hasTeacherInstructions: false,
				hasGroupInstructions: true,
			}
		},
		clues: {
			hypothesis: [
				{
					type: 'image',
					filename: 'clue-1.svg',
					imageCaption: 'Her kan i se det spektrum vi har optaget med gammateleskopet. Den har 3 grafer: en viser gammastrålingsintensiteten for hver bølgelængde af strålingen, en viser den totale styrke af gammastrålingen for alle bølgelængder på samme tid som funktion af tid, og den sidste graf viser intensiteten som funktion af tid for alle bølgelængder. Lysere farve betyder kraftigere gammastråling ved den givne bælgelængde på et givent tidspunkt.'
				},
				{
					type: 'image',
					filename: 'clue-2.svg',
					imageCaption: 'Her er nogle referencer af gammastråling som bliver udsendt fra forskellige grundstoffer. Vi bruger dette i gammaspektroskopi til at finde ud af hvilke grundstoffer der er i en jordprøve.'
				},
				{
					type: 'image',
					filename: 'clue-3.svg',
					imageCaption: 'Her er den totale gammastråling per tid der er blevet observeret i gammaglimt fra rummet (også kaldt GRB, Gamma ray bursts.) Disse bliver sendt ud når for eksempel neutronstjerner kolkiderer i det ydre rum. Alle gammaglimt er unikke, men disse eksempler på GRB’er kan bruges til at vurdere om det er et gammaglimt vi ser.'
				},
				{
					type: 'image',
					filename: 'clue-4.svg',
					imageCaption: 'Her er et idealiseret teoretisk spektrum for en pulsar - altså en død supertung stjerne som udsender en jet af stråling fra dens magnetiske poler. Stjernen roterer hurtigt om sin egen akse og den jets fejer gennem universet som et enormt fyrtårn. Her er også et eksempel på en pulsars intensitetsplot set fra jorden. Hver peak i intensitet er når pulsarens jet peger mod jorden, og bølgedalene er når den peger væk fra os. Pulsarer drejer generelt hurtigere end dette, men der findes også nogle der kun drejer om sig selv cirka en gang i sekundet.'
				},
			],
			experiment: null
		},
		steps: [
			{
				id: 'hypothesis1',
				part: 'hypothesis',
				type: 'write',
				startingPoint: 'clues',
			}, 
			{
				id: 'hypothesis2',
				part: 'hypothesis',
				type: 'review',
				startingPoint: 'clues',
			},
			{
				id: 'experiment1',
				part: 'experiment',
				type: 'write',
				startingPoint: 'selectedGroups',
			}
		]
	},
	{
		id: 'investigation-9',
		title: 'investigation 9',
		parts: ['hypothesis'],
		instructions: {
			hypothesis: {
				hasTeacherInstructions: true,
				hasGroupInstructions: true,
			},
			experiment: {
				hasTeacherInstructions: false,
				hasGroupInstructions: false,
			}
		},
		clues: {
			hypothesis: [
				{
					type: 'image',
					filename: 'clue-1.svg',
					title: 'VIDEO RECORDING',
					imageCaption: '[SCREEN CAPTURE]',
				},
				{
					type: 'image',
					filename: 'clue-2.svg',
					imageCaption: `
						Tid T = 0 svarer til klokkeslettet 13:42:34, som er nedslagspunktet.
						Den seismiske bølge fra nedslaget har to komponenter, en transvers bølge gennem overfladen og en longitudinalbølge der spreder sig gennem overfladen og ned i jorden. Transverse bølger der udbreder sig i overfladen af Mars i området hvor basen ligger er blevet målt til at bevæge sig med 2300 m/s.
					`
				},
				{
					type: 'image',
					filename: 'clue-3.svg',
				}
			],
			experiment: null
		},
		steps: [
			{
				id: 'hypothesis1',
				part: 'hypothesis',
				type: 'write',
				startingPoint: 'clues',
			}
		]
	},
	{
		id: 'investigation-10',
		title: 'investigation 10',
		parts: ['hypothesis', 'experiment'],
		instructions: {
			hypothesis: {
				hasTeacherInstructions: true,
				hasGroupInstructions: true,
			},
			experiment: {
				hasTeacherInstructions: true,
				hasGroupInstructions: true,
			}
		},
		clues: {
			hypothesis: [
				{
					type: 'audio',
					title: 'LYDOPTAGELSER',
					soundFiles: [
						{
							title: 'Log-uddrag',
							filename: 'heat-exchanger-log.mp3'
						}
					]
				},
				{
					type: 'image',
					filename: 'clue-2.svg',
				},
				{
					type: 'text',
					textHasWrapper: true,
					text: [
						'<p>Strøm Genereret = 0 kW</p>',
						'<p>Hastighed af Primær loop pumpe = 1 l/s</p>',
						'<p>Hastighed af Sekundært loop pumpe = 10 l/s</p>'
					]
				},
				{
					type: 'image',
					filename: 'clue-4.svg',
				},
			],
			experiment: null
		},
		steps: [
			{
				id: 'hypothesis1',
				part: 'hypothesis',
				type: 'write',
				startingPoint: 'clues',
			}, 
			{
				id: 'hypothesis2',
				part: 'hypothesis',
				type: 'review',
				startingPoint: 'clues',
			},
			{
				id: 'experiment1',
				part: 'experiment',
				type: 'write',
				startingPoint: 'selectedGroups',
			}
		]
	},
	{
		id: 'investigation-11',
		title: 'investigation 11',
		parts: ['hypothesis'],
		instructions: {
			hypothesis: {
				hasTeacherInstructions: true,
				hasGroupInstructions: true,
			},
			experiment: {
				hasTeacherInstructions: false,
				hasGroupInstructions: false,
			}
		},
		clues: {
			hypothesis: [
				{
					type: 'text',
					textHasWrapper: true,
					text: [
						`
						Differentierede meteorer
						Differentierede meteorer kommer fra asteroider der er blevet opvarmet af henfaldet af radioaktive isotoper inde i dem indtil de smeltede. Mens de var smeltede har det tunge jern over tusinder af år samlet sig i midten, mens af de lettere grundstoffer er blevet på overfladen. Her har de lavet en stenskal omkring meteorens jernkerne, ligesom planeter.
						Når en sådan asteroide bliver ramt af en anden asteroide kan dette skabe tre typer meteorer: Jernmeteorer fra kernen, achondriter eller stenmeteorer fra skallen og pallasitter fra området mellem stenskallen og jernkernen.
						`
					]
				},
				{
					type: 'text',
					textHasWrapper: true,
					text: [
						`
						Jernmeteorer fra kernen af asteroiden som indeholder:
						Mest Kamacite som er en nikkel-jern-krystal (Fe og Ni), men også phosphor (P) og oxygen (O).
						`
					]
				},
				{
					type: 'text',
					textHasWrapper: true,
					text: [
						`
						Achondritter som kommer fra asteroidens stenskal er en blanding af basaltsten og kamacite
						<strong>Basalt</strong> indeholder mest siliciumoxid (SiO<sub>2</sub>), men også titaniumoxid (TiO<sub>2</sub>), jernoxid (FeO), Aluminiumsoxid (Al<sub>2</sub>O<sub>3</sub>), calsiumoxid (CaO) og magnesiumoxide (MgO).
						<strong>Kamacite</strong> består af nikkel (Ni) og jern (Fe).
						`
					]
				},
				{
					type: 'text',
					textHasWrapper: true,
					text: [
						`
						En særlig meteor som kan komme ud af en differentieret asteroide er en pallasit. De bliver dannet i grænsen mellem jernkernen og stenskallen og består af olivin omgivet af kamacite.
						<strong>Olivin</strong> er en krystal af jern (Fe) silicium (Si) og oxygen (O).
						<strong>Kamacite</strong> er en krystal af jern (Fe) og nikkel (Ni).
						Udover dette indeholder pallasitter også phosphor (P) og oxygen (O) som er blandet med kamaciten.
						`
					]
				},
				{
					type: 'text',
					textHasWrapper: true,
					text: [
						`
						Ikke-differentierede asteroider er asteroider der ikke blev varmet nok op til at smelte efter de blev dannet. Dette betyder at de mere eller mindre består af det samme hele vejen igennem. Den mest almindelige type er kondrit type H, H står for højt jern indhold.
						Kondritter består af basaltsten, kamacite og små pletter af olivin.
						<strong>Basalt</strong> indeholder mest siliciumoxid (SiO<sub>2</sub>), men også titaniumoxid (TiO<sub>2</sub>), jernoxid (FeO), aluminiumoxid (Al<sub>2</sub>O<sub>3</sub>), calciumoxid (CaO) og magnesiumoxid (MgO).
						<strong>Kamacite</strong> består af nikkel (Ni) og jern (Fe) og kan være blandet med lidt phosphor (P) og oxygen (O).
						<strong>Olivin</strong> er en krystal af jern (Fe) silicium (Si) og oxygen (O).
						`
					]
				},
				{
					type: 'text',
					textHasWrapper: true,
					text: [
						`
						Det område hvor meteoren styrtede ned er der også en del basaltsten. Varmen fra nedslaget kan have brændt dem på en måde så de er svære at kende fra en meteoritsten. Vi kan derfor være ude for at en eller flere af prøverne er ordinære Mars-basaltsten. 
						Basalt består mest af siliciumoxid (SiO<sub>2</sub>), men også titaniumoxid (TiO<sub>2</sub>), jernoxid (FeO), aluminiumoxid (Al<sub>2</sub>O<sub>3</sub>), calciumoxid (CaO) og magnesiumoxid (MgO).
						`
					]
				},
			],
			experiment: null
		},
		steps: [
			{
				id: 'hypothesis1',
				part: 'hypothesis',
				type: 'write',
				startingPoint: 'clues',
			}
		]
	},
	{
		id: 'investigation-12',
		title: 'investigation 12',
		parts: ['hypothesis'],
		instructions: {
			hypothesis: {
				hasTeacherInstructions: true,
				hasGroupInstructions: true,
			},
			experiment: {
				hasTeacherInstructions: false,
				hasGroupInstructions: false,
			}
		},
		clues: {
			hypothesis: [
				{
					type: 'image',
					filename: 'clue-1.svg',
					imageCaption: [
						'<h2>Jorden og Månen</h2>',
						'Jorden og vores eneste måne, Månen. Dette er det længste et menneske havde rejst i 2020, før mars-missionerne begyndte 10 år senere.'
					]
				},
				{
					type: 'image',
					filename: 'clue-2.svg',
					imageCaption: [
						'<h2>Det Indre Solsystem</h2>',
						'Det indre solsystem indrammet af asteroidebæltet. Vi sender "regelmæssigt" ubemandede sonder ud inden for denne afstand.'
					]
				},
				{
					type: 'image',
					filename: 'clue-3.svg',
					imageCaption: [
						'<h2>Det Ydre Solsystem</h2>',
						'Det ydre solsystem indrammet af det andet asteroidebæltet ved navnet Kuiper-bæltet. Alle solsystemets planeter er inden for dette område.',
						'Alle sonder, som vi har kontakt med, er inden for dette område, med undtagelse af Voyager 1 og 2, som blev sendt op fra jorden i 1977 og forlod solsystemet 2018.'
					]
				},
				{
					type: 'image',
					filename: 'clue-4.svg',
					imageCaption: [
						'<h2>De nærmeste stjerner</h2>',
						'Dette er de stjerner, der er tættest på vores eget solsystem. Den nærmeste nabo er Alpha Centauri, som er et triple stjerne system.'
					]
				},
				{
					type: 'image',
					filename: 'clue-5.svg',
					imageCaption: [
						'<h2>Mælkevejen</h2>',
						'Mælkevejen er vores egen lokale galakse. Alle stjerner vi kan se på nattehimlen er inden for dette område. Der er 400 milliarder stjerner i mælkevejen, så en del flere end der er stjerner på nattehimlen eller sandkorn på jorden.'
					]
				},
				{
					type: 'image',
					filename: 'clue-6.svg',
					imageCaption: [
						'<h2>Den lokale galaksegruppe</h2>',
						'Den lokale galaksegruppe består af vores nærmeste galakser. Der er mellem 30 og 40 galakser i den. De tre største er Andromeda, Mælkevejen og Triangulum.'
					]
				},
				{
					type: 'image',
					filename: 'clue-7.svg',
					imageCaption: [
						'<h2>Laniakea</h2>',
						'Laniakea er vores lokale super galaksehob. Laniakea indeholder et sted mellem 100.000 og 150.000 galakser.'
					]
				},
				{
					type: 'image',
					filename: 'clue-8.svg',
					imageCaption: [
						'<h2>Det observerbare univers</h2>',
						'Dette er universet vi kan måle. Hvis der er mere end dette, er det så langt væk og bevæger sig så hurtigt væk fra os, at lys aldrig vil komme fra det til os. Så i alle henseender er det hele vores univers.'
					]
				}
			]
		},
		steps: [
			{
				id: 'hypothesis1',
				part: 'hypothesis',
				type: 'write',
				startingPoint: 'clues',
			}
		]
	},
	{
		id: 'investigation-13',
		title: 'investigation 13',
		parts: ['hypothesis'],
		instructions: {
			hypothesis: {
				hasTeacherInstructions: true,
				hasGroupInstructions: true,
			},
			experiment: {
				hasTeacherInstructions: false,
				hasGroupInstructions: false,
			}
		},
		clues: {
			hypothesis: [
				{
					type: 'text',
					textHasWrapper: true,
					text: [
						'<h2>Drakes Ligning forsimplet:</h2>',
						'<strong>N = S * fp * fe * fl</strong>',
						'<strong>N:</strong> antal planeter med liv',
						'<strong>S:</strong> antal stjerner',
						'<strong>fp:</strong> andel af planeter per stjerne',
						'<strong>fe:</strong> andel af jordlignende planeter i den beboelige zone',
						'<strong>fl:</strong> andel af jordlignende planeter der har udviklet liv'
					]
				},
				{
					type: 'text',
					textHasWrapper: true,
					text: [
						'Det er anslået, at der er et sted mellem 100 milliarder og 400 milliarder stjerner i mælkevejen, fordelt mellem galaksens kerne og spiralarmene. Af dem er 5% hvad man kalder sollignende stjerner, altså stjerner i samme fase af deres livscyklus og med cirka samme masse.',
						'</br>',
						'Spørgsmålet er, hvor mange planeter der er per stjerne. Det kan være alt mellem 0 og rigtigt mange.',
						'Et meget forsigtigt bud ville være 1 per stjerne. Et andet bud ville være 8 lige som vores solsystem. Det kunne også være imellem disse to, eller meget højere.',
					]
				},
				{
					type: 'image',
					filename: 'clue-3.svg',
					imageCaption: [
						'Der er et bælte rundt om alle stjerner, som vi kalder den beboelige zone. Det er her liv kan opstå mener man. I den beboelige zone er planeten varm nok til, at der kan være flydende vand, men ikke så varm at det koger. Er man tættere på stjernen er planeten alt for varm, og er man længere væk er der alt for koldt. Hvor denne zone ligger et solsystem afhænger af stjernens størrelse.',
						'Det er selvfølgelig heller ikke nok at en planet ligger i den beboelige zone, den skal også være den rigtige type planet. Det går for eksempel ikke, at det er en gasplanet.',
						'I vores solsystem er der 2 ud af 8, det giver en andel på 2/8=0.25, men er vi specielle eller normalen?'
					]
				},
				{
					type: 'text',
					textHasWrapper: true,
					text: [
						'Hvad er chancen for, at liv opstår på en planet, hvor mulighederne eksisterer? Er liv en helt almindelig ting, som vil opstå hvis mulighederne er der? Eller er det uhyre sjældent? Der har eksisteret planeter i universet i ~11 milliarder år, Jorden er kun ~4.5 milliarder år gammel, og der har eksisteret liv på jorden i ~3.5 milliarder år. Så liv har haft rigeligt chance for at blive dannet også tidligere end på jorden. Men vi ved faktisk ikke, hvor sandsynligt det er, det sker. Det eneste vi ved med sikkerhed er, at det kan ske.',
						'Så er et godt tal, at der kan opstå liv 10. planet - altså 1/10 = 0.1 Eller er det hver tusinde - 0.001 - eller måske en ud af en million, altså 0.000001?',
					]
				},
			]
		},
		steps: [
			{
				id: 'hypothesis1',
				part: 'hypothesis',
				type: 'write',
				startingPoint: 'clues',
			}
		]
	},
	{
		id: 'investigation-14',
		title: 'investigation 14',
		parts: ['hypothesis'],
		instructions: {
			hypothesis: {
				hasTeacherInstructions: true,
				hasGroupInstructions: true,
			},
			experiment: {
				hasTeacherInstructions: false,
				hasGroupInstructions: false,
			}
		},
		clues: {
			hypothesis: [
				{
					type: 'image',
					filename: 'clue-1.svg',
				},
				{
					type: 'image',
					filename: 'clue-2.svg',
				},
			]
		},
		steps: [
			{
				id: 'hypothesis1',
				part: 'hypothesis',
				type: 'write',
				startingPoint: 'clues',
			}
		]
	}
];

module.exports = {
	investigationsData
};