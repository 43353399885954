import React from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts, resultUiTexts} from 'data/ui-texts';
import MissionControlMessage from 'components/layout/mission-control-message';
import Button from 'components/button/button';
import GroupBadge from 'components/group-badge/group-badge';
import './result.scss';

const Result = ({isLoading, page, sessions, rankedGroups, handleActivateTagging}) => {
	return (
		<div className="Result">
			<div className="Result-title">{page.title}</div>
			{page.storyText && <MissionControlMessage message={page.storyText} type={page.storyType} />}

			{(sessions.length > 0 && rankedGroups.length > 0)
				? <div className="Result-ranking">
					<div className={'Result-headerRow Result-headerRow--' + sessions.length}>
						{sessions.map((session, index) => {
							return <div key={index}>{resultUiTexts.session} {session.id.split('-').pop()}</div>;
						})}
						{sessions.length > 1 && <div>{resultUiTexts.total}</div>}
					</div>
					{rankedGroups.map((group) => {
						return (
							<div key={group.id} className="Result-groupRow">
								<GroupBadge group={group} showName={false} page="result" />
								{sessions.map((session) => {
									return (
										<div key={session.id} className="Result-groupPoints">
											{group.points.hasOwnProperty(session.id) ? group.points[session.id] : '-'}
										</div>
									);
								})}
								{sessions.length > 1 && 
									<div className="Result-groupPoints Result-groupPoints--total">
										{group.totalPoints}
									</div>}
							</div>
						);
					})}
				</div>
				: <div>{resultUiTexts.sessionNotStarted}</div>
			}

			{handleActivateTagging && 
				<Button 
					class="activateTagging"
					text={generalUiTexts.activateTagging}
					isLoading={isLoading}
					onClick={() => {handleActivateTagging();}}
				/>}
		</div>
	);
};


Result.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	page: PropTypes.object.isRequired,
	sessions: PropTypes.array.isRequired,
	rankedGroups: PropTypes.array.isRequired,
	handleActivateTagging: PropTypes.func
};

export default Result;
