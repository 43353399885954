import React from 'react';
import PropTypes from 'prop-types';
import NavigationPanel from 'components/layout/navigation-panel';
import InstructionsController from 'components/instructions/instructions-controller';
import BasicPage from 'components/basic-page/basic-page';
import MinigameController from 'components/minigames/minigame-controller';
import InvestigationController from 'components/investigation/investigation-controller';
import CyoaGamesController from 'components/cyoa-games/cyoa-games-controller';
import InfoMinigamesController from 'components/info-minigames/info-minigames-controller';
import EvaluationController from 'components/evaluation/evaluation-controller';
import Presentation from 'components/presentation/presentation';
import GroupLoginStatuses from 'components/group-login-statuses/group-login-statuses';
import ResultController from 'components/result/result-controller';
import Base from 'components/base/base';
import './mission-panel.scss';

const MissionPanel = (props) => {
	let {
		isTeacher, 
		page, 
		sessionData, 
		minigames,
		game, 
		groups, 
		group,
		showPage, 
		updateGroup,
		updateGame,
		updateGroups,
		submitMinigamePart,
		openPopup,
		closePopup,
		editEvaluationCallback,
		saveParamName,
		saveEvaluation,
		closeSession,
		activateTagging
	} = props;

	let content = null;
	let minigame = null;
	let minigame2 = null;
	let investigation = null;
	let cyoaGame = null;
	let infoMinigame = null;

	switch (page.type) {
	case 'text':
		content = 
			<BasicPage 
				page={page}
			/>;
		break;
	case 'minigame': 
		if (page.minigameId && minigames.some((minigame) => {return minigame.id === page.minigameId;})) {
			minigame = minigames.filter((minigame) => {return minigame.id === page.minigameId;})[0];
		}
		/** Page EM Bølger in Nedslaget has two minigame puzzles on the same page */
		if (page.minigameId2 && minigames.some((minigame) => {return minigame.id === page.minigameId2;})) {
			minigame2 = minigames.filter((minigame) => {return minigame.id === page.minigameId2;})[0];
		}
		content = <React.Fragment>
			<MinigameController
				isTeacher={isTeacher}
				page={page}
				game={game}
				sessionStatus = {sessionData.status}
				minigame={minigame}
				groups={groups}
				group={group}
				updateGame={updateGame}
				updateGroup={updateGroup}
				submitMinigamePart={submitMinigamePart}
				openPopup={openPopup}
				closePopup={closePopup}
				minigameNumber={minigame2 ? 1 : null}
			/>
			{minigame2 && <MinigameController
				isTeacher={isTeacher}
				page={page}
				game={game}
				sessionStatus = {sessionData.status}
				minigame={minigame2}
				groups={groups}
				group={group}
				updateGame={updateGame}
				updateGroup={updateGroup}
				submitMinigamePart={submitMinigamePart}
				openPopup={openPopup}
				closePopup={closePopup}
				minigameNumber={minigame2 ? 2 : null}
			/>}
		</React.Fragment>;
		break;
	case 'investigation':
		if (page.investigationId && 
			minigames.some((minigame) => {return minigame.id === page.investigationId;})
		) {
			investigation = minigames.filter((minigame) => {return minigame.id === page.investigationId;})[0];
		}
		content = 
			<InvestigationController
				isTeacher={isTeacher}
				page={page}
				game={game}
				investigation={investigation}
				sessionStatus={sessionData.status}
				groups={groups}
				group={group}
				updateGame={updateGame}
				updateGroup={updateGroup}
				updateGroups={updateGroups}
				openPopup={openPopup}
				closePopup={closePopup}
			/>;
		break;
	case 'minigameAndInvestigation':
		if (page.minigameId && minigames.some((minigame) => {return minigame.id === page.minigameId;})) {
			minigame = minigames.filter((minigame) => {return minigame.id === page.minigameId;})[0];
		}
		if (page.investigationId && minigames.some((minigame) => {return minigame.id === page.investigationId;})) {
			investigation = minigames.filter((minigame) => {return minigame.id === page.investigationId;})[0];
		}
		content =
			<React.Fragment>
				<MinigameController
					isTeacher={isTeacher}
					page={page}
					game={game}
					minigame={minigame}
					sessionStatus={sessionData.status}
					groups={groups}
					group={group}
					updateGame={updateGame}
					updateGroup={updateGroup}
					submitMinigamePart={submitMinigamePart}
					openPopup={openPopup}
					closePopup={closePopup}
				/>
				<InvestigationController
					isTeacher={isTeacher}
					page={page}
					game={game}
					investigation={investigation}
					sessionStatus={sessionData.status}
					groups={groups}
					group={group}
					updateGame={updateGame}
					updateGroup={updateGroup}
					updateGroups={updateGroups}
					openPopup={openPopup}
					closePopup={closePopup}
				/>
			</React.Fragment>;
		break;
	case 'cyoaGame':
		if (page.cyoaGameId && minigames.some((minigame) => {return minigame.id === page.cyoaGameId;})) {
			cyoaGame = minigames.filter((minigame) => {return minigame.id === page.cyoaGameId;})[0];
		}
		content =
			<CyoaGamesController
				isTeacher={isTeacher}
				page={page}
				game={game}
				group={group}
				sessionStatus={sessionData.status}
				updateGame={updateGame}
				updateGroup={updateGroup}
				cyoaGame={cyoaGame}
				openPopup={openPopup}
			/>;
		break;
	case 'infoMinigame':
		if (page.infoMinigameId && minigames.some((minigame) => {return minigame.id === page.infoMinigameId;})) {
			infoMinigame = minigames.filter((minigame) => {return minigame.id === page.infoMinigameId;})[0];
		}
		content =
			<InfoMinigamesController
				isTeacher={isTeacher}
				page={page}
				game={game}
				group={group}
				sessionStatus={sessionData.status}
				updateGame={updateGame}
				updateGroup={updateGroup}
				infoMinigame={infoMinigame}
				openPopup={openPopup}
			/>;
		break;
	case 'groupLogin':
		content = 
			<GroupLoginStatuses
				page={page}
				game={game}
				groups={groups}
			/>;
		break;
	case 'evaluation':
		content = 
			<EvaluationController
				sessionId={sessionData.id}
				sessionStatus = {sessionData.status}
				game={game}
				groups={groups}
				updateGame={updateGame}
				updateGroups={updateGroups}
				editEvaluationCallback={editEvaluationCallback}
				saveParamName={saveParamName}
				saveEvaluation={saveEvaluation}
				pageTitle={page.title}
				closeSession={closeSession}
				openPopup={openPopup}
				closePopup={closePopup}
			/>;
		break;
	case 'presentation':
		content = 
			<Presentation
				page={page}
			/>;
		break;
	case 'result':
		content = 
			<ResultController
				page={page}
				game={game}
				sessionData={sessionData}
				groups={groups}
				activateTagging={activateTagging}
			/>;
		break;
	case 'base':
		content = 
			<Base
				page={page}
			/>;
		break;
	default: 
		content = <div>Unknown page type: {page.type}.</div>;
	}

	return (
		<React.Fragment>
			<div className="MissionPanel">
				<NavigationPanel isTeacher={isTeacher} page={page} sessionData={sessionData} showPage={showPage} />
				<div className="MissionPanel-content">
					<InstructionsController
						isTeacher={isTeacher}
						page={page}
						game={game}
						updateGame={updateGame}
						type='dropdown'
					/>
					{content}
				</div>
			</div>
		</React.Fragment>
	);
};


MissionPanel.propTypes = {
	isTeacher: PropTypes.bool.isRequired,
	page: PropTypes.object.isRequired,
	sessionData: PropTypes.object.isRequired,
	minigames: PropTypes.array.isRequired,
	game: PropTypes.object.isRequired,
	groups: PropTypes.array,
	group: PropTypes.object,
	showPage: PropTypes.func.isRequired,
	updateGame: PropTypes.func,
	updateGroups: PropTypes.func,
	updateGroup: PropTypes.func,
	submitMinigamePart: PropTypes.func,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	editEvaluationCallback: PropTypes.func,
	saveParamName: PropTypes.func,
	saveEvaluation: PropTypes.func,
	closeSession: PropTypes.func,
	activateTagging: PropTypes.func
};

export default MissionPanel;
