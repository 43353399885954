import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button/button';
import {controlPanelUiTexts as uiTexts} from 'data/ui-texts';
import './session-status.scss';

const SessionStatus = ({sessionData, startSession}) => {
	return (
		<div className="SessionStatus">
			{sessionData.status === 'ready' ?
				<Button
					text={uiTexts[sessionData.status]}
					onClick={() => {startSession(sessionData.id, sessionData.pageIds[0]);}}
				/>
				: uiTexts[sessionData.status]
			}
		</div>
	);
};

SessionStatus.propTypes = {
	sessionData: PropTypes.object,
	startSession: PropTypes.func.isRequired
};

export default SessionStatus;
