import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {baseData} from 'data/base-data';
import {getBasePopupData, getStatusOfMission} from 'helpers/game-flow-helper';
import Base from './base';
import './base.scss';

class BaseController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expandTagId: null,
			tagName: null,
			isEditing: false,
			isSaving: false,
			popupData: {},
			popupIsOpen: false,
			tagSaved: false,
			showCloseAnimation: false,
			showFadeInAnimation: false
		};
		this.expandTag = this.expandTag.bind(this);
		this.editTagName = this.editTagName.bind(this);
		this.saveTagName = this.saveTagName.bind(this);
		this.handleCloseBase = this.handleCloseBase.bind(this);
		this.showPopup = this.showPopup.bind(this);

		this.elements = JSON.parse(JSON.stringify(baseData)).map((mission) => {
			return mission.elements.map((element) => {return {id: element, missionId: mission.id};});
		}).flat();
		this.tags = JSON.parse(JSON.stringify(baseData)).reduce(function(filtered, mission) {
			if (mission.tagId) {
				filtered.push({id: mission.tagId, missionId: mission.id});
			}
			return filtered;
		}, []).flat();
	};

	/**
	 * Component did update
	 * Get popup mission data of the mission in which the tag was saved
	 * @param {object} prevProps 
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.game.lastTaggedSessionId !== this.props.game.lastTaggedSessionId) {
			let storyIndex = parseInt(this.props.game.lastTaggedSessionId.split('-')[1]);
			let missionIndex = parseInt(this.props.game.lastTaggedSessionId.split('-')[2]) - 1;
			let popupData = getBasePopupData(storyIndex, missionIndex, this.props.stories, this.props.game);
			let tagId = this.tags.filter((tag) => {
				return tag.missionId === 'mission-' + storyIndex + '-' + (missionIndex + 1);
			})[0].id;
			this.setState({popupData, tagSaved: true, expandTagId: tagId});
		} else if (prevProps.game.activeSessionId !== this.props.game.activeSessionId) {
			let storyIndex = parseInt(this.props.game.activeSessionId.split('-')[1]);
			let missionIndex = parseInt(this.props.game.activeSessionId.split('-')[2]) - 1;
			let popupData = getBasePopupData(storyIndex, missionIndex, this.props.stories, this.props.game);
			this.setState({popupData});
		}
		if (!prevProps.baseIsOpen && this.props.baseIsOpen) {
			this.setState({showCloseAnimation: false});
		}
	}

	/**
	 * Hide / show tag name
	 * @param {string} tagId 
	 */
	expandTag(tagId)  {
		if (this.state.expandTagId === tagId || tagId === null) {
			this.setState({expandTagId: null, tagName: null, isEditing: false});
		} else {
			this.setState({expandTagId: tagId});

			/* Check if group can tag */
			if (!this.props.isTeacher && this.props.group) {
				let tag = this.tags.filter((tag) => {return tag.id === tagId;})[0];
				let storyId = tag.missionId.split('-')[1];
				let missionId = tag.missionId.split('-')[2];
				let sessionId =
					this.props.game.sessions.filter((session) => {
						return session.id.startsWith('session-' + storyId + '-' + missionId);
					}).slice(-1)[0].id;
				if (this.props.game.sessions.some((session) => {return session.id === sessionId;})) {
					let session = this.props.game.sessions.filter((session) => {return session.id === sessionId;})[0];
					if (
						session.winningGroupId && session.winningGroupId === this.props.group.id && 
						session.winningGroupCanTag === true
					) {
						this.props.showTagBasePopup(session.id);
					}
				}
			}
		}
	}

	/**
	 * Edit tag name
	 */
	editTagName(event) {
		let value = event.target.value;
		if (value.length > 14) return;
		this.setState({tagName: value, isEditing: true});
	}

	/**
	 * Save tag name
	 */
	saveTagName() {
		if (this.state.tagName.length === 0) return;
		this.setState({isSaving: true});
		let tag = this.tags.filter((tag) => {return tag.id === this.state.expandTagId;})[0];
		let storyId = tag.missionId.split('-')[1];
		let missionId = tag.missionId.split('-')[2];
		let sessionId =
			this.props.game.sessions.filter((session) => {
				return session.id.startsWith('session-' + storyId + '-' + missionId);
			}).slice(-1)[0].id;
		let gameSessions = JSON.parse(JSON.stringify(this.props.game)).sessions;
		let sessionIndex = gameSessions.findIndex((session) => {return session.id === sessionId;});
		gameSessions[sessionIndex].tagName = this.state.tagName;
		this.props.updateGame({sessions: gameSessions}).then(() => {
			this.setState({isSaving: false, isEditing: false, tagName: null});
		});
	}

	/**
	 * Close base
	 */
	handleCloseBase() {
		if (this.props.game.showBasePopupTeacher || (this.props.group && this.props.group.showBasePopupStudent)) {
			this.setState({isSaving: true});
			if (this.props.isTeacher) {
				this.props.updateGame({showBasePopupTeacher: false}).then(() => {
					this.setState({isSaving: false});
				});
			} else {
				this.props.updateGroup({showBasePopupStudent: false}).then(() => {
					this.setState({isSaving: false});
				});
			}
		}
		this.props.toggleBase(false);
		if (this.state.tagSaved) {
			setTimeout(() => {
				this.setState({tagSaved: false, expandTagId: null});
			}, 1000);
		}
		if (this.state.popupIsOpen) {
			this.setState({popupIsOpen: false});
		}

		this.setState({showCloseAnimation: true});
	}

	/**
	 * Show popup
	*/
	showPopup() {
		this.setState({popupIsOpen: true});
	}

	render() {
		/* Get base element statuses (depends on game progress) */
		let elements = JSON.parse(JSON.stringify(this.elements));
		elements.forEach((element) => {
			let story = this.props.stories.find((story) => {
				return story.storyIndex === Number(element.missionId.split('-')[1]);
			});
			let elementMission = story.missions.find((mission) => {
				return element.missionId === mission.id;
			});
			/* Get status of mission corresponding to the element */
			if (elementMission) {
				let elementMissionStatus = getStatusOfMission(this.props.game, elementMission);
				if (elementMissionStatus === 'closed') {
					element.status = 'repaired';
				}
			}
		});

		/* Get tag states */
		let tags = JSON.parse(JSON.stringify(this.tags));
		tags.forEach((tag) => {
			let storyId = tag.missionId.split('-')[1];
			let missionId = tag.missionId.split('-')[2];
			let sessionData =
				this.props.game.sessions.filter((session) => {
					return session.id.startsWith('session-' + storyId + '-' + missionId);
				}).slice(-1)[0];
			if (this.props.game.code) {
				if (sessionData && sessionData.winningGroupId && sessionData.winningGroupCraterId) {
					tag.groupId = sessionData.winningGroupId;
					tag.craterId = sessionData.winningGroupCraterId;
					tag.name = (sessionData.tagName ? sessionData.tagName : '');
				}
				if (sessionData && this.props.game.lastTaggedSessionId === sessionData.id) {
					tag.isPulsating = true;
				}
			}
			if ('mission-' + this.props.page.storyIndex + '-' + (this.props.page.missionIndex + 1) === tag.missionId) {
				tag.isCurrentMission = true;
			}
		});

		/* Get mission info for popup */
		let popupData = JSON.parse(JSON.stringify(this.state.popupData));
		if (!this.props.openedFromOverview && !this.state.tagSaved) {
			popupData = getBasePopupData(
				this.props.page.storyIndex,
				this.props.page.missionIndex,
				this.props.stories,
				this.props.game
			);
		}

		return (
			<Base 
				isTeacher={this.props.isTeacher} 
				isEditing={this.state.isEditing}
				isSaving={this.state.isSaving}
				elements={elements} 
				tags={tags} 
				expandTagId={this.state.expandTagId}
				tagName={this.state.tagName}
				expandTag={this.expandTag}
				editTagName={this.editTagName}
				saveTagName={this.saveTagName}
				toggleBase={this.props.toggleBase}
				baseIsOpen={this.props.baseIsOpen}
				handleCloseBase={this.handleCloseBase}
				popupIsOpen={this.state.popupIsOpen}
				popupIsOpenProps={this.props.basePopupIsOpen}
				popupData={popupData}
				showPopup={this.showPopup}
				isOverviewPage={this.props.page.id === 'overview'}
				showCloseAnimation={this.state.showCloseAnimation}
				showFadeInAnimation={this.props.fadeInBase}
			/>
		);
	}
}


BaseController.propTypes = {
	isTeacher: PropTypes.bool.isRequired,
	game: PropTypes.object.isRequired,
	group: PropTypes.object,
	toggleBase: PropTypes.func.isRequired,
	updateGame: PropTypes.func,
	updateGroup: PropTypes.func,
	showTagBasePopup: PropTypes.func,
	baseIsOpen: PropTypes.bool.isRequired,
	basePopupIsOpen: PropTypes.bool.isRequired,
	stories: PropTypes.array.isRequired,
	page: PropTypes.object.isRequired,
	openedFromOverview: PropTypes.bool.isRequired,
	fadeInBase: PropTypes.bool
};

export default BaseController;
