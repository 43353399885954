import React from 'react';
import PropTypes from 'prop-types';
import {errorFeedbackHelper} from 'helpers/error-feedback-helper';
import errorTexts from 'data/error-texts';
import {renderMarkdown} from 'helpers/text-helper';
import {landingPageUiTexts} from 'data/ui-texts';
import Button from 'components/button/button';
import './landing-page.scss';

const LandingPage = ({browserInfo, launchGame}) => {
	return (
		<div className={'LandingPage'}>
			<img className="Logo-textured-glow" src={require('../../assets/images/logo-textured.svg')} alt="logo"/>
			{browserInfo
				? <React.Fragment>
					<div className="LandingPage-message">
						{errorTexts[errorFeedbackHelper(browserInfo.status)]}
						<ul>
							{browserInfo.supportedBrowsers.map((browser, i) => {
								return <li key={i}>{browser.name}</li>;
							})}
						</ul>
					</div>
				</React.Fragment>
				: <React.Fragment>
					<div className="LandingPage-message two-column">{renderMarkdown(landingPageUiTexts.message)}</div>
					<Button text={landingPageUiTexts.launch} onClick={() => {launchGame();}}/>
				</React.Fragment>
			}
			<div className="LandingPage-logos">
				<div className="LandingPage-logoWrapper">
					<div>{landingPageUiTexts.developedBy}</div>
					<img src={require('../../assets/images/logo-ku.svg')} alt="Københavns Universitet logo"/>
					<img src={require('../../assets/images/logo-cgl.svg')} alt="Copenhagen Game Lab logo" onClick={() => {window.open('https://cphgamelab.dk/', '_blank', 'noopener,noreferrer');}}/>
				</div>
				<div className="LandingPage-logoWrapper">
					<div>{landingPageUiTexts.developedWith}</div>
					<img src={require('../../assets/images/logo-novo-nordisk-fonden.svg')}
						alt="Novo Nordisk-fonden"/>
				</div>
			</div>
		</div>
	);
};

LandingPage.propTypes = {
	browserInfo: PropTypes.object,
	launchGame: PropTypes.func
};

export default LandingPage;
