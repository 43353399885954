const evaluationData = {
	minVal: 1,
	maxVal: 5,
	parameters: {
		x: 'Evalueringskriterie 1',
		y: 'Evalueringskriterie 2'
	},
	1: 75,
	2: 175,
	3: 300,
	4: 450,
	5: 650
};

module.exports = {
	evaluationData
};