import React from 'react';
import PropTypes from 'prop-types';
import {useDragLayer} from 'react-dnd';
import {getItemStyles} from 'helpers/dnd-helper';
import GroupBadge from 'components/group-badge/group-badge';
import './evaluation-dnd-drag-layer.scss';

const EvaluationDragLayer = ({groups}) => {
	const {
		item,
		initialOffset,
		currentOffset,
	} = useDragLayer((monitor) => {
		return {
			item: monitor.getItem(),
			initialOffset: monitor.getInitialSourceClientOffset(),
			currentOffset: monitor.getSourceClientOffset(),
		};
	});

	let groupId = null;
	let group = null;
	if (item && item.groupId) groupId = item.groupId;
	if (groups.some((group) => {return group.id === groupId;})) {
		group = groups.filter((group) => {return group.id === groupId;})[0];
	}
	let style = getItemStyles(initialOffset, currentOffset);

	return (
		<div className="EvaluationDndDragLayer">
			{group &&
				<div style={style} >
					<GroupBadge group={group} showName={false} page="evaluation" />
				</div>	
			}
		</div>
	);
};

EvaluationDragLayer.propTypes = {
	groups: PropTypes.array.isRequired
};

export default EvaluationDragLayer;