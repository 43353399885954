import React from 'react';
import marked from 'marked';

/**
 * Markdown
 * @param {string} marked
 * @return {html}
 */
function renderMarkdown(markdown) {
	var renderer = new marked.Renderer();
	renderer.link = function(href, title, text) {
		var link = marked.Renderer.prototype.link.apply(this, arguments);
		return link.replace('<a', '<a target="_blank"');
	};

	marked.setOptions({
		renderer: renderer
	});

	let lexed = marked.lexer(markdown);
	let parsed = marked.parser(lexed);

	return <div dangerouslySetInnerHTML={{__html: parsed}} />;
}

/**
* Wraps strings from Array into <p>-tags
* @param {Array} paragraphArray - array of strings
* @return Html
*/
function addParagraph(paragraphArr) {
	return paragraphArr.map((item, index) => {
		return <p key={index}>{item}</p>;
	});
}

/**
* Wraps strings from Array into whatever tag the string has
* @param {Array} textArr - array of strings with p, div, table etc. tags
* @return Html
*/
function addHTMLElement(textArr) {
	return textArr.map((item, index) => {
		return <div key={index} dangerouslySetInnerHTML={{__html: item}} />;
	});
}

export {renderMarkdown, addParagraph, addHTMLElement};