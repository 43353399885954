import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button/button';
import {formatDate} from 'helpers/date-helper';
import {settingsUiTexts} from 'data/ui-texts';
import './settings.scss';

const Settings = (props) => {
	let {
		isAdmin,
		games,
		handleSelectGame,
		showCreateGamePopup,
		showDeleteGamePopup,
		showVideosPopup,
		showResourcesPopup
	} = props; 

	return (
		<div className="Settings">
			<div className="Settings-actions">
				<Button
					class="dark"
					onClick={()=>{showCreateGamePopup();}}
					text="Opret nyt spil"
				/>
				<Button
					class="dark"
					text= "Lærervejledning"
					type="a"
					target="_blank"
					href={'resources/Velkommen_til_Marsbasen.pdf'}
				/>
				<Button
					class="dark wide"
					onClick={()=>{showVideosPopup();}}
					text= "Instruktionsvideoer"
				/>
				<Button
					class="dark"
					onClick={()=>{showResourcesPopup();}}
					text= "Ressourcer"
				/>
			</div>	
			{isAdmin &&
				<div className="Settings-adminBtn">
					<Button class="dark" type="a" href="/admin" text="Gå til Admin" />
				</div>}

			{games.length > 0 && <div className="Settings-games">
				<div className="Settings-title">{settingsUiTexts.myGames}</div>
				{games.map((game) => {
					let gameStarted = formatDate(game.created);
					let gameCode = game.code;
					let goToGameButtonText = settingsUiTexts.goToGame;
					return (
						<div key={game.id} className="Settings-game">
							{/* <div className="Settings-manageTeachersBtn" onClick={() => {
								showManageTeachersPopup(game.id);
							}} /> */}
							<div className='Settings-gameDeleteBtn' onClick={() => {
								showDeleteGamePopup(game.id);
							}} />
							<div className="Settings-gameClass">
								<span>{settingsUiTexts.gameName}</span>{game.name}
							</div>
							<div className="Settings-gameInfo">
								<span>{settingsUiTexts.code}</span> {gameCode}
							</div>
							<div className="Settings-gameStarted">
								<span>{settingsUiTexts.started}</span>{gameStarted}
							</div>
							<div className="Settings-gameButtons">
								<Button text={goToGameButtonText} 
									onClick={() => {handleSelectGame(game.id, false);}} />
							</div>
						</div>	
					);
				})}
			</div>}
		</div>
	);
};

Settings.propTypes = {
	isAdmin: PropTypes.bool.isRequired,
	games: PropTypes.array.isRequired,
	handleSelectGame: PropTypes.func.isRequired,
	showCreateGamePopup: PropTypes.func.isRequired,
	showDeleteGamePopup: PropTypes.func.isRequired,
	showVideosPopup: PropTypes.func.isRequired,
	showResourcesPopup: PropTypes.func.isRequired
};


export default Settings;
