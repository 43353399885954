import React from 'react';
import PropTypes from 'prop-types';
import {useDrag} from 'react-dnd';

const PuzzleDnDItem = ({
	piece,
	areaIndex,
	isAvailable,
	isCorrect,
	isSolved,
	isDraggable,
	handleDrop,
	showColorHint,
	areaPieceType
}) => {
	
	const [{ isDragging }, drag] = useDrag({
		item: {type: 'puzzlePiece', piece: piece, fromAreaIndex: areaIndex},
		canDrag() {return isDraggable;},
		isDragging(monitor) {
			return (piece.id === monitor.getItem().piece.id && areaIndex === monitor.getItem().fromAreaIndex);
		},
		endDrag(monitor) {
			if (handleDrop && !(monitor.didDrop())) {
				let item = monitor.getItem();
				handleDrop(item.piece, item.fromAreaIndex, null, item.piece.type, areaPieceType);
			}
		},
		collect: (monitor) => {return {canDrag: monitor.canDrag(), isDragging: monitor.isDragging()};}
	});
	
	
	let opacity = (isDragging ? 0.4 : 1);
	let className = 'Puzzle-piece'  +
		(isDraggable ? ' Puzzle-piece--draggable' : '') +
		(isSolved && showColorHint ? ' Puzzle-piece--solved' : '') +
		(isDragging ? ' Puzzle-piece--dragging' : '') +
		(piece.type ? ' Puzzle-piece--' + piece.type : '');
	if (areaIndex !== null) {
		className = className + ' Puzzle-piece--placed';
		if (showColorHint) {
			className = className + ' Puzzle-piece--' + (isCorrect ? 'correct' : 'wrong');
		}
	} else {
		if (!isAvailable) {
			className = className + ' Puzzle-piece--notAvailable';
			opacity = 0.4;
		}
	}


	return (
		<div ref={(node) => {return drag(node);}} className={className} style={{opacity}}>
			{piece.icon && <div className={'Puzzle-icon Puzzle-icon--' + piece.icon} />}
			<div className="Puzzle-pieceName" dangerouslySetInnerHTML={{ __html: piece.name}}></div>
		</div>
	);

};

PuzzleDnDItem.propTypes = {
	piece: PropTypes.object.isRequired,
	areaIndex: PropTypes.number,
	isAvailable: PropTypes.bool,
	isCorrect: PropTypes.bool,
	isSolved: PropTypes.bool,
	isDraggable: PropTypes.bool.isRequired,
	handleDrop: PropTypes.func,
	showColorHint: PropTypes.bool,
	areaPieceType: PropTypes.string
};

export default PuzzleDnDItem;