import React from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {AuthSessionConsumer} from 'context/auth-session';
import Admin from 'components/admin/admin';

class AdminController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isAuthorized: false,
			page: null
		};
		this.goToPage = this.goToPage.bind(this);
	};

	/* Component did mount */
	componentDidMount() {
		const db = firebase.firestore();
		db.collection('users').doc(this.props.userId).get().then((doc) => {
			if (doc.exists) {
				if (doc.data().hasOwnProperty('isAdmin')) {
					this.setState({isAuthorized: doc.data().isAdmin});
				}
			}
		}).catch((error) => {
			console.error(error);
			const msg = 'unknown-error';
			this.setState({
				feedback: msg
			});
		});	
	}

	/**
	 * Go to admin page
	 * @param {string} page 
	 */
	goToPage(page) {
		this.setState({page: page});
	}


	/**
	 * Update input field
	 * @param {obj} event 
	 */
	// handleInput(event) {
	// 	let value = event.target.value;
	// 	let name = event.target.name;
	// 	this.setState({
	// 		[name]: value,
	// 		feedback: null
	// 	});
	// }
	



	/**
	 * Render component
	 */
	render() {
		return (
			<Admin 
				isAuthorized={this.state.isAuthorized}
				userTokenId={this.props.userTokenId}
				page={this.state.page}
				goToPage={this.goToPage}
				handleLogout={this.props.handleLogout}
			/>
		);
	}
};

AdminController.propTypes = {
	userId: PropTypes.string.isRequired,
	userTokenId: PropTypes.string.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default (props) => {
	return (
		<AuthSessionConsumer>
			{(authData) => {
				return (
					<AdminController 
						{...props}
						userId={authData.userId}
						userTokenId={authData.userTokenId}
						handleLogout={authData.handleLogout}
					/>
				);
			}}
		</AuthSessionConsumer>
	);
};