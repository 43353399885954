import React from 'react';
import PropTypes from 'prop-types';
import {Line, Chart} from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import './graph.scss';

Chart.register([annotationPlugin]);

const Graph = ({xValues, yValues, yTitle, xTitle, yMin, yMax, yTickStepSize, dataType, sunCycles}) => {
	let data = {
		labels: xValues,
		datasets: [{
			label: yTitle.split(' ')[0],
			data: yValues,
			borderColor: '#353a3e',
			borderWidth: 1,
			pointRadius: 1,
		}],
	};

	if (dataType.includes('atomReactor')) {
		data.datasets = [{
			backgroundColor: '#1F1F1F',
			borderColor: '#1F1F1F',
			data: yValues,
		}];
	}

	let annotations = [];
	if (dataType === 'energy') {
		[0, 2, 4].forEach((nightStart) => {
			annotations.push(
				{
					type: 'box',
					drawTime: 'beforeDatasetsDraw',
					display: true,
					fill: true,
					yMin: 0,
					yMax: 200,
					xMin: xValues.indexOf(sunCycles[nightStart].toFixed(1)),
					xMax: xValues.indexOf(sunCycles[nightStart + 1].toFixed(1)),
					backgroundColor: '#bbb',
					borderColor: '#bbb',
					borderWidth: 0,
					yScaleID: 'y-axis-0',
				},
				{
					type: 'line',
					drawTime: 'beforeDatasetsDraw',
					display: true,
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					yMin: 0,
					yMax: 200,
					xMin: xValues.indexOf(sunCycles[nightStart].toFixed(1)),
					xMax: xValues.indexOf(sunCycles[nightStart + 1].toFixed(1)),
					label: {
						color: '#888',
						font: {style: 'normal'},
						backgroundColor: 'transparent',
						position: 'center',
						enabled: true,
						content: 'Nat'
					}
				},
				{
					type: 'line',
					drawTime: 'beforeDatasetsDraw',
					display: true,
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					yMin: 0,
					yMax: 200,
					xMin: xValues.indexOf(nightStart === 0 ? 0 : sunCycles[nightStart - 1].toFixed(1)),
					xMax: xValues.indexOf(sunCycles[nightStart].toFixed(1)),
					label: {
						color: nightStart === 0 ? 'transparent' : '#888',
						font: {style: 'normal'},
						backgroundColor: 'transparent',
						position: 'center',
						enabled: true,
						content: 'Dag'
					}
				}
			);
		});
		annotations.push({
			type: 'line',
			drawTime: 'beforeDatasetsDraw',
			display: true,
			backgroundColor: 'transparent',
			borderColor: 'transparent',
			yMin: 0,
			yMax: 200,
			xMin: xValues.indexOf(sunCycles[sunCycles.length - 2].toFixed(1)),
			xMax: xValues.indexOf(sunCycles[sunCycles.length - 1].toFixed(1)),
			label: {
				color: '#888',
				font: {style: 'normal'},
				backgroundColor: 'transparent',
				position: 'center',
				enabled: true,
				content: 'Dag'
			}
		});
	}

	return (
		<div className={'Graph ' + dataType}>
			<Line
				data={data}
				redraw={false}
				options={{
					responsive: true,
					plugins: {
						annotation: {
							annotations: annotations
						},
						legend: {
							display: false
						}
					},
					scales: {
						x: {
							title: {
								display: true,
								text: xTitle,
								font: {
									size: window.innerWidth > 1300 ? 14 : window.innerWidth > 1025 ? 12 : 8
								}
							},
							ticks: {
								font: {
									size: window.innerWidth > 1300 ? 14 : window.innerWidth > 1025 ? 12 : 8
								}
							}
						},
						y: {
							title: {
								display: true,
								text: yTitle,
								align: dataType === 'atomReactor' ? 'end' : 'start',
								font: {
									size: window.innerWidth > 1300 ? 14 : window.innerWidth > 1025 ? 12 : 8
								}
							},
							min: yMin,
							max: yMax,
							ticks: {
								stepSize: yTickStepSize,
								font: {
									size: window.innerWidth > 1300 ? 14 : window.innerWidth > 1025 ? 12 : 8
								}
							}
						}
					},
					animation: {duration: 0}
				}}
			/>
		</div>
	);
};

Graph.propTypes = {
	xValues: PropTypes.array.isRequired,
	yValues: PropTypes.array.isRequired,
	xTitle: PropTypes.string.isRequired,
	yTitle: PropTypes.string.isRequired,
	dataType: PropTypes.string.isRequired,
	yMin: PropTypes.number,
	yMax: PropTypes.number,
	yTickStepSize: PropTypes.number,
	sunCycles: PropTypes.array
};

export default Graph;
