const {cratersData} = require('./groups-data');

// Demo game
const demoGame = {
	id: null,
	code: null,
	class: 'din klasse',
	school: 'din skole',
	sessionId: 'session-0-1-1',
	created: null,
	started: null,
	sessions: [{id: 'session-0-1-1', status: 'read'}],
	investigations: [
		{id: 'investigation-1', status: 'ready', stepIndex: 0, selectedGroups: [], isOpenForSubmissions: false}
	],
	// activeSessionId: 'session-0-1-1'
};

// Demo groups
const demoGroups = cratersData.map((crater, index) => {
	let groupObj = {
		id: 'test-' + (index + 1),
		index: index + 1,
		craterId: crater.id,
		gameId: null,
		isPlaying: true,
		points: 0,
		evaluations: {},
		investigations: {
			'investigation-1': {
				title: 'Lorem ipsum',
				text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at maximus augue. Proin sit amet tempor quam, nec blandit libero. Maecenas congue lorem pulvinar, tincidunt erat eget, facilisis ex. Sed a enim est. Ut tristique imperdiet nunc sed accumsan. Aliquam erat volutpat. Aliquam maximus venenatis maximus.'
			}
		}
	}
	return groupObj;
});

export {
	demoGame,
	demoGroups
};