import React, {Component} from 'react';
import PropTypes from 'prop-types';
import 'firebase/firestore';
import {AuthSessionConsumer} from 'context/auth-session';
import errorTexts from 'data/error-texts';
import popups from 'data/popups-teacher-data';
import {pageIsTeacherOnly} from 'helpers/game-flow-helper';
import {investigationsData} from 'data/investigations-data';
import {imagesData} from 'data/images-data';
import ImageLoader from 'components/image-loader/image-loader';
import Game from './game';
import GameLoading from './game-loading';
import TeacherController from 'components/users/teacher-controller';
import GroupController from 'components/users/group-controller';
import apiHelper from 'helpers/api-helper';
import 'firebase/firestore';

class GameController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isPreloading: true,
			preloadImages: true,
			showBase: false,
			showBasePopup: false,
			showWaveSimulator: false,
			initialHistoryLength: 0,
			pageId: null,
			prevPageId: null,
			gameCode: null,
			groupIndex: null,
			games: [],
			stories: [],
			pages: [],
			minigames: [],
			videos: []
		};
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Listen to browser prev/next button events */
		window.history.replaceState({page: 1}, null, '');
		window.addEventListener('popstate', () => {this.goToPreviousPage(true);});

		/* If group, get group index */
		if (!this.props.isTeacher && this.props.userId.includes('-')) {
			let groupIndex = this.props.userId.substr(this.props.userId.indexOf('-') + 1);
			if (groupIndex >= 0) this.setState({groupIndex: parseInt(groupIndex)});
		}
		
		/* Load game content */
		apiHelper('common/load-game-content', {
			isTeacher: this.props.isTeacher,
			investigationsData: investigationsData
		}).then(
			(response) => {
				let pageId = (this.props.isTeacher ? 'settings' : 'overview');
				this.setState({
					isLoading: false,
					pageId: pageId,
					stories: response.stories,
					pages: response.pages,
					minigames: response.minigames,
					videos: response.videos,
					initialHistoryLength: window.history.length
				});
			},
			(rejection) => {
				if (rejection.error && rejection.error === 'auth-failed') {
					this.openAuthErrorPopup();
				} else {
					console.error(rejection);
					this.openServerErrorPopup();
				}
				console.log(rejection);
			}
		);
	}

	/** Navigate to page
 	 * @param {string} pageId
	 * @param {bool} closePopup
 	*/
	showPage = (pageId, closePopup = false) => {
		return new Promise((resolve)=>{
			if (closePopup) this.props.closePopup();
			
			/* Check if page exists */
			let pageData = null;
			if (pageId.includes('overview')) {
				pageData = this.state.pages.filter((page) => {return page.id === 'overview';})[0];
			} else {
				pageData = this.state.pages.filter((page) => {return page.id === pageId;})[0];
			}
			if (pageData) {
				/* Page exists */
				if (this.props.isTeacher || !pageIsTeacherOnly(pageData.type, pageData.id)) {
					/* Show page */
					let prevPageId = this.state.pageId;
					this.setState({pageId, prevPageId, showBase: false}, () => {
						if (window.history.length === this.state.initialHistoryLength) {
							window.history.pushState({page: 2}, null, '');
						}
						window.history.forward();
						resolve({status: 'ok'});
					});
				} else {
					/* Group tried to access teacher only page */
					window.location.reload();
				}
			} else {
				/* Page does not exist */
				this.setState({showBase: false}, () => {
					console.error('invalid pageId:', pageId);
					resolve({status: 'error', error: 'invalid pageId:' + pageId});
				});
			}
		});
	}


	/**
	 * Go to previous page (called when using browser back button)
	 * @param {bool} triggeredeByEventListener
	 */
	goToPreviousPage = (triggeredeByEventListener = false) => {
		if (window.history.state.page >= 2 && triggeredeByEventListener) return;

		/* Last page of app */
		if (this.state.pageId.includes('overview') && !this.props.isTeacher) return;
		if (this.state.pageId === 'settings') return;
		
		let pageId = null;
		if (this.state.pageId.includes('overview')) {
			/* Teacher overview page */
			pageId = 'settings';
		} else {
			/* Session page */
			if (this.state.pages.some((page) => {return page.id === this.state.pageId;})) {
				let pageData = this.state.pages.filter((page) => {return page.id === this.state.pageId;})[0];
				pageId = 'overview-' + pageData.storyIndex;
			}
		}

		if (pageId) this.showPage(pageId, true);
	}

	/**
	 * Set game code
	 * @param {string} code 
	 */
	setGameCode = (code) => {
		this.setState({gameCode: code});
	}

	/**
	 * Toggle base
	 * @param {bool} showBase
	 * @param {string} sessionId
	 */
	toggleBase = (showBase, showBasePopup = false) => {
		this.setState({showBase, showBasePopup});
	}

	/**
	 * Hide/show wave simulator popup
	 */
	toggleWaveSimulator = (showWaveSimulator) => {
		this.setState({showWaveSimulator});
	}

	/**
	 * Show authentication error popup
	 */
	openAuthErrorPopup = () => {
		let popupData = errorTexts.popups.authError;
		let btnConfig = [];
		let okBtn = {
			text: popupData.buttonTexts.ok,
			type: 'button',
			action: this.props.handleLogout,
			parameters: []
		};
		btnConfig.push(okBtn);
		this.props.openPopup(popupData, btnConfig, 'serverError');
	}

	/**
	 * Show server error popup
	 */
	openServerErrorPopup = () => {
		let popupData = errorTexts.popups.serverError;
		let btnConfig = [];
		let okBtn = {
			text: popupData.buttonTexts.ok,
			type: 'button',
			action: this.props.closePopup,
			parameters: []
		};
		let reloadBtn = {
			text: popupData.buttonTexts.reload,
			type: 'button',
			action: this.reloadPage,
			parameters: []
		};
		btnConfig.push(okBtn, reloadBtn);
		this.props.openPopup(popupData, btnConfig, 'serverError');
	}


	/**
	 * Show logout popup
	 */
	openLogoutPopup = () => {
		let popupData = popups.logoutWarningPopup;
		let btnConfig = [];
		let yesBtn = {
			text: popupData.buttonTexts.yes,
			type: 'button',
			action: this.props.handleLogout,
			parameters: []
		};
		let noBtn = {
			text: popupData.buttonTexts.no,
			type: 'button',
			action: this.props.closePopup,
			parameters: []
		};
		btnConfig.push(yesBtn, noBtn);
		this.props.openPopup(popupData, btnConfig, 'serverError');
	}

	/**
	 * Reload page
	 */
	reloadPage = () => {
		// eslint-disable-next-line no-restricted-globals
		location.reload();
	}

	/* Check if all images have been preloaded */
	handlePreloadImage = (preloadingFinished) => {
		if (preloadingFinished) {
			this.setState({isPreloading: false, preloadImages: false});
		}
	}

	/**
 	* Render component
 	*/
	render = () => {
		/* Game is loading */
		if (this.state.isLoading || this.state.isPreloading) {
			return (
				<React.Fragment>
					<GameLoading />
					{this.state.preloadImages
						&& <ImageLoader images={imagesData} handlePreloadImage={this.handlePreloadImage} />};
				</React.Fragment>
			);
		}
		
		/* Game is ready */
		let UserController = (this.props.isTeacher ? TeacherController : GroupController);



		/* Get page data */
		let page = null;
		if (this.state.pageId.includes('overview')) {
			/* Special case: 'overview', can have an index e.g. overview-1 */
			page = this.state.pages.filter((page) => {return page.id === 'overview';})[0];
		} else {
			/* All other pages */
			if (this.state.pages.some((page) => {return page.id === this.state.pageId;})) {
				page = this.state.pages.filter((page) => {return page.id === this.state.pageId;})[0];
			}
		}

		/* Get session data */
		let sessionData = null;
		if (page && page.hasOwnProperty('sessionIndex')) {
			sessionData = JSON.parse(JSON.stringify(
				this.state.stories[page.storyIndex].missions[page.missionIndex].sessions[page.sessionIndex]
			));
			sessionData.storyTitle = this.state.stories[page.storyIndex].title;
			sessionData.missionTitle = this.state.stories[page.storyIndex].missions[page.missionIndex].title;
			sessionData.missionSessions = this.state.stories[page.storyIndex].missions[page.missionIndex].sessions;
			sessionData.pages = [];
			if (sessionData.pageIds) {
				sessionData.pageIds.forEach((pageId) => {
					if (this.state.pages.some((page) => {return page.id === pageId;})) {
						let pageData = this.state.pages.filter((page) => {return page.id === pageId;})[0];
						pageData.id = pageId;
						pageData.menuTitle = (pageData.menuTitle ? pageData.menuTitle : pageData.title);
						sessionData.pages.push(pageData);
					}
				});
			}
			// Had to add this block to avoid nested missionSession arrays - see story #12019
			sessionData.missionSessions.forEach((session) => {
				session.pages = [];
				if (session.pageIds) {
					session.pageIds.forEach((pageId) => {
						if (this.state.pages.some((page) => {return page.id === pageId;})) {
							let pageData = this.state.pages.filter((page) => {return page.id === pageId;})[0];
							pageData.id = pageId;
							pageData.menuTitle = (pageData.menuTitle ? pageData.menuTitle : pageData.title);
							session.pages.push(pageData);
						}
					});
				}
			});
		}
		
		return (
			<Game
				isTeacher={this.props.isTeacher} 
				popupIsOpen={this.props.popupIsOpen} 
				fullscreenAllowed={this.props.fullscreenAllowed}
				isFullscreen={this.props.isFullscreen}
				gameCode={this.state.gameCode}
				groupIndex={this.state.groupIndex}
				pageId={this.state.pageId}
				userEmail={this.props.userEmail}
				showPage={this.showPage}
				handleToggleFullscreen={this.props.handleToggleFullscreen}
				handleLogout={this.openLogoutPopup}
				baseIsOpen={this.state.showBase}
			>
				<UserController
					userId={this.props.userId}
					userTokenId={this.props.userTokenId}
					pageId={this.state.pageId}
					showBase={this.state.showBase}
					showBasePopup={this.state.showBasePopup}
					stories={this.state.stories}
					page={page}
					sessionData={sessionData}
					minigames={this.state.minigames}
					videos={this.state.videos}
					popupFormData={this.props.popupFormData}			
					showPage={this.showPage} 
					setGameCode={this.setGameCode}
					toggleBase={this.toggleBase}
					openPopup={this.props.openPopup}
					closePopup={this.props.closePopup}
					updatePopupState={this.props.updatePopupState}
					openAuthErrorPopup={this.openAuthErrorPopup}
					openServerErrorPopup={this.openServerErrorPopup}
					handleLogout={this.props.handleLogout}
					toggleWaveSimulator={this.toggleWaveSimulator}
					showWaveSimulator={this.state.showWaveSimulator}
				/>
			</Game>
		);
	}
}

GameController.defaultProps = {
	userEmail: null
};

GameController.propTypes = {
	// isOnline: PropTypes.bool.isRequired,
	isFullscreen: PropTypes.bool.isRequired,
	fullscreenAllowed: PropTypes.bool.isRequired,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	updatePopupState: PropTypes.func.isRequired,
	popupIsOpen: PropTypes.bool.isRequired,
	popupFormData: PropTypes.object.isRequired,
	userId: PropTypes.string.isRequired,
	userTokenId: PropTypes.string.isRequired,
	userEmail: PropTypes.string,
	isTeacher: PropTypes.bool.isRequired,
	handleToggleFullscreen: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default (props) => {
	return (
		<AuthSessionConsumer>
			{(authData) => {
				return (
					<GameController 
						{...props}
						userId={authData.userId}
						userTokenId={authData.userTokenId}
						userEmail={authData.email}
						isTeacher={authData.isTeacher}
						handleLogout={authData.handleLogout}
					/>
				);
			}}
		</AuthSessionConsumer>
	);
};