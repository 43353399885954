import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button/button';
import Tab from 'components/layout/tab';
import GroupBadge from 'components/group-badge/group-badge';
import {loginUiTexts} from 'data/ui-texts';

const LoginGroup = (props) => {
	const {
		handleInput,
		handleFindGame,
		handleJoinGame,
		code,
		groups,
		feedback,
		isLoading,
		groupIndex,
		invalidGameCode
	} = props;

	function renderGroupLogin() {
		return (
			<Tab title={<div className="Login-title">{loginUiTexts.group} <span>\\</span> {loginUiTexts.login}</div>} >
				<form className="Login-form" onSubmit={handleFindGame}>
					<input
						className={invalidGameCode ? ' invalid' : ''}
						name="code"
						placeholder={loginUiTexts.groupLoginPlaceholder} 
						autoComplete="section-grouplogin code"
						value={code ? code : ''}
						onChange={(event)=>{handleInput(event);}}
					/>
					<Button
						isLoading={isLoading}
						text={loginUiTexts.loginBtn}
						onClick={(event) => {handleFindGame(event);}}
					/>
					<div className={'Login-errorMessage'}>{feedback}</div>
				</form>
			</Tab>
		);
	}

	function renderChooseGroup() {
		return (
			<Tab title={<div className="Login-title">{loginUiTexts.group} <span>\\</span> {loginUiTexts.login}</div>} >
				{groups.map((group)=>{
					return (
						<GroupBadge
							key={group.index}
							group={group}
							showName={true}
							onClick={() => {handleJoinGame(group.index);}}
							isLoading={isLoading && groupIndex === group.index}
						/>
					);
				})}
			</Tab>
		);
	}

	return (
		<div className="LoginGroup">{(groups ? renderChooseGroup() : renderGroupLogin())}</div>
	);
};

LoginGroup.propTypes = {
	handleInput: PropTypes.func.isRequired,
	handleFindGame: PropTypes.func.isRequired,
	handleJoinGame: PropTypes.func.isRequired,
	code: PropTypes.any,
	groups: PropTypes.any,
	feedback: PropTypes.string,
	isLoading: PropTypes.bool.isRequired,
	groupIndex: PropTypes.number.isRequired,
	invalidGameCode: PropTypes.bool.isRequired
};

export default LoginGroup;