import {getText, saveText} from './storage-helper';

/** Note (Dana): This should be the same as functions/helpers/game-content-helper.js */

/**
 * Get page texts (main text, story text, teacher & group instructions)
 * @param {object} page 
 * @param {string} role
 */
function getPageTexts(page, role = null) {
	let pageId = page.id;
	let pageTexts = {text: null, text2: null, storyText: null, instructions: {teacher: null, group: null}};
	return new Promise((resolve)=>{
		let promises = [];
		if (page.loadText) promises.push(getText('pages/' + pageId + '.md'));
		if (page.loadText2) promises.push(getText('pages/' + pageId + '2.md'));
		if (page.loadStoryText) promises.push(getText('pages/' + pageId + '-story.md'));
		if (role !== 'group' && page.loadTeacherInstructions) {
			promises.push(getText('instructions/teacher/' + pageId + '.md'));
		}
		if (role !== 'teacher' && page.loadGroupInstructions) {
			promises.push(getText('instructions/group/' + pageId + '.md'));
		}

		Promise.all(promises).then((response) => {
			if (response.length > 0) {
				let responseIndex = 0;
				if (page.loadText) {
					if (response[responseIndex].status === 'ok') pageTexts.text = response[responseIndex].text;	
					responseIndex = responseIndex + 1;
				}
				if (page.loadText2) {
					if (response[responseIndex].status === 'ok') pageTexts.text2 = response[responseIndex].text;	
					responseIndex = responseIndex + 1;
				}
				if (page.loadStoryText) {
					if (response[responseIndex].status === 'ok') {
						pageTexts.storyText = response[responseIndex].text;	
					}
					responseIndex = responseIndex + 1;
				}
				if (role !== 'group' && page.loadTeacherInstructions) {
					if (response[responseIndex].status === 'ok') {
						pageTexts.instructions.teacher = response[responseIndex].text;	
					}
					responseIndex = responseIndex + 1;
				}
				if (role !== 'teacher' && page.loadGroupInstructions) {
					if (response[responseIndex].status === 'ok') {
						pageTexts.instructions.group = response[responseIndex].text;	
					}
				}			
			}
			resolve({status: 'ok', pageTexts: pageTexts});
		}, (error) => {
			resolve({status: 'error', error: error});
		});		
	});
}

/**
 * Save page texts (main text, story text, teacher & group instructions)
 * @param {string} pageId 
 * @param {object} pageInstructions 
 * @param {string} pageText 
 * @param {string} pageText2 
 * @param {string} pageStoryText 
 */
function savePageTexts(pageId, pageInstructions, pageText, pageText2, pageStoryText) {
	return new Promise((resolve)=>{
		let promises = [];
		
		/* Save instructions */
		if (pageInstructions) {
			if (pageInstructions.teacher) {
				promises.push(saveText('instructions/teacher/' + pageId + '.md', pageInstructions.teacher));
			}
				
			if (pageInstructions.group) {
				promises.push(saveText('instructions/group/' + pageId + '.md', pageInstructions.group));
			}
		}

		/* Save texts */
		if (pageText) promises.push(saveText('pages/' + pageId + '.md', pageText));
		if (pageText2) promises.push(saveText('pages/' + pageId + '2.md', pageText2));
		if (pageStoryText) promises.push(saveText('pages/' + pageId + '-story.md', pageStoryText));

		Promise.all(promises).then(
			() => {
				resolve({status: 'ok'});
			}, (error) => {resolve({status: 'error', error: error});}
		);	
	});
}

/**
 * Get minigame or cyoa game instructions
 * @param {string} minigameId
 */
function getMinigameInstructions(minigameId, role) {
	return new Promise((resolve)=>{
		let instructions = null;
		getText('instructions/' + role + '/' + minigameId + '.md').then((response) => {
			if (response.status === 'ok') instructions = response.text;
			resolve({status: 'ok', instructions: instructions});
		}, (error) => {resolve({status: 'error', error: error});});
	});
}

/**
 * Save minigame or cyoa game instructions
 * @param {string} minigameId
 * @param {string} role
 * @param {string} instructions
 */
function saveMinigameInstructions(minigameId, role, instructions) {
	return new Promise((resolve)=>{
		saveText('instructions/' + role + '/' + minigameId + '.md', instructions).then(
			() => {
				resolve({status: 'ok'});
			}, (error) => {resolve({status: 'error', error: error});}
		);
	});
}

/**
 * Get investigation instructions
 * @param {string} investigationId 
 * @param {string} fileName 
 */
function getInvestigationInstructions(investigationId, instructions) {
	return new Promise((resolve)=>{
		let promises = [];
		instructions.forEach((instruction) => {
			promises.push(
				getText('investigations/' + investigationId + '-' + instruction.part + 
					'-instructions-' + instruction.role + '.md')
			);
		});
		Promise.all(promises).then(
			(responses) => {
				instructions.forEach((instruction, index) => {
					if (responses[index].status === 'ok') {
						instruction.text = responses[index].text;
					}
				});
				resolve({status: 'ok', instructions: instructions});
			}, (error) => {resolve({status: 'error', error: error});}
		);
	});
}


/**
 * Save investigation instructions
 * @param {string} investigationId 
 * @param {array} instructions 
 */
function saveInvestigationInstructions(investigationId, instructions) {
	return new Promise((resolve)=>{
		let promises = [];

		instructions.forEach((instruction) => {
			promises.push(
				saveText('investigations/' + investigationId + '-' + instruction.part + 
					'-instructions-' + instruction.role + '.md', instruction.text)
			);
		});
		
		Promise.all(promises).then(
			() => {
				resolve({status: 'ok'});
			}, (error) => {resolve({status: 'error', error: error});}
		);	
	});
}

export {
	getPageTexts, 
	savePageTexts, 
	getMinigameInstructions,
	saveMinigameInstructions,
	getInvestigationInstructions, 
	saveInvestigationInstructions
};