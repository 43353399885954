import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {puzzleUiTexts} from 'data/ui-texts';
import PuzzleDnDContainer from './puzzle-dnd-container';
import PuzzleDnDItem from './puzzle-dnd-item';
import PuzzleDnDDragLayer from './puzzle-dnd-drag-layer';
import './puzzle.scss';

const Puzzle = (props) => {
	let {
		isTeacher, 
		isCompleted, 
		minigameStatus, 
		puzzleId, 
		puzzleTitle, 
		puzzleInfo,
		inifiniteNumberOfPieces,
		piecesHaveLabels,
		areasHaveLabels,
		pieces, 
		areas, 
		handleCheckSolution,
		attempts,
		goToPuzzlePiece,
		currentPieceIndex
	} = props;

	let [areasList, setAreas] = useState([]);
	useEffect(() => {setAreas(areas);}, [areas]);

	let [piecesList, setPieces] = useState([]);
	useEffect(() => {setPieces(pieces);}, [pieces]);

	const handleDrop = (piece, fromAreaIndex, toAreaIndex, pieceType, areaPieceType) => {
		let newAreas = JSON.parse(JSON.stringify(areasList));

		/* Check if piece can be placed on an area */
		if (pieceType === areaPieceType) {
			/* From pieces to area */
			if (fromAreaIndex === null && toAreaIndex !== null) {
				newAreas[toAreaIndex].placed = piece.id;
			}
	
			/* From areas to area */
			if (fromAreaIndex !== null && toAreaIndex !== null && fromAreaIndex !== toAreaIndex) {
				let pieceId1 = newAreas[fromAreaIndex].placed;
				let pieceId2 = newAreas[toAreaIndex].placed;
				newAreas[fromAreaIndex].placed = pieceId2;
				newAreas[toAreaIndex].placed = pieceId1;
			}
		}
		/* From area to pieces */
		if (fromAreaIndex !== null && toAreaIndex === null) {
			newAreas[fromAreaIndex].placed = null;
		}
		setAreas(newAreas);
	};


	let showAnswerBtn = areasList.every((area) => {return area.placed !== null;}) && !isCompleted ? true : false;

	let puzzlePieces = piecesList.map((piece, index) => {
		let isAvailable = true; 
		if (!inifiniteNumberOfPieces) {
			isAvailable = !(areasList.some((area) => {
				return (
					area.placed === piece.id ||
					(isCompleted && area.pieceIds.includes(piece.id) && area.placed === piece.id)
				);
			}));
		}
		return <PuzzleDnDItem 
			key={index} 
			piece={piece} 
			areaIndex={null} 
			isAvailable={isAvailable}
			isDraggable={isAvailable && !isCompleted && (isTeacher || minigameStatus === 'open')} 
			handleDrop={null}
			showColorHint={false}
		/>;
	});
	if (puzzleId === 17) {
		puzzlePieces = <React.Fragment>
			{currentPieceIndex > 0
				&& <div className="Puzzle-arrow Puzzle-arrow--left"
					onClick={() => {goToPuzzlePiece(currentPieceIndex - 1);}}></div>}
			{puzzlePieces[currentPieceIndex]}
			{currentPieceIndex < 4
				&& <div className="Puzzle-arrow Puzzle-arrow--right"
					onClick={() => {goToPuzzlePiece(currentPieceIndex + 1);}}></div>}
		</React.Fragment>;
	}

	return (
		<div className={'Puzzle Puzzle--' + puzzleId}>
			<div className="Puzzle-title">{puzzleTitle}</div>
			{puzzleInfo && <div className="Puzzle-info">
				{puzzleInfo.image && <div className={'Puzzle-infoImage Puzzle-infoImage--' + puzzleInfo.image} />}
				{puzzleInfo.text && <div className="Puzzle-infoText">{puzzleInfo.text}</div>}
			</div>}

			{/* Puzzle areas */}
			<div className="Puzzle-areas">
				{isCompleted
					? areas.map((area, index) => {
						let pieceElement = null;
						let pieceId = area.pieceIds.filter((pieceId) => {return pieceId === area.placed;})[0];
						let pieceInfo = pieces.filter((piece) => {return piece.id === pieceId;})[0];
						pieceElement = <PuzzleDnDItem 
							piece={pieceInfo} 
							areaIndex={index} 
							isCorrect={true}
							isSolved={true}
							isDraggable={!isCompleted && (isTeacher || minigameStatus === 'open')}
							handleDrop={handleDrop}
							showColorHint={attempts >= 3 || isCompleted}
							areaPieceType={area.pieceType}
						/>;
						return (
							<PuzzleDnDContainer
								key={index}
								area={area}
								areaIndex={index}
								isDroppable={!isCompleted && (isTeacher || minigameStatus === 'open')}
								handleDrop={handleDrop}
							>{pieceElement ? pieceElement : <span>{index + 1}</span>}
							</PuzzleDnDContainer>
						);
					})
					: areasList.map((area, index) => {
						let isCorrect = (area.pieceIds.includes(area.placed));
						let pieceElement = null;
						if (isCorrect || area.placed !== null) {
							let pieceId = (isCorrect 
								? area.pieceIds.filter((pieceId) => {return pieceId === area.placed;})[0]
								: area.placed);
							let pieceInfo = piecesList.filter((piece) => {return piece.id === pieceId;})[0];
							pieceElement = <PuzzleDnDItem 
								piece={pieceInfo} 
								areaIndex={index} 
								isCorrect={isCorrect}
								isSolved={isCorrect}
								isDraggable={!isCompleted && (isTeacher || minigameStatus === 'open')}
								handleDrop={handleDrop}
								showColorHint={attempts >= 3 || isCompleted}
								areaPieceType={area.pieceType}
							/>;
						}
						return (
							<PuzzleDnDContainer
								key={index}
								area={area}
								areaIndex={index}
								isDroppable={!isCompleted && (isTeacher || minigameStatus === 'open')}
								handleDrop={handleDrop}
							>{pieceElement ? pieceElement : <span>{index + 1}</span>}
							</PuzzleDnDContainer>
						);
					})}
				{areasHaveLabels && areasList.map((area, index) => {
					if (area.textLabel) {
						return (
							<div 
								key={index}
								className={'Puzzle-areaLabel Puzzle-areaLabel--' + area.id}
							>{area.textLabel}</div>
						);
					}
					return null;
				})}
			</div>

		
			{/* Puzzle pieces */}
			<PuzzleDnDContainer
				area={null}
				areaIndex={null}
				isDroppable={true}
				handleDrop={handleDrop}
			>
				{puzzlePieces}
			</PuzzleDnDContainer>
			{piecesHaveLabels && <div className="Puzzle-pieceLabels">
				{piecesList.map((piece, index) => {
					if (piece.imageLabel) {
						let isAvailable = true; 
						if (!inifiniteNumberOfPieces) {
							isAvailable = !(areasList.some((area) => {
								return (
									area.placed === piece.id ||
									(isCompleted && area.pieceIds.includes(piece.id))
								);
							}));
						}
						return (
							<div 
								key={index} 
								className={'Puzzle-pieceLabel Puzzle-pieceLabel--' + piece.imageLabel + 
									(!isAvailable ? ' Puzzle-pieceLabel--notAvailable' : '')}
							/>
						);
					}
					if (piece.textLabel) {
						return (
							<div 
								key={index}
								className={'Puzzle-pieceTextLabel Puzzle-pieceTextLabel--' + (index + 1)}
							>
								{piece.textLabel}
							</div>
						);
					}
					return null;
				})}
			</div>}

			{/* Puzzle drag layer */}
			<PuzzleDnDDragLayer pieces={pieces} />

			{/* Answer Button */}
			{showAnswerBtn && <div
				className={'Puzzle-answerBtn' + (isTeacher ? ' Puzzle-answerBtn--teacher' : '')}
				onClick={() => {handleCheckSolution(areasList);}}
			>{puzzleUiTexts.answer}</div>}
		</div>
	);
};


Puzzle.propTypes = {
	isTeacher: PropTypes.bool.isRequired,
	isCompleted: PropTypes.bool.isRequired,
	minigameStatus: PropTypes.string.isRequired,
	puzzleId: PropTypes.number.isRequired,
	puzzleTitle: PropTypes.string.isRequired,
	puzzleInfo: PropTypes.object,
	inifiniteNumberOfPieces: PropTypes.bool.isRequired,
	piecesHaveLabels: PropTypes.bool.isRequired,
	areasHaveLabels: PropTypes.bool.isRequired,
	pieces: PropTypes.array.isRequired,
	areas: PropTypes.array.isRequired,
	handleCheckSolution: PropTypes.func.isRequired,
	attempts: PropTypes.number.isRequired,
	goToPuzzlePiece: PropTypes.func.isRequired,
	currentPieceIndex: PropTypes.number.isRequired
};

export default Puzzle;
