import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {storyTypesData} from 'data/content-data';
import {getPageTexts, savePageTexts} from 'helpers/game-content-helper';
import Pages from './pages';

class PagesController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stories: [],
			pages: [],
			storyIndex: 0,
			missionIndex: 0,
			sessionIndex: 0,
			selectedPageIndex: 0,
			selectedPage: null,
			pageInstructions: {
				teacher: null,
				group: null
			},
			pageText: null,
			pageText2: null,
			pageStoryText: null,
			images: {},
			isEditingPage: false,
			isEditingPageInstructions: false,
			isEditingText: false,
			isSaving: false,
			errorMsg: null
		};
		this.loadStories = this.loadStories.bind(this);
		this.subscribeToPages = this.subscribeToPages.bind(this);
		this.unsubscribePages = null;
		this.selectStory = this.selectStory.bind(this);
		this.selectPage = this.selectPage.bind(this);
		this.updatePage = this.updatePage.bind(this);
		this.updatePageInstructions = this.updatePageInstructions.bind(this);
		this.updatePageText = this.updatePageText.bind(this);
		this.handleSavePage = this.handleSavePage.bind(this);
		this.savePage = this.savePage.bind(this);
	};

	/**
	 * Component mounted
	 */
	componentDidMount() {
		Promise.all([
			this.loadStories(),
			this.subscribeToPages(),
		]).then((responses) => {
			let stories = (responses[0].status === 'ok' ? responses[0].stories : []);
			let pages = (responses[1].status === 'ok' ? responses[1].pages : []);

			let selectedPageId = stories[0].missions[0].sessions[0].pageIds[0];
			let selectedPageIndex = pages.findIndex((page) => {return page.id === selectedPageId;});
			let selectedPage = pages[selectedPageIndex];

			let pageInstructions = {teacher: null, group: null};
			let pageText = null;
			let pageText2 = null;
			let pageStoryText = null;
			getPageTexts(selectedPage).then((response) => {
				if (response.status === 'ok' && response.pageTexts) {
					pageInstructions = response.pageTexts.instructions;
					pageText = response.pageTexts.text;
					pageText2 = response.pageTexts.text2;
					pageStoryText = response.pageTexts.storyText;
				}

				this.setState({
					storyIndex: 0,
					missionIndex: 0,
					sessionIndex: 0,
					pageIndex: selectedPageIndex,
					selectedPage: selectedPage,
					pageInstructions: pageInstructions,
					pageText: pageText,
					pageText2: pageText2,
					pageStoryText: pageStoryText
				});
			});
		});
	}

	loadStories() {
		const db = firebase.firestore();
		return new Promise((resolve)=>{
		
			let promises = [
				db.collection('sessions').get(),
				db.collection('missions').get(),
				db.collection('stories').get()
			];

			Promise.all(promises).then((response) => {	
				let sessions = [];
				response[0].docs.forEach((doc) => {
					let session = doc.data();
					session.id = doc.id;
					sessions.push(session);
				});

				let missions = [];
				response[1].docs.forEach((doc) => {
					let mission = doc.data();
					mission.id = doc.id;
					mission.sessions = [];
					missions.push(mission);
				});

				let stories = [];
				response[2].docs.forEach((doc, storyIndex) => {
					let story = doc.data();
					story.id = doc.id;
					story.storyIndex = storyIndex;
					story.missions = [];
					if (story.missionIds) {
						story.missionIds.forEach((missionId, missionIndex) => {
							if (missions.some((mission) => {return mission.id === missionId;})) {
								let mission = missions.filter((mission) => {return mission.id === missionId;})[0];
								mission.storyIndex = storyIndex;
								mission.missionIndex = missionIndex;
								mission.sessions = [];
								if (mission.sessionIds) {
									mission.sessionIds.forEach((sessionId, sessionIndex) => {
										if (sessions.some((session) => {return session.id === sessionId;})) {
											let session = sessions.filter((session) => {
												return session.id === sessionId;
											})[0];
											session.storyIndex = storyIndex;
											session.missionIndex = missionIndex;
											session.sessionIndex = sessionIndex;
											mission.sessions.push(session);
										}
									}); 
								}
								story.missions.push(mission);
							}
						});
					}
					stories.push(story);
				});

				this.setState({stories: stories}, () => {resolve({status: 'ok', stories: stories});});
			}, (error) => {resolve({status: 'error', error: error});});
		});
	}
	

	/**
	 * Subscribe to pages
	 */
	subscribeToPages() {
		if (this.unsubscribePages !== null) this.unsubscribePages();
		const db = firebase.firestore();
		return new Promise((resolve, reject)=>{
			this.unsubscribePages = db.collection('pages').onSnapshot((querySnapshot) => {
				let pages = [];
				if (!querySnapshot.empty) {
					pages = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = doc.id;
						return data;						
					});
				}
				this.setState({pages: pages}, () => {resolve({status: 'ok', pages: pages});});

			}, (error) => {resolve({status: 'error', error: error});});
		});
	}

	/**
	 * Select first page of first session of first mission of story
	 * @param {object} event 
	 */
	selectStory(event) {
		let selectedStoryIndex = parseInt(event.target.value);
		let selectedPageId = this.state.stories[selectedStoryIndex].missions[0].sessions[0].pageIds[0];
		let selectedPageIndex = this.state.pages.findIndex((page) => {return page.id === selectedPageId;});
		
		this.setState({storyIndex: selectedStoryIndex});
		this.selectPage({target: {value: selectedPageIndex}});
	}

	/**
	 * Select page
	 * @param {object} event 
	 */
	selectPage(event) {
		let selectedPageIndex = parseInt(event.target.value);
		let selectedPage = this.state.pages[selectedPageIndex];
		let pageInstructions = {teacher: null, group: null};
		let pageText = null;
		let pageText2 = null;
		let pageStoryText = null;
		
		getPageTexts(selectedPage).then((response) => {
			if (response.status === 'ok' && response.pageTexts) {
				pageInstructions = response.pageTexts.instructions;	
				pageText = response.pageTexts.text;
				pageText2 = response.pageTexts.text2;
				pageStoryText = response.pageTexts.storyText;
			}

			this.setState({
				pageIndex: selectedPageIndex,
				selectedPage: selectedPage,
				pageInstructions: pageInstructions,
				pageText: pageText,
				pageText2: pageText2,
				pageStoryText: pageStoryText,
				isEditingPage: false,
				isEditingPageInstructions: false,
				isEditingText: false,
				errorMsg: null
			});
		});
	}
		
	/**
	 * Update page title / menu-title
	 * @param {object} event 
	 */
	updatePage(event) {
		let selectedPage = JSON.parse(JSON.stringify(this.state.selectedPage));
		selectedPage[event.target.name] = event.target.value;
		this.setState({
			selectedPage: selectedPage,
			isEditingPage: true,
			errorMsg: null
		});
	}

	/**
	 * 
	 * @param {object} event 
	 */
	updatePageInstructions(event, role) {
		let pageInstructions = JSON.parse(JSON.stringify(this.state.pageInstructions));
		pageInstructions[role] = event.target.value;
		this.setState({
			pageInstructions: pageInstructions,
			isEditingPageInstructions: true,
			errorMsg: null
		});
	}

	/**
	 * Update page text
	 * @param {object} event 
	 */
	updatePageText(event) {
		this.setState({
			[event.target.name]: event.target.value,
			isEditingText: true,
			errorMsg: null
		});
	}


	/**
	 * Handle save page
	 */
	handleSavePage() {		
		if (
			(!this.state.isEditingPage && !this.state.isEditingText && !this.state.isEditingPageInstructions) || 
			this.state.isSaving
		) return;
		
		this.setState({isSaving: true});

		let promises = [];
		let selectedPage = JSON.parse(JSON.stringify(this.state.selectedPage));
		selectedPage.loadText = (this.state.pageText !== null && this.state.pageText.length > 0);
		selectedPage.loadText2 = (this.state.pageText2 !== null && this.state.pageText2.length > 0);
		selectedPage.loadStoryText = (this.state.pageStoryText !== null && this.state.pageStoryText.length > 0);
		selectedPage.loadTeacherInstructions = (this.state.pageInstructions.hasOwnProperty('teacher') && 
			this.state.pageInstructions.teacher !== null && this.state.pageInstructions.teacher.length > 0);
		selectedPage.loadGroupInstructions = (this.state.pageInstructions.hasOwnProperty('group') && 
			this.state.pageInstructions.group !== null && this.state.pageInstructions.group.length > 0);

		/* Save page data */
		promises.push(this.savePage(selectedPage));

		/* Save page texts */
		if (this.state.isEditingPageInstructions || this.state.isEditingText) {
			promises.push(savePageTexts(
				selectedPage.id, 
				this.state.isEditingPageInstructions ? this.state.pageInstructions : null,
				this.state.isEditingText ? this.state.pageText : null,
				this.state.isEditingText ? this.state.pageText2 : null,
				this.state.isEditingText ? this.state.pageStoryText : null
			));
		}
		Promise.all(promises).then(
			() => {
				this.setState({
					isEditingPage: false, 
					isEditingPageInstructions: false, 
					isEditingText: false, 
					isSaving: false
				});
			}, (error) => {console.error(error);this.setState({isSaving: false, errorMsg: 'save error'});}
		);		
	}

	/* Save page (database) */
	savePage(selectedPage) {
		let pageData = {
			title: selectedPage.title,
			menuTitle: (selectedPage.menuTitle ? selectedPage.menuTitle : selectedPage.title),
			storyType: (selectedPage.storyType ? selectedPage.storyType : storyTypesData[0]),
			loadText: selectedPage.loadText,
			loadText2: selectedPage.loadText2,
			loadStoryText: selectedPage.loadStoryText,
			loadTeacherInstructions: selectedPage.loadTeacherInstructions,
			loadGroupInstructions: selectedPage.loadGroupInstructions
		};
		const db = firebase.firestore();
		return db.collection('pages').doc(selectedPage.id).update(pageData);
	}


	/**
	 * Render component
	 */
	render() {
		return (
			<Pages 
				isEditing={this.state.isEditingPage || this.state.isEditingPageInstructions || this.state.isEditingText}
				isSaving={this.state.isSaving}
				stories={this.state.stories}
				pages={this.state.pages}
				storyIndex={this.state.storyIndex}
				selectedPage={this.state.selectedPage}
				pageInstructions={this.state.pageInstructions}
				pageText={this.state.pageText}
				pageText2={this.state.pageText2}
				pageStoryText={this.state.pageStoryText}
				errorMsg={this.state.errorMsg}
				selectStory={this.selectStory}
				selectPage={this.selectPage}
				updatePage={this.updatePage}
				updatePageInstructions={this.updatePageInstructions}
				updatePageText={this.updatePageText}
				handleSavePage={this.handleSavePage}
				goToPage={this.props.goToPage}
			/>
		);
	}
}

PagesController.propTypes = {
	goToPage: PropTypes.func.isRequired
};

export default PagesController;