import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {Collapse} from 'react-collapse';
import './instructions.scss';

const Instructions = ({
	instructionsStatus,
	iconType,
	pageType,
	id,
	instructionText,
	toggleInstructions,
	type,
	isTeacher
}) => {
	let iconClassName = '';
	let instructionsClassName = '';
	if (type === 'popup') {
		iconClassName = 'InstructionsIcon InstructionsIcon--' + iconType + ' InstructionsIcon--' + pageType;
		if (id) iconClassName += ' InstructionsIcon--' + id;
		instructionsClassName = 'InstructionsPopup InstructionsPopup--' + instructionsStatus;
		if (pageType) instructionsClassName += ' InstructionsPopup--' + pageType;
		if (id) instructionsClassName += ' InstructionsPopup--' + id;
		if (isTeacher) instructionsClassName += ' InstructionsPopup--teacher';

	} else {
		iconClassName = 'InstructionsIconDropdown InstructionsIconDropdown--' + instructionsStatus;
		instructionsClassName = 'InstructionsDropdown InstructionsDropdown--' + instructionsStatus;
	}

	let instructions = <div
		className={instructionsClassName}
		onClick={(e) => {e.stopPropagation(); toggleInstructions();}}
	>
		<div className="Instructions-body" onClick={(e) => {e.stopPropagation();}}>
			<div className="Instructions-text">{renderMarkdown(instructionText)}</div>
			<div className="Instructions-close" onClick={() => {toggleInstructions();}} />
		</div>
	</div>;

	return (
		<div className={'Instructions ' + instructionsStatus}>
			{type === 'popup'
				? <React.Fragment>
					<div className={iconClassName} onClick={() => {toggleInstructions();}}/>
					{instructions}
				</React.Fragment>
				: <React.Fragment>
					<div className={iconClassName} onClick={() => {toggleInstructions();}}>Vejledning</div>
					<Collapse isOpened={instructionsStatus === 'show'}>
						{instructions}
					</Collapse>
				</React.Fragment>}
		</div>
	);
};

Instructions.propTypes = {
	instructionsStatus: PropTypes.string.isRequired,
	iconType: PropTypes.string.isRequired,
	pageType: PropTypes.string.isRequired,
	id: PropTypes.string,
	instructionText: PropTypes.string.isRequired,
	toggleInstructions: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	isTeacher: PropTypes.bool.isRequired
};

export default Instructions;
