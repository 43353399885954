let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;

if (!env) {env = 'test';}

let apiURL = 'http://localhost:8080/api/';
let groupURL = 'http://localhost:3000/';

if (env === 'test') {
	apiURL = 'https://cgl-marsbasen-test.firebaseapp.com/api/';
	groupURL = 'https://cgl-marsbasen-test.web.app/';
}

if (env === 'demo') {
	apiURL = 'https://cgl-marsbasen-demo.firebaseapp.com/api/';
	groupURL = 'https://cgl-marsbasen-demo.web.app/';
}

if (env === 'production') {
	apiURL = 'https://marsbasen.dk/api/';
	groupURL = 'https://marsbasen.dk';
}

let whitelistURLs = [
	'http://localhost:3000',
	'https://cgl-marsbasen-test.firebaseapp.com',
	'https://cgl-marsbasen-test.web.app',
	'https://cgl-marsbasen-demo.firebaseapp.com',
	'https://cgl-marsbasen-demo.web.app',
	'https://cgl-marsbasen-production.firebaseapp.com',
	'https://cgl-marsbasen-production.web.app',
	'https://marsbasen.dk',
	'https://www.marsbasen.dk',
];

var config = {
	env: env,
	apiURL: apiURL,
	groupURL: groupURL,
	whitelistURLs: whitelistURLs,
	cookiesAcceptedCookieName: 'marsbasen_cookies_accepted'
};

export default config;