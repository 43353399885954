import React from 'react';
import PropTypes from 'prop-types';
import './minigame-score.scss';

const MinigameScore = ({score, maxScore}) => {
	let scoreCounterWidth = 0;
	if (maxScore > 0) scoreCounterWidth = (parseFloat(score) / parseFloat(maxScore)) * 100;
	
	return (
		<div className="MinigameScore">
			<div className="MinigameScore-slider">
				<div className="MinigameScore-counter" style={{width: scoreCounterWidth + '%'}} />
			</div>
			<div className="MinigameScore-value"><span>{score}</span> / {maxScore}</div>
		</div>
	);
};


MinigameScore.propTypes = {
	score: PropTypes.number.isRequired,
	maxScore: PropTypes.number.isRequired
};

export default MinigameScore;
