let tutorialInstructions = [
	{
		stepId: 1,
		title: 'Trin 1',
		text: [
			'Eleverne bruger pilene til at bladre gennem spor. De skriver deres besvarelse nedenfor.',
			'Når de er klar trykker de “Send”.',
		],
		prevBtn: null,
		nextBtn: 'Næste'
	},
	{
		stepId: 2,
		title: 'Trin 2',
		text: [
			'Elevernes tekster dukker op på din skærm. Her kan I sammen læse dem, og du kan redigere, slette eller markere.',
			'De markerede tekster følger med til næste trin, hvor de kan viderebearbejdes.'
		],
		prevBtn: 'Tilbage',
		nextBtn: 'Næste'
	},
	{
		stepId: 3,
		title: 'Trin 3',
		text: [
			'Nu dukker de udvalgte tekster op hos eleverne. Nedenfor står en vejledning til eleverne. I dette eksempel skal de genskrive den valgte hypotese, lave forsøgsdesign mm.'
		],
		prevBtn: 'Tilbage',
		nextBtn: 'Næste'
	},
	{
		stepId: 4,
		title: 'Trin 4',
		text: [
			'I det sidste trin dukker elevernes anden besvarelse op her. Hvis eleverne skal fremlægge mundtligt, kan de bruge denne side som reference.'
		],
		prevBtn: 'Tilbage',
		nextBtn: 'Luk'
	}
]

let tutorialGroups = [
	{
		id: 'test-1',
		index: 1,
		craterId: 'arandas',
		gameId: null,
		isPlaying: true,
		points: 0,
		investigations: {
			investigation: {
				hypothesis: {
					title: 'Overskrift',
					text: 'Tekst'
				}
			}
		}
	},
	{
		id: 'test-2',
		index: 2,
		craterId: 'bacolor',
		gameId: null,
		isPlaying: true,
		points: 0,
		investigations: {
			investigation: {
				hypothesis: {
					title: 'Overskrift',
					text: 'Tekst'
				}
			}
		}
	},
	{
		id: 'test-3',
		index: 3,
		craterId: 'eberswalde',
		gameId: null,
		isPlaying: true,
		points: 0,
		investigations: {
			investigation: {
				hypothesis: {
					title: 'Overskrift',
					text: 'Tekst'
				}
			}
		}
	}
];
export {tutorialInstructions, tutorialGroups};