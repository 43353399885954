import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup from './popup';

class PopupController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPopup: false,
			isLoading: false,
			data: null, 
			buttons: null, 
			form: null,
			formData: {},
			teacherList: null,
			errorMessage: ''
		};
		this.inputs = {};
		this.openPopup = this.openPopup.bind(this);
		this.closePopup = this.closePopup.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleButtonClick = this.handleButtonClick.bind(this);
		this.updatePopupState = this.updatePopupState.bind(this);
	};

	/**
	 * Open popup
	 * @param {object} popupData 
	 * @param {object} btnConfig 
	 * @param {string} className 
	 */
	openPopup(popupData, btnConfig, className = null) {
		let form = null;
		let formData = {};
		if (popupData.form && popupData.form.length) {
			form = popupData.form;
			popupData.form.forEach((input) => {
				if (input.type === 'text') formData[input.name] = '';
			});
		}
		this.setState({
			showPopup: true, 
			isLoading: false,
			data: popupData,
			buttons: btnConfig,
			form: form,
			formData: formData,
			teacherList: popupData.teacherList,
			className: className,
			errorMessage: '',
			closePopupAnimation: false
		}, () => {
			if (className === 'videos') {

			}
		});
	}

	/**
	 * Close popup
	 */
	closePopup() {
		if (this.state.className === 'intro' && this.state.buttons !== null) {
			this.handleButtonClick(this.state.buttons[0]);
		}
		this.setState({
			showPopup: false,
			isLoading: false,
			closePopupAnimation: true,
		});
	}

	/**
	 * Handle input
	 * @param {object} event 
	 */
	handleInput(event) {
		let type = event.target.type;
		let name = event.target.name;
		let value = event.target.value;
		let formData = this.state.formData;

		if (name === 'tagName' && value.length > 14) return;
		if (type === 'text') formData[name] = value;
		this.setState({formData: formData, errorMessage: ''});
	}

	/**
	 * Handle button click
	 * @param {object} button
	 */
	handleButtonClick(button) {
		if (button.showLoadingIcon) this.setState({isLoading: true});
		button.action(...button.parameters);
	}

	/**
	 * Update popup state
	 * Handles error messages from failed actions
	 * @param {object} updates 
	 */
	updatePopupState(updates) {
		this.setState(updates);
	}

	/**
	 * Render popup component
	 */
	render() {
		// Enforce that there's only one child, clone it and pass props
		let onlyChild = React.Children.only(this.props.children);
		let childrenCloned = React.cloneElement(onlyChild, {
			openPopup: this.openPopup,
			closePopup: this.closePopup,
			popupIsOpen: this.state.showPopup,
			popupFormData: this.state.formData,
			updatePopupState: this.updatePopupState
		});

		return (
			<React.Fragment>
				<div className={'Popup-overlay'
					+ (this.state.showPopup ? ' Popup-overlay--show' : '')
					+ (this.state.closePopupAnimation ? ' Popup-overlay--hide' : '')
					+ (this.state.className ? ' Popup-overlay--' + this.state.className : '' )
				}
				onClick={() => {
					if (this.state.data.closeBtn === true) this.closePopup();
				}}>
					<Popup 
						isLoading={this.state.isLoading}
						className={this.state.className}
						data={this.state.data}		
						btnConfig={this.state.buttons}
						formConfig={this.state.form}
						formData={this.state.formData}
						teacherList={this.state.teacherList}
						errorMessage={this.state.errorMessage}
						handleInput={this.handleInput}
						handleButtonClick={this.handleButtonClick}
						handleClose={this.closePopup}
					/>
				</div>
				{childrenCloned}
			</React.Fragment>
		);
	}
}

PopupController.propTypes = {
	children: PropTypes.any
};

export default PopupController;