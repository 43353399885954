let firstImagesData = [
	'rocket-tail.png',
	'rocket-loading.svg',
	'background.jpg',
	'group-badges/arandas.png',
	'group-badges/bacolor.png',
	'group-badges/eberswalde.png',
	'group-badges/jezero.png',
	'group-badges/korolev.png',
	'group-badges/lowell.png',
	'group-badges/magong.png',
	'group-badges/terby.png',
	'group-badges/victoria.png',
	'group-badges/zunil.png',
	'logo-cgl.svg',
	'logo-ku.svg',
	'logo-novo-nordisk-fonden.svg'
];

let imagesData = [
	'background-overview.jpg',
	'morse-code-print.svg',
	'icon-settings.svg',
	'icon-print.svg',
	'border-bottom.svg',
	'icon-up.svg',
	'icon-down.svg',
	'rotary-button.svg',
	'next-step-button.svg',
	'next-step-button-small.svg',
	'menu-border.png',
	'logo-textured.svg',
	'logo-small.svg',
	'logo-medium.svg',
	'icon-x.svg',
	'icon-play.svg',
	'icon-pencil.svg',
	'icon-next.svg',
	'icon-logout.svg',
	'icon-lock.svg',
	'icon-lock-unlocked.svg',
	'icon-loading.svg',
	'icon-loading.gif',
	'icon-loading-white.svg',
	'icon-instructions.svg',
	'icon-instructions-light.svg',
	'icon-instructions-group.svg',
	'icon-info.svg',
	'icon-fullscreen-expand.svg',
	'icon-fullscreen-contract.svg',
	'icon-experiment.svg',
	'icon-clock.svg',
	'icon-circle-light.svg',
	'icon-circle-light-full.svg',
	'icon-circle-dark.svg',
	'icon-circle-dark-full.svg',
	'icon-circle-cyan.svg',
	'icon-circle-cyan-full.svg',
	'icon-back.svg',
	'frame-red.svg',
	'frame-green.svg',
	'frame-cyan.svg',
	'frame-big-cyan.svg',
	'corner-triangle.svg',
	'control-panel.svg',
	'camera-border.svg',
	'camera-border.png',
	'pages/orbit-investigation.png',
	'pages/orbit-seasons-2.png',
	'pages/orbit-seasons-1.png',
	'pages/orbit-seasons-0.png',
	'pages/orbit-seasons-3.png',
	'pages/orbit.gif',
	'pages/marsbasen-eksplosionen-skitse-a.png',
	'pages/marsbasen-eksplosionen-skitse-b.png',
	'minigames/next-button.svg',
	'minigames/puzzles/puzzle-12-bg.svg',
	'minigames/puzzles/puzzle-26-bg.svg',
	'minigames/puzzles/puzzle-25-bg.svg',
	'minigames/puzzles/puzzle-24-bg.svg',
	'minigames/puzzles/puzzle-23-bg.svg',
	'minigames/puzzles/puzzle-16-bg.svg',
	'minigames/puzzles/puzzle-time-bg.png',
	'minigames/puzzles/puzzle-22-bg.svg',
	'minigames/puzzles/puzzle-21-bg.svg',
	'minigames/puzzles/puzzle-17-bg.svg',
	'minigames/puzzles/puzzle-18-bg.svg',
	'minigames/puzzles/puzzle-9-bg.svg',
	'minigames/puzzles/puzzle-8-bg.svg',
	'minigames/puzzles/puzzle-7-bg.svg',
	'minigames/puzzles/puzzle-6-bg.svg',
	'minigames/puzzles/puzzle-5-bg.svg',
	'minigames/puzzles/puzzle-4-bg.svg',
	'minigames/puzzles/puzzle-3-bg.svg',
	'minigames/puzzles/puzzle-20-bg.svg',
	'minigames/puzzles/puzzle-2-bg.svg',
	'minigames/puzzles/puzzle-19-bg.svg',
	'minigames/puzzles/puzzle-1-bg.svg',
	'minigames/puzzles/soundwave/arrows-downwards.svg',
	'minigames/puzzles/soundwave/arrows-from-center.svg',
	'minigames/puzzles/soundwave/arrows-to-right.svg',
	'minigames/puzzles/soundwave/arrows-to-left.svg',
	'minigames/puzzles/soundwave/arrows-towards-center.svg',
	'minigames/puzzles/soundwave/arrows-upwards.svg',
	'minigames/puzzles/soundwave/arrows-with-fluctuations.svg',
	'minigames/puzzles/soundwave/arrows-without-fluctuations.svg',
	'minigames/puzzles/soundwave/graph-1.svg',
	'minigames/puzzles/soundwave/graph-2.svg',
	'minigames/puzzles/soundwave/graph-3.svg',
	'minigames/puzzles/soundwave/graph-4.svg',
	'minigames/puzzles/soundwave/graph-5.svg',
	'minigames/puzzles/soundwave/graph-grid.svg',
	'minigames/puzzles/food/apple.svg',
	'minigames/puzzles/food/capri-sonne.svg',
	'minigames/puzzles/food/cheese.svg',
	'minigames/puzzles/food/coffee.svg',
	'minigames/puzzles/food/crackers.svg',
	'minigames/puzzles/food/hazelnuts.svg',
	'minigames/puzzles/food/marsbar.svg',
	'minigames/puzzles/food/pizza.svg',
	'minigames/puzzles/food/potato.svg',
	'minigames/puzzles/food/salat.svg',
	'minigames/puzzles/food/smarties.svg',
	'minigames/puzzles/food/spaghetti.svg',
	'minigames/puzzles/food/tortilla.svg',
	'minigames/puzzles/food/tuna.svg',
	'minigames/puzzles/food/water.svg',
	'minigames/puzzles/energy/chemical-energy.svg',
	'minigames/puzzles/energy/electric-energy.svg',
	'minigames/puzzles/energy/kinetic-energy.svg',
	'minigames/puzzles/energy/nuclear-energy.svg',
	'minigames/puzzles/energy/potential-energy.svg',
	'minigames/puzzles/energy/radiation-energy.svg',
	'minigames/puzzles/energy/thermal-energy.svg',
	'minigames/puzzles/puzzle-28-bg.svg',
	'minigames/puzzles/puzzle-29-bg.svg',
	'minigames/puzzles/puzzle-30-bg.svg',
	'minigames/puzzles/puzzle-31-bg.svg',
	'minigames/puzzles/puzzle-32-bg.svg',
	'minigames/puzzles/puzzle-33-bg.svg',
	'minigames/puzzles/puzzle-34-bg.svg',
	'minigames/puzzles/puzzle-35-bg.svg',
	'minigames/puzzles/puzzle-36-bg.svg',
	'minigames/puzzles/puzzle-37-bg.svg',
	'minigames/puzzles/puzzle-38-bg.svg',
	'minigames/puzzles/puzzle-39-bg.svg',
	'minigames/puzzles/soundwaveMath/wave-1.svg',
	'minigames/puzzles/soundwaveMath/wave-2.svg',
	'minigames/puzzles/soundwaveMath/wave-3.svg',
	'minigames/puzzles/soundwaveMath/wave-4.svg',
	'minigames/puzzles/soundwaveMath/wave-5.svg',
	'minigames/puzzles/soundwaveMath/wave-6.svg',
	'minigames/puzzles/soundwaveMath/wave-7.svg',
	'minigames/puzzles/soundwaveMath/wave-8.svg',
	'minigames/puzzles/soundwaveMath/wave-9.svg',
	'minigames/puzzles/soundwaveMath/wave-10.svg',
	'minigames/puzzles/soundwaveMath/wave-11.svg',
	'minigames/puzzles/soundwave2/arrow-left-cyan.svg',
	'minigames/puzzles/soundwave2/arrow-left.svg',
	'minigames/puzzles/soundwave2/arrow-right-cyan.svg',
	'minigames/puzzles/soundwave2/arrow-right.svg',
	'minigames/puzzles/soundwave2/bacteria.svg',
	'minigames/puzzles/soundwave2/dna.svg',
	'minigames/puzzles/soundwave2/family.svg',
	'minigames/puzzles/soundwave2/football-field.svg',
	'minigames/puzzles/soundwave2/gamma-rays.svg',
	'minigames/puzzles/soundwave2/molecule.svg',
	'minigames/puzzles/soundwave2/infrared-waves.svg',
	'minigames/puzzles/soundwave2/microwaves.svg',
	'minigames/puzzles/soundwave2/needle.svg',
	'minigames/puzzles/soundwave2/radio-waves-radio.svg',
	'minigames/puzzles/soundwave2/radio-waves-router.svg',
	'minigames/puzzles/soundwave2/virus.svg',
	'minigames/puzzles/soundwave2/ultraviolet.svg',
	'minigames/puzzles/soundwave2/x-rays.svg',
	'investigations/investigation-8/clue-1.svg',
	'investigations/investigation-8/clue-2.svg',
	'investigations/investigation-8/clue-3.svg',
	'investigations/investigation-8/clue-4.svg',
	'investigations/investigation-7/clue-1.svg',
	'investigations/investigation-6/clue-1.svg',
	'investigations/investigation-5/clue-2.svg',
	'investigations/investigation-3/clue-1.svg',
	'investigations/investigation-2/clue-2.svg',
	'investigations/investigation-2/clue-3.svg',
	'investigations/investigation-2/clue-5.svg',
	'investigations/investigation-2/clue-7.png',
	'investigations/investigation-1/clue-1.jpg',
	'investigations/investigation-1/clue-2.jpg',
	'investigations/investigation-1/clue-3.jpg',
	'investigations/striped-background.png',
	'investigations/icon-delete.svg',
	'investigations/icon-arrow-up.svg',
	'investigations/icon-checkmark.svg',
	'investigations/icon-checkbox.svg',
	'investigations/next-step-button.svg',
	'investigations/icon-star.svg',
	'investigations/icon-star-selected.svg',
	'investigations/icon-prev.svg',
	'investigations/icon-next.svg',
	'investigations/icon-edit.svg',
	'cyoa/rover-navigation.jpg',
	'cyoa/rover-storm.jpg',
	'camera/cam-1.png',
	'camera/cam-2.png',
	'camera/cam-3.png',
	'camera/map-after.jpg',
	'camera/phet-simulator.png',
	'camera/solar-cells-before.png',
	'camera/wave-simulator.png',
	'camera/morse-code.svg',
	'camera/sciendomus.png',
	'camera/map-print.png',
	'base/foreground.svg',
	'base/tag-bg-full.svg',
	'base/tag-bg-full-pulsating.svg',
	'base/tag-cone-full.svg',
	'base/tag-empty-glow.svg',
	'base/tag-name-bg.svg',
	'base/elements-repaired/3d-base.svg',
	'base/elements-repaired/3d-printer.svg',
	'base/elements-repaired/background.svg',
	'base/elements-repaired/base.svg',
	'base/elements-repaired/communication.svg',
	'base/elements-repaired/dome.svg',
	'base/elements-repaired/dorms.svg',
	'base/elements-repaired/drone.svg',
	'base/elements-repaired/entrance.svg',
	'base/elements-repaired/garage.svg',
	'base/elements-repaired/greenhouse.svg',
	'base/elements-repaired/ice.svg',
	'base/elements-repaired/lab.svg',
	'base/elements-repaired/rocket.svg',
	'base/elements-repaired/rover-curiosity.svg',
	'base/elements-repaired/satellite.svg',
	'base/elements-repaired/solar-cells.svg',
	'base/elements-repaired/vehicle-big.svg',
	'base/elements-repaired/vehicle-small.svg',
	'base/elements-repaired/crater.svg',
	'base/elements-repaired/hidden-base.svg',
	'base/elements-repaired/spacesuit.svg',
	'base/elements/background.svg',
	'base/elements/communication.svg',
	'base/elements/dome.svg',
	'base/elements/dorms.svg',
	'base/elements/drone.svg',
	'base/elements/entrance.svg',
	'base/elements/garage.svg',
	'base/elements/greenhouse.svg',
	'base/elements/ice.svg',
	'base/elements/lab.svg',
	'base/elements/rover-curiosity.svg',
	'base/elements/satellite.svg',
	'base/elements/solar-cells.svg',
	'base/elements/vehicle-big.svg',
	'base/elements/vehicle-small.svg',
	'base/elements/stars.svg',
	'infoMinigames/infoMinigame-1/image-rock-1.png',
	'infoMinigames/infoMinigame-1/image-rock-2.png',
	'infoMinigames/infoMinigame-1/image-rock-3.png',
	'infoMinigames/infoMinigame-1/rock-1-A1.png',
	'infoMinigames/infoMinigame-1/rock-1-A2.png',
	'infoMinigames/infoMinigame-1/rock-1-A3.png',
	'infoMinigames/infoMinigame-1/rock-1-A4.png',
	'infoMinigames/infoMinigame-1/rock-1-B1.png',
	'infoMinigames/infoMinigame-1/rock-1-B2.png',
	'infoMinigames/infoMinigame-1/rock-1-B3.png',
	'infoMinigames/infoMinigame-1/rock-1-B4.png',
	'infoMinigames/infoMinigame-1/rock-1-C1.png',
	'infoMinigames/infoMinigame-1/rock-1-C2.png',
	'infoMinigames/infoMinigame-1/rock-1-C3.png',
	'infoMinigames/infoMinigame-1/rock-1-C4.png',
	'infoMinigames/infoMinigame-1/rock-1-D1.png',
	'infoMinigames/infoMinigame-1/rock-1-D2.png',
	'infoMinigames/infoMinigame-1/rock-1-D3.png',
	'infoMinigames/infoMinigame-1/rock-1-D4.png',
	'infoMinigames/infoMinigame-1/rock-2-A1.png',
	'infoMinigames/infoMinigame-1/rock-2-A2.png',
	'infoMinigames/infoMinigame-1/rock-2-A3.png',
	'infoMinigames/infoMinigame-1/rock-2-A4.png',
	'infoMinigames/infoMinigame-1/rock-2-B1.png',
	'infoMinigames/infoMinigame-1/rock-2-B2.png',
	'infoMinigames/infoMinigame-1/rock-2-B3.png',
	'infoMinigames/infoMinigame-1/rock-2-B4.png',
	'infoMinigames/infoMinigame-1/rock-2-C1.png',
	'infoMinigames/infoMinigame-1/rock-2-C2.png',
	'infoMinigames/infoMinigame-1/rock-2-C3.png',
	'infoMinigames/infoMinigame-1/rock-2-C4.png',
	'infoMinigames/infoMinigame-1/rock-2-D1.png',
	'infoMinigames/infoMinigame-1/rock-2-D2.png',
	'infoMinigames/infoMinigame-1/rock-2-D3.png',
	'infoMinigames/infoMinigame-1/rock-2-D4.png',
	'infoMinigames/infoMinigame-1/rock-3-A1.png',
	'infoMinigames/infoMinigame-1/rock-3-A2.png',
	'infoMinigames/infoMinigame-1/rock-3-A3.png',
	'infoMinigames/infoMinigame-1/rock-3-A4.png',
	'infoMinigames/infoMinigame-1/rock-3-B1.png',
	'infoMinigames/infoMinigame-1/rock-3-B2.png',
	'infoMinigames/infoMinigame-1/rock-3-B3.png',
	'infoMinigames/infoMinigame-1/rock-3-B4.png',
	'infoMinigames/infoMinigame-1/rock-3-C1.png',
	'infoMinigames/infoMinigame-1/rock-3-C2.png',
	'infoMinigames/infoMinigame-1/rock-3-C3.png',
	'infoMinigames/infoMinigame-1/rock-3-C4.png',
	'infoMinigames/infoMinigame-1/rock-3-D1.png',
	'infoMinigames/infoMinigame-1/rock-3-D2.png',
	'infoMinigames/infoMinigame-1/rock-3-D3.png',
	'infoMinigames/infoMinigame-1/rock-3-D4.png',
	'infoMinigames/infoMinigame-2/graph-common.png',
	'infoMinigames/infoMinigame-2/graph-kitchen.png',
	'infoMinigames/infoMinigame-2/graph-office.png',
	'infoMinigames/infoMinigame-2/graph-reactor.png',
	'infoMinigames/infoMinigame-2/graph-workshop-lab.png',
	'infoMinigames/infoMinigame-2/rooms.png',
];

export {firstImagesData, imagesData};