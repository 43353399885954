import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {evaluationUiTexts} from 'data/ui-texts';
import {evaluationData} from 'data/evaluation-data';
import Button from 'components/button/button';
import EvaluationDnDContainer from './evaluation-dnd-container';
import EvaluationDnDItem from './evaluation-dnd-item';
import EvaluationDndDragLayer from './evaluation-dnd-drag-layer';
import './evaluation.scss';

const Evaluation = (props) => {
	let {
		isSavingEvaluation,
		isSavingParamXName,
		isSavingParamYName,
		isEditingEvaluation,
		isEditingParamXName,
		isEditingParamYName,
		paramXName,
		paramYName,
		groups, 
		groupIds, 
		evaluationGrid, 
		hoverGroupId, 
		setHoverGroupId, 
		handleInput,
		handleSaveParamName,
		handleSaveEvaluation,
		focusOnInput,
		handleInputFocus,
		handleInputBlur,
		handleGridChanges,
		pageTitle
	} = props;


	let [groupsList, setGroups] = useState(groupIds);
	useEffect(() => {setGroups(groupIds);}, [groupIds]);

	let [squares, setSquares] = useState(evaluationGrid);
	useEffect(() => {setSquares(evaluationGrid);}, [evaluationGrid]);

	const handleDrop = (groupId, toSquareIndex) => {
		const fromSquareIndex = findGroup(groupId);
		if (toSquareIndex === fromSquareIndex || toSquareIndex < 0) return;

		/* Move within grid */
		if (toSquareIndex >= 0 && fromSquareIndex >= 0) {
			let newSquares = JSON.parse(JSON.stringify(squares));
			newSquares[fromSquareIndex].groupIds.splice(newSquares[fromSquareIndex].groupIds.indexOf(groupId), 1);
			newSquares[toSquareIndex].groupIds.push(groupId);
			setSquares(newSquares);
			handleGridChanges(newSquares);
		}

		/* Move to grid */
		if (toSquareIndex >= 0 && fromSquareIndex < 0) {
			let newSquares = JSON.parse(JSON.stringify(squares));
			let newGroupsList = JSON.parse(JSON.stringify(groupsList));
			newGroupsList.splice(newGroupsList.indexOf(groupId), 1);
			newSquares[toSquareIndex].groupIds.push(groupId);
			setSquares(newSquares);
			setGroups(newGroupsList);
			handleGridChanges(newSquares);

		}
	};

	const findGroup = (groupId) => {
		let squareIndex = -1;
		if (squares.some((square) => {return square.groupIds.indexOf(groupId) >= 0 ;})) {
			squareIndex = squares.filter((square) => {return square.groupIds.indexOf(groupId) >= 0 ;})[0].index;
		}
		return squareIndex;
	};

	
	return (
		<div className="Evaluation">
			<div className="Evaluation-title">{pageTitle}</div>
			<div className="Evaluation-container">
				<div className="EvaluationGrid">
					{squares.map((square, index) => {
						return (
							<EvaluationDnDContainer 
								key={index} 
								squareIndex={index} 
								numberOfGroups={square.groupIds.length} 
								handleDrop={handleDrop}
							>
								{square.groupIds.map((groupId) => {
									let group = groups.filter((group) => {return group.id === groupId;})[0];
									let isPlaying = group.isPlaying;
									if (isPlaying) {
										return (
											<EvaluationDnDItem
												key={groupId}
												squareIndex={index}
												isPlaced={true}
												isDraggable={!isSavingEvaluation}
												showHoverEffect={group.id === hoverGroupId}
												group={group}
												findGroup={findGroup}
												setHoverGroupId={setHoverGroupId}
											/>
										);
									}
									return null;
								})}
							</EvaluationDnDContainer>
						);
					})}
				</div>
				<div className="EvaluationGroups">
					{groups.map((group) => {
						let isPlaced = (groupsList.indexOf(group.id) === -1);
						let isPlaying = group.isPlaying;
						if (isPlaying) {
							return (
								<EvaluationDnDItem
									key={group.id}
									squareIndex={-1}
									isPlaced={isPlaced}
									isDraggable={(!isSavingEvaluation && !isPlaced)}
									showHoverEffect={group.id === hoverGroupId}
									group={group}
									findGroup={findGroup}
									setHoverGroupId={setHoverGroupId}
								/>
							);
						}
						return null;
					})}
				</div>
				<EvaluationDndDragLayer groups={groups} />
				<div className="Evaluation-axis Evaluation-axis--x">
					<div className="Evaluation-axisLabel">
						{[...Array(evaluationData.maxVal - evaluationData.minVal + 1)].map((_, index) =>  {
							return <div key={index} className="Evaluation-axisTic">{index + 1}</div>;
						})}
					</div>
					<form className="Evaluation-axisTitle" onClick={() => {focusOnInput('paramXName');}}>
						<input
							id="paramXName" 
							name="paramXName"
							type="text"
							placeholder=''
							autoComplete="evaluation-param-x"
							value={paramXName ? paramXName : ''}
							maxLength="30"
							onChange={(event)=>{handleInput(event);}}
							onFocus={(event)=>{handleInputFocus(event);}}
							onBlur={(event)=>{handleInputBlur(event);}}
						/>
						<Button
							hidden={!isEditingParamXName}
							isLoading={isSavingParamXName}
							text={evaluationUiTexts.save} 
							onClick={()=>{handleSaveParamName('paramXName');}}
						/>
					</form>
				</div>
				<div className="Evaluation-axis Evaluation-axis--y">
					<form className="Evaluation-axisTitle" onClick={() => {focusOnInput('paramYName');}}>
						<input
							id="paramYName" 
							name="paramYName"
							type="text"
							placeholder=''
							autoComplete="evaluation-param-y"
							value={paramYName ? paramYName : ''}
							maxLength="30"
							onChange={(event)=>{handleInput(event);}}
							onFocus={(event)=>{handleInputFocus(event);}}
							onBlur={(event)=>{handleInputBlur(event);}}
						/>
						<Button
							hidden={!isEditingParamYName}
							isLoading={isSavingParamYName}
							text={evaluationUiTexts.save} 
							onClick={()=>{handleSaveParamName('paramYName');}} 
						/>
					</form>
					<div className="Evaluation-axisLabel">
						{[...Array(evaluationData.maxVal - evaluationData.minVal + 1)].map((_, index) =>  {
							return (
								<div key={index} className="Evaluation-axisTic">{evaluationData.maxVal - index}</div>
							);
						})}
					</div>
				</div>
				<div className="Evaluation-saveBtn">
					<Button
						disabled={isEditingEvaluation ? false : true}
						isLoading={isSavingEvaluation}
						text={evaluationUiTexts.saveEvaluation}
						onClick={() => {handleSaveEvaluation(squares, groupsList);}}
					/>
				</div>
			</div>
		</div>
	);
};


Evaluation.propTypes = {
	isSavingEvaluation: PropTypes.bool.isRequired,
	isSavingParamXName: PropTypes.bool.isRequired,
	isSavingParamYName: PropTypes.bool.isRequired,
	isEditingEvaluation: PropTypes.bool.isRequired,
	isEditingParamXName: PropTypes.bool.isRequired,
	isEditingParamYName: PropTypes.bool.isRequired,
	handleGridChanges: PropTypes.func.isRequired,
	paramXName: PropTypes.string.isRequired,
	paramYName: PropTypes.string.isRequired,
	groups: PropTypes.array.isRequired,
	groupIds: PropTypes.array.isRequired,
	evaluationGrid: PropTypes.array.isRequired,
	hoverGroupId: PropTypes.string,
	setHoverGroupId: PropTypes.func.isRequired,
	handleInput: PropTypes.func.isRequired,
	handleSaveParamName: PropTypes.func.isRequired,
	handleSaveEvaluation: PropTypes.func.isRequired,
	focusOnInput: PropTypes.func.isRequired,
	handleInputFocus: PropTypes.func.isRequired,
	handleInputBlur: PropTypes.func.isRequired,
	pageTitle: PropTypes.string.isRequired
};

export default Evaluation;
