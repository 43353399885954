import React from 'react';
import PropTypes from 'prop-types';
import {overviewUiTexts} from 'data/ui-texts';
import {subjectsData} from 'data/content-data';
import './overview-story.scss';
import { getStatusOfMission } from 'helpers/game-flow-helper';

const OverviewStory = ({isTeacher, storyIndex, game, stories, goToSession}) => {
	let selectedStory = stories.filter((story) => {return story.storyIndex === storyIndex;})[0];

	return (
		<div className={'OverviewStory OverviewStory--' + (isTeacher ? 'teacher' : 'group')}>
			<div className="OverviewStory-info">
				<div className="OverviewStory-title">
					<div className="OverviewStory-titleBackground"><div className="OverviewStory-titleBorder" /></div>
					<div className="OverviewStory-titleText">{selectedStory.title}</div>
				</div>
				<div className="OverviewStory-text">
					<div className="OverviewStory-introText">{selectedStory.introduction}</div>
					{(selectedStory.objectives && selectedStory.objectives.length > 0) &&
						<div className="OverviewStory-objectivesText">
							<p><span>
								{(selectedStory.objectives.length > 1 
									? overviewUiTexts.objectives 
									: overviewUiTexts.objective)}
								:</span></p>
							{selectedStory.objectives.map((objective, index) => {
								return <p key={index}><span>- </span>{objective}</p>;
							})}
						</div>}
				</div>
			</div>

			<div className={'OverviewMissions OverviewMissions--' + selectedStory.missions.length}>
				{selectedStory.missions.map((mission, missionIndex) => {
					let missionStatus = getStatusOfMission(game, mission);
					return (
						<div key={missionIndex} className={'OverviewMission OverviewMission--' + missionStatus}>
							<div className="OverviewMission-info">
								<div className="OverviewMission-title">{mission.title}</div>
								<div className="OverviewMission-text">{mission.description}</div>
							</div>
							<div className={'OverviewSessions OverviewSessions--' + mission.sessions.length 
									+ ' OverviewSessions--' + selectedStory.id}>
								{mission.sessions.map((session, sessionIndex) => {
									let sessionStatus = 'ready';
									if (game.sessions.some((gameSession) => {return gameSession.id === session.id;})) {
										sessionStatus = game.sessions.filter((gameSession) => {
											return gameSession.id === session.id;
										})[0].status;
									}
									if (game.activeSessionId && game.activeSessionId === session.id) {
										sessionStatus = 'active';
									}
									let className = 'OverviewSession OverviewSession--' + sessionStatus +
											(session.isExperiment ? ' OverviewSession--experiment' : '');
									return (
										<div key={sessionIndex} className={className} onClick={() => {
											if (
												session.pageIds && session.pageIds.length > 0 &&
												(isTeacher || sessionStatus !== 'ready')
											) {
												goToSession(session.id, session.title, session.pageIds[0]);
											}
										}}>
											<div className="OverviewSession-title" >{session.title}	</div>
											<div className="OverviewSession-text">
												{(session.subjectIds && session.subjectIds.length > 0) &&
												<div className="OverviewSession-subjectsText">
													<p>{overviewUiTexts.coreMaterial}</p>
													{session.subjectIds.map((subjectId, index) => {
														let subject = overviewUiTexts.nan;
														if (subjectsData.hasOwnProperty(subjectId)) {
															subject = subjectsData[subjectId].name;
														} else {
															console.error('Unknown subjectId: ' + subjectId);
														}
														return <span key={index}>
															{subject}
															{index !== session.subjectIds.length - 1 ? ', ' : '' }
														</span>;
													})}
												</div>}
												{isTeacher && <div className="OverviewSession-durationText">
													{session.minutes} {overviewUiTexts.minutes}
												</div>}
											</div>
											<div className="OverviewSession-playBtn" onClick={() => {
												if (session.pageIds && session.pageIds.length > 0) {
													goToSession(session.id, session.title, session.pageIds[0]);
												}
											}}/>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
		</div>

	);
};


OverviewStory.propTypes = {
	isTeacher: PropTypes.bool.isRequired,
	storyIndex: PropTypes.number.isRequired,
	game: PropTypes.object.isRequired,
	stories: PropTypes.array.isRequired,
	goToSession: PropTypes.func.isRequired
};

export default OverviewStory;
