import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import LoginTeacher from './login-teacher';
import {errorFeedbackHelper, emailErrors} from 'helpers/error-feedback-helper';
import errorTexts from 'data/error-texts';

class LoginTeacherController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: null,
			password: null,
			feedback: null,
			isLoading: false,
			invalidInputEmail: false,
			invalidInputPassword: false
		};
		this.handleLogin = this.handleLogin.bind(this);
		this.handleInput = this.handleInput.bind(this);
	}

	/**
	 * Update input field
	 * @param {obj} event 
	 */
	handleInput(event) {
		let value = event.target.value;
		let name = event.target.name;
		this.setState({
			[name]: value,
			feedback: null,
			['invalidInput' + name.charAt(0).toUpperCase() + name.slice(1)]: false
		});
	}
	
	/**
	 * Handle login
	 * @param {obj} event 
	 */
	handleLogin(event) {
		event.preventDefault();
		this.setState({isLoading: true});
		let email = this.state.email;
		let password = this.state.password;
		
		let error = null;
		if (!email || !password) error = 'empty-fields';
		if (!error) {
			firebase.auth().signInWithEmailAndPassword(email, password)
				.catch((error) => {
					let errorStatus = errorFeedbackHelper(error.code);
					let errorMsg = errorTexts[errorStatus];
					let invalidInputEmail = emailErrors.indexOf(errorStatus) === -1 ? false : true;
					this.setState({
						feedback: errorMsg,
						isLoading: false,
						invalidInputEmail: invalidInputEmail,
						invalidInputPassword: !invalidInputEmail
					});
				});
		} else {
			const msg = errorTexts[errorFeedbackHelper(error)];
			this.setState({
				feedback: msg,
				isLoading: false,
				invalidInputEmail: email ? false : true,
				invalidInputPassword: password ? false : true
			});
		}
	}


	render() {
		return (
			<LoginTeacher
				handleLogin={this.handleLogin}
				handleInput={this.handleInput}
				goToTeacherBox={this.props.goToTeacherBox}
				email={this.state.email}
				password={this.state.password}
				feedback={this.state.feedback}
				isLoading={this.state.isLoading}
				invalidInputEmail={this.state.invalidInputEmail}
				invalidInputPassword={this.state.invalidInputPassword}
			/>
		);
	}
}

LoginTeacherController.propTypes = {
	goToTeacherBox: PropTypes.func.isRequired,
};

export default LoginTeacherController;
