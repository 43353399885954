import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown, addParagraph} from 'helpers/text-helper';
import {popupUiTexts} from 'data/ui-texts';
import Button from 'components/button/button';
import './popup.scss';

const Popup = (props) => {
	let {
		isLoading,
		className,
		data,
		btnConfig,
		formConfig,
		formData,
		teacherList,
		errorMessage,
		handleInput,
		handleButtonClick,
		handleClose
	} = props;

	let popupClass = 'Popup'; 
	if (className) popupClass += ' Popup--' + className;

	let buttons = null;
	let buttonsClass = null;
	if (btnConfig) {
		buttonsClass = 'Popup-buttons Popup-buttons--' + btnConfig.length;
		buttons = <div className={buttonsClass}>{btnConfig.map((button, index) => {
			return <Button
				key={index}
				class="popup"
				type={button.type}
				text={button.text}
				isLoading={button.showLoadingIcon && isLoading}
				onClick={() => {handleButtonClick(button);}}
			/>;
		})}</div>;
	}

	let form = null;
	if (formConfig) {
		form = <div className="Popup-form">{formConfig.map((input, index) => {
			if (input.type === 'text') {
				return (
					<div key={index} className='PopupForm-text' >
						{input.label && <p>{input.label}</p>}
						<input
							key={index}								
							name={input.name} 
							placeholder={input.placeholder} 
							value={formData[input.name]} 
							onChange={(event)=>{handleInput(event);}}
						/>
					</div>
				);
			}
			if (input.type === 'checkbox') {
				let checkBoxes = input.options.map((option, index) => {
					let selected = formData[input.name][index].checked;
					let event = {target: {type: 'checkbox', name: input.name, value: index}};		
					let className = 'PopupForm-selectGroup PopupForm-selectGroup--' + option.id;
					if (selected) className += ' selected';
					return (
						<div 
							key={index} 
							className={className}
							onClick={() => {handleInput(event);}}
						>{option.name}
						</div>
					);
				});
				return (
					<div className="PopupForm-countries" key={index}>
						<p>{input.label}</p>
						{checkBoxes}
					</div>
				);
			}
			if (input.type === 'submit') {
				return (
					<Button 
						key={index} 
						class="dark"
						text={input.text}
						isLoading={isLoading}
						onClick={() => {handleButtonClick(input.button);}}
					/>
				);
			}
			return null;
		})}</div>;
	}

	let teachers = null;
	if (teacherList) {
		teachers = 
			<div className="Popup-teachers">
				<p>{popupUiTexts.invitedTeachers}:</p>
				{teacherList.map((teacher, index) => {
					let className = 'Popup-teacher' + (teacher.created ? ' PopupTeacher--created' : '');
					return (
						<div className={className} key={index}>
							{teacher.name}
							<Button 
								class="deleteTeacher"
								text={teacher.button.text} 
								onClick={() => {handleButtonClick(teacher.button);}}
							/>
						</div>);
				})}
				{teacherList.length === 0 && <div>{popupUiTexts.noTeachers}</div>}
			</div>;
	}


	
	
	return (
		<div className={popupClass} onClick={(e) => {e.stopPropagation();}}>
			{data && <React.Fragment>
				<div className="Popup-title">{data.title}</div>
				<div className="Popup-close" onClick={handleClose} />
				<div className="Popup-body">
					<div className="Popup-text">
						{data.text
							? Array.isArray(data.text) ? addParagraph(data.text) : renderMarkdown(data.text)
							: ''}
						{(className === 'videos' && data.videos) &&
							<ul>
								{data.videos.map((video, index) => {
									console.log(video.url);
									return (
										<li key={index}><a href={video.url} download>{video.name}</a></li>
									);
								})}
							</ul>
						}
					</div>
					{form}
					<div className="Popup-errorMessage">{errorMessage}</div>
					{teachers}
					{buttons}
				</div>
			</React.Fragment>}
		</div>
	);
};

Popup.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	className: PropTypes.string,
	data: PropTypes.object,
	btnConfig: PropTypes.array,
	formConfig: PropTypes.array,
	formData: PropTypes.object.isRequired,
	teacherList: PropTypes.array,
	errorMessage: PropTypes.string.isRequired,
	handleInput: PropTypes.func.isRequired,
	handleButtonClick: PropTypes.func.isRequired,
	handleClose: PropTypes.func.isRequired	
};

export default Popup;