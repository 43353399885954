/**
 * Error feedback for login
 * @param {string} errorCode 
 */
const errorFeedbackHelper = function(errorCode) {
	let status = null;

	switch (errorCode) {
	// Browser
	case 'browser-old':
		status = 'browserOld';
		break;
	case 'browser-unknown':
		status = 'browserUnknown';
		break;

	// Create user (teacher)
	case 'empty-fields':
		status = 'emptyFields';
		break;
	case 'auth/email-already-exists': 
		status = 'emailExists';
		break;
	case 'weak-password': 
	case 'auth/weak-password': 	
	case 'no-password':
	case 'auth/invalid-password': 
		status = 'invalidPassword';
		break;
	case 'no-password-match':
		status = 'noPasswordMatch';
		break;

	// Login / reset password (teacher)
	case 'auth/user-not-found':
		status = 'emailNotFound';
		break;
	case 'no-email': 
	case 'auth/wrong-password':
		status = 'invalidEmailPasswordFormat';
		break;
	case 'auth/invalid-email': 
		status = 'invalidEmail';
		break;

	// Login (group)
	case 'no-game-code':
	case 'wrong-game-code':
		status = 'noGameCodeMatch';
		break;

	// Create game
	case 'invalid-chars':
		status = 'invalidCharacters';
		break;
	case 'name-too-long':
		status = 'groupNameTooLong';
		break;
	case 'no-name':
		status = 'noGroupName';
		break;
	case 'not-enough-groups':
		status = 'notEnoughGroups';
		break;

	// Add guest teacher
	case 'no-email-match':
		status = 'noEmailMatch';
		break;
	case 'invalid-email-chars': 
		status = 'invalidEmailChars';
		break;
	case 'own-email':
		status = 'ownEmail';
		break;
	case 'email-already-added':
		status = 'emailAlreadyAdded';
		break;
	case 'max-teachers-reached':
		status = 'maxTeachersReached';
		break;

	// Default
	default:
		status = 'unknownError';
	}

	return status;
};

const emailErrors = ['emailExists', 'emailNotFound', 'invalidEmail'];

export { errorFeedbackHelper, emailErrors };