import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WriteSubmission from './write-submission';
import GroupSubmissions from './group-submissions';
import Clues from './clues';
import ManageSubmissions from './manage-submissions';
import {tutorialInstructions, tutorialGroups} from 'data/investigation-tutorial-data';
import {addParagraph} from 'helpers/text-helper';
import Draggable from 'react-draggable';
import './investigation-tutorial.scss';

class InvestigationTutorial extends Component {
	constructor(props) {
		super(props);
		this.state = {
			investigation: this.props.investigation,
			investigationStepIndex: 0,
			tutorialStepIndex: 0,
			isOpenForSubmissions: true,
			text: '',
			title: '',
			group: {
				id: 'test-1',
				index: 1,
				craterId: 'arandas',
				gameId: null,
				isPlaying: true,
				points: 0,
				investigations: {}
			},
			tutorialSteps: this.props.investigation.steps.length === 1
				? tutorialInstructions.slice(0, 2)
				: tutorialInstructions,
			groups: tutorialGroups,
			hypothesis: {selectedGroups: [tutorialGroups[0]]},
			experiment: {selectedGroups: [tutorialGroups[0]]},
		};
	};

	/**
	 * Go to instruction step
	 * @param {number} tutorialStepIndex
	 */
	goToStep = (tutorialStepIndex) => {
		if (tutorialStepIndex >= this.state.tutorialSteps.length) {
			this.props.toggleTutorial(false);
		} else {
			this.setState({tutorialStepIndex: tutorialStepIndex});
		}
	}

	/**
	 * Select / deselect group
	 */
	toggleGroup = (groupId) => {
		let currentPart = this.state.investigation.steps[this.state.investigationStepIndex].part;
		let selectedGroups = this.state[currentPart].selectedGroups;
		let groupIndex = selectedGroups.findIndex((group) => {return group.id === groupId;});

		if (groupIndex >= 0) {
			selectedGroups.splice(groupIndex, 1);
		} else {
			let groupObj = {id: groupId, title: '', text: ''};
			selectedGroups.push(groupObj);
		}
		this.setState({[currentPart]: {selectedGroups: selectedGroups}});
	}

	/**
	 * Select / deselect group
	 */
	toggleIsOpenForSubmissions = () => {
		let isOpenForSubmissions = !this.state.isOpenForSubmissions;
		this.setState({isOpenForSubmissions});
	}

	/**
	 * Reject group submission
	 */
	rejectTutorialGroupSubmission = (groupId) => {
		let groups = JSON.parse(JSON.stringify(this.state.groups));
		let groupIndex = groups.findIndex((group) => {return group.id === groupId;});
		groups.splice(groupIndex, 1);
		this.setState({groups});
	}

	updateGroup = (groupsUpdates) => {
		let groupUpdatesInvestigations = groupsUpdates[0].updates.investigations;
		return new Promise((resolve, _) => {
			this.setState({
				group: {
					...this.state.group,
					investigations: {
						[this.state.investigation.id]: groupUpdatesInvestigations[this.state.investigation.id]
					}
				}
			}, () => {resolve();});
		});
	}

	onStart = () => {
		this.setState({
			isDragging: true,
			activeDrags: this.state.activeDrags + 1
		});
	};

	onStop = () => {
		this.setState({
			isDragging: false,
			activeDrags: this.state.activeDrags - 1
		});
	};

	render() {
		let content = null;
		let investigationStep = this.state.investigation.steps[this.state.investigationStepIndex];
		let currentPart = investigationStep.part;
		let stepIndex = this.state.investigationStepIndex;
		let nextStepIndex = null;
		let prevStepIndex = null;
		if (this.state.investigation.steps.length > (stepIndex + 1)) nextStepIndex = stepIndex + 1;
		if (stepIndex > 0) prevStepIndex = stepIndex - 1;

		/* Tutorial steps and instructions - depends on whether the investigation has 1 or 2 steps */
		let tutorialSteps = JSON.parse(JSON.stringify(this.state.tutorialSteps));
		if (this.state.tutorialStepIndex === 1 && tutorialSteps.length === 2) {
			tutorialSteps = tutorialSteps.map((step, index) => {
				if (index === this.state.tutorialStepIndex) {
					step.text.pop();
					step.nextBtn = 'Luk';
				}
				return step;
			});
		}

		/* Clues - special clue for step 3 of the tutorial */
		let investigationClues = this.state.investigation.clues[investigationStep.part];
		if (this.state.tutorialStepIndex === 2) {
			investigationClues = [{type: 'text', text: 'Her står de tekster som du har markeret.'}];
		}
		
		switch (this.state.tutorialStepIndex) {
		case 0:
		case 2:
			content = <React.Fragment>
				{(investigationStep.startingPoint === 'clues' && investigationClues) &&
					<Clues 
						investigationId={this.state.investigation.id}
						clueIndex={0}
						investigationClues={investigationClues}
						investigationPart={currentPart}
					/>}

				{(investigationStep.startingPoint === 'selectedGroups' && this.state.selectedGroup) &&
					<GroupSubmissions
						groupIndex={0}
						selectedGroups = {[this.state.selectedGroup]}
					/>}
				<WriteSubmission
					isOpenForSubmissions={this.state.isOpenForSubmissions}
					investigationId={this.state.investigation.id}
					investigationPart={currentPart}
					group={this.state.group}
					isTutorial={true}
				/>
			</React.Fragment>;
			break;
		case 1:
		case 3:
			content = <ManageSubmissions
				investigationId={this.state.investigation.id}
				investigationStep={this.state.investigation.steps[this.state.investigationStepIndex]}
				nextStepIndex={nextStepIndex}
				prevStepIndex={prevStepIndex}
				groups={this.state.groups}
				selectedGroups={this.state[currentPart].selectedGroups}
				isOpenForSubmissions={this.state.isOpenForSubmissions}
				toggleIsOpenForSubmissions={this.toggleIsOpenForSubmissions}
				toggleGroup={this.toggleGroup}
				updateGroups={this.updateGroup}
				openPopup={this.props.openPopup}
				closePopup={this.props.closePopup}
				nrOfSteps={this.state.investigation.steps.length}
				isTutorial={true}
				rejectTutorialGroupSubmission={this.rejectTutorialGroupSubmission}
			/>;
			break;
		default:
			break;
		}
		return (
			<div className={'InvestigationTutorial InvestigationTutorial--' + this.state.investigation.id}>
				<div className="Investigation-close" onClick={() => {this.props.toggleTutorial(false);}} />
				<Draggable
					handle="strong"
					onStart={this.onStart}
					onStop={this.onStop}
				>
					<div className={'box no-cursor InvestigationTutorial-instructions'}
						onClick={(e) => {e.stopPropagation();}}
					>
						<strong className="cursor">
							<div
								className={'InvestigationTutorial-dragBtn ' + (this.state.isDragging ? 'dragging' : '')}
								onClick={(e)=>{e.stopPropagation();}} />
						</strong>
						<div className="InvestigationTutorial-instructionsTitle">
							{tutorialSteps[this.state.tutorialStepIndex].title}
						</div>
						<div className="InvestigationTutorial-instructionsText">
							{addParagraph(tutorialSteps[this.state.tutorialStepIndex].text)}
						</div>
						<div className="InvestigationTutorial-instructionsButtons">
							{tutorialSteps[this.state.tutorialStepIndex].prevBtn &&
								<div 
									className="InvestigationTutorial-instructionsPrevBtn"
									onClick={()=>{this.goToStep(this.state.tutorialStepIndex - 1);}}
								>{tutorialSteps[this.state.tutorialStepIndex].prevBtn}</div>}
							{tutorialSteps[this.state.tutorialStepIndex].nextBtn &&
								<div className="InvestigationTutorial-instructionsNextBtn"
									onClick={()=>{this.goToStep(this.state.tutorialStepIndex + 1);}}
								>{tutorialSteps[this.state.tutorialStepIndex].nextBtn}
								</div>}
						</div>
					</div>
				</Draggable>
				{content}
			</div>
		);
	}
}

InvestigationTutorial.propTypes = {
	toggleTutorial: PropTypes.func,
	investigation: PropTypes.object,
	openPopup: PropTypes.func,
	closePopup: PropTypes.func
};

export default InvestigationTutorial;