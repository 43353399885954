import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as serviceWorker from './service-worker-register';
import App from 'components/app';
import 'styles/index.scss';

/* Render App */
ReactDOM.render(<App />, document.getElementById('root'));

/* Register service worker */
serviceWorker.register();