import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import Tips from 'components/admin/tips';
import './wave-simulators.scss';

const WaveSimulators = ({
	goToPage,
	waveSimulators,
	selectWaveSimulator,
	isSaving,
	isEditing,
	selectedWaveSimulator,
	errorMsg,
	updateWaveSimulator,
	teacherInstructions,
	groupInstructions,
	updateInstructions,
	handleSaveWaveSimulator
}) => {
	let saveBtnClass = 'WaveSimulatorsAdmin-saveBtn ' + (isSaving ? ' saving' : '');
	if (isEditing) saveBtnClass += ' editing';
	if (!isEditing) saveBtnClass += ' disabled';

	return (
		<div className="WaveSimulatorsAdmin">
			{/* Back button and title */}
			<p className="WaveSimulatorsAdmin-backBtn" onClick={() => {goToPage(null);}}>&lArr; tilbage</p>
			<div className="WaveSimulatorsAdmin-title">Rediger Bølgesimulator spil</div>

			{/* Select wave simulator game */}
			<form className="WaveSimulatorsAdmin-navForm" onChange={(event) => {selectWaveSimulator(event.target.value);}}>
				<select>
					{waveSimulators.map((waveSimulator, index) => {
						return (
							<option key={index} value={index}>{waveSimulator.title}</option>
						);
					})}
				</select>
			</form>

			<div>
				<form className="WaveSimulatorsAdmin-form">
					{/* Save */}
					<div
						className={saveBtnClass}
						onClick={(event) => {handleSaveWaveSimulator(event);}}>Gem</div>
					{errorMsg && <div>{errorMsg}</div>}

					{/* Edit title */}
					<div className="WaveSimulatorsAdmin-inputRow">
						<span className="WaveSimulatorsAdmin-label">Titel:</span>
						<input
							className="WaveSimulatorsAdmin-input"
							name="title"
							value={selectedWaveSimulator ? selectedWaveSimulator.title : ''}
							placeholder="titel"
							onChange={(event) => {updateWaveSimulator(event);}}
						/>
					</div>

					{/* Edit teacher instructions */}
					{(selectedWaveSimulator && selectedWaveSimulator.hasTeacherInstructions) &&
							<div className="WaveSimulatorsAdmin-inputRow">
								<p className="WaveSimulatorsAdmin-label">Lærerinstruktioner:</p>
								<textarea 
									className="WaveSimulatorsAdmin-textarea"
									name="teacherInstructions"
									value={teacherInstructions ? teacherInstructions : ''}
									placeholder=""
									onChange={(event) => {updateInstructions(event, 'teacher');}}
								/>
								<div className="WaveSimulatorsAdmin-preview">
									{renderMarkdown((teacherInstructions ? teacherInstructions : ''))}
								</div>
							</div>
					}

					{/* Edit group instructions */}
					{(selectedWaveSimulator && selectedWaveSimulator.hasGroupInstructions) &&
							<div className="WaveSimulatorsAdmin-inputRow">
								<p className="WaveSimulatorsAdmin-label">Gruppeinstruktioner:</p>
								<textarea 
									className="WaveSimulatorsAdmin-textarea"
									name="groupInstructions"
									value={groupInstructions ? groupInstructions : ''}
									placeholder=""
									onChange={(event) => {updateInstructions(event, 'group');}}
								/>
								<div className="WaveSimulatorsAdmin-preview">
									{renderMarkdown((groupInstructions ? groupInstructions : ''))}
								</div>
							</div>
					}
				</form>
			</div>

			{/* Markdown tips */}
			<Tips />
		</div>
	);
};

WaveSimulators.propTypes = {
	goToPage: PropTypes.func.isRequired,
	waveSimulators: PropTypes.array.isRequired,
	selectWaveSimulator: PropTypes.func.isRequired,
	isSaving: PropTypes.bool.isRequired,
	isEditing: PropTypes.bool.isRequired,
	selectedWaveSimulator: PropTypes.object,
	errorMsg: PropTypes.string,
	updateWaveSimulator: PropTypes.func.isRequired,
	teacherInstructions: PropTypes.string,
	groupInstructions: PropTypes.string,
	updateInstructions: PropTypes.func.isRequired,
	handleSaveWaveSimulator: PropTypes.func.isRequired
};


export default WaveSimulators;
