import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AtomReactor from './atom-reactor';

/** Check original code in src/assets/html/atom-reactor.html (Jesper from KU) */

class AtomReactorController extends Component {
	constructor(props) {
		super(props);

		this.initialState = {
			day: 1,
			reactorTemp: 25,
			reactorTempOld: 25,
			heatExchangerTemp: 25,
			heatExchangerTempOld: 25,
			coolingTowerTemp: 25,
			coolingTowerTempOld: 25,
			backupCoolant: 300,
			primaryCoolant: 120,
			secondaryCoolant: 120,
			energy: 0,
			totalEnergy: 0,
			fuel: 0,
			controlRods: 0,
			backupPumpPower: 0,
			primaryPumpPower: 0,
			secondaryPumpPower: 0,
			backupFlow: 0,
			primaryFlow: 0,
			secondaryFlow: 0,
			warnings: [],
			damages: [],
			isDiagramShown: false,
			meltdown: false,
			fuelCycleEnd: false,
			isRepairing: false,
			reactorTempValues: [25],
			heatExchangerTempValues: [25],
			coolingTowerTempValues: [25],
			energyValues: [0],
			dayValues: [1],
			showOverlay: false
		};

		this.RH = 0;
		this.PH = 0;
		this.SH = 0;
		this.EH = 0;
		this.damage = 0;
		this.A1 = 0;
		this.A2 = 0;
		this.A = 0;
		this.RD = 0;
		this.ED = 0;
		this.PD = 0;
		this.SD = 0;
		this.XD = 0;
		this.GD = 0;
		this.PB = 0;
		this.SB = 0;
		this.XB = 0;
		this.GB = 0;
		this.shutdowns = 0;
		this.failedRepair = 0;
		this.damage = this.damage + 2 * this.RD + this.ED + this.PD + this.XD + this.SD + this.GD;
		this.MD = 5 + 3 * (10 * (this.RD > 3) + (this.ED > 3) + (this.PD > 3) + (this.SD > 3) + 2 * this.PB
			+ 2 * this.SB + 3 * this.XB + 3 * this.GB);

		this.state = this.initialState;
	}

	componentDidMount() {
		this.printStatusReport();
	}

	handleRangeChange = (event) => {
		let {name, value} = event.target;
		this.setState({[name]: Number(value)});
	}

	resetGame = () => {
		this.RH = 0;
		this.PH = 0;
		this.SH = 0;
		this.EH = 0;
		this.damage = 0;
		this.A1 = 0;
		this.A2 = 0;
		this.A = 0;
		this.RD = 0;
		this.ED = 0;
		this.PD = 0;
		this.SD = 0;
		this.XD = 0;
		this.GD = 0;
		this.PB = 0;
		this.SB = 0;
		this.XB = 0;
		this.GB = 0;
		this.shutdowns = 0;
		this.failedRepair = 0;
		this.damage = this.damage + 2 * this.RD + this.ED + this.PD + this.XD + this.SD + this.GD;
		this.MD = 5 + 3 * (10 * (this.RD > 3) + (this.ED > 3) + (this.PD > 3) + (this.SD > 3) + 2 * this.PB
			+ 2 * this.SB + 3 * this.XB + 3 * this.GB);

		this.setState(this.initialState, () => {
			this.printStatusReport();
		});


		if (window.sessionStorage) {
			window.sessionStorage.clear();
		}
	}

	toggleCyoaGame = (showCyoa) => {
		this.props.toggleCyoaGame(showCyoa);
		if (showCyoa === false) {
			this.resetGame();
		}
	}

	printStatusReport = () => {
		let warnings = [];
		if (this.state.reactorTemp <= 800
			&& this.state.heatExchangerTemp <= 500
			&& this.state.coolingTowerTemp <= 300
			&& this.state.energy <= 2000
			&& this.state.energy >= 1000
			&& this.state.backupCoolant >= 200
			&& this.state.primaryCoolant >= 100
			&& this.state.secondaryCoolant >= 10
		) {
			warnings.push('Ingen.');
		}
		if (this.state.reactorTemp > 800) {
			warnings.push('Reaktoren er overophedet.');
		}
		if (this.state.heatExchangerTemp > 500) {
			warnings.push('Varmeveksleren er overophedet.');
			this.XD = this.XD + 1 + (this.state.heatExchangerTemp > 600);
			this.PD += 1;
			this.SD += 1;
		}
		if (this.state.energy > 2000) {
			warnings.push('Dampturbinen er overbelastet.');
			this.GD = this.GD + 1 + (this.state.energy > 2500);
			this.SD += 1;
		}
		if (this.state.coolingTowerTemp > 300) {
			warnings.push('Temperaturen i køletårnet er for høj.');
			this.SD += 1;
		}
		if (this.state.energy < 1000) {
			warnings.push('Effekten af kraftværket er for lav til at drive brændstofanlægget.');
		}
		if (this.state.backupCoolant < 200) {
			warnings.push('Mængde af kølevæske i backup-kølesystemet er lav.');
		}
		if (this.state.primaryCoolant < 100) {
			warnings.push('Mængde af kølevæske i det primære kølesystem er lav.');
			this.PD += 1;
		}
		if (this.state.secondaryCoolant < 100) {
			warnings.push('Mængde af kølevæske i det sekundære kølesystem er lav.');
			this.SD += 1;
		}

		this.damage = this.damage + 2 * this.RD + this.ED + this.PD + this.XD + this.SD + this.GD;
	
		let damages = [];
		if (this.RD <= 3 && this.PD <= 4 && this.SD <= 4 && this.ED <= 2
			&& this.PB === 0 && this.GB === 0 && this.XB === 0
		) {
			damages.push('Ingen.');
		}
		if (this.RD > 3) {
			damages.push('Reaktorkernen er beskadiget.');
		}

		let primaryCoolant = this.state.primaryCoolant;
		if (this.PD > 4) {
			damages.push('Lækage i det primære kølesystem. Lækagen er: ' + this.PD + ' L per dag.');
			primaryCoolant = (primaryCoolant - this.PD) * (primaryCoolant - this.PD > 0);
		}
		
		let secondaryCoolant = this.state.secondaryCoolant;
		if (this.SD > 4) {
			damages.push('Lækage i det sekundære kølesystem. Lækagen er: ' + this.SD + ' L per dag.');
			secondaryCoolant = (secondaryCoolant - this.SD) * (secondaryCoolant - this.SD > 0);
		}

		let backupCoolant = this.state.backupCoolant;
		let backupFlow = this.state.backupFlow;
		let backupPumpPower = this.state.backupPumpPower;
		if (this.ED > 2) {
			damages.push('Lækage i backup-kølesystemet. Lækagen er: ' + 2 * this.ED + ' L per dag.');
			backupCoolant = (backupCoolant - 2 * this.ED) * ((backupCoolant - 2 * this.ED) > 0);
			if (backupCoolant <= 0) {
				backupCoolant = 0;
				backupFlow = 0;
				backupPumpPower = 0;
			}
		}
		if (this.PB > 0) {
			damages.push('Pumpen i det primære kølesystem er beskadiget. Pumpen fungerer på ' +
			+ (100 - (10 * this.PD * (this.PD < 10) + 100 * (this.PD >= 10))) + '% af normal styrke.');
		}
		if (this.SB > 0) {
			damages.push('Pumpen i det sekundære kølesystem er beskadiget. Pumpen fungerer på ' +
			+ (100 - (10 * this.SD * (this.SD < 10) + 100 * (this.SD >= 10))) + '% af normal styrke.');
		}
		if (this.XB > 0) {
			damages.push('Varmeveksleren er ude af drift.');
			damages.push('Dampturbinen er ude af drift.');
		}

		this.setState({
			warnings,
			damages,
			backupCoolant,
			backupPumpPower,
			backupFlow,
			primaryCoolant,
			secondaryCoolant
		});
	}

	/**
	 * Note: Check original code in src/assets/html/atom-reactor.html, lines 2258 - 2327
	 */
	goToNextDay = () => {
		this.setState({showOverlay: true}, () => {
			setTimeout(() => {
				/* Opdatering af emergency coolant volume */
				// set $EV = $EV - $EF - 2 * $ED * ($ED &gt; 3)
				let backupFlow = this.state.backupPumpPower;
				let backupPumpPower = this.state.backupPumpPower;
				let backupCoolant = this.state.backupCoolant - backupFlow - 2 * this.ED * (this.ED > 3);
				if (backupCoolant < 0) {
					backupCoolant = 0;
					backupFlow = 0;
					backupPumpPower = 0;
				}
		
				/* Hæv muligvis skadesparameter på den primære pumpe, hvis primary flow er over 90% */
				// set $PD = $PD + ($PF &gt; 90) * (randomFloat(1.0)&gt;0.95)
				let primaryFlow = this.state.primaryPumpPower;
				this.PD = this.PD + (primaryFlow > 90) * (Math.random() > 0.95);
		
				/* Hæv muligvis skadesparameter på den skundære pumpe, hvis secondary flow er over 90% */
				// set $SD = $SD + ($SF &gt; 90) * (randomFloat(1.0)&gt;0.92)
				let secondaryFlow = this.state.secondaryPumpPower;
				this.SD = this.SD + (secondaryFlow > 90) * (Math.random() > 0.92);
		
				/* Ødelæg primær pumpe, hvis skadeparameter er over 5 */
				// set $PB = ($PD &gt; 5)
				this.PB = Number(this.PD > 5);
		
				/* Ødelæg secondær pumpe, hvis skadeparameter er over 5 */
				// set $SB = ($SD &gt; 5)
				this.SB = Number(this.SD > 5);
		
				/* Hvis primary flow er større end den beskadigede pumpe kan klare,
				** nedsæt da primary flow ift. den indstillede */
				// if $PF &gt; (100-$PD*10) &amp;&amp; $PB&gt;0
				// set $PF = (100-$PD*10) * ((100-$PD*10) &gt;0)
				if (primaryFlow > (100 - this.PD * 10) && this.PB > 0) {
					primaryFlow = (100 - this.PD * 10) * ((100 - this.PD * 10) > 0);
				}
		
				/* Hvis secondary flow er større end den beskadigede pumpe kan klare,
				** nedsæt da secondary flow ift. den indstillede */
				// if $SF &gt; (100-$SD*10) &amp;&amp; $SB&gt;0
				// set $SF = (100-$SD*10) * ((100-$SD*10) &gt;0)
				if (secondaryFlow > (100 - this.SD * 10) && this.SB > 0) {
					secondaryFlow = (100 - this.SD * 10) * ((100 - this.SD * 10) > 0);
				}
		
				/* Udregne fuel level (reactor life) på baggrund af generet heat flow */
				// set $RL = $RL + $RH/50
				let fuel = this.state.fuel + this.RH / 50;
		
				/* Beregn heat flow i reaktoren på baggrund af kontrolstavsindstillinger og fuel level */
				// set $A2 = $A1; $A1=$A
				// set $RH = ($A*30 + $A1 * 60 + $A2*10) / 2500 * (100-$RL)
				this.A2 = this.A1;
				this.A1 = this.A;
				this.RH = (this.state.controlRods * 30 + this.A1 * 60 + this.A2 * 10) / 2500 * (100 - fuel);
		
				/* Beregn primary heat flow */
				// set $PH = $PF * (100 * ($PV &gt; 100) + $PV * ($PV &lt;= 100) ) / 350
				this.PH = primaryFlow * (100 * (this.state.primaryCoolant > 100) +
					+ this.state.primaryCoolant * (this.state.primaryCoolant <= 100)) / 350;
		
				/* Beregn emergency heat flow */
				// set $EH = $EF / 200 * ($RT - 25)
				// set $oldRT =$RT
				this.EH = backupFlow / 200 * (this.state.reactorTemp - 25);
				let reactorTempOld = this.state.reactorTemp;
		
				/* Beregn reaktortemp. */
				// set $RT = $RT + $RH - $EH - $PH - 5*($RT&gt;25)
				let reactorTemp =
					this.state.reactorTemp + this.RH - this.EH - this.PH - 5 * (this.state.reactorTemp > 25);
		
				/* Sæt nedre grænse af reaktortemp. til 25 */
				// set $RT = 25 + ($RT-25)*($RT&gt;25)
				// set $oldXT = $XT
				reactorTemp = 25 + (reactorTemp - 25) * (reactorTemp > 25);
				let heatExchangerTempOld = this.state.heatExchangerTemp;
		
				/* Beregn temperatur i varmeveksleren */
				// set $XT = ( ($RT - 25) * $PF + ($CT-25)*$SF) / ($PF + $SF + 1) + 25
				let heatExchangerTemp = ((reactorTemp - 25) * primaryFlow + (this.state.coolingTowerTemp - 25)
					* secondaryFlow) / (primaryFlow + secondaryFlow + 1) + 25;
		
				/* Beregn heat flow i det sekundære system */
				// set $SH = $SF*(100*($PV&gt;100)+$PV*($PV &lt;= 100)) / 350 *($XT - $CT)
				this.SH = secondaryFlow * (100 * (this.state.primaryCoolant > 100) + this.state.primaryCoolant
					* (this.state.primaryCoolant <= 100)) / 350 * (heatExchangerTemp - this.state.coolingTowerTemp);
				
				/* Hvis varmevekslerer er ødelagt */
				// if $XB
				// set $XT = $RT * 0.8 + 5 /*Opdater temp. i varmeveksleret */
				// set $SH = $SH * 0.2 /*Opdater heat flow i det sekundære system */
				// /if
				if (this.XB) {
					heatExchangerTemp = reactorTemp * 0.8 + 5;
					this.SH = this.SH * 0.2;
				}
		
				/* Beregn output for dampturbine (kW) */
				// set $GO = $SH / $XT *($XT-$CT) * 2 / 3
				let energy = this.SH / heatExchangerTemp * (heatExchangerTemp - this.state.coolingTowerTemp) * 2 / 3;
				
				/* Sæt øvre grænse for dampturbinen på 2600kW */
				// if $GO &gt; 2600
				// set $GO = 2600
				// /if
				if (energy > 2600) {
					energy = 2600;
				}
		
				/* Sæt nedre grænse for dampturbinen på 0kW */
				// set $GO = $GO * ($GO&gt;0)
				energy = energy * (energy > 0);
		
				/* Beregn temp. i køletårnet */
				// set $oldCT = $CT
				// set $CT = 25 + (($XT - 25)*($SH-$GO) / ($SH+1)*0.75)
				let coolingTowerTempOld = this.state.coolingTowerTemp;
				let coolingTowerTemp = 25 + ((heatExchangerTemp - 25) * (this.SH - energy) / (this.SH + 1) * 0.75);
		
				/* Sæt nedre grænse af køletårnets temperatur til 25 */
				// set $CT = 25 * ($CT &lt;= 25) + $CT * ($CT &gt; 25)
				coolingTowerTemp = 25 * (coolingTowerTemp <= 25) + coolingTowerTemp * (coolingTowerTemp > 25);
				
				/* Hvis varmeveksleren ikke er ødelagt, men dog beskadiget, er der 10% risiko for at den ødelægges */
				// if $XB &lt; 0
				// set $XB = ($XD &gt; 2)*(randomFloat(1.0)&gt;0.90)
				// /if
				if (this.XB < 0) {
					this.XB = (this.XD > 2) * (Math.random() > 0.9);
				}
				
				/* Hvis turbinen ikke er ødelagt, men dog beskadiget, er der 10% risiko for at den ødelægges. */
				// if $GB &lt; 0
				// set $GB = ($GD &gt; 4)*(randomFloat(1.0)&gt;0.90)
				// /if
				if (this.GB < 0) {
					this.GB = (this.GB > 4) * (Math.random() > 0.9);
				}
		
				/* Optæl power output for hver dag. */
				// set $TT = $TT + $GO
				let totalEnergy = this.state.totalEnergy + energy;
		
				/* Rund ned til nærmeste helttal. */
				// set $RT = Math.floor($RT)
				// set $XT = Math.floor($XT)
				// set $CT = Math.floor($CT)
				// set $GO = Math.floor($GO)
				// set $TT = Math.floor($TT)
				reactorTemp = Math.floor(reactorTemp);
				heatExchangerTemp = Math.floor(heatExchangerTemp);
				coolingTowerTemp = Math.floor(coolingTowerTemp);
				energy = Math.floor(energy);
				totalEnergy = Math.floor(totalEnergy);
		
				/* Opdater skadesparameter for reaktoren*/
				// if $RT&gt; 800
				// set $RD =
				// = $RD + 1 + ($RT&gt;850) + ($RT&gt;900) + 2*($RT&gt;950); $PD += 1; $ED = $ED + 1 + ($RT&gt;850)
				// /if
				if (reactorTemp > 800) {
					this.RD = this.RD + 1 + (reactorTemp > 850) + (reactorTemp > 900) + 2 * (reactorTemp > 950);
					this.PD += 1;
					this.ED = this.ED + 1 + (reactorTemp > 850);
				}
		
				let meltdown = this.state.meltdown;
				let fuelCycleEnd = this.state.fuelCycleEnd;
				if (this.RD > 5) {
					meltdown = true;
				} else if (fuel > 95) {
					fuelCycleEnd = true;
				}
		
				let day = this.state.day + 1;
				this.setState({
					backupCoolant: backupCoolant,
					fuel: fuel,
					reactorTemp: reactorTemp,
					reactorTempOld: reactorTempOld,
					heatExchangerTemp: heatExchangerTemp,
					heatExchangerTempOld: heatExchangerTempOld,
					energy: energy,
					coolingTowerTemp: coolingTowerTemp,
					coolingTowerTempOld: coolingTowerTempOld,
					totalEnergy: totalEnergy,
					day: day,
					backupFlow: backupFlow,
					primaryFlow: this.state.primaryPumpPower,
					secondaryFlow: this.state.secondaryPumpPower,
					backupPumpPower: backupPumpPower,
					meltdown: meltdown,
					fuelCycleEnd: fuelCycleEnd,
					reactorTempValues: [...this.state.reactorTempValues, reactorTemp],
					heatExchangerTempValues: [...this.state.heatExchangerTempValues, heatExchangerTemp],
					coolingTowerTempValues: [...this.state.coolingTowerTempValues, coolingTowerTemp],
					energyValues: [...this.state.energyValues, energy],
					dayValues: [...this.state.dayValues, day],
					showOverlay: false
				}, () => {
					this.A = this.state.controlRods;
					this.printStatusReport();
				});
			}, 300);
		});
	}

	toggleDiagram = (isDiagramShown) => {
		this.setState({isDiagramShown});
	}

	toggleRepair = (toggleRepair) => {
		this.setState({isRepairing: toggleRepair});
	}

	makeRepairs = () => {
		/* Initialisering af variable. */
		// set $EV=300; $PV=120; $SV=120;
		// set $RT=25; $XT=25; $CT=25;
		// set $RD=0; $ED=0; $PD=0; $XD=0; $SD=0; $GD=0;
		// set $PB=0; $SB=0; $XB=0; $GB=0;
		// set $EF=0; $PF=0; $SF=0;
		// set $GO=0
		// set $damage = 0
		// set $MD = (5+3*(10*($RD&gt;3)+($ED&gt;3)+($PD&gt;3)+($SD&gt;3)+2*$PB+2*$SB+3*$XB+3*$GB));
		let day = this.state.day + 5;
		this.setState({
			backupCoolant: 300,
			primaryCoolant: 120,
			secondaryCoolant: 120,
			reactorTemp: 25,
			heatExchangerTemp: 25,
			coolingTowerTemp: 25,
			backupFlow: 0,
			primaryFlow: 0,
			secondaryFlow: 0,
			backupPumpPower: 0,
			primaryPumpPower: 0,
			secondaryPumpPower: 0,
			energy: 0,
			isRepairing: false,
			day: day,
			reactorTempValues: [...this.state.reactorTempValues, 25],
			heatExchangerTempValues: [...this.state.heatExchangerTempValues, 25],
			coolingTowerTempValues: [...this.state.coolingTowerTempValues, 25],
			energyValues: [...this.state.energyValues, 0],
			dayValues: [...this.state.dayValues, day]
		}, () => {
			this.RD = 0;
			this.ED = 0;
			this.PD = 0;
			this.XD = 0;
			this.SD = 0;
			this.GD = 0;
			this.PB = 0;
			this.SB = 0;
			this.XB = 0;
			this.GB = 0;
			this.damage = 0;
			this.MD = 5 + 3 * (10 * (this.RD > 3) + (this.ED > 3) + (this.PD > 3) + (this.SD > 3) + 2 * this.PB
			+ 2 * this.SB + 3 * this.XB + 3 * this.GB);
			this.printStatusReport();
		});
	}

	render() {
		return (
			<AtomReactor
				toggleCyoaGame={this.toggleCyoaGame}
				cyoaGame={this.props.cyoaGame}
				updateGroup={this.props.updateGroup}
				updateGame={this.props.updateGame}
				game={this.props.game}
				group={this.props.group}
				isTeacher={this.props.isTeacher}
				day={this.state.day}
				reactorTemp={this.state.reactorTemp}
				reactorTempOld={this.state.reactorTempOld}
				heatExchangerTemp={this.state.heatExchangerTemp}
				heatExchangerTempOld={this.state.heatExchangerTempOld}
				coolingTowerTemp={this.state.coolingTowerTemp}
				coolingTowerTempOld={this.state.coolingTowerTempOld}
				backupCoolant={this.state.backupCoolant}
				primaryCoolant={this.state.primaryCoolant}
				secondaryCoolant={this.state.secondaryCoolant}
				warnings={this.state.warnings}
				damages={this.state.damages}
				energy={this.state.energy}
				totalEnergy={this.state.totalEnergy}
				fuel={this.state.fuel}
				controlRods={this.state.controlRods}
				backupPumpPower={this.state.backupPumpPower}
				primaryPumpPower={this.state.primaryPumpPower}
				secondaryPumpPower={this.state.secondaryPumpPower}
				handleRangeChange={this.handleRangeChange}
				backupFlow={this.state.backupFlow}
				primaryFlow={this.state.primaryFlow}
				secondaryFlow={this.state.secondaryFlow}
				isDiagramShown={this.state.isDiagramShown}
				toggleDiagram={this.toggleDiagram}
				goToNextDay={this.goToNextDay}
				meltdown={this.state.meltdown}
				fuelCycleEnd={this.state.fuelCycleEnd}
				resetGame={this.resetGame}
				toggleRepair={this.toggleRepair}
				isRepairing={this.state.isRepairing}
				damage={this.damage}
				makeRepairs={this.makeRepairs}
				reactorTempValues={this.state.reactorTempValues}
				heatExchangerTempValues={this.state.heatExchangerTempValues}
				coolingTowerTempValues={this.state.coolingTowerTempValues}
				energyValues={this.state.energyValues}
				dayValues={this.state.dayValues}
				showOverlay={this.state.showOverlay}
			/>
		);
	}
}


AtomReactorController.propTypes = {
	game: PropTypes.object.isRequired,
	updateGame: PropTypes.func,
	cyoaGame: PropTypes.object,
	updateGroup: PropTypes.func,
	group: PropTypes.object,
	toggleCyoaGame: PropTypes.func.isRequired,
	isTeacher: PropTypes.bool.isRequired,
};

export default AtomReactorController;