import React from 'react';
import PropTypes from 'prop-types';
import {cratersData} from 'data/groups-data';
import './group-badge.scss';

const GroupBadge = ({group, showName, page, onClick, isLoading}) => {
	let className = 'GroupBadge';
	if (page) className += ' GroupBadge--' + page;
	if (group.craterId) className += ' GroupBadge--' + group.craterId;
	if (showName) className += ' GroupBadge--showName';
	if (group.isPlaying) className += ' GroupBadge--isPlaying ';
	if (isLoading) className += ' GroupBadge--loading ';

	let imgSrc = require('../../assets/images/group-badges/' + group.craterId + '.png');
	let groupName = '';
	if (cratersData.some((crater) => {return crater.id === group.craterId;})) {
		groupName = cratersData.filter((crater) => {return crater.id === group.craterId;})[0].name;
	}

	return (
		<div className={className} onClick={onClick}>
			<img className='GroupBadge-icon' src={imgSrc} alt='group-badge-icon'/>
			{showName && <div className='GroupBadge-name'><span>{groupName}</span></div>}
		</div>
	);
};

GroupBadge.propTypes = {
	group: PropTypes.object.isRequired,
	showName: PropTypes.bool.isRequired,
	page: PropTypes.string,
	onClick: PropTypes.func,
	isLoading: PropTypes.bool
};

export default GroupBadge;
