import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Stats from './stats';
import {sortArrayByProperty} from 'helpers/array-helper';
import {numberToMonth, formatDateStats} from 'helpers/date-helper';


class StatsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			users: [],
			games: [],
		};
		this.unsubscribeUsers = null;
		this.unsubscribeGames = null;
	};

	/**
	 * Component mounted
	 */
	componentDidMount() {
		Promise.all([
			this.subscribeToGames(),
			this.subscribeToUsers()
		]).then((responses) => {
			if (responses[0].status === 'ok' && responses[1].status === 'ok') {
				this.setState({isLoading: false});
			}
		});
	}
	/**
	 * Component will unmount
	 */
	componentWillUnmount() {
		if (this.unsubscribeUsers) this.unsubscribeUsers();
		if (this.unsubscribeGames) this.unsubscribeGames();
	}

	/**
	 * Subscribe to teachers
	*/
	subscribeToUsers = () => {
		if (this.unsubscribeUsers !== null) this.unsubscribeUsers();
		let db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeUsers = db.collection('users').onSnapshot((querySnapshot) => {
				let users = [];
				querySnapshot.forEach((doc) => {
					let data = doc.data();
					data.id = doc.id;
					users.push(data);
				});
				users = sortArrayByProperty(users, 'created', 'DESC');
				this.setState({users: users}, () => {
					resolve({status: 'ok'});
				});
			},
			(error) => {
				console.error('could not get Users: ', error);
				resolve({status: 'error', error: error});
			});	
		});	
	}
	
	/**
	 * Subscribe to teachers
	*/
	subscribeToGames = () => {
		if (this.unsubscribeGames !== null) this.unsubscribeGames();
		let db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeGames = db.collection('games').onSnapshot((querySnapshot) => {
				let games = [];
				querySnapshot.forEach((doc) => {
					games.push(doc.data());
				});
				this.setState({games: games}, ()=> {
					resolve({status: 'ok'});
				});
			},
			(error) => {
				console.error('could not get Games: ', error);
				resolve({status: 'error', error: error});
			});
		});			
	}


	/**
	 * Download emails
	 */
	downloadEmails = () => {
		let emails = '';
		this.state.users.forEach((user) => {
			if (user.hasOwnProperty('email') && user.email.length > 0) {
				if (emails.length > 0) emails = emails + ',';
				emails = emails + user.email;
			}
		});
		let blob = new Blob([emails], {type: 'text/html'});
		let url = window.URL.createObjectURL(blob);
		let link = document.createElement('a'); 
		link.href = url;
		link.setAttribute('download', 'emails.csv');
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	}

	

	/**
	 * Render component
	 */
	render() {
		// Chart
		let labels = [];
		let data = [];
		let users = JSON.parse(JSON.stringify(this.state.users));
		
		users = sortArrayByProperty(users, 'created', 'ASC');
		let counter = 0;
		let month = null;
		let year = null;
		users.forEach((user, index) => {
			let creationDateArray = formatDateStats(user.created).split('/');
			if (index === 0) {
				month = creationDateArray[1];
				year = creationDateArray[2];
				counter = counter + 1;
			} else if (month === creationDateArray[1] && (index < (users.length - 1))) {
				counter = counter + 1;
			} else {
				counter = counter + 1;
				if (parseInt(month) === 1 || labels.length === 0) {
					labels.push(numberToMonth(parseInt(month)) + ' ' + year);
				} else {

					labels.push(numberToMonth(parseInt(month)));
				}				
				data.push(counter);
				month = creationDateArray[1];
				year = creationDateArray[2];				
			}
		});
		let chartData = {
			labels: labels,
			datasets: [{
				label: 'Teachers',
				backgroundColor: 'transparent',
				borderColor: 'rgb(255, 99, 132)',
				data: data,
			}]
		};

		return (
			<Stats
				goToPage={this.props.goToPage}
				isLoading={this.state.isLoading}
				users={this.state.users}
				downloadEmails={this.downloadEmails}
				games={this.state.games}
				chartData={chartData}
			/>
		);
	}
}

StatsController.propTypes = {
	goToPage: PropTypes.func.isRequired
};

export default StatsController;