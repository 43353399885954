const storyTiersData = [
	{index: 1, storyIds: ['story-0', 'story-1']},
	{index: 2, storyIds: ['story-2', 'story-3', 'story-4']},
	{index: 3, storyIds: ['story-5', 'story-6']},
	{index: 4, storyIds: ['story-7', 'story-8', 'story-9']}
];

const storyTypesData = ['missionControlMessage', 'shipLog'];

const subjectIds = ['energy', 'sound', 'light', 'world'];
const subjectsData = {
	energy: {
		name: 'Energi'
	},
	sound: {
		name: 'Lyd'
	},
	light: {
		name: 'Lys'
	},
	world: {
		name: 'Verdensbillede'
	}
};

const teacherPagesData = [
	'settings', 
	// 'overview', 
	'makeGroups', 
	'groupLogin', 
	'evaluation', 
	'presentation', 
	'result'
];


module.exports = {
	storyTiersData,
	storyTypesData,
	teacherPagesData,
	subjectIds,
	subjectsData
};