/**
 * Convert seconds to dd/mm-yy
 * @param {number} seconds 
 */
function formatDate(seconds) {
	let dateObj = new Date(seconds * 1000);
	let month = String(dateObj.getMonth() + 1);
	let day = String(dateObj.getDate());
	const year = String(dateObj.getFullYear());

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return `${day}/${month}-${year}`;
}

/**
* Convert seconds to dd/mm/yy
* @param {number} seconds 
*/
function formatDateStats(seconds) {
	let dateObj = new Date(seconds * 1000);
	let month = String(dateObj.getMonth() + 1);
	let day = String(dateObj.getDate());
	const year = String(dateObj.getFullYear());

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return `${day}/${month}/${year}`;
}

const numberToMonth = function(number) {
	switch (number) {
	case 1:
		return 'Jan';
	case 2:
		return 'Feb';
	case 3:
		return 'Mar';
	case 4:
		return 'Apr';
	case 5:
		return 'May';
	case 6: 
		return 'Jun';
	case 7:
		return 'Jul';
	case 8:
		return 'Aug';
	case 9:
		return 'Sep';
	case 10:
		return 'Oct';
	case 11:
		return 'Nov';
	case 12:
		return 'Dec';
	default:
		return null;
	}
};

export {
	formatDate,
	numberToMonth,
	formatDateStats
};