import React from 'react';
import PropTypes from 'prop-types';
import {minigamesUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import Button from 'components/button/button';
import MissionControlMessage from 'components/layout/mission-control-message';
import './cyoa-games-intro.scss';

const CyoaGamesIntro = ({toggleCyoaGame, page, isTeacher, cyoaGameStatus, setCyoaGameStatus}) => {
	return (
		<div className="CyoaIntro">
			<div className="CyoaIntro-title">{page.title}</div>
			{page.storyText && <MissionControlMessage message={page.storyText} type={page.storyType} />}
			<div className="CyoaIntro-text">
				{renderMarkdown(page.text)}
			</div>
			{toggleCyoaGame && <Button
				class={isTeacher ? '' : 'wide'}
				text={isTeacher ? minigamesUiTexts.browseMinigame : minigamesUiTexts.play + '!'}
				onClick={() => {toggleCyoaGame(true);}}
				disabled={!isTeacher && ['ready', 'locked', 'closed'].includes(cyoaGameStatus)}
			/>}
			{(isTeacher && (cyoaGameStatus === 'ready' || cyoaGameStatus === 'closed')) && 
				<Button text={minigamesUiTexts.startMinigame} onClick={() => {setCyoaGameStatus('open');}} 
				/>
			}
			{(isTeacher && cyoaGameStatus === 'open') && 
				<Button text={minigamesUiTexts.stopMinigame} onClick={() => {setCyoaGameStatus('closed');}} 
				/>
			}
		</div>
	);
};

CyoaGamesIntro.propTypes = {
	toggleCyoaGame: PropTypes.func,
	page: PropTypes.object.isRequired,
	isTeacher: PropTypes.bool.isRequired,
	cyoaGameStatus: PropTypes.string.isRequired,
	setCyoaGameStatus: PropTypes.func
};


export default CyoaGamesIntro;
