import React from 'react';
import PropTypes from 'prop-types';
import './next-step.scss';

const NextStep = ({type, nextStepData}) => {
	return (
		<div className={'NextStep NextStep--' + type}onClick={() => {nextStepData.action(...nextStepData.parameters);}}>
			{nextStepData.text}
		</div>
	);
};


NextStep.propTypes = {
	type: PropTypes.string.isRequired,
	nextStepData: PropTypes.object.isRequired,
};

export default NextStep;
