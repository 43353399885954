import React from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {demoGame, demoGroups} from 'data/demo-data';
import {cratersData} from 'data/groups-data';
import popupsData from 'data/popups-group-data';
import {getStatusOfSession} from 'helpers/game-flow-helper';
import apiHelper from 'helpers/api-helper';
import GameLoading from 'components/game/game-loading';
import OverviewController from 'components/overview/overview-controller';
import Menu from 'components/menu/menu';
import CameraController from 'components/camera/camera-controller';
import MissionPanel from 'components/mission-panel/mission-panel';
import BaseController from 'components/base/base-controller.js';
import WaveSimulatorController from 'components/wave-simulator/wave-simulator-controller.js';

class GroupController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			game: demoGame,
			group: demoGroups[0]
		};
		this.subscribeToGame = this.subscribeToGame.bind(this);
		this.subscribeToGroup = this.subscribeToGroup.bind(this);
		this.checkGameUpdate = this.checkGameUpdate.bind(this);
		this.checkGroupUpdate = this.checkGroupUpdate.bind(this);
		this.unsubscribeGame = null;
		this.unsubscribeGroup = null;
		this.updateGroup = this.updateGroup.bind(this);
		this.updateGame = this.updateGame.bind(this);
		this.goToSession = this.goToSession.bind(this);
		this.submitMinigamePart = this.submitMinigamePart.bind(this);
		this.showTagBasePopup = this.showTagBasePopup.bind(this);
		this.handleTagBase = this.handleTagBase.bind(this);
	}

	/**
	 * Component did mount
	 * Subscribe to game and group, show welcome popup
	 */
	componentDidMount() {
		/* Subscribe to game and group */
		let groupId = this.props.userId;
		let gameId = groupId.substr(0, groupId.indexOf('-'));
		Promise.all([
			this.subscribeToGame(gameId),
			this.subscribeToGroup(groupId)
		]).then((responses) => {
			/* Navigate to first page of active session */
			if (responses[0].status === 'ok') {
			// 	let sessionId = responses[0].game.sessions.filter((session) => {
			// 		return session.status === 'active';
			// 	})[0].id;
			// 	let pageId = this.props.pageId;
			// 	this.props.stories.forEach((story) => {
			// 		story.missions.forEach((mission) => {
			// 			mission.sessions.forEach((session) => {
			// 				if (session.id === sessionId && session.pageIds && session.pageIds.length > 0) {
			// 					pageId = session.pageIds[0];
			// 				}
			// 			});
			// 		});
			// 	});
			// 	this.props.showPage(pageId).then(() => {this.setState({isLoading: false});});
				this.setState({isLoading: false});
			} else {
				console.error(responses);
			}
		});
	}

	/**
	 * Component updated
	 * @param {object} prevProps
	 * @param {object} prevState
	 */
	componentDidUpdate(prevProps, prevState) {
		/* Group navigated to map, show tag popup if needed */
		if (prevProps.showBase === false && this.props.showBase === true) {
			// let activeSession = this.state.game.sessions.filter((session) => {return session.status === 'active';});
			let activeSession = this.state.game.sessions.filter((session) => {
				return session.id === this.state.game.activeSessionId;
			});
			if (
				activeSession.length > 0 &&
				activeSession[0].winningGroupId && activeSession[0].winningGroupId === this.props.userId &&
				activeSession[0].winningGroupCanTag === true
			) {
				this.showTagBasePopup(activeSession[0].id);
			}
		}

		/* Group saved base tag */
		if (prevState.game.lastTaggedSessionId !== this.state.game.lastTaggedSessionId) {
			if (
				(!prevState.game.lastTaggedSessionId && prevState.game.id)
				|| prevState.game.lastTaggedSessionId
			) {
				this.props.toggleBase(true, true);
			}
		}

		/* Check if map was closed; if so, it shouldn't fade in anymore */
		if (prevProps.showBase === true && this.props.showBase === false) {
			if (this.state.fadeInBase) this.setState({fadeInBase: false});
		}
	}

	/**
	 * Called by group to subscribe to specific game
	 * @param {string} gameId 
	 */
	subscribeToGame(gameId) {
		if (this.unsubscribeGame !== null) this.unsubscribeGame();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeGame = db.collection('games').doc(gameId)
				.onSnapshot((doc) => {
					if (doc.exists) {
						let data = doc.data();
						data.id = doc.id;
						let prevGame = this.state.game;
						this.setState({game: data}, () => {
							this.checkGameUpdate(prevGame);
							resolve({status: 'ok', game: data});
						});
					} else {
						/* No game, probably deleted so log out group */
						this.props.handleLogout();
					}
				}, (error) => {
					console.error('could not get game: ', error);
					this.props.openServerErrorPopup();
					resolve({status: 'error', error: error});
				});
		});
	}

	/**
	 * Called by group to subscribe to their own group
	 * @param {string} groupId 
	 */
	subscribeToGroup(groupId) {
		if (this.unsubscribeGroup !== null) this.unsubscribeGroup();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeGroup = db.collection('groups').doc(groupId)
				.onSnapshot((doc) => {
					if (doc.exists) {
						let data = doc.data();
						data.id = doc.id;

						let prevGroup = this.state.group;
						this.setState({group: data}, () => {
							this.checkGroupUpdate(prevGroup);
							resolve({status: 'ok', group: data});
						});
					}
				}, (error) => {
					console.error('could not get group: ', error);
					this.props.openServerErrorPopup();
					resolve({status: 'error', error: error});
				});
		});
	}

	/**
	 * Check game update, called after game has been updated
	 * @param {object} prevGame 
	 */
	checkGameUpdate(prevGame) {
		let game = this.state.game;

		
		/* Check for changes in game session statuses and tagging */
		let openedSessionId = null;
		let openedSessionPageId = null;
		let taggingSessionId = null;
		this.props.stories.forEach((story) => {
			story.missions.forEach((mission) => {
				mission.sessions.forEach((session) => {
					let prevSession = null;
					let currentSession = null;
					if (prevGame.sessions.some((gameSession) => {return gameSession.id === session.id;})) {
						prevSession = prevGame.sessions.filter((gameSession) => {
							return gameSession.id === session.id;
						})[0];
					}
					if (game.sessions.some((gameSession) => {return gameSession.id === session.id;})) {
						currentSession = game.sessions.filter((gameSession) => {
							return gameSession.id === session.id;
						})[0];
					}

					if (prevSession && currentSession) {
						if (prevSession.status === 'ready' && currentSession.status === 'open') {
							openedSessionId = session.id;
							openedSessionPageId = session.pageIds[0];
						}

						if (
							!prevSession.hasOwnProperty('winningGroupId') &&
							currentSession.hasOwnProperty('winningGroupId') && 
							currentSession.winningGroupId === this.props.userId &&
							currentSession.winningGroupCanTag === true
						) {
							taggingSessionId = session.id;
						}
					}
				});
			});
		});

		/* Teacher opened new session, auto navigate to it and show base */
		if (openedSessionId && openedSessionPageId) {
			this.props.showPage(openedSessionPageId);
			this.props.toggleBase(true, true);
			return;
		}

		/* Teacher activated tagging for this group, show base and popup */
		if (taggingSessionId) {
			this.props.toggleBase(true, true);
			setTimeout(() => {
				this.showTagBasePopup(taggingSessionId);
			}, 1000);
		}

		// let navigateToActiveSession = (
		// 	prevGame.activeSessionId !== null && game.activeSessionId !== null && 
		// 	prevGame.activeSessionId !== game.activeSessionId
		// );
		// if (navigateToActiveSession) {
		// 	let pageId = this.props.pageId;
		// 	this.props.stories.forEach((story) => {
		// 		story.missions.forEach((mission) => {
		// 			mission.sessions.forEach((session) => {
		// 				if (session.id === game.activeSessionId && session.pageIds && session.pageIds.length > 0) {
		// 					pageId = session.pageIds[0];
		// 				}
		// 			});
		// 		});
		// 	});
		// 	this.props.showPage(pageId);
		// 	if (prevGame.id !== null) {
		// 		setTimeout(() => {
		// 			this.props.toggleBase(true);
		// 		}, 1000);
		// 	}
		// 	return;
		// }

		/* Teacher activated tagging for this group */
		// let prevActiveSession = prevGame.sessions.filter((session) => {
		// 	return session.id === prevGame.activeSessionId;
		// });
		// let activeSession = game.sessions.filter((session) => {return session.id === game.activeSessionId;});
		// if (
		// 	prevActiveSession.length > 0 && activeSession.length > 0 && 
		// 	!prevActiveSession[0].hasOwnProperty('winningGroupId') && 
		// 	activeSession[0].winningGroupId && activeSession[0].winningGroupId === this.props.userId &&
		// 	activeSession[0].winningGroupCanTag === true
		// ) {
		// 	this.showTagBasePopup(activeSession[0].id);
		// }
	}

	/**
	 * Check group update, called after group has been updated
	 * @param {object} prevGroup 
	 */
	checkGroupUpdate(prevGroup) {
		let group = this.state.group;
		
		/* Flag group as online */
		if (!group.isPlaying) {
			this.updateGroup({isPlaying: true});
		}

		/* Ignore updates if previous group was demo group (student just logged in) */
		if (prevGroup.id === 'test-1') return;
	}

	/**
	 * Update group
	 * @param {object} updates 
	 */
	updateGroup(updates) {
		let groupId = this.props.userId;
		let db = firebase.firestore();
		let groupRef = db.collection('groups').doc(groupId);
		return groupRef.update(updates);
	}

	/**
	 * Update game
	 * @param {object} updates 
	 */
	updateGame(updates) {
		return new Promise((resolve, reject) => {
			if (this.state.game.id !== null) {
				let db = firebase.firestore();
				db.collection('games').doc(this.state.game.id).update(updates).then(() => {
					resolve();
				}, (error) => {
					reject(error);
					console.error('could not update game: ', error);
					this.props.openServerErrorPopup();
				});

			} else {
				// reject('no game id');
				let demoGame = Object.assign({}, this.state.game, updates);
				this.setState({game: demoGame}, () => {
					resolve();
				});
			}
		});
	}
	

	/**
	 * Navigate to a session
	 * @param {string} sessionId
	 * @param {string} sessionTitle
	 * @param {string} pageId 
	 */
	goToSession(sessionId, _, pageId) {
		let game = this.state.game;
		let sessionStatus = null;
		if (game.sessions.some((session) => {return session.id === sessionId;})) {
			sessionStatus = game.sessions.filter((session) => {return session.id === sessionId;})[0].status;
		}

		/* Groups do not have access to future sessions */
		if (!sessionStatus || sessionStatus === 'ready') return;

		/* Redirect to session */
		this.props.showPage(pageId, true).then(() => {
			/* Open base */
			this.setState({fadeInBase: true}, () => {
				this.props.toggleBase(true, true);
			});
		});
	}


	/**
	 * Submit completed minigame part
	 * @param {string} minigameId 
	 * @param {number} minigamePartIndex 
	 * @param {object} minigamePart 
	 * @param {number} numberOfParts
	 */
	submitMinigamePart(minigameId, minigamePartIndex, minigamePart, numberOfParts) {
		return new Promise((resolve, reject) => {
			apiHelper('group/submit-minigame-part', {
				userTokenId: this.props.userTokenId,
				groupId: this.props.userId,
				minigameId: minigameId,
				minigamePartIndex: minigamePartIndex,
				minigamePart: minigamePart,
				numberOfParts: numberOfParts
			}).then(
				()=>{
					resolve({status: 'ok'});
				},
				(rejection) => {
					if (rejection.error && rejection.error === 'alreadyCompleted') {
						resolve({status: 'ok'});
						let popupData = JSON.parse(JSON.stringify(popupsData.minigamePartAlreadyCompleted));
						let groupName = cratersData.filter((crater) => {
							return crater.id === this.state.group.craterId;
						})[0].name;
						popupData.title = popupData.title.replace('%groupName%', groupName);
						this.props.openPopup(popupData, []);
					} else {
						if (rejection.error && rejection.error === 'auth-failed') {
							this.props.openAuthErrorPopup();
						} else {
							this.props.openServerErrorPopup();
						}
						reject({status: 'error', error: rejection});
					}
				}
			);
		});
	}

	/**
	 * Show tag base popup
	 * @param {string} sessionId 
	 */
	showTagBasePopup(sessionId) {
		let popupData = JSON.parse(JSON.stringify(popupsData.tagBaseElement));
		let groupName = cratersData.filter((crater) => {return crater.id === this.state.group.craterId;})[0].name;
		popupData.title = popupData.title.replace('%groupName%', groupName);
		let formSubmitBtn = {
			text: popupData.buttonTexts.submit,
			type: 'button',
			action: this.handleTagBase,
			parameters: [sessionId],
			showLoadingIcon: false
		}; 
		popupData.form.push({
			type: 'submit',
			text: popupData.buttonTexts.submit,
			button: formSubmitBtn
		});
		this.props.openPopup(popupData, [], 'tagBase');	
	}

	/**
	 * Tag base element
	 * @param {string} sessionId 
	 */
	handleTagBase(sessionId) {
		let tagName = this.props.popupFormData.tagName;
		if (tagName.trim().length === 0) return;

		this.props.updatePopupState({isLoading: true});
		// let error = verifyTextInput(tagName, 20);
		// let error = null;
		// if (error) {
		// 	let errorMsg = errorTexts[errorFeedbackHelper(error)];
		// 	this.props.updatePopupState({errorMessage: errorMsg, isLoading: false});
		//	return
		// }

		let sessions = JSON.parse(JSON.stringify(this.state.game.sessions));
		let sessionIndex = sessions.findIndex((session) => {return session.id === sessionId;});

		if (sessionIndex >= 0 && this.state.game.id) {
			sessions[sessionIndex].tagName = tagName;
			sessions[sessionIndex].winningGroupCanTag = false;

			apiHelper('group/submit-tag-name', {
				userTokenId: this.props.userTokenId,
				gameId: this.state.game.id,
				sessions: sessions,
				lastTaggedSessionId: sessions[sessionIndex].id,
			}).then(
				() => {
					this.props.updatePopupState({isLoading: false});
					this.props.closePopup();
				},
				(rejection) => {
					this.props.updatePopupState({isLoading: false});

					if (rejection.error && rejection.error === 'alreadyCompleted') {
						let popupData = JSON.parse(JSON.stringify(popupsData.minigamePartAlreadyCompleted));
						let groupName = cratersData.filter((crater) => {
							return crater.id === this.state.group.craterId;
						})[0].name;
						popupData.title = popupData.title.replace('%groupName%', groupName);
						this.props.openPopup(popupData, []);
					} else {
						if (rejection.error && rejection.error === 'auth-failed') {
							this.props.openAuthErrorPopup();
						} else {
							this.props.openServerErrorPopup();
						}
					}
				}
			);
		}
	}



	/**
	 * Render group component
	 */
	render() {
		if (this.state.isLoading) return <GameLoading />;

		/* Game overview page */
		let isOverviewPage = this.props.page && this.props.pageId.includes('overview');
		if (isOverviewPage) {
			let storyIndex = 0;
			if (this.props.pageId.includes('-')) [, storyIndex] = this.props.pageId.split('-');
			return (
				<React.Fragment>
					<CameraController 
						game={this.state.game}
						page={this.props.page}
						toggleBase={this.props.toggleBase}
						toggleWaveSimulator={this.props.toggleWaveSimulator}
						stories={this.props.stories}
					/>
					<OverviewController
						isTeacher={false}
						storyIndex={parseInt(storyIndex)}
						game={this.state.game}
						stories={this.props.stories}
						goToSession={this.goToSession}
						openPopup={this.props.openPopup}
					/>
					<BaseController
						isTeacher={false}
						game={this.state.game}
						group={this.state.group}
						toggleBase={this.props.toggleBase}
						showTagBasePopup={this.showTagBasePopup}
						baseIsOpen={this.props.showBase}
						basePopupIsOpen={this.props.showBasePopup}
						updateGroup={this.updateGroup}
						stories={this.props.stories}
						page={this.props.page}
						openedFromOverview={true}
					/>
				</React.Fragment>
			);
		}

		/* Game page */
		if (this.props.page && this.props.sessionData) {
			// let isActiveSession = (
			// 	this.state.game.activeSessionId && 
			// 	this.state.game.activeSessionId === this.props.sessionData.id
			// );

			// let activeSessionId = null;
			// if (this.state.game.sessions.some((session) => {return session.status === 'active';})) {
			// 	activeSessionId = this.state.game.sessions.filter((session) => {
			// 		return session.status === 'active';
			// 	})[0].id;
			// }
			
			// let sessionStatus = 'ready';
			// if (this.state.game.sessions.some((gameSession) => {
			// 	return gameSession.id === this.props.sessionData.id;
			// })) {
			// 	sessionStatus = this.state.game.sessions.filter((gameSession) => {
			// 		return gameSession.id === this.props.sessionData.id;
			// 	})[0].status;
			// }
			// let sessionData = Object.assign({}, this.props.sessionData);
			// sessionData.status = sessionStatus;
			// let isActiveSession = sessionStatus === 'active';

			/* Session status */
			let sessionStatus = getStatusOfSession(this.props.sessionData.id, this.state.game);
			let sessionData = Object.assign({}, this.props.sessionData, {status: sessionStatus});

			let waveSimulator = this.props.minigames.filter((minigame) => {
				return minigame.id === 'waveSimulator-1';
			})[0];

			return (
				<React.Fragment>
					<Menu 
						isTeacher={false} 
						page={this.props.page}
						sessionData={sessionData}
						game={this.state.game}
						showPage={this.props.showPage}
					/>
					<MissionPanel 
						isTeacher={false}
						// isActiveSession={isActiveSession} // NOTE: for now, always true for groups
						page={this.props.page}
						sessionData={sessionData}
						minigames={this.props.minigames}
						game={this.state.game}
						group={this.state.group}
						showPage={this.props.showPage}
						updateGroup={this.updateGroup}
						submitMinigamePart={this.submitMinigamePart}
						openPopup={this.props.openPopup}
						closePopup={this.props.closePopup}
					/>
					<CameraController 
						game={this.state.game}
						page={this.props.page}
						toggleBase={this.props.toggleBase}
						toggleWaveSimulator={this.props.toggleWaveSimulator}
						stories={this.props.stories}
					/>
					<BaseController 
						isTeacher={false}
						game={this.state.game}
						group={this.state.group}
						toggleBase={this.props.toggleBase}
						showTagBasePopup={this.showTagBasePopup}
						baseIsOpen={this.props.showBase}
						basePopupIsOpen={this.props.showBasePopup}
						updateGroup={this.updateGroup}
						stories={this.props.stories}
						page={this.props.page}
						openedFromOverview={false}
						fadeInBase={this.state.fadeInBase}
					/>
					{this.props.page.storyIndex === 3 && this.props.page.missionIndex === 1
						&& <WaveSimulatorController
							isTeacher={true}
							page={this.props.page}
							game={this.state.game}
							group={this.state.group}
							updateGame={this.updateGame}
							updateGroup={this.updateGroup}
							waveSimulator={waveSimulator}
							showWaveSimulator={this.props.showWaveSimulator}
							toggleWaveSimulator={this.props.toggleWaveSimulator}
						/>
					}
				</React.Fragment>
			);
		}

		/* Unknown page */
		return <div>Unknown page: {this.props.pageId}.</div>;
	}
}

GroupController.propTypes = {
	userId: PropTypes.string.isRequired,
	userTokenId: PropTypes.string.isRequired,
	pageId: PropTypes.string.isRequired,
	showBase: PropTypes.bool.isRequired,
	showBasePopup: PropTypes.bool.isRequired,
	page: PropTypes.object,
	sessionData: PropTypes.object,
	stories: PropTypes.array.isRequired,
	minigames: PropTypes.array.isRequired,
	toggleBase: PropTypes.func.isRequired,
	showPage: PropTypes.func.isRequired,
	openAuthErrorPopup: PropTypes.func.isRequired,
	openServerErrorPopup: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	popupFormData: PropTypes.object.isRequired,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	updatePopupState: PropTypes.func.isRequired,
	toggleWaveSimulator: PropTypes.func.isRequired,
	showWaveSimulator: PropTypes.bool.isRequired
};


export default GroupController;