import React from 'react';
import PropTypes from 'prop-types';
import BaseElement from '../base/base-element';
import {baseData} from '../../data/base-data';
import './camera-base.scss';

const CameraBase = ({elements, missionId, type}) => {
	let vehicleSessionStatus = elements
		.find((element) => {return element.missionId === 'mission-5-2';}).status;
	
	let mission = baseData.find((mission) => {return mission.id === missionId;});
	let cameraLocation = mission ? mission.tagId : '';

	return (
		<div className={'CameraBaseContainer CameraBaseContainer--' + type}>
			<div className={'CameraBase'
				+ ' CameraBase--' + type
				+ ' CameraBase--' + cameraLocation
				+ (vehicleSessionStatus === 'repaired' ? ' Base--repaired' : '')}>
				{elements.map((element) => {
					return <BaseElement key={element.id} element={element} />;
				})}
			</div>
		</div>
	);
};

CameraBase.propTypes = {
	type: PropTypes.string.isRequired,
	elements: PropTypes.array.isRequired,
	missionId: PropTypes.string.isRequired,
};

export default CameraBase;
