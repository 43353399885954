import React from 'react';
import PropTypes from 'prop-types';
import './navigation-panel.scss';

const NavigationPanel = ({isTeacher, page, sessionData, showPage}) => {
	return (
		<div className="NavigationPanel">
			<div className="NavigationPanel-navTabBottomLine" />
			{sessionData.missionSessions.map((session, sessionIndex) => {
				let className = 'NavigationPanel-navTab NavigationPanel-navTab--' + (sessionIndex + 1) +
					(page.sessionIndex === sessionIndex ? ' NavigationPanel-navTab--selected' : '') +
					(!isTeacher && page.sessionIndex !== sessionIndex ? ' NavigationPanel-navTab--locked' : '');
				return (
					<div key={sessionIndex} className={className} onClick={()=>{
						if (session.pageIds && session.pageIds.length > 0 && isTeacher) showPage(session.pageIds[0]);
					}}>
						<div className="NavigationPanel-navTabBackground">
							<div className="NavigationPanel-navTabBorder" />
						</div>
						
						<div className="NavigationPanel-navTabText">{session.title}</div>
					</div>
				);
			})}
		</div>
	);
};


NavigationPanel.propTypes = {
	isTeacher: PropTypes.bool.isRequired,
	page: PropTypes.object.isRequired,
	sessionData: PropTypes.object.isRequired,
	showPage: PropTypes.func.isRequired,

};

export default NavigationPanel;
