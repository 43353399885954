import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {DndProvider} from 'react-dnd';
import TouchBackend from 'react-dnd-touch-backend';
import {popupsData} from 'data/popups-game-data';
import Puzzle from './puzzle';

class PuzzleController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			warningText: null,
			attempts: 0,
			currentPieceIndex: 0
		};
		this.updatePuzzlePart = this.updatePuzzlePart.bind(this);
		this.handleCheckSolution = this.handleCheckSolution.bind(this);
		this.showResultPopup = this.showResultPopup.bind(this);
		this.goToPuzzlePiece = this.goToPuzzlePiece.bind(this);
	};

	componentDidUpdate(prevProps, _) {
		if (prevProps.puzzle.puzzleId !== this.props.puzzle.puzzleId) {
			this.setState({ attempts: 0 });
		}
	}

	showResultPopup(nrOfCorrectAnswers, totalAnswers, puzzleCompletionType) {
		let popupData = JSON.parse(JSON.stringify(popupsData[puzzleCompletionType]));
		popupData.text = popupData.text.replace('%correctAnswers%', nrOfCorrectAnswers);
		popupData.text = popupData.text.replace('%totalAnswers%', totalAnswers);
		let btnConfig = [{
			text: popupData.buttonTexts.ok,
			type: 'button',
			action: this.props.closePopup,
			parameters: []
		}];
		this.props.openPopup(popupData, btnConfig);
	}

	handleCheckSolution(areas) {
		this.setState({attempts: this.state.attempts + 1}, () => {
			let nrOfCorrectAnswers = areas.filter((area) => {return area.pieceIds.includes(area.placed);}).length;
			if (nrOfCorrectAnswers === 0) {
				this.showResultPopup(nrOfCorrectAnswers, areas.length, 'puzzleWrong');
			} else if (nrOfCorrectAnswers !== areas.length) {
				this.showResultPopup(nrOfCorrectAnswers, areas.length, 'puzzleIncomplete');
			} else {
				let puzzle = JSON.parse(JSON.stringify(this.props.puzzle));
				let attempts = this.state.attempts;
				let reward = null;
				if (attempts === 1) {
					reward = puzzle.reward;
				} else if (attempts === 2) {
					reward = puzzle.reward - 10;
				} else if (attempts === 3) {
					reward = puzzle.reward - 25;
				} else if (attempts === 4) {
					reward = puzzle.reward - 50;
				} else {
					reward = puzzle.reward - attempts * 15;
					if (reward < 0) reward = 0;
				}
				puzzle.areas = areas;
				puzzle.reward = reward;
				puzzle.completed = true;
				this.showResultPopup(nrOfCorrectAnswers, areas.length, 'puzzleCompleted');
				this.updatePuzzlePart(puzzle);
			}
		});
	}

	/**
	 * Puzzle completed
	 */
	updatePuzzlePart(puzzle) {
		this.setState({isLoading: true}, () => {
			this.props.updateMinigamePart(this.props.minigamePartIndex, puzzle).then(
				() => {
					this.setState({isLoading: false});
				}, 
				(error) => {
					console.log('error: ', error);
					this.setState({isLoading: false});
				}
			);
		});
	}

	/**
	 * Puzzle piece navigation for puzzle 17 (graphs navigation)
	*/
	goToPuzzlePiece(currentPieceIndex) {
		this.setState({currentPieceIndex});
	}

	/**
	 * Render puzzle component
	 */
	render() {
		return (
			<DndProvider backend={TouchBackend} options={{enableMouseEvents: true}}>
				<Puzzle 
					isTeacher={this.props.isTeacher}
					isCompleted={this.props.puzzle.completed}
					minigameStatus={this.props.minigameStatus}
					puzzleId={this.props.puzzle.puzzleId}
					puzzleTitle={this.props.puzzle.name}
					puzzleInfo={this.props.puzzle.info}
					inifiniteNumberOfPieces={this.props.puzzle.inifiniteNumberOfPieces}
					piecesHaveLabels={this.props.puzzle.piecesHaveLabels}
					areasHaveLabels={this.props.puzzle.areasHaveLabels}
					pieces={this.props.puzzle.pieces} 
					areas={this.props.puzzle.areas}
					handleCheckSolution={this.handleCheckSolution}
					attempts={this.state.attempts}
					goToPuzzlePiece={this.goToPuzzlePiece}
					currentPieceIndex={this.state.currentPieceIndex}
				/>
			</DndProvider>
		);
	}
}

PuzzleController.propTypes = {
	isTeacher: PropTypes.bool.isRequired,
	minigameStatus: PropTypes.string.isRequired,
	puzzle: PropTypes.object,
	minigamePartIndex: PropTypes.number.isRequired,
	updateMinigamePart: PropTypes.func.isRequired,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired
};

export default PuzzleController;