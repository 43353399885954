const connectionStatusUiTexts = {
	title: 'INTERNET CONNECTION LOST',
	text: 'I har mistet forbindelsen til internettet. \n \n Forbind igen for at komme tilbage til spillet.'
};

const generalUiTexts = {
	title: 'Marsbasen',
	group: 'Gruppe',
	camera: 'Kamera',
	activateTagging: 'Aktivér tagging',
	goToNextSession: 'Gå til næste spilgang',
	loginInfo: 'Eleverne skal gå til siden og logge på med koden',
	url: 'URL',
	code: 'Kode',
	demoInfo: 'Her logger eleverne på ved at gå ind på en webadresse og indtaste en unik kode.\n \n Du gennemser spillet og kan derfor ikke invitere elever.'
};

const loginUiTexts = {
	teacher: 'Lærer',
	login: 'login',
	placeholderEmail: 'Email',
	placeholderPassword: 'Password',
	resetPassword: 'Glemt dit password?',
	loginBtn: 'Log ind',
	createUserMessage: 'Har du ikke en brugerprofil?',
	createUserButton: 'Opret bruger',
	
	titleCreateUser: 'Opret bruger',
	placeholderRepeatPassword: 'Gentag password',
	createUserBtn: 'Opret bruger',
	createUserFeedback: 'Du er oprettet som bruger!',
	passwordResetFeedback: 'Et link til at nulstille dit password er blevet sendt til din mail.',
	goToLoginBtn: 'Tilbage til Log ind',
	
	titleResetPassword: 'Nulstil password',
	newPassword: 'Indtast din e-mail og klik send for at nulstille dit password.',
	newPasswordBtn: 'Send',

	group: 'Elev',
	groupLoginPlaceholder: 'Spilkode',

	loading: 'Loading',
	
	logoutBtn: 'Log ud',

	cookies: {
		text: 'Vi bruger cookies til at forbedre din spiloplevelse. Ved at klikke på \'OK\' eller logge ind accepterer du vores brug af cookies.',
		more: 'Læs mere',
		btn: 'Ok',
	}
};

const popupUiTexts = {
	invitedTeachers: 'Inviterede lærere',
	noTeachers: 'Du har ikke inviteret nogen lærere endnu.'
}


const settingsUiTexts = {
	myGames: 'Mine spilforløb',
	myGuestGames: 'Mine gæstespil',
	createGame: 'Opret nyt spilforløb',
	browseGame: 'Se eksempel på spil',
	downloadManual: 'Lærervejledning',
	gameName: 'Spilnavn:',
	code: 'Kode:',
	started: 'Startet:',
	goToGame: 'Gå til spil',
	continueGame: 'Fortsæt spil',
	loggedInAs: 'Du er logget ind som'
};

const overviewUiTexts = {
	objectives: 'Objektiver',
	objective: 'Objektiv',
	coreMaterial: 'Kernestof:',
	nan: 'n/a',
	minutes: 'min'
}

const evaluationUiTexts = {
	save: 'Gem',
	saveEvaluation: 'Bekræft evaluering',
};

const resultUiTexts = {
	session: 'Spilgang',
	total: 'I alt',
	sessionNotStarted: 'Spilgangen er ikke startet, og der er ingen resultater at vise...'
}

const baseUiTexts = {
	save: 'Gem',
	mission: 'MISSION: ',
	baseLocation: 'Base location: ',
	missionStatus: 'Mission status: ',
	goal: 'Goal: ',
	ready: 'Not started',
	open: 'Incomplete',
	closed: 'Completed'
}

const storyUiTexts = {
	missionControlStart: 'BESKED FRA MISSION CONTROL',
	missionControlEnd: 'BESKED SLUT',
	shipLogStart: 'UDDRAG FRA SKIBSLOG',
	shipLogEnd: null
}

const minigamesUiTexts = {
	play: 'Spil',
	browseMinigame: 'Gennemse',
	next: 'Næste',
	finish: 'Afslut',
	startMinigame: 'Giv eleverne adgang til at spille',
	stopMinigame: 'Luk for elevernes spiladgang',
	result: 'RESULTAT',
	point: 'Point',
	minigameNotStarted: 'Spilgangen er ikke startet, og der er ingen resultater at vise...'
};

const puzzleUiTexts = {
	answer: 'Svar',
};

const investigationUiTexts = {
	title: 'Skriv en overskrift',
	text: 'Tekst',
	save: 'Gem',
	upload: 'Upload',
	openInput: 'Åbn for input',
	closeInput: 'Luk for input',
	groups: 'grupper',
	back: 'Tilbage',
	next: 'Næste',
	noSubmissionsPart1: 'Afventer input fra eleverne. Når alle grupper har delt deres input, skal du lukke for input for at redigere og udvælge dem, der skal arbejdes videre med.',
	noSubmissionsSelected: 'Du har ikke valgt nogen nogen af gruppernes input.',
	noSubmissionsPart2: 'Afventer input fra eleverne. Når alle grupper har delt deres input skal du lukke for input, for at redigere og udvælge dem der skal arbejdes videre med.'
}

// const cyoaUiTexts = {
// 	nrOfPeople: 'Personer ombord',
// 	nrOfSuits: 'Antal dragter',
// 	energy: 'Energi',
// 	engine: 'Motor',
// 	sensors: 'Sensorer',
// 	navigation: 'Navigation',
// 	timeSpent: 'Tid brugt',
// 	on: 'tændt',
// 	off: 'slukket'
// }

const cyoaUiTexts = {
	time: 'Tid brugt: ',
	timeMeasure: ' timer',
	timeTotal: ' / 55 timer',
	oxygen: 'Oxygen tilbage: ',
	oxygenMeasure: 'liter',
	energy: 'Energi tilbage: ',
	energyMeasure: 'kWh',
	distance: 'Afstand til basen: ',
	distanceMeasure: 'km'
}

const waveSimulatorUiTexts = {
	playWave: 'Spil! (Bølgesimulator)',
	playInterference: 'Spil! (Wave Interference)',
	light: 'Lys',
	sound: 'Lyd',
	start: 'START',
	stop: 'STOP',
	frequency: 'Frekvens: ',
	wavelength: 'Bølgelængde: ',
	velocity: 'Hastighed: ',
	velocityUnit: 'm/s',
	temperature: 'Temperatur: ',
	waveType: 'Bølgetype',
	atmosphere: 'Atmosfære',
	environments: {
		earth: 'Jorden',
		mars: 'Mars',
		space: 'Rummet'
	},
	frequencyUnit: {
		light: 'THz',
		sound: 'Hz'
	},
	wavelengthUnit: {
		light: 'nm',
		sound: 'cm'
	},
	temperatureUnit: {
		light: 'K',
		sound: 'K'
	}
}

const controlPanelUiTexts = {
	ready: 'Start spilgang',
	open: 'Startet',
	closed: 'Afsluttet'
};

const landingPageUiTexts = {
	developedBy: 'Udviklet af:',
	developedWith: 'Udviklet med støtte fra:',
	launch: 'Launch!',
	message:
		'<div><b>UDDRAG FRA SKIBSLOG:</b></div>' +
		`Det er fire måneder siden, vi tog afsted fra Jorden, og om ikke så længe lander vi på Mars. Vi ved stadig ikke helt, hvad vi finder deroppe. Vi ved, at den gamle base stadig står, men vi ved ikke, hvad der skete. I løbet af de få dage hvor der ikke var direkte kontakt til Mars fra Jorden, mistede vi kontakten til alle 51 personer. Og vi har hverken hørt fra dem eller fundet billeder af dem siden.</br></br>
		Der er masser af teorier. Sygdom, nedbrud af life support, masseselvmord, støvstorm. Der er i hvert fald spor efter vildt vejr i de dage, og der er masser af bygninger, vi ikke kan se ind i. Måske ligger de alle sammen og venter på os, når vi lander.

		Men denne gang bliver det anderledes. Vi er kommet langt de sidste 40 år. Vores teknologi er bedre, vi har erfaring fra månebasen, og frem for alt er vi flere. Denne gang er vi 100 personer, og det kommer til at skabe et mere stabilt samfund. Udover dette er flere på vej. Allerede få måneder efter vi er landet, ankommer de næste 30, og derfra skal der støt komme flere.

		Vi er forskere og udforskere. Vi står over for et af de største projekter i menneskehedens historie. Men vi er også tilflyttere. For selvom det teoretisk set er muligt at flyve tilbage til Jorden, så har vi alle tænkt, at vi skal bo på Mars resten af livet. Jeg glæder mig.`
}

const statsUiTexts = {
	email: 'Email',
	games: 'Antal spil',
	created: 'Oprettet',
	teachers: 'Lærere:',
	overview: 'Overview',
	title: 'Statistik',
	goback: 'tilbage',
	loading: 'Loader',
	buttontext: 'download emails as csv.',
}

export {
	baseUiTexts,
	overviewUiTexts,
	connectionStatusUiTexts,
	generalUiTexts,
	puzzleUiTexts,
	loginUiTexts,
	minigamesUiTexts,
	popupUiTexts,
	settingsUiTexts,
	evaluationUiTexts,
	storyUiTexts,
	investigationUiTexts,
	cyoaUiTexts,
	resultUiTexts,
	waveSimulatorUiTexts,
	controlPanelUiTexts,
	landingPageUiTexts,
	statsUiTexts,
};