import React from 'react';
import PropTypes from 'prop-types';
import InstructionsController from 'components/instructions/instructions-controller';
import Graph from 'components/graph/graph';
import './atom-reactor.scss';

const AtomReactor = ({
	cyoaGame,
	isTeacher,
	updateGame,
	updateGroup,
	group,
	game,
	toggleCyoaGame,
	day,
	reactorTemp,
	reactorTempOld,
	heatExchangerTemp,
	heatExchangerTempOld,
	coolingTowerTemp,
	coolingTowerTempOld,
	backupCoolant,
	primaryCoolant,
	secondaryCoolant,
	warnings,
	damages,
	energy,
	totalEnergy,
	fuel,
	controlRods,
	backupPumpPower,
	primaryPumpPower,
	secondaryPumpPower,
	backupFlow,
	primaryFlow,
	secondaryFlow,
	handleRangeChange,
	isDiagramShown,
	toggleDiagram,
	goToNextDay,
	meltdown,
	fuelCycleEnd,
	resetGame,
	toggleRepair,
	isRepairing,
	damage,
	makeRepairs,
	reactorTempValues,
	heatExchangerTempValues,
	coolingTowerTempValues,
	energyValues,
	dayValues,
	showOverlay
}) => {
	let graphs = (
		<React.Fragment>
			{/* Reactor temperature */}
			<Graph
				yValues={reactorTempValues}
				xValues={dayValues}
				yTitle={'Reaktor T. (°C)'}
				xTitle={'Dage'}
				dataType={'atomReactor'}
			/>

			{/* Heat exchanger temperature */}
			<Graph
				yValues={heatExchangerTempValues}
				xValues={dayValues}
				yTitle={'Varmeveksler T. (°C)'}
				xTitle={'Dage'}
				dataType={'atomReactor'}
			/>


			{/* Cooling tower temperature */}
			<Graph
				yValues={coolingTowerTempValues}
				xValues={dayValues}
				yTitle={'Køletårn T. (°C)'}
				xTitle={'Dage'}
				dataType={'atomReactor'}
			/>


			{/* Energy */}
			<Graph
				yValues={energyValues}
				xValues={dayValues}
				yTitle={'Energi (kW)'}
				xTitle={'Dage'}
				dataType={'atomReactorEnergy'}
			/>
		</React.Fragment>
	);

	let content = (
		<div className="AtomReactor-content">
			<div className="Column">
				<div className="Temperature">
					<b>Dag {day}</b><br/>
					<b><u>Driftsoplysninger:</u></b><br/>
					<table>
						<thead>
							<tr>
								<th></th>
								<th>Maks.</th>
								<th>Ændring</th>
								<th>Nuværende</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Reaktor</td>
								<td>800°C</td>
								<td>{reactorTemp - reactorTempOld}°C</td>
								<td>{reactorTemp}°C</td>
							</tr>
							<tr>
								<td>Varmeveksler</td>
								<td>500°C</td>
								<td>{heatExchangerTemp - heatExchangerTempOld}°C</td>
								<td>{heatExchangerTemp}°C</td>
							</tr>
							<tr>
								<td>Køletårn</td>
								<td>300°C</td>
								<td>{coolingTowerTemp - coolingTowerTempOld}°C</td>
								<td>{coolingTowerTemp}°C</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div className="CoolantLevels">
					<i><u>Kølevæsker:</u></i><br/>
					<table>
						<thead>
							<tr>
								<th></th>
								<th>Beholdning</th>
								<th>Flow</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Backup-kølevæske</td>
								<td>{backupCoolant} L</td>
								<td>{backupFlow}%</td>
							</tr>
							<tr>
								<td>Primær kølevæske</td>
								<td>{primaryCoolant} L</td>
								<td>{primaryFlow}%</td>
							</tr>
							<tr>
								<td>Sekundær kølevæske</td>
								<td>{secondaryCoolant} L</td>
								<td>{secondaryFlow}%</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div className="Column">
				<b><u>Advarsler:</u></b><br/>
				{warnings.map((warning, i) => {
					return <div key={i}>{warning}</div>;
				})}
				<b><u>Alvorlige skader:</u></b><br/>
				{damages.map((damage, i) => {
					return <div key={i}>{damage}</div>;
				})}
			</div>

			<div className="Column">
				Elektrisk effekt fra turbine (maks. 2000 kW): {energy} kW <br/>
				Gennemsnitlige effekt: {day > 1 ? Math.floor(totalEnergy / (day - 1)) : 0} kW <br/>
				Tilbageværende brændsel (fuel level): {100 - Math.floor(fuel)} % <br/>
				<p>
					Indstil kontrolstave: <br/>
					<input type="range" name="controlRods" min="0" max="100" value={controlRods}
						onChange={(e) => {handleRangeChange(e);}} /> {controlRods}<br/>
				</p>
				<p>
					Indstil pumpekraft i backup-kølesystemet: <br/>
					<input type="range" name="backupPumpPower" min="0" max="100" value={backupPumpPower}
						onChange={(e) => {handleRangeChange(e);}} /> {backupPumpPower}<br/>
				</p>
				<p>
					Indstil pumpekraft i det primære kølesystem: <br/>
					<input type="range" name="primaryPumpPower" min="0" max="100" value={primaryPumpPower}
						onChange={(e) => {handleRangeChange(e);}} /> {primaryPumpPower}<br/>
				</p>
				<p>
					Indstil pumpekraft i det sekundære kølesystem: <br/>
					<input type="range" name="secondaryPumpPower" min="0" max="100" value={secondaryPumpPower}
						onChange={(e) => {handleRangeChange(e);}} /> {secondaryPumpPower}<br/>
				</p>

				<div className="AtomReactor-btn" onClick={() => {goToNextDay();}}>
					Gå til næste dag
				</div>
				<div className="AtomReactor-btn" onClick={() => {toggleRepair(true);}}>
					Foretag reparation af kraftværket
				</div>
				<div className="AtomReactor-btn" onClick={() => {toggleDiagram(true);}}>
					Se diagram over kraftværket
				</div>
			</div>
		</div>
	);

	if (isDiagramShown) {
		graphs = null;
		content = (
			<div className="AtomReactor-content">
				<div className="AtomReactor-diagram">
					<div className="AtomReactor-gif" />
					<div className="AtomReactor-btn" onClick={() => {toggleDiagram(false);}}>
						Gå tilbage til statusrapporten
					</div>
				</div>
			</div>
		);
	}

	if (isRepairing) {
		graphs = null;
		let repairText = null;
		if (damage === 0) {
			repairText = (
				<div>
					Der er ingen skader på kraftværket.<br/>
					<div className="AtomReactor-btn inline" onClick={() => {toggleRepair(false);}}>
						Gå tilbage til statusrapporten
					</div> og start kraftværket.
				</div>
			);
		} else if (reactorTemp < 100 && damage > 0) {
			repairText = (
				<div>
					I har lukket kraftværket midlertidigt ned og repareret alle delene. Dvs alle kølevæsker er fyldte
					og alle lækager er lukkede.<br/>
					<div className="AtomReactor-btn inline" onClick={() => {makeRepairs();}}>
						Få en ny statusrapport
					</div> og iværksæt operation af kraftværket.
				</div>
			);
		} else {
			repairText = (
				<div>
					Temperaturen i reaktoren er over 100°C., og det er ikke muligt at foretage reparationer
					før I har bragt temperaturen ned.<br/>
					<div className="AtomReactor-btn inline" onClick={() => {toggleRepair(false);}}>
						Gå tilbage til statusrapporten
					</div> og foretag ændringer i forsøg på at sænke reaktortemperaturen.
				</div>
			);
		}
		content = (
			<div className="AtomReactor-content">
				{repairText}
			</div>
		);
	}

	if (meltdown) {
		content = (
			<div className="AtomReactor-content">
				Glem alt om status rapporten. Reaktorkernen i jeres atomreaktor er nedsmeltet pga. den høje temperatur!
				<br/>
				Diskutér hvad I kunne have gjort for at forhindre nedsmeltningen.<br/>
				Hvis I vil prøve simuleringen forfra kan I trykke
				<div className="AtomReactor-btn inline margin-left" onClick={() => {resetGame();}}>her</div>.
			</div>
		);
	}

	if (fuelCycleEnd) {
		content = (
			<div className="AtomReactor-content">
				Brændselsstavene i reaktoren er udtømte. Dvs. der ikke kan dannes tilstrækkeligt med energi fra dem nu,
				og simuleringen er ovre. I har opereret kraftværket over {day} dage med en gennemsnitlig effekt på
				{Math.floor(totalEnergy / day)} kW per dag fra dampturbinen. Med næste launch vindue om 17 måneder,
				og denne simulering som en indikator for hvor meget energi I kan producere,
				vurderer I så at raketten kan nå at fyldes med brændstof?
			</div>
		);
	}

	return (
		<div className={'AtomReactor AtomReactor--' + (showOverlay ? 'showOverlay' : '')}>
			{cyoaGame.instructions &&
				<InstructionsController
					isTeacher={isTeacher}
					page={{type: 'cyoa', instructions: cyoaGame.instructions, id: cyoaGame.id}}
					iconType="info"
					updateGroup={updateGroup}
					updateGame={updateGame}
					group={group}
					game={game}
					type='popup'
				/>}

			{/* Load graphs for status report, meltdown and fuelCycleEnd screens */}
			{graphs}

			{/* Load specific content (status report, diagram, repair, meltdown, fuelCycleEnd) */}
			{content}

			<div className="AtomReactor-close" onClick={() => {toggleCyoaGame(false);}} />
		</div>
	);
};

AtomReactor.propTypes = {
	toggleCyoaGame: PropTypes.func.isRequired,
	isTeacher: PropTypes.bool.isRequired,
	cyoaGame: PropTypes.object.isRequired,
	updateGroup: PropTypes.func,
	updateGame: PropTypes.func,
	group: PropTypes.object,
	game: PropTypes.object.isRequired,
	day: PropTypes.number.isRequired,
	warnings: PropTypes.array.isRequired,
	damages: PropTypes.array.isRequired,
	energy: PropTypes.number.isRequired,
	totalEnergy: PropTypes.number.isRequired,
	fuel: PropTypes.number.isRequired,
	controlRods: PropTypes.number.isRequired,
	backupPumpPower: PropTypes.number.isRequired,
	primaryPumpPower: PropTypes.number.isRequired,
	secondaryPumpPower: PropTypes.number.isRequired,
	backupFlow: PropTypes.number.isRequired,
	primaryFlow: PropTypes.number.isRequired,
	secondaryFlow: PropTypes.number.isRequired,
	handleRangeChange: PropTypes.func.isRequired,
	isDiagramShown: PropTypes.bool.isRequired,
	toggleDiagram: PropTypes.func.isRequired,
	goToNextDay: PropTypes.func.isRequired,
	reactorTemp: PropTypes.number.isRequired,
	reactorTempOld: PropTypes.number.isRequired,
	heatExchangerTemp: PropTypes.number.isRequired,
	heatExchangerTempOld: PropTypes.number.isRequired,
	coolingTowerTemp: PropTypes.number.isRequired,
	coolingTowerTempOld: PropTypes.number.isRequired,
	backupCoolant: PropTypes.number.isRequired,
	primaryCoolant: PropTypes.number.isRequired,
	secondaryCoolant: PropTypes.number.isRequired,
	meltdown: PropTypes.bool.isRequired,
	fuelCycleEnd: PropTypes.bool.isRequired,
	resetGame: PropTypes.func.isRequired,
	toggleRepair: PropTypes.func.isRequired,
	isRepairing: PropTypes.bool.isRequired,
	damage: PropTypes.number.isRequired,
	makeRepairs: PropTypes.func.isRequired,
	reactorTempValues: PropTypes.array.isRequired,
	heatExchangerTempValues: PropTypes.array.isRequired,
	coolingTowerTempValues: PropTypes.array.isRequired,
	energyValues: PropTypes.array.isRequired,
	dayValues: PropTypes.array.isRequired,
	showOverlay: PropTypes.bool.isRequired
};


export default AtomReactor;
