import React from 'react';
import PropTypes from 'prop-types';
import InstructionsController from 'components/instructions/instructions-controller';
import Button from 'components/button/button';
import './lips.scss';

const Lips = ({
	toggleInfoMinigame,
	isTeacher,
	lipsGame,
	updateGame,
	updateGroup,
	group,
	game,
	selectedImage,
	selectImage,
	currentStep,
	goToStep,
	selectedGridCoordinates,
	selectCoordinate,
	deleteCoordinate,
	showGraph,
	prevSelectedCoordinates
}) => {
	let graphFilename = '';
	if (isNaN(parseInt(selectedGridCoordinates.substring(0, 1)))) {
		graphFilename += selectedGridCoordinates + '.png';
	} else {
		graphFilename = selectedGridCoordinates.substring(1, 2) + selectedGridCoordinates.substring(0, 1) + '.png';
	}
	return (
		<div className="Lips">
			{lipsGame.instructions &&
				<InstructionsController
					isTeacher={isTeacher}
					page={{type: 'infoMinigame', instructions: lipsGame.instructions, id: lipsGame.id}}
					iconType="info"
					updateGroup={updateGroup}
					updateGame={updateGame}
					group={group}
					game={game}
					type='popup'
				/>}
			<div className="Lips-close" onClick={() => {toggleInfoMinigame(false);}} />
			<div className="Lips-content">
				{/* Step 1 - choose a rock image */}
				{currentStep === 'choose-rock' && <div className="Lips-rockChoices">
					{lipsGame.images.map((img) => {
						return <div key={img.id} className="Lips-rockChoice">
							<img className="Lips-rockChoiceImg" alt="rock"
								src={require('assets/images/infoMinigames/' + lipsGame.id + '/' + img.filename)} />
							<Button class="dark" text={img.title} onClick={() => {selectImage(img);}}/>
						</div>;
					})}
				</div>}
				{/* Step 2 - type in grid coordinates */}
				{currentStep === 'choose-coordinates' && <div className="Lips-squareChoices">
					<div className="Lips-rockGrid">
						<img className="Lips-chosenRock"
							src={require('assets/images/infoMinigames/' + lipsGame.id + '/' + selectedImage.filename)}
							alt="rock" />
						<table>
							<thead>
								<tr>
									<th></th>
									{lipsGame.xAxisValues.map((xVal, i) => {return <th key={i}>{xVal}</th>;})}
								</tr>
							</thead>
							<tbody>
								{lipsGame.yAxisValues.map((yVal, i) => {
									return <tr key={i}>
										<td>{yVal}</td>
										{lipsGame.xAxisValues.map((xVal, i) => {
											let isSelected = xVal + yVal === selectedGridCoordinates
															|| yVal + xVal === selectedGridCoordinates;
											let wasSelected = prevSelectedCoordinates.includes(xVal + yVal)
															|| prevSelectedCoordinates.includes(yVal + xVal);
											return <td key={i} className={(isSelected ? 'selected ' : '')
																		+ (wasSelected ? 'prevSelected ' : '')} />;
										})}
										<td></td>
									</tr>;
								})}
								<tr><td/><td/><td/><td/><td/><td/></tr>
							</tbody>
						</table>
					</div>
					<Button text="Tilbage" class="backBtn"
						onClick={() => {goToStep(lipsGame.steps[lipsGame.steps.indexOf(currentStep) - 1]);}} />
					<div className="Lips-squareSelection">
						<div className="Lips-selections">
							<div>{selectedImage.title}</div>
							<div className="Lips-selectedGridCoordinates">{selectedGridCoordinates}</div>
						</div>
						<Button class="dark deleteBtn" text="Slet" onClick={() => {deleteCoordinate();}}/>
						<div className="Lips-gridCoordinates">
							{lipsGame.xAxisValues.concat(lipsGame.yAxisValues).map((val, i) => {
								return <Button class={'dark coordBtn '
									+ (selectedGridCoordinates.includes(val) ? 'selected ' : '')} text={val}
								onClick={() => {selectCoordinate(val);}} key={i}/>;
							})}
						</div>
						<Button class="dark analyseBtn" text="Analyser"
							onClick={() => {showGraph(selectedGridCoordinates);}}/>
					</div>
				</div>}
				{/* Step 3 - show graph corresponding to coordinates */}
				{currentStep === 'show-graph' && <div className="Lips-showGraph">
					<div className="Lips-selections">
						<div>{selectedImage.title}</div>
						<div className="Lips-selectedGridCoordinates">{selectedGridCoordinates}</div>
					</div>
					<img className="Lips-graphImg"
						src={require('assets/images/infoMinigames/' + lipsGame.id + '/'
							+ selectedImage.id + '-' + graphFilename)}
						alt="graph" />
					<Button text="Tilbage" class="backBtn"
						onClick={() => {goToStep(lipsGame.steps[lipsGame.steps.indexOf(currentStep) - 1]);}} />
				</div>}
			</div>
		</div>
	);
};

Lips.propTypes = {
	toggleInfoMinigame: PropTypes.func.isRequired,
	isTeacher: PropTypes.bool.isRequired,
	lipsGame: PropTypes.object.isRequired,
	updateGame: PropTypes.func,
	updateGroup: PropTypes.func,
	game: PropTypes.object.isRequired,
	group: PropTypes.object,
	selectedImage: PropTypes.object,
	selectImage: PropTypes.func.isRequired,
	currentStep: PropTypes.string.isRequired,
	goToStep: PropTypes.func.isRequired,
	selectedGridCoordinates: PropTypes.string.isRequired,
	selectCoordinate: PropTypes.func.isRequired,
	deleteCoordinate: PropTypes.func.isRequired,
	showGraph: PropTypes.func.isRequired,
	prevSelectedCoordinates: PropTypes.array.isRequired
};

export default Lips;
